<template>
  <div class="at-Loader">
    <div v-for="row in ROWS" :key="row" class="at-Loader_Row">
      <div class="at-Cell">
        <SkeletonItem width="10px" :size="XS" />
      </div>
      <div class="at-Cell">
        <SkeletonItem :width="row" :size="XS" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ApiTokensLoader'
})
</script>

<script setup>
import { SKELETON_SIZES } from '@/utils/components-configurations/skeleton-item'

const { XS } = SKELETON_SIZES

import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

const ROWS = ['75px', '120px', '80px', '260px', '20px']
</script>

<style scoped lang="scss">
.at-Loader_Row {
  height: 45px;
  padding: 0 32px;
  display: flex;
  align-items: center;
}

.at-Cell {
  display: flex;
  align-items: center;

  &:first-child {
    width: 24px;
  }

  &:nth-child(2) {
    flex: 1 1 auto;
  }
}
</style>
