<template>
  <div
    :data-auto-test-inital-data-loaded="initialDataAreLoaded"
    class="of-ObjectiveForm"
    data-auto-testid="objective-form"
  >
    <portal :to="portalName">
      <template v-if="formModel.parentId === null">
        <!-- inline search isn't used, but needed to activate search-related features
        such as height alignment-->

        <LinkToParentButton
          :disabled="isOkrElementClosed"
          class="of-ParentObjectiveTrigger"
          data-auto-testid="link-to-parent-button"
        />
        <AppSelect
          ref="parentObjectiveSelect"
          v-model="formModel.parentId"
          :bottom-fixed-items="getBottomFixedItems"
          :disabled="disableOnEdit"
          :item-icon="getParentObjectiveIconName"
          :item-label="item => `${item.displayId} ${item.name}`"
          :loading="isParentObjectivesLoading || isParentObjectivesLoadingLocal"
          :offset="[0, -44]"
          :options="getObjectivesAsOptions"
          :search-function="getParentObjectives"
          data-auto-testid="parent-field"
          dropdown-search
          item-value="id"
          to-selector=".of-ParentObjectiveTrigger"
          @update:options="$emit('update:parentObjectives', $event)"
          @update:model-value="onParentObjectiveUpdate"
        >
          <template #option-label="{ option }">
            <ObjectiveSelectItem v-if="option" :objective="option" show-interval show-workspace />
          </template>
          <template #bottom-fixed-items="{ bottomFixedItems, searchString }">
            <div v-for="item in bottomFixedItems" :key="item.id">
              <BottomFixedSelectItem v-if="!searchString" icon-name="info-next" no-hover>
                {{ item.text }}
              </BottomFixedSelectItem>
            </div>
          </template>
        </AppSelect>
      </template>
      <template v-else-if="selectedParentObjective">
        <div class="of-ParentObjectiveField_Element">
          <OkrElementsListItem
            :allowed-depth="0"
            :id-as-link="parenObjectiveReadable && isEdit"
            :objective="selectedParentObjective"
            :rotate-toggle-sign="true"
            :show-interval="true"
            data-auto-testid="parent-element"
            show-only-grade-value
            type="secondary"
            @edit-element="$emit('edit-element', $event)"
          >
            <template #actions>
              <AppButton
                v-tippy="{
                  content: $t('action.unlink')
                }"
                v-visible="!disableOnEdit"
                :height="24"
                icon="unlink-from-parent"
                size="sm"
                type="subtle"
                @click.stop="unlinkParentObjective"
              />
            </template>
          </OkrElementsListItem>
        </div>

        <div class="of-ContributeWrapper">
          <AppCheckbox
            v-model="formModel.contribute"
            :disabled="formModel.parentId === null || disableOnEdit"
            data-auto-testid="contribute-switch"
            @update:model-value="onContributeChange"
          >
            {{ $t('field.contribute.label') }}
          </AppCheckbox>
        </div>
      </template>

      <ImmediateParentLoader v-else with-checkbox />
    </portal>

    <SidebarLayout>
      <LockMessage
        v-if="lockedMessagesStates.isShowNoPermissionsMessage"
        :type="LOCK_MESSAGE_TYPES.WARNING"
        class="of-NoPermissionsMessage"
      >
        {{ $t('lock_message', { element: $t('okr_elements.objective') }) }}
      </LockMessage>

      <LockMessage
        v-if="lockedMessagesStates.isShowClosedElementMessage"
        :type="LOCK_MESSAGE_TYPES.INFO"
        class="of-ClosedStatusMessage"
        icon-name="info-next"
      >
        <span>
          {{ $t('lock_message.grade_closed', { status: getOkrElementClosedStatusName }) }}
          <br />
          <span class="of-ClosedStatusMessage_Description">
            {{ $t('lock_message.grade_closed.description') }}
          </span>
        </span>
      </LockMessage>

      <OkrTitleFiled
        ref="title"
        v-model.trim="formModel.name"
        :is-error="isTitleEmpty"
        :placeholder="$t('objective.modal_title.placeholder')"
        :readonly="isFieldDisabled || isOkrElementClosed"
        class="of-Title"
        data-auto-testid="name-field"
        data-testid="name-field"
        @blur="onNameUpdate"
        @update:model-value="isTitleEmpty = $event === ''"
      />

      <DescriptionField
        v-if="isOkrElementClosed ? formModel.description : true"
        ref="description"
        v-model="formModel.description"
        :actions="editable"
        :max-length="17500"
        :placeholder="$t('description.placeholder')"
        :readonly="disableOnEdit"
        :show-placeholder="!disableOnEdit"
        :workspace-id="workspaceId"
        data-auto-testid="description-field"
        data-testid="description-field"
        @cancel="cancelDescriptionEditing"
        @save="saveDescription"
      />

      <LockMessage
        v-if="lockedMessagesStates.isShowClosedParentMessage"
        :type="LOCK_MESSAGE_TYPES.WARNING"
        class="of-LockMessage"
        icon-name="warning-icon"
      >
        <i18n-t keypath="lock_message.entity_parent_closed" scope="global">
          <template #parentOkr>
            {{ getParentOkrTypeName }}
          </template>
          <template #statusName> {{ getParentElementClosedStatusName }}</template>
        </i18n-t>
      </LockMessage>

      <FormNestedItemsField
        :is-expand-all-loading="isExpandAllLoading"
        :show-expand-collapse-button="someItemIsExpandable"
        :show-head="krs.length > 0"
        :title="$t('objective.key_results')"
        class="of-KRList"
        @expand-all="expandAll"
        @collapse-all="collapseAll"
      >
        <template #actions>
          <WeightsModalTrigger
            v-if="showWeightsModalTrigger"
            v-tippy="{
              content: weightTriggerTooltip,
              placement: 'top',
              theme: `${DROP_LIST_THEMES.TRANSLUCENT_NEXT} ${DROP_LIST_THEMES.PADDING_DEFAULT} ${DROP_LIST_THEMES.TEXT_CENTER} ${DROP_LIST_THEMES.WHITE_SPACE_PRE_LINE}`
            }"
            :disabled="isWeightsTriggerDisabled"
            data-auto-testid="custom-weights-button"
            data-testid="custom-weights-button"
            @click="$emit('open-custom-weights', modelValue)"
          />

          <template v-if="allowCreateElements">
            <UseAppPlatform v-slot="{ isJiraApp, isWebApp, isJiraConnected }">
              <template
                v-if="isJiraApp || (isWebApp && isJiraConnected && actionMenuItems.length > 1)"
              >
                <AddNestedItem
                  v-tippy="{
                    placement: 'top',
                    content: $t('objective.add_key_result'),
                    boundary: 'viewport'
                  }"
                  :dropdown-options="actionMenuItems"
                  :follow-cursor="false"
                  data-auto-testid="add-nested-kr"
                  simplify
                  @on-option-click="onActionMenuItemsClick"
                />
              </template>

              <AppButton
                v-else
                v-tippy="{
                  placement: 'top',
                  content: $t('objective.add_key_result'),
                  boundary: 'viewport'
                }"
                icon="plus"
                size="sm"
                type="subtle"
                @click="onKRCreateClick"
              />
            </UseAppPlatform>
          </template>
        </template>

        <OkrElementsList
          ref="childKRs"
          :allow-delete-action="!isOkrElementClosed"
          :allow-unlink-action="!isOkrElementClosed"
          :allowed-depth="1"
          :dim-nested-items-weights="dimNestedItemsWeights"
          :objectives="krs"
          :placement="LIST_ITEM_PLACEMENTS.OKR_ELEMENT_FORM"
          :some-item-is-expandable="someItemIsExpandable"
          always-show-weight
          fetch-child-objectives-on-expand
          item-type="primary-next"
          show-status
          type="default-next"
          @expand-item="expandItem"
          @update-elements="onChildUpdated"
          @okr-element-deleted="onChildUpdated(true)"
          @edit-element="$emit('edit-element', $event)"
          @link-jira-issue="$emit('link-jira-issue', getKrJiraIssuePayload($event))"
          @create-jira-issue="createJiraIssue($event)"
        >
          <template v-if="!krs.length" #no-objectives>
            <UseAppPlatform v-slot="{ isJiraApp, isWebApp, isJiraConnected }">
              <template
                v-if="isJiraApp || (isWebApp && isJiraConnected && actionMenuItems.length > 1)"
              >
                <AddNestedItem
                  :disabled="!allowCreateElements"
                  :dropdown-options="actionMenuItems"
                  :follow-cursor="true"
                  data-auto-testid="add-nested-kr"
                  disable-margin
                  @on-option-click="onActionMenuItemsClick"
                >
                  {{
                    $t(allowCreateElements ? 'action.create_keyResult' : 'objective.no_key_results')
                  }}
                </AddNestedItem>
              </template>

              <AddOkrButton v-else @click="onKRCreateClick">
                {{ $t('action.create_keyResult') }}
              </AddOkrButton>
            </UseAppPlatform>
          </template>
        </OkrElementsList>
      </FormNestedItemsField>

      <FormNestedItemsField
        v-if="isEdit"
        :show-head="otherChildObjectives.length > 0"
        :title="$t('objective.nested_objectives')"
      >
        <template #actions>
          <WeightsModalTrigger
            v-if="showWeightsModalTrigger"
            v-tippy="{
              content: weightTriggerTooltip,
              placement: 'top',
              theme: `${DROP_LIST_THEMES.TRANSLUCENT_NEXT} ${DROP_LIST_THEMES.PADDING_DEFAULT} ${DROP_LIST_THEMES.TEXT_CENTER} ${DROP_LIST_THEMES.WHITE_SPACE_PRE_LINE}`
            }"
            :disabled="isWeightsTriggerDisabled"
            @click="$emit('open-custom-weights', modelValue)"
          />
          <div>
            <!-- ↑ this wrapper is necessary for correct layout -->
            <OkrElementCreator
              v-if="allowCreateElements"
              :follow-cursor="false"
              :objective-levels="objectiveLevelsForCreate"
              :select-options-list-test-id="OBJECTIVE_FORM_OKR_ELEMENT_CREATOR_TEST_ID"
              dropdown-position="left"
              type="default-next"
              @on-create-okr-element="addChildObjective"
            >
              <template #trigger="{ onTriggerClick, showDropdown, triggerSelector }">
                <AddOkrButton
                  v-tippy="{
                    placement: 'top',
                    content: $t('objective.btn.add_nested_objective'),
                    boundary: 'viewport'
                  }"
                  :class="triggerSelector"
                  :is-active="showDropdown"
                  data-auto-testid="add-nested-objective"
                  disable-margin
                  simplify
                  @click="onTriggerClick"
                />
              </template>
            </OkrElementCreator>
          </div>
        </template>

        <OkrElementsList
          :allow-delete-action="!isOkrElementClosed"
          :allow-unlink-action="!isOkrElementClosed"
          :allowed-depth="0"
          :dim-nested-items-weights="dimNestedItemsWeights"
          :fetch-child-objectives-on-expand="false"
          :has-jira-issue-actions="false"
          :objectives="otherChildObjectives"
          :placement="LIST_ITEM_PLACEMENTS.OKR_ELEMENT_FORM"
          always-show-weight
          external-actions-listener
          has-unlink-action
          item-type="primary-next"
          type="default-next"
          @on-menu-item-click="onChildObjectiveMenuActionsClick"
        >
          <template v-if="!otherChildObjectives.length" #no-objectives>
            <OkrElementCreator
              v-if="allowCreateElements"
              :dropdown-width-as-parent="true"
              :follow-cursor="true"
              :objective-levels="objectiveLevelsForCreate"
              :select-options-list-test-id="OBJECTIVE_FORM_OKR_ELEMENT_CREATOR_TEST_ID"
              dropdown-position="bottom"
              type="default-next"
              @on-create-okr-element="addChildObjective"
            >
              <template #trigger="{ onTriggerClick }">
                <AddOkrButton
                  data-auto-testid="add-nested-objective"
                  disable-margin
                  @click="onTriggerClick"
                >
                  {{ $t('objective.btn.add_nested_objective') }}
                </AddOkrButton>
              </template>
            </OkrElementCreator>
            <AddOkrButton v-else data-auto-testid="add-nested-objective" disable-margin disabled>
              {{ $t('objective.btn.no_nested_objectives') }}
            </AddOkrButton>
          </template>
        </OkrElementsList>
      </FormNestedItemsField>

      <OkrElementsFormCustomFieldsHub
        v-model:loading="contentCustomFieldsLoading"
        :data-testid="OKR_ELEMENT_FORM_CUSTOM_FIELD_HUB_TEST_ID"
        :element-id="modelValue.id || null"
        :fields-values="customFieldsValues"
        :is-edit="isEdit"
        :okr-type-id="CUSTOM_FIELDS_OKR_TYPE_IDS.OBJECTIVE.id"
        :user-has-update-access="userCanUpdateCustomFields"
        :workspace-id="workspaceId"
        @update-field-value="onUpdateCustomFieldValue"
        @update-activities="updateActivities"
      />

      <Activities v-if="isEdit" ref="activities" :model-value="modelValue" class="of-Activities" />

      <template #sidebar>
        <div class="of-Sidebar">
          <FormGradeAndStatus
            v-if="isEdit"
            :disabled="isFieldDisabled"
            :form-model="formModel"
            :grade="formModel.gradeToUse"
            :grade-color="modelValue.gradeColor"
            :predicted-score="showPredictedScore ? modelValue.predictedGrade : 0"
            :show-predicted-score="showPredictedScore"
            data-testid="form-grade-and-status"
            @update:confidence-level-id="onStatusUpdate"
          >
            <template #status-right>
              <StatusIndicator
                v-if="showPredictedScore && modelValue.timePassedPercent >= 0"
                :grade="modelValue.gradeToUse"
                :grade-color="modelValue.gradeColor"
                :okr-element="formModel"
                :passed="modelValue.timePassedPercent"
                :predicted-score="modelValue.predictedGrade"
                data-auto-testid="status-indicator"
                data-testid="status-indicator"
              />
            </template>
          </FormGradeAndStatus>

          <div
            v-if="showChart && modelValue.uniqueId"
            class="of-Progress"
            data-auto-testid="progress-chart"
            data-testid="progress-chart"
          >
            <ObjectiveChart
              ref="objectiveChart"
              :element-id="modelValue.uniqueId"
              :grade-color="modelValue.gradeColor"
              :workspace-id="workspaceId"
              chart-type="simple-next"
              has-full-size-toggle
            />
          </div>

          <FormItemsGroup :disabled="disableOnEdit" data-auto-testid="general-fields-group">
            <AppSelect
              v-model="formModel.levelId"
              :disabled="disableOnEdit"
              :loading="isLevelsLoading"
              :offset="[0, 0]"
              :options="objectiveLevelsForCreate"
              data-auto-testid="objective-type-field"
              data-testid="objective-type-field"
              item-label="name"
              item-value="id"
              skeleton-loader
              @update:model-value="onParameterUpdate($options.OKR_PARAMETERS_SAVING_STATUSES.TYPE)"
            >
              <template #button="{ option, active }">
                <OkrFormFieldSelectTriggerNext :opened="active">
                  <OkrTypeFieldOption v-if="option" :option="option" />
                </OkrFormFieldSelectTriggerNext>
              </template>

              <template #option-label="{ option }">
                <OkrTypeFieldOption :option="option" />
              </template>
            </AppSelect>

            <AppSelect
              v-model="formModel.ownerId"
              :disabled="disableOnEdit"
              :loading="assigneesAreLoading"
              :offset="[0, 0]"
              :options="ownerFieldOptions"
              :search-function="getAssignees"
              data-auto-testid="owner-field"
              data-testid="owner-field"
              dropdown-search
              item-label="name"
              item-value="accountId"
              skeleton-loader
              @change="onOwnerSelect"
              @update:options="assignees = $event"
              @update:model-value="onParameterUpdate($options.OKR_PARAMETERS_SAVING_STATUSES.OWNER)"
            >
              <template #button="{ option, active }">
                <OkrFormFieldSelectTriggerNext
                  :empty-state-label="$t('field.owner.placeholder')"
                  :opened="active"
                >
                  <OwnerFieldOption v-if="option" :option="option" />
                </OkrFormFieldSelectTriggerNext>
              </template>
              <template #option-label="{ option }">
                <OwnerFieldOption :option="option" />
              </template>
            </AppSelect>

            <AppSelect
              v-model="formModel.groups"
              :disabled="disableOnEdit"
              :loading="isTeamsLoading"
              :offset="[0, 0]"
              :options="groups"
              :search-function="getGroups"
              data-auto-testid="groups-field"
              data-testid="groups-field"
              has-only-this-button
              hide-selected-items-in-dropdown
              inline-search
              item-label="name"
              item-value="id"
              multi
              show-selected-options-inside
              skeleton-loader
              @change="onGroupSelect"
              @update:options="groups = $event"
              @update:model-value="onParameterUpdate($options.OKR_PARAMETERS_SAVING_STATUSES.GROUP)"
            >
              <template #option-label="{ option }">
                <GlobalGroupsSelectOption v-if="option" :group="option" />
              </template>
              <template #button="{ active, fullDataOption }">
                <OkrFormFieldSelectTriggerNext
                  :empty-state-label="$t('field.group.placeholder')"
                  :label="$t('field.groups.title')"
                  :opened="active"
                  :selected-options="fullDataOption"
                  style="--value-item-padding: 0"
                >
                  <template #value-item-content="{ item }">
                    <GroupSelectLabel v-if="item" :group="item" />
                  </template>
                </OkrFormFieldSelectTriggerNext>
              </template>
            </AppSelect>
          </FormItemsGroup>

          <FormItemsGroup :disabled="disableOnEdit" data-auto-testid="common-fields-group">
            <AppSelect
              :disabled="disableOnEdit"
              :hidden-items="archivedIntervals"
              :loading="isIntervalsLoading"
              :model-value="formModel.intervalId"
              :offset="[0, 0]"
              :options="getIntervalsAsOptions"
              data-auto-testid="interval-field"
              data-testid="interval-field"
              skeleton-loader
              @update:model-value="onIntervalUpdate"
            >
              <template #button="{ option, active }">
                <OkrFormFieldSelectTriggerNext
                  :empty-state-label="$t('field.quoter.placeholder')"
                  :label="$t('field.quoter.title')"
                  :opened="active"
                  :selected-options="option?.label || ''"
                  separated-label
                />
              </template>
            </AppSelect>

            <OkrFormFieldSelectTriggerNext
              :disabled="isFieldDisabled || isOkrElementClosed"
              :opened="showDatesDropdown"
              data-auto-testid="dates-field-wrapper"
              data-testid="dates-field-wrapper"
              @click="onDatesTriggerClick"
            >
              <template #label>
                <div class="of-DatesLabel">
                  <span class="of-DatesLabel_Text">
                    {{ $t('objectives.table_header_dates') }}
                  </span>
                  <AppIcon
                    :icon-name="iconName"
                    class="of-DatesLabel_Icon"
                    height="24"
                    width="24"
                  />

                  <span class="of-DatesLabel_Value">
                    <OkrDatesDropdownTrigger
                      :active="showDatesDropdown"
                      :form-model="formModel"
                      :is-auto-period-mode="isAutoPeriodMode"
                      multi-line
                      type="default-next"
                    />

                    <OkrDatesDropdown
                      v-if="!isFieldDisabled && initialDataAreLoaded"
                      :follow-cursor="false"
                      :is-auto-period-mode="isAutoPeriodMode"
                      :objective="formModel"
                      :offset="[0, 11]"
                      :show-dates-dropdown="showDatesDropdown"
                      :show-period-mode-title="false"
                      append-to="body"
                      data-auto-testid="dates-field"
                      data-testid="dates-field"
                      position="bottom"
                      show-period-mode-switch
                      split-update-date-events
                      @hide-dates-dropdown="showDatesDropdown = false"
                      @update-element-date="onUpdateElementDate"
                      @update-period-mode="updatePeriodMode"
                    />
                  </span>
                </div>
              </template>
            </OkrFormFieldSelectTriggerNext>

            <AppSelect
              v-if="showFormField(formModel.labelIds.length > 0)"
              ref="labelsSelect"
              v-model="formModel.labelIds"
              :allow-create-on-search="allowCreateOnSearch"
              :create-label="$t('labels.new_label')"
              :disabled="disableOnEdit"
              :loading="isLabelsLoading"
              :offset="[0, 0]"
              :options="labels"
              :search-function="getLabels"
              :search-max-length="50"
              data-auto-testid="labels-field"
              data-testid="labels-field"
              has-only-this-button
              hide-selected-items-in-dropdown
              item-label="name"
              item-value="id"
              multi
              show-selected-options-inside
              skeleton-loader
              @create="createLabelOnSearch"
              @update:model-value="
                onParameterUpdate($options.OKR_PARAMETERS_SAVING_STATUSES.LABELS)
              "
            >
              <template #button="{ option, active }">
                <OkrFormFieldSelectTriggerNext
                  :empty-state-label="$t('field.labels.placeholder')"
                  :label="$t('field.labels.title')"
                  :opened="active"
                  :selected-options="option"
                />
              </template>
            </AppSelect>

            <AppSelect
              v-if="showFormField(formModel.stakeholders.length > 0)"
              v-model="formModel.stakeholders"
              :disabled="disableOnEdit"
              :hidden-items="[null]"
              :loading="assigneesAreLoading"
              :offset="[0, 0]"
              :options="assignees"
              :search-function="getAssignees"
              data-auto-testid="stakeholders-field"
              data-testid="stakeholders-field"
              dropdown-search
              has-only-this-button
              hide-selected-items-in-dropdown
              item-label="name"
              item-value="accountId"
              multi
              show-selected-options-inside
              skeleton-loader
              @update:options="assignees = $event"
              @update:model-value="
                onParameterUpdate($options.OKR_PARAMETERS_SAVING_STATUSES.STAKEHOLDERS)
              "
            >
              <template #button="{ fullDataOption, active }">
                <OkrFormFieldSelectTriggerNext
                  :empty-state-label="$t('filed.assignee.stakeholders.plug')"
                  :label="$t('field.assignee.stakeholders')"
                  :opened="active"
                  :selected-options="fullDataOption"
                  separated-label
                >
                  <template #values>
                    <SelectedUsersAvatarsList :users="fullDataOption" />
                  </template>
                </OkrFormFieldSelectTriggerNext>
              </template>
              <template #option-label="{ option }">
                <OwnerFieldOption :option="option" />
              </template>
            </AppSelect>
          </FormItemsGroup>

          <OkrElementsFormCustomFieldsHub
            v-model:loading="sidebarCustomFieldsLoading"
            :data-testid="OKR_ELEMENT_FORM_CUSTOM_FIELD_HUB_TEST_ID"
            :element-id="modelValue.id || null"
            :fields-values="customFieldsValues"
            :is-edit="isEdit"
            :okr-type-id="CUSTOM_FIELDS_OKR_TYPE_IDS.OBJECTIVE.id"
            :placement="CUSTOM_FIELDS_PLACEMENTS.SIDEBAR"
            :user-has-update-access="userCanUpdateCustomFields"
            :workspace-id="workspaceId"
            @update-field-value="onUpdateCustomFieldValue"
            @update-activities="updateActivities"
          />
        </div>
      </template>

      <template #footer>
        <ModalFooterActions
          v-if="!isEdit"
          v-model="createAnotherStatus"
          :disabled="loading"
          :loading="loading"
          data-auto-testid="create-okr-footer"
          data-testid="create-okr-footer"
          @close="$emit('close')"
          @create="onSaveButtonClick"
        >
          <template #checkbox-label>
            {{ $t('create.okr_element.create_another') }}
          </template>

          <template #cancel-button-text>
            {{ $t('action.cancel') }}
          </template>

          <template #confirm-button-text> {{ $t('action.create') }}</template>
        </ModalFooterActions>
      </template>
    </SidebarLayout>

    <CreateJiraIssueForm
      ref="createJiraIssueForm"
      source="form"
      @save="onCreateJiraIssueFormSave"
    />

    <ObjectiveDeleteModal
      v-model:show="showConfirmDelete"
      :objective="deleteObjectiveData"
      source="form"
      @deleted="onChildUpdated(true)"
      @update:show="!$event ? (deleteObjectiveData = null) : undefined"
    />
    <CloseObjectiveModal
      v-model:show-modal="showCloseModal"
      :objective="formModel"
      :temp-status="tempStatus"
      data-testid="close-okr-modal"
      @update-status="onUpdateStatus"
    />
  </div>

  <AppDialog
    :data-auto-testid="CONFIRM_INTERVAL_CHANGE_MODAL_TEST_ID"
    :data-testid="CONFIRM_INTERVAL_CHANGE_MODAL_TEST_ID"
    :show="isConfirmIntervalChangeShow"
    :title="$t('confirm_interval_change.title')"
    :type="DIALOG_TYPES.WARNING"
    @on-close="hideConfirmIntervalChange"
    @on-confirm="onConfirmIntervalChange"
  >
    {{ confirmDescription }}
  </AppDialog>
</template>

<script>
import { cloneDeep, isEmpty, isNull, uniqBy } from 'lodash'
import { defineComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'

import AssigneesInfoApiHandler from '@/api/assignees-info'
import GlobalGroupsApiHandler from '@/api/global-groups'
import IntervalsInfoApiHandler from '@/api/intervals-info'
import LabelsApiHandler from '@/api/labels'
import LevelApiHandler from '@/api/level'
import ObjectivesInfoApiHandler, { SEARCH_TYPES } from '@/api/okr-elements'
import ObjectivesApiHandler from '@/api/okr-elements'
import { BOTTOM_INFO_PANEL, isClearSelectionAction } from '@/composables/bottom-fixed-items'
import { tracker } from '@/tracking/amplitude'
import {
  EVENT_CATEGORIES,
  EVENT_SOURCES,
  trackLabelCreatedEvent
} from '@/tracking/amplitude-helpers'
import { EVENT_NAMES } from '@/tracking/gtm-helpers'
import { gtmTracker } from '@/tracking/gtm-tracking'
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { LOCK_MESSAGE_TYPES } from '@/utils/components-configurations/lock-message'
import { LIST_ITEM_PLACEMENTS } from '@/utils/components-configurations/okr-elements-list-item'
import { SIZES } from '@/utils/components-configurations/okr-icon'
import { CUSTOM_FIELDS_PLACEMENTS } from '@/utils/custom-fields/factory'
import {
  createInitialCustomFieldsValuesForOkrElement,
  CUSTOM_FIELDS_OKR_TYPE_IDS,
  DEFAULT_CUSTOM_FIELDS_VALUES_FOR_OKR_ELEMENT
} from '@/utils/custom-fields/helpers'
import { OKR_ELEMENT_FORM_CUSTOM_FIELD_HUB_TEST_ID } from '@/utils/custom-fields/jest-helpers'
import {
  ASSIGNEE_ENTITY_KEYS,
  COMPANY_SETTINGS_ENTITY_KEYS,
  OKR_ELEMENT_ENTITY_KEYS,
  REQUEST_ENTITY_KEYS
} from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { checkSomeValueIsTruthy, isStringEmpty } from '@/utils/general'
import {
  isJiraAppInjectionKey,
  isSalesforceAppInjectionKey,
  isWebAppInjectionKey
} from '@/utils/injection-keys'
import { getArchivedIntervals } from '@/utils/interval'
import { CHART_TYPES, isRefreshChartDataNeed } from '@/utils/objective-chart'
import { OBJECTIVE_TYPES } from '@/utils/objective-types'
import {
  getObjectiveIconName,
  findIntervalNameById,
  currentUserCanUpdateObjective,
  levelToOkrIconElement,
  OBJECTIVE_SORT_OPTIONS,
  createUsersList,
  convertUsersListToOwnerAndStakeholders,
  createGroupsList,
  convertGroupsListToGroups,
  okrElementToLinkIssuePayload,
  getObjectiveLevelsForCreate,
  currentUserCanReadObjective,
  OKR_ELEMENT_PARAMETERS_SAVING_STATUSES,
  getDescriptionForIntervalChangeConfirmation,
  OKR_ELEMENT_FORM_MENU_ACTIONS,
  getShowChartStatus,
  OKR_STATUSES,
  OKR_FORM_VIEWS,
  OKR_ELEMENT_PERMISSIONS,
  getNotSetOwnerOption,
  isOkrElementClosed,
  ALL_STATUS_OPTIONS,
  isKR,
  saveUpdatedChildParameters,
  getClosedStatusName,
  getTaskMenuItems
} from '@/utils/objectives'
import { getPortalName } from '@/utils/okr-breadcrumbs'
import {
  isManualPeriodModeEnabled,
  OKR_DATES_SELECT_DATE_PROPS,
  PERIOD_MODES
} from '@/utils/okr-element-dates'
import {
  getGradeColorStyle,
  getGradeColorVariable,
  getGradeDisplayValue
} from '@/utils/okr-elements/grade'
import {
  checkIsExpandableNestedItemsExist,
  createGetGroupsPayload,
  createGetUsersPayload,
  createPayloadForExpandAllNestedItems,
  getLockMessagesStates,
  isFormModelsEqual
} from '@/utils/okr-elements-forms-helpers'
import { isAllNestedItemsWeightsDimmed } from '@/utils/okr-weights-helpers'
import { scrollToCommentsSection } from '@/utils/scrollTo-utils'
import { getNavigationTabName } from '@/utils/tracking'
import { uid } from '@/utils/uid'
import { usePlansLimitations } from '@/utils/web-app/plans-limitations'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

import AppDialog from '@/components/AppDialog'
import OkrElementsFormCustomFieldsHub from '@/components/custom-fields/okr-elements-form/OkrElementsFormCustomFieldsHub'
import FormGradeAndStatus from '@/components/form/FormGradeAndStatus'
import FormItemsGroup from '@/components/form/FormItemsGroup'
import FormNestedItemsField from '@/components/form/FormNestedItemsField'
import LockMessage from '@/components/form/LockMessage'
import OkrTypeFieldOption from '@/components/form/OkrTypeFieldOption'
import OwnerFieldOption from '@/components/form/OwnerFieldOption'
import SelectedUsersAvatarsList from '@/components/form/SelectedUsersAvatarsList'
import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import GroupSelectLabel from '@/components/global-groups/GroupSelectLabel'
import ObjectiveChart from '@/components/objectives/chart/ObjectiveChart'
import CloseObjectiveModal from '@/components/objectives/close-objective-modal/CloseObjectiveModal'
import AddNestedItem from '@/components/objectives/forms/AddNestedItem'
import AddOkrButton from '@/components/objectives/forms/AddOkrButton'
import DescriptionField from '@/components/objectives/forms/DescriptionField'
import { CONFIRM_INTERVAL_CHANGE_MODAL_TEST_ID } from '@/components/objectives/forms/jest-helpers'
import LinkToParentButton from '@/components/objectives/forms/LinkToParentButton'
import ModalFooterActions from '@/components/objectives/forms/ModalFooterActions'
import Activities from '@/components/objectives/forms/objective-form/Activities'
import ObjectiveSelectItem from '@/components/objectives/forms/ObjectiveSelectItem'
import OkrTitleFiled from '@/components/objectives/forms/OkrTitleFiled'
import WeightsModalTrigger from '@/components/objectives/forms/WeightsModalTrigger'
import ObjectiveDeleteModal from '@/components/objectives/ObjectiveDeleteModal'
import OkrElementsList from '@/components/objectives/okr-elements-list/OkrElementsList'
import OkrElementsListItem from '@/components/objectives/okr-elements-list/OkrElementsListItem'
import OkrDatesDropdown from '@/components/objectives/OkrDatesDropdown'
import OkrDatesDropdownTrigger from '@/components/objectives/OkrDatesDropdownTrigger'
import OkrElementCreator from '@/components/objectives/OkrElementCreator'
import StatusIndicator from '@/components/objectives/status-indicator/StatusIndicator'
import BottomFixedSelectItem from '@/components/objectives/toolbar/BottomFixedSelectItem'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import { OBJECTIVE_FORM_OKR_ELEMENT_CREATOR_TEST_ID } from '@/components/ui/AppSelect/jest-helpers'
import OkrFormFieldSelectTriggerNext from '@/components/ui/AppSelect/TriggerButtons/OkrFormFieldSelectTriggerNext'
import SidebarLayout from '@/components/ui/SidebarLayout/SidebarLayout'
import ImmediateParentLoader from '@/components/ui/SkeletonLoaders/ImmediateParentLoader'
import UseAppPlatform from '@/components/UseAppPlatform'
import CreateJiraIssueForm from '@jira/components/objectives/forms/CreateJiraIssue'

const DEFAULT_FORM_MODEL = {
  name: '',
  description: '',
  groups: [],
  labelIds: [],
  ownerId: null,
  stakeholders: [],
  intervalId: null,
  parentId: null,
  levelId: 0,
  contribute: false,
  objectiveId: null,
  grade: 0,
  gradeToUse: 0,
  dueDate: null,
  elementStartDate: null,
  startDateManual: PERIOD_MODES.AUTO.value,
  dueDateManual: PERIOD_MODES.AUTO.value,
  automaticElementStartDate: null,
  automaticDueDate: null,
  confidenceLevelId: OKR_STATUSES.AUTO,
  automaticConfidenceLevelId: 0,
  updateStatusForNestedElements: false,
  private: false
}

// TODO: make fields editable only if user has access
export default defineComponent({
  name: 'ObjectiveForm',

  components: {
    CloseObjectiveModal,
    OkrElementsFormCustomFieldsHub,
    GlobalGroupsSelectOption,
    GroupSelectLabel,
    BottomFixedSelectItem,
    AppDialog,
    WeightsModalTrigger,
    LockMessage,
    FormNestedItemsField,
    AddNestedItem,
    ModalFooterActions,
    OkrTitleFiled,
    ImmediateParentLoader,
    LinkToParentButton,
    SelectedUsersAvatarsList,
    FormGradeAndStatus,
    OkrTypeFieldOption,
    OwnerFieldOption,
    OkrFormFieldSelectTriggerNext,
    FormItemsGroup,
    UseAppPlatform,
    OkrDatesDropdownTrigger,
    OkrDatesDropdown,
    OkrElementsListItem,
    AddOkrButton,
    OkrElementsList,
    OkrElementCreator,
    ObjectiveChart,
    AppButton,
    DescriptionField,
    AppSelect,
    AppIcon,
    ObjectiveSelectItem,
    AppCheckbox,
    CreateJiraIssueForm,
    SidebarLayout,
    ObjectiveDeleteModal,
    Activities,
    StatusIndicator
  },

  inject: {
    isWebApp: {
      from: isWebAppInjectionKey
    },

    isSalesforceApp: {
      from: isSalesforceAppInjectionKey
    },

    isJiraApp: {
      from: isJiraAppInjectionKey
    }
  },

  props: {
    modelValue: {
      type: Object,
      default: null
    },

    parentObjectives: {
      type: Array,
      default: () => []
    },

    isParentObjectivesLoading: {
      type: Boolean,
      default: false
    },

    source: {
      type: String,
      default: ''
    },

    childrenOrder: {
      type: Array,
      default: () => [OBJECTIVE_SORT_OPTIONS.ORDER_ASC]
    },

    allowCreateElements: {
      type: Boolean
    },

    okrPayload: {
      type: Object,
      default: () => ({})
    }
  },

  CHART_TYPES: { ...CHART_TYPES },
  OKR_DATES_SELECT_DATE_PROPS: { ...OKR_DATES_SELECT_DATE_PROPS },

  emits: {
    'open-custom-weights': null,
    'update:parentObjectives': null,
    'link-jira-issue': null,
    'edit-element': null,
    close: null,
    'saving-started': null,
    'saving-ended': null,
    'open-child-objective': null,
    'update:isParentObjectivesLoading': null,
    'update:areParentObjectivesLoaded': null,
    update: null,
    'open-kr': null,
    'update:modelValue': null,
    'on-created': null,
    'level-changed': null,
    'set-child-modal-opened': null
  },

  setup() {
    const { okrWeightsLimitations } = usePlansLimitations()

    return {
      okrWeightsLimitations
    }
  },

  data() {
    return {
      uid: uid(),
      localFormModel: cloneDeep(DEFAULT_FORM_MODEL),
      formModel: cloneDeep(DEFAULT_FORM_MODEL),
      assignees: [],
      childObjectives: [],
      isTitleEmpty: false,
      loading: false,
      isIntervalsLoading: false,
      areIntervalsLoaded: false,
      assigneesAreLoading: false,
      areAssigneesLoaded: false,
      initialGroupsAreLoaded: false,
      isTeamsLoading: false,
      isLabelsLoading: false,
      isLevelsLoading: false,
      areGroupsLoaded: false,
      areParentObjectivesLoaded: false,
      isParentObjectivesLoadingLocal: false,
      isGroupManuallyChanged: false,
      isOwnerManuallyChanged: false,
      createAnotherStatus: false,
      showGroupNotSetOption: false,
      initialDataAreLoaded: false,
      showConfirmDelete: false,
      isParentObjectiveFieldFocused: false,
      deleteObjectiveData: null,
      groups: [],
      labels: [],
      intervals: [],
      levels: [],
      weightIsManual: false,

      ...Object.fromEntries(
        Object.values(OKR_ELEMENT_PARAMETERS_SAVING_STATUSES).map(item => [item, false])
      ),

      showChart: false,

      isConfirmIntervalChangeShow: false,
      temporaryIntervalId: null,

      showDatesDropdown: false,

      isExpandAllLoading: false,

      showCloseModal: false,
      tempStatus: null,

      contentCustomFieldsLoading: false,
      sidebarCustomFieldsLoading: false,
      customFieldsValues: DEFAULT_CUSTOM_FIELDS_VALUES_FOR_OKR_ELEMENT,
      isCustomFieldsLoading: false,
      descriptionWasChanged: false
    }
  },

  OKR_PARAMETERS_SAVING_STATUSES: { ...OKR_ELEMENT_PARAMETERS_SAVING_STATUSES },

  computed: {
    ...mapState('system', {
      settings: state => state.settings,
      userData: state => state.userData || {}
    }),

    ...mapGetters('customFields', {
      fieldsByWorkspaceId: 'fieldsByWorkspaceId'
    }),

    ...mapState('pluginOptions', {
      isJiraConnected: state => state[PLUGIN_OPTIONS_KEYS.JIRA_CONNECTED]
    }),

    DROP_LIST_THEMES: () => DROP_LIST_THEMES,

    LIST_ITEM_PLACEMENTS: () => LIST_ITEM_PLACEMENTS,

    OKR_ELEMENT_FORM_CUSTOM_FIELD_HUB_TEST_ID: () => OKR_ELEMENT_FORM_CUSTOM_FIELD_HUB_TEST_ID,

    OBJECTIVE_FORM_OKR_ELEMENT_CREATOR_TEST_ID: () => OBJECTIVE_FORM_OKR_ELEMENT_CREATOR_TEST_ID,

    CUSTOM_FIELDS_OKR_TYPE_IDS: () => CUSTOM_FIELDS_OKR_TYPE_IDS,

    CUSTOM_FIELDS_PLACEMENTS: () => CUSTOM_FIELDS_PLACEMENTS,

    DIALOG_TYPES: () => DIALOG_TYPES,

    LOCK_MESSAGE_TYPES: () => LOCK_MESSAGE_TYPES,

    CONFIRM_INTERVAL_CHANGE_MODAL_TEST_ID: () => CONFIRM_INTERVAL_CHANGE_MODAL_TEST_ID,

    currentWorkspaceCustomFields() {
      return this.fieldsByWorkspaceId(this.workspaceId)
    },

    ownerFieldOptions() {
      return uniqBy(
        [getNotSetOwnerOption(this.$t), ...this.assignees],
        ASSIGNEE_ENTITY_KEYS.ACCOUNT_ID
      )
    },

    getBottomFixedItems() {
      return [
        {
          id: 0,
          text: this.$t('objectives.last_count_items_shown', {
            count: 10,
            entity: this.$t('objectives.okr', 2)
          }),

          action: BOTTOM_INFO_PANEL
        }
      ]
    },

    dimNestedItemsWeights() {
      return isAllNestedItemsWeightsDimmed({
        weightIsManual: this.weightIsManual,
        nestedItems: this.childObjectives
      })
    },

    showWeightsModalTrigger() {
      // return this.isEdit && this.editable && this.formModel.childCount > 1
      return this.isEdit && this.editable
    },

    isWeightsTriggerDisabled() {
      return this.okrWeightsLimitations.isDisabled({ okrElement: this.modelValue })
    },

    weightTriggerTooltip() {
      return this.okrWeightsLimitations.getTooltip({ okrElement: this.modelValue })
    },

    someItemIsExpandable() {
      return checkIsExpandableNestedItemsExist(this.krs)
    },

    portalName() {
      return getPortalName(OKR_FORM_VIEWS.OBJECTIVE, this.isEdit)
    },

    SIZES: () => SIZES,
    OKR_STATUSES: () => OKR_STATUSES,

    showPredictedScore() {
      return (
        this.formModel.confidenceLevelId === OKR_STATUSES.AUTO &&
        this.isEdit &&
        this.showChart &&
        Math.max(this.modelValue.timePassedPercent, 0) >= 0
      )
    },

    chartTriggers() {
      const { intervals, formModel } = this
      const { intervalId } = formModel
      return {
        intervals,
        intervalId
      }
    },

    isAutoPeriodMode() {
      return !isManualPeriodModeEnabled(
        this.formModel.startDateManual,
        this.formModel.dueDateManual
      )
    },

    automaticElementDatesForCreate() {
      const { intervalId } = this.formModel
      const currentInterval = this.intervals.find(item => item.id === intervalId)
      return {
        startDate: currentInterval?.startDate,
        dueDate: currentInterval?.endDate
      }
    },

    iconName() {
      return this.isAutoPeriodMode ? PERIOD_MODES.AUTO.icon : PERIOD_MODES.MANUAL.icon
    },

    isFieldDisabled() {
      return this.isEdit && !this.userCanUpdateObjective
    },

    confirmDescription() {
      return getDescriptionForIntervalChangeConfirmation({
        formModel: this.formModel,
        i18nInstance: this.$t
      })
    },

    parenObjectiveReadable() {
      return currentUserCanReadObjective(this.selectedParentObjective)
    },

    isDescriptionTooLong() {
      return !!this.formModel.description && this.formModel.description.length > 18000
    },

    isEdit() {
      return Boolean(this.modelValue && this.modelValue.id)
    },

    editable() {
      return this.isEdit && currentUserCanUpdateObjective(this.modelValue)
    },

    disableOnEdit() {
      if (!this.initialDataAreLoaded) return true
      return (this.isEdit && !this.editable) || this.isOkrElementClosed
    },

    getParentOkrTypeName() {
      return this.selectedParentObjective && isKR(this.selectedParentObjective)
        ? this.$t('element.type.key_result')
        : this.$t('okr_elements.objective')
    },

    isClosedParent() {
      if (!this.formModel.parentId) return false
      return this.selectedParentObjective && isOkrElementClosed(this.selectedParentObjective)
    },

    getOkrElementClosedStatusName() {
      const statusName = getClosedStatusName({
        okrElement: this.modelValue
      })
      return this.$t(statusName).toLowerCase()
    },

    getParentElementClosedStatusName() {
      const statusName = getClosedStatusName({
        okrElement: this.selectedParentObjective
      })
      return this.$t(statusName).toLowerCase()
    },

    isOkrElementClosed() {
      return isOkrElementClosed(this.modelValue)
    },

    getObjectivesAsOptions() {
      return this.parentObjectives.filter(o => o.id !== this.formModel.objectiveId)
    },

    krs() {
      return this.childObjectives.filter(objective =>
        [OBJECTIVE_TYPES.KR, OBJECTIVE_TYPES.TASK].includes(objective.typeId)
      )
    },

    otherChildObjectives() {
      return this.childObjectives.filter(
        objective => ![OBJECTIVE_TYPES.KR, OBJECTIVE_TYPES.TASK].includes(objective.typeId)
      )
    },

    getIntervalsAsOptions() {
      return this.intervals.map(i => ({
        label: i.name,
        value: i.id
      }))
    },

    // passing archived intervals as hidden-items because
    // OKR element can has archived interval as selected
    // so we must show it like selected but dont pass it to all options list
    archivedIntervals() {
      return getArchivedIntervals(this.intervals).map(item => item.id)
    },

    selectedParentObjective() {
      return this.parentObjectives.find(objective => objective.id === this.formModel.parentId)
    },

    userCanUpdateObjective() {
      return this.modelValue && currentUserCanUpdateObjective(this.modelValue)
    },

    userCanUpdateCustomFields() {
      if (this.isEdit) {
        return this.userCanUpdateObjective && !this.isOkrElementClosed
      }

      return true
    },

    skipDescriptionCheck() {
      // ↓ optional channing coz in case of closed okr elements we hide description field
      if (this.$refs.description?.active) {
        return false
      }
      return !this.descriptionWasChanged
    },

    areDataChanged() {
      return !isFormModelsEqual({
        localFormModel: this.localFormModel,
        formModel: this.formModel,
        skipDescriptionCheck: this.skipDescriptionCheck
      })
      // return JSON.stringify(this.localFormModel) !== JSON.stringify(this.formModel)
    },

    workspaceId() {
      if (this.modelValue && this.modelValue.workspaceId && this.modelValue.workspaceId !== -1) {
        return this.modelValue.workspaceId
      }
      return this.$route.params.workspaceId
    },

    actionMenuItemss() {
      return [
        ...this.levels.filter(item => isKR(item)),
        {
          id: OKR_ELEMENT_FORM_MENU_ACTIONS.LINK_TASK,
          name: this.$t('action.link_jira_issue'),
          icon: 'link-task',
          disabled: this.isOkrElementClosed
        },
        {
          id: OKR_ELEMENT_FORM_MENU_ACTIONS.CREATE_JIRA_ISSUE,
          name: this.$t('action.create_jira_issue'),
          icon: 'create-task',
          disabled: this.isOkrElementClosed
        }
      ]
    },

    actionMenuItems() {
      return [
        ...this.levels.filter(item => isKR(item)),
        ...getTaskMenuItems({
          isOkrElementClosed: this.isOkrElementClosed,
          isJiraApp: this.isJiraApp,
          isWebApp: this.isWebApp,
          isSalesforceApp: this.isSalesforceApp,
          isJiraConnected: this.isJiraConnected,
          hasAccessToJira: this.userData.hasAccessToJira
        })
      ].filter(Boolean)
    },

    objectiveLevelsForCreate() {
      return getObjectiveLevelsForCreate(this.levels)
    },

    editDataAreSaving() {
      return (
        this.isEdit &&
        checkSomeValueIsTruthy(
          ...Object.values(OKR_ELEMENT_PARAMETERS_SAVING_STATUSES).map(item => this[item]),
          this.contentCustomFieldsLoading,
          this.sidebarCustomFieldsLoading
        )
      )
    },

    allowCreateOnSearch() {
      return this.settings[COMPANY_SETTINGS_ENTITY_KEYS.ALLOW_LABEL_CREATION]
    },

    lockedMessagesStates() {
      return getLockMessagesStates({
        isEdit: this.isEdit,
        modelValue: this.modelValue,
        isClosedParent: this.isClosedParent,
        contribute: this.formModel.contribute
      })
    }
  },

  watch: {
    showCloseModal(value) {
      this.$emit('set-child-modal-opened', value)
    },

    automaticElementDatesForCreate: {
      handler(newValue) {
        if (newValue && !this.isEdit) {
          const { startDate, dueDate } = newValue
          this.formModel.automaticElementStartDate = startDate
          this.formModel.automaticDueDate = dueDate
        }
      },

      deep: true,
      immediate: true
    },

    'formModel.intervalId': async function intervalIdWatcher() {
      if (this.areIntervalsLoaded && this.areParentObjectivesLoaded) {
        if (this.isEdit) {
          this.onParameterUpdate(this.$options.OKR_PARAMETERS_SAVING_STATUSES.INTERVAL)
        }
        this.getParentObjectives()
      }
    },

    'formModel.ownerId': async function ownerIdWatcher() {
      if (this.areAssigneesLoaded && this.initialGroupsAreLoaded) {
        this.groups = await this.getGroups()
      }
    },

    async modelValue(newValue, oldValue) {
      if (newValue) {
        const { showChart } = this
        this.initCustomFieldsValues()
        this.saveModelValueToFormModel()

        if (oldValue?.id !== newValue.id && newValue.id && oldValue?.name) {
          // check oldValue name for suppress data request if added child element for newly created objective
          // another objective
          await this.fetchData()
          this.initialDataAreLoaded = true
        } else {
          // the same objective
          // eslint-disable-next-line no-lonely-if
          if (oldValue?.contribute !== newValue.contribute) {
            // contribute changed, update parent objectives to get new grade value of
            // parent objective
            this.getParentObjectives()
          }
        }

        if (isRefreshChartDataNeed({ oldValue, newValue, showChart })) {
          await this.$nextTick()
          await this.$refs.objectiveChart.refreshData()
        }
      }
    },

    editDataAreSaving(newValue) {
      if (newValue) {
        this.$emit('saving-started')
      } else {
        this.$emit('saving-ended')
      }
    },

    chartTriggers: {
      handler(newValue) {
        const { isEdit } = this
        this.showChart = getShowChartStatus({ ...newValue, isEdit })
      },

      deep: true
    }
  },

  mounted() {
    this.assigneesAreLoading = true
    this.isTeamsLoading = true
    this.isLabelsLoading = true
    this.isIntervalsLoading = true

    this.initCustomFieldsValues()

    if (this.modelValue) {
      this.saveModelValueToFormModel()
    }

    if (this.isEdit || this.createAnotherStatus) {
      this.isGroupManuallyChanged = true
      this.isOwnerManuallyChanged = true
    }

    if (!this.isEdit) {
      this.setFocusOnTitle()
    }

    this.fetchData().then(() => {
      this.initialDataAreLoaded = true
      this.$nextTick(() => {
        scrollToCommentsSection(this.okrPayload)
      })
    })

    if (this.$route?.query.levelId) {
      this.formModel.levelId = this.$route.query.levelId
    }
  },

  methods: {
    isClearSelectionAction,
    getGradeColorStyle,
    getGradeDisplayValue,
    levelToOkrIconElement,
    getGradeColorVariable,

    async expandAll() {
      this.isExpandAllLoading = true
      const objectivesApi = new ObjectivesApiHandler()

      try {
        const payload = createPayloadForExpandAllNestedItems({
          modelValue: this.modelValue,
          childrenOrder: this.childrenOrder
        })
        this.childObjectives = await objectivesApi.getObjectives(payload)

        this.$refs.childKRs.toggleExpandAll()
        this.isExpandAllLoading = false
      } catch (error) {
        handleError({ error })
      }
    },

    collapseAll() {
      this.$refs.childKRs.toggleExpandAll(false)
    },

    showFormField(isValueExist = false) {
      if (this.isEdit) {
        return this.editable ? true : isValueExist
      }
      return true
    },

    expandItem({ parentId, items }) {
      const target = this.childObjectives.find(item => item.id === parentId)
      if (target) {
        target.childElements = items
      }
    },

    async onChildObjectiveMenuActionsClick({ name, item }) {
      if (name === 'remove') {
        this.deleteItem(item)
      } else if (name === 'edit') {
        this.$emit('open-child-objective', item)
      } else if (name === 'unlink') {
        this.unlinkChildObjective(item)
      }
    },

    async fetchData() {
      this.initialGroupsAreLoaded = false
      this.areAssigneesLoaded = false
      this.areIntervalsLoaded = false
      this.initialDataAreLoaded = false

      const handleAssignees = async () => {
        this.assignees = await this.getAssignees(null, true)
      }

      const handleGroups = async () => {
        this.groups = await this.getGroups()
      }

      const handleLabels = async () => {
        this.labels = await this.getLabels()
      }

      const handleLevels = async () => {
        this.levels = await this.getLevelsForFilter()
      }

      if (!this.isEdit) {
        await handleAssignees()
      }

      const promises = [
        handleGroups(),
        this.getIntervals(),
        this.getParentObjectives(),
        handleLevels(),
        handleLabels()
      ]

      if (this.isEdit) {
        promises.unshift(handleAssignees())
        promises.push(
          this.getChildObjectives({
            isRefetch: false,
            isRefetchParent: true,
            isUpdateHistory: false
          })
        )
      }

      return Promise.all(promises)
    },

    scheduleCreateAnother() {
      this.formModel.name = ''
      this.formModel.description = ''
    },

    /** @public */
    async getChildObjectives({
      isRefetch = false,
      isRefetchParent = true,
      isUpdateHistory = false
    } = {}) {
      // this.areBreadcrumbsLoading = true;
      try {
        const objectivesApi = new ObjectivesInfoApiHandler()
        const { items, weightManual } = await objectivesApi.getChildObjectives({
          parentId: this.modelValue.id,
          workspaceId: this.modelValue.workspaceId,
          // intervalIds: [this.modelValue.intervalId],
          searchType: SEARCH_TYPES.OKR_EXPLORER,
          order: this.childrenOrder,
          childOrder: this.childrenOrder,
          offset: 0,
          limit: 1000
        })
        this.childObjectives = items
        this.weightIsManual = weightManual
        if (isUpdateHistory) {
          this.updateActivities()
        }
        if (isRefetch && this.$refs.childKRs) {
          this.$refs.childKRs.refetchExpandedElementsChildItems()
        }
        if (isRefetchParent) {
          await this.getParentObjectives()
        }
      } catch (error) {
        handleError({ error })
      }
    },

    async getParentObjectives(searchString = null) {
      this.isParentObjectivesLoadingLocal = true
      let result = []

      const { intervalId, parentId } = this.formModel
      const payload = {
        intervalId,
        elementId: this.modelValue?.id || null,
        elementTypeId: OBJECTIVE_TYPES.PERSONAL,
        parentId,
        searchString,
        workspaceId: this.workspaceId,
        dueDate: this.formModel.dueDate
      }

      try {
        const objectivesApi = new ObjectivesInfoApiHandler()
        const { elements } = await objectivesApi.getParentObjectiveInfo(payload)
        result = elements
        if (searchString === null) {
          this.$emit('update:parentObjectives', elements)

          this.$nextTick(() => {
            if (!this.areParentObjectivesLoaded) {
              this.localFormModel.parentId = this.formModel.parentId
            }
            this.areParentObjectivesLoaded = true
          })
        }
      } catch (error) {
        handleError({ error })
      } finally {
        this.$emit('update:isParentObjectivesLoading', false)
        this.isParentObjectivesLoadingLocal = false
      }

      return result
    },

    getParentObjectiveIconName(objective) {
      if (objective && objective.id === 0) {
        return null
      }

      return getObjectiveIconName({
        objective: { ...objective, permissions: [OKR_ELEMENT_PERMISSIONS.READ] }
      })
    },

    validateName() {
      if (this.formModel.name === '') {
        this.isTitleEmpty = true
        this.setFocusOnTitle()
        return false
      }
      return true
    },

    async onLinkJiraIssueClick() {
      let isValid = false
      let saved = false

      if (!this.isEdit) {
        isValid = this.validate()
        if (isValid) {
          const result = await this.onSaveButtonClick({
            showLoader: false,
            closeForm: false,
            ignoreCreateAnotherStatus: true
          })
          saved = result.saved
        }

        if (!isValid || !saved) {
          return
        }
      }

      this.$emit('link-jira-issue', okrElementToLinkIssuePayload(this.modelValue))
    },

    async onCreateJiraIssueClick() {
      let isValid = false
      let saved = false

      if (!this.isEdit) {
        isValid = this.validate()
        if (isValid) {
          const result = await this.onSaveButtonClick({
            showLoader: false,
            closeForm: false
          })
          saved = result.saved
        }

        if (!isValid || !saved) {
          return
        }
      }

      const payload = {
        id: this.modelValue.id,
        parentId: this.modelValue.parentId,
        users: this.modelValue.users,
        groups: this.modelValue.groups,
        intervalId: this.modelValue.intervalId,
        workspaceId: this.modelValue.workspaceId,
        dueDate: this.modelValue.dueDate
      }

      this.createJiraIssue(payload)
    },

    createJiraIssue(payload) {
      this.$refs.createJiraIssueForm.create(payload)
    },

    updateChildrenParams(updatedElementParameters) {
      saveUpdatedChildParameters(this.krs, updatedElementParameters)
      saveUpdatedChildParameters(this.childObjectives, updatedElementParameters)
    },

    async updateObjective() {
      if (this.initialDataAreLoaded && this.isEdit && this.areDataChanged) {
        const api = new ObjectivesInfoApiHandler()
        try {
          const { element, updatedElementParameters } = await api.updateOkrElement({
            contribute: this.formModel.contribute,
            description: this.formModel.description,
            groups: createGroupsList(this.formModel.groups),
            intervalId: this.formModel.intervalId,
            name: this.formModel.name || this.modelValue.name,
            orderValue: this.formModel.orderValue,
            parentId: this.formModel.parentId,
            levelId: this.formModel.levelId,
            workspaceId: this.formModel.workspaceId,
            confidenceLevelId: this.formModel.confidenceLevelId,
            users: createUsersList(this.formModel),
            elementId: this.modelValue.id,
            dueDate: this.formModel.dueDate,
            elementStartDate: this.formModel.elementStartDate,
            labelIds: this.formModel.labelIds,
            startDateManual: this.formModel.startDateManual,
            dueDateManual: this.formModel.dueDateManual,
            updateStatusForNestedElements: this.formModel.updateStatusForNestedElements
          })
          this.isTitleEmpty = false
          this.formModel = {
            ...this.formModel,
            ...element,
            ...convertUsersListToOwnerAndStakeholders(element.users),
            groups: [...convertGroupsListToGroups(element.groups)],
            labelIds: element.labels?.map(label => label.id) || []
          }

          this.localFormModel = {
            ...this.localFormModel,
            ...this.formModel
          }

          this.updateChildrenParams(updatedElementParameters)
          this.formModel.updateStatusForNestedElements = false
          this.$emit('update')

          this.updateActivities()
        } catch (error) {
          handleError({ error })
        }
      }
    },

    async onNameUpdate() {
      if (this.editable && this.validateName()) {
        this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.NAME] = true
        await this.updateObjective()
        this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.NAME] = false
      }
    },

    async saveDescription() {
      if (!this.descriptionWasChanged) {
        this.descriptionWasChanged = true
      }
      this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.DESCRIPTION] = true
      await this.updateObjective()
      this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.DESCRIPTION] = false
    },

    cancelDescriptionEditing() {
      if (this.isEdit) {
        this.formModel.description = this.modelValue.description
      }
    },

    async onContributeChange() {
      this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.CONTRIBUTE] = true
      await this.updateObjective()
      this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.CONTRIBUTE] = false

      const level = this.objectiveLevelsForCreate.find(level => this.formModel.levelId === level.id)
      const contributeStatus = this.formModel.contribute ? 'on' : 'off'
      tracker.logEvent(`Switched ${contributeStatus} contribution`, {
        category: EVENT_CATEGORIES.OKR_MANAGEMENT,
        label: level?.name,
        ent_id: this.formModel.objectiveId
      })
    },

    async onKRCreateClick() {
      let isValid = false
      let saved = false

      if (!this.isEdit) {
        isValid = this.validate()
        if (isValid) {
          const result = await this.onSaveButtonClick({
            showLoader: false,
            closeForm: false,
            ignoreCreateAnotherStatus: true
          })
          saved = result.saved
        }

        if (!isValid || !saved) {
          return
        }
      }

      // wait until modelValue is updated
      await this.$nextTick()

      const parameters = {
        intervalStartDate: this.modelValue.intervalStartDate,
        intervalEndDate: this.modelValue.intervalEndDate,
        intervalId: this.modelValue.intervalId,
        parentId: this.modelValue.id,
        workspaceId: this.workspaceId
      }
      this.$emit('open-kr', parameters)
    },

    async getIntervals() {
      const api = new IntervalsInfoApiHandler()
      this.isIntervalsLoading = true
      try {
        const { currentIndex, intervals } = await api.getIntervalsInfo({
          workspaceId: this.workspaceId
        })
        this.intervals = intervals

        if (!this.formModel.intervalId) {
          this.formModel.intervalId = intervals[currentIndex].id
        }

        this.$nextTick(() => {
          this.areIntervalsLoaded = true
        })
      } catch (error) {
        handleError({ error })
      } finally {
        this.isIntervalsLoading = false
      }
    },

    async getAssignees(searchString = null, selectUser = false) {
      let result = []
      const api = new AssigneesInfoApiHandler()

      const { OWNER_ID } = OKR_ELEMENT_ENTITY_KEYS

      this.isAssigneeLoading = true
      const payload = createGetUsersPayload({
        workspaceId: this.workspaceId,
        searchString,
        userData: this.userData,
        isOwnerManuallyChanged: this.isOwnerManuallyChanged,
        isEdit: this.isEdit,
        formModel: this.formModel
      })

      try {
        const users = await api.getUsers(payload)

        result = [...users]

        if (!isNull(searchString)) {
          return users
        }

        if (
          !isEmpty(users) &&
          isNull(this.formModel[OWNER_ID]) &&
          selectUser &&
          !this.isOwnerManuallyChanged
        ) {
          this.formModel[OWNER_ID] = this.userData.userAccountId
        }
        if (!this.areAssigneesLoaded) {
          this.localFormModel[OWNER_ID] = this.formModel[OWNER_ID]
        }

        this.$nextTick(async () => {
          this.areAssigneesLoaded = true
        })
      } catch (error) {
        handleError({ error })
      } finally {
        this.assigneesAreLoading = false
      }

      return result
    },

    async getGroups(searchString = null) {
      const api = new GlobalGroupsApiHandler()
      this.isTeamsLoading = true
      let result = []

      const { GROUP_IDS } = REQUEST_ENTITY_KEYS
      const { GROUPS } = OKR_ELEMENT_ENTITY_KEYS

      const { ownerId, groups: selectedGroups } = this.formModel

      try {
        const payload = createGetGroupsPayload({
          searchString,
          ownerId,
          selectedGroups,
          workspaceId: this.workspaceId,
          isGroupManuallyChanged: this.isGroupManuallyChanged,
          users: this.assignees
        })

        const groups = await api.getGroupsForFilter(payload)
        this.isTeamsLoading = false

        if (!this.isEdit) {
          this.formModel[GROUPS] = payload[GROUP_IDS]
        }

        this.$nextTick(() => {
          if (!this.areGroupsLoaded) {
            this.localFormModel[GROUPS] = this.formModel[GROUPS]
          }
          this.areGroupsLoaded = true

          if (!this.initialGroupsAreLoaded) {
            this.initialGroupsAreLoaded = true
          }
        })

        result = groups
      } catch (error) {
        handleError({ error })
      } finally {
        this.isTeamsLoading = false
      }

      return result
    },

    async getLevelsForFilter() {
      const levelApi = new LevelApiHandler()
      let result = []
      try {
        this.isLevelsLoading = true
        result = await levelApi.getLevelsForFilter({
          workspaceId: this.workspaceId
        })
      } catch (error) {
        handleError({ error })
      }
      this.isLevelsLoading = false
      return result
    },

    async getLabels(searchString = null) {
      let result = []
      const api = new LabelsApiHandler()
      if (!searchString) {
        this.isLabelsLoading = true
      }
      try {
        result = await api.getLabels({ name: searchString })
      } catch (error) {
        handleError({ error })
      }
      this.isLabelsLoading = false
      return result
    },

    async createLabelOnSearch(searchString) {
      const api = new LabelsApiHandler()
      try {
        const label = await api.createLabel({ name: searchString })
        this.formModel.labelIds.push(label.id)
        this.labels.push(label)

        trackLabelCreatedEvent({
          source: EVENT_SOURCES.OKR_FORM,
          label: label.name
        })

        this.updateObjective()
        this.$refs.labelsSelect?.clearInput()
      } catch (error) {
        handleError({ error })
      }
    },

    onOwnerSelect() {
      this.isOwnerManuallyChanged = true
    },

    onGroupSelect() {
      this.isGroupManuallyChanged = true
    },

    validate() {
      if (isStringEmpty(this.formModel.name)) {
        this.isTitleEmpty = true
        this.setFocusOnTitle()
        return false
      }
      return !this.isDescriptionTooLong
    },

    // don't call it directly, use `onSaveButtonClick` instead
    async save() {
      if (!this.validate()) {
        return { saved: false, createdObjective: null }
      }

      if (!this.isEdit && this.$refs.description.active) {
        this.$refs.description.blur()
      }

      let isSuccessful = true

      const payload = {
        name: this.formModel.name,
        description: this.formModel.description || '',
        users: createUsersList(this.formModel),
        levelId: this.formModel.levelId,
        intervalId: this.formModel.intervalId,
        parentId: this.formModel.parentId,
        contribute: this.formModel.contribute || false,
        groups: createGroupsList(this.formModel.groups),
        confidenceLevelId: this.formModel.confidenceLevelId,
        workspaceId: this.workspaceId,
        dueDate: this.formModel.dueDate,
        elementStartDate: this.formModel.elementStartDate,
        labelIds: this.formModel.labelIds,
        startDateManual: this.formModel.startDateManual,
        dueDateManual: this.formModel.dueDateManual,
        private: this.modelValue.private
      }

      if (!this.isEdit) {
        payload.customFields = this.customFieldsValues
      }

      let result = null
      const objectivesApi = new ObjectivesInfoApiHandler()
      if (!this.isEdit) {
        try {
          result = await objectivesApi.createOkrElement(payload)
          const createdElement = result.elements[0]
          result = createdElement

          const level = this.objectiveLevelsForCreate.find(
            level => this.formModel.levelId === level.id
          )
          tracker.logEvent('Created objective', {
            category: EVENT_CATEGORIES.OKR_MANAGEMENT,
            label: level?.name,
            quarter: findIntervalNameById(this.intervals, createdElement.intervalId),
            contribution: createdElement.contribute ? 'Yes' : 'No',
            ent_id: createdElement.displayId,
            source: this.source,
            tab: getNavigationTabName(this.$route),
            parent_objective: createdElement.parentId !== null ? createdElement.parentId : ''
          })

          gtmTracker.logEvent(EVENT_NAMES.OBJECTIVE_CREATED)
        } catch (error) {
          isSuccessful = false
          handleError({ error })
        }
      } else {
        payload.elementId = this.modelValue.id
        try {
          result = await objectivesApi.updateOkrElement(payload)
        } catch (error) {
          isSuccessful = false
          handleError({ error })
        }
      }

      return { saved: isSuccessful, createdObjective: result }
    },

    async onSaveButtonClick({
      showLoader = true,
      closeForm = true,
      ignoreCreateAnotherStatus = false
    } = {}) {
      if (showLoader) {
        this.loading = true
      }
      const { saved, createdObjective } = await this.save()

      if (saved) {
        if (!this.isEdit) {
          this.$emit('on-created', createdObjective)
        }

        if (!this.isEdit && this.createAnotherStatus) {
          this.scheduleCreateAnother()
          this.setFocusOnTitle()
          // showNotify({
          //   title: this.$t('create.objective.success_title')
          // })
        }

        if (!this.createAnotherStatus || ignoreCreateAnotherStatus) {
          const closeEventData = {
            checkDataChange: false,
            createdObjective: !this.isEdit ? createdObjective : undefined
          }

          if (closeForm) {
            this.$emit('close', closeEventData)
          } else {
            this.$emit('update:modelValue', {
              ...this.formModel,
              ...createdObjective
            })
          }
        }
      }

      if (showLoader) {
        this.loading = false
      }

      return { saved, createdObjective }
    },

    setFocusOnTitle() {
      // nextTick and setTimeout(both!) are needed to make input always focused on modal window
      // opening because modal window(o-modal) has transition of opacity with 0.2s
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.title && this.$refs.title.focus()
        }, 200)
      })
    },

    initCustomFieldsValues() {
      this.customFieldsValues = {
        ...createInitialCustomFieldsValuesForOkrElement({
          isEdit: this.isEdit,
          elementTypeId: OBJECTIVE_TYPES.PERSONAL,
          modelValueCustomFields: this.modelValue?.customFields,
          currentWorkspaceCustomFields: this.currentWorkspaceCustomFields
        })
      }
    },

    saveModelValueToFormModel() {
      this.formModel = {
        ...this.formModel,
        ...this.modelValue,
        ...convertUsersListToOwnerAndStakeholders(this.modelValue.users),
        groups: [...convertGroupsListToGroups(this.modelValue.groups)],
        labelIds: this.modelValue.labels?.map(label => label.id) || [],
        confidenceLevelId: this.modelValue.confidenceLevelId || OKR_STATUSES.AUTO,
        parentId: this.modelValue.parentId ? this.modelValue.parentId : null,
        dueDate: this.modelValue.dueDate || null,
        elementStartDate: this.modelValue.elementStartDate || null,

        automaticElementStartDate: this.modelValue.automaticElementStartDate,
        automaticDueDate: this.modelValue.automaticDueDate
      }
      if (this.modelValue.groups) {
        this.isGroupManuallyChanged = true
      }
      if (!this.isEdit) {
        this.formModel.contribute =
          this.settings[COMPANY_SETTINGS_ENTITY_KEYS.OBJECTIVE_CONTRIBUTE_BY_DEFAULT]
      }
      this.localFormModel = cloneDeep(this.formModel)
    },

    async onParentObjectiveUpdate() {
      this.isParentObjectiveFieldFocused = false
      this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.PARENT] = true
      await this.updateObjective()
      this.localFormModel.parentId = this.formModel.parentId
      this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.PARENT] = false
    },

    onIntervalUpdate(intervalId) {
      if (this.formModel.elementStartDate || this.formModel.dueDate) {
        this.temporaryIntervalId = intervalId
        this.isConfirmIntervalChangeShow = true
      } else {
        this.formModel.intervalId = intervalId
      }
    },

    hideConfirmIntervalChange() {
      this.isConfirmIntervalChangeShow = false
      this.temporaryIntervalId = null
    },

    onConfirmIntervalChange() {
      this.formModel.elementStartDate = null
      this.formModel.dueDate = null
      this.formModel.intervalId = this.temporaryIntervalId
      this.hideConfirmIntervalChange()
    },

    async onParameterUpdate(savingStatus) {
      this[savingStatus] = true
      await this.updateObjective()
      this[savingStatus] = false
      // emits only if level was changed
      // to update OKR icon in breadcrumbs
      if (savingStatus === OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.TYPE) {
        this.$emit('level-changed', this.formModel.levelId)
      }
    },

    async onUpdateCustomFieldValue({ fieldId, value }) {
      this.customFieldsValues[fieldId] = value
    },

    updateActivities() {
      this.$refs.activities?.refreshData()
    },

    onDatesTriggerClick() {
      if (!this.isFieldDisabled) {
        this.showDatesDropdown = !this.showDatesDropdown
      }
    },

    onUpdateElementDate(params) {
      const { val, dateProp, savingStatus } = params

      this.formModel[dateProp] = val

      this.onParameterUpdate(savingStatus)
    },

    updatePeriodMode(mode) {
      this.formModel.startDateManual = mode
      this.formModel.dueDateManual = mode
      if (mode) {
        this.formModel.elementStartDate = this.formModel.automaticElementStartDate
        this.formModel.dueDate = this.formModel.automaticDueDate
      } else {
        this.formModel.elementStartDate = null
        this.formModel.dueDate = null
      }

      this.onParameterUpdate(OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.PERIOD_MODE)
    },

    async onUpdateStatus({ updateStatus, updateStatusForNestedElements, reset = false }) {
      if (!reset) {
        this.formModel.updateStatusForNestedElements = updateStatusForNestedElements
        await this.updateStatus(updateStatus)
      }
      this.tempStatus = null
    },

    async updateStatus(value) {
      this.formModel.confidenceLevelId = value
      this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.STATUS] = true
      await this.updateObjective()
      const statusValue = ALL_STATUS_OPTIONS.map(option => ({
        ...option,
        label: this.$t(option.label)
      })).find(item => item.value === this.formModel.confidenceLevelId)
      tracker.logEvent('Changed okr status', {
        category: EVENT_CATEGORIES.OKR_MANAGEMENT,
        label: 'objective',
        source: EVENT_SOURCES.FORM,
        value: this.formModel.confidenceLevelId ? statusValue.label : 'auto'
      })
      this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.STATUS] = false
    },

    onStatusUpdate(value) {
      if (isOkrElementClosed({ confidenceLevelId: value })) {
        this.showCloseModal = true
        this.tempStatus = value
        return
      }
      this.updateStatus(value)
    },

    addChildObjective(levelId) {
      this.$emit('open-child-objective', {
        parentId: this.modelValue.id,
        workspaceId: this.modelValue.workspaceId,
        intervalId: this.modelValue.intervalId,
        levelId
      })
      if (this.isEdit) {
        this.updateActivities()
      }
    },

    getKrJiraIssuePayload(kr) {
      return okrElementToLinkIssuePayload(kr)
    },

    onActionMenuItemsClick(name) {
      if (this.allowCreateElements) {
        const [action] = [
          name === OKR_ELEMENT_FORM_MENU_ACTIONS.LINK_TASK && this.onLinkJiraIssueClick,
          name === OKR_ELEMENT_FORM_MENU_ACTIONS.CREATE_JIRA_ISSUE && this.onCreateJiraIssueClick,
          this.onKRCreateClick
        ].filter(Boolean)

        action()
      }
    },

    onCreateJiraIssueFormSave() {
      this.getChildObjectives({ isRefetch: true })
      this.$emit('update')
      if (this.isEdit) {
        this.updateActivities()
      }
    },

    async onChildUpdated(isRefetch = false) {
      this.getChildObjectives({ isRefetch })
      this.$emit('update')
      if (this.isEdit) {
        this.updateActivities()
      }
    },

    async unlinkParentObjective() {
      this.formModel.parentId = null
      if (this.isEdit) {
        this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.PARENT] = true
        await this.onParentObjectiveUpdate()
        this[OKR_ELEMENT_PARAMETERS_SAVING_STATUSES.PARENT] = false
      }
    },

    async unlinkChildObjective(objective) {
      try {
        const objectivesApi = new ObjectivesInfoApiHandler()
        await objectivesApi.updateOkrElement({
          ...objective,
          elementId: objective.id,
          parentId: null
        })

        this.$emit('update')
        this.onChildUpdated(true)
      } catch (error) {
        handleError({ error })
      }
    },

    deleteItem(objective) {
      this.deleteObjectiveData = objective
      this.showConfirmDelete = true
      this.updateActivities()
    },

    /** @public */
    expandKr(krId) {
      if (this.$refs[`kr-${krId}`]) {
        this.$refs[`kr-${krId}`][0].expand()
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.of-ObjectiveForm {
  min-height: 0;
  // margin: 14px 0 24px 0;
  // margin: 26px 0;
  height: 100%;
  /* important for correct stretching/scroll in Safari */
  display: flex;
  flex-direction: column;
}

.of-ParentObjectiveField_Element {
  // border: 1px solid $azure-medium;
  border-radius: $border-radius-sm-next;
  overflow: hidden;
  --right-items-gap: 24px;
}

.of-NoPermissionsMessage {
  margin-bottom: 26px;
  --icon-color: #{$grade-medium-color-next};
}

.of-ClosedStatusMessage {
  margin-bottom: 26px;
}

.of-ClosedStatusMessage_Description {
  font-weight: fw('regular');
}

.of-KRList,
.of-LockMessage,
.of-Title {
  margin-bottom: 8px;
}

.of-NestedItemsWeightsButton {
  font-weight: fw('regular');
  color: $dark-2;
  padding: 0 8px 0 4px;
}

.of-Activities {
  margin-top: 28px;
}

.of-Progress {
  // margin-bottom: 22px;
}

.of-DatesLabel_Text {
  font-weight: fw('regular');
  font-size: $fs-14;
  align-self: flex-start;
}

.of-DatesLabel_Icon {
  margin-right: auto;
  align-self: flex-start;
}

.of-DatesLabel {
  display: flex;
  align-items: center;
  gap: 4px;
}

.of-DatesLabel_Value {
  width: auto;
  flex: 1;
  text-align: right;
  .is-safari & {
    // Fix for safari breaking wbr
    line-height: 20px;
  }
}

.of-Sidebar {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.of-ContributeWrapper {
  padding-top: 10px;
  padding-left: 14px;

  &:deep(.ac-Text) {
    line-height: normal;
  }
}
</style>

<style lang="scss">
.of-ObjectiveForm {
  .of-TitleErrorField .o-form-error {
    min-height: 20px;
  }

  .gl-AppSelectWrapper-formContentStyle .as-AppSelect {
    .as-AppDroplistButton {
      background: $white;
      border: 1px solid $grey-13;

      &:hover {
        border-color: $grey-10;
        box-shadow: 0 2px 2px rgba($black, 0.05);
      }

      &-focused:hover {
        border-color: $primary-color;
        box-shadow: 0 0 0 1px $primary-color;
        background: url('~@/assets/images/chevron-down.svg') right 8px center no-repeat;
      }

      &_Icon {
        display: none;
      }

      &_Content {
        padding-left: 0;
      }
    }
  }
}
</style>
