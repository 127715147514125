import { computed, inject } from 'vue'
import { useStore } from 'vuex'

import { isSalesforceAppInjectionKey, isWebAppInjectionKey } from '@/utils/injection-keys'
import { JIRA_CLOUD_API } from '@jira/util'
import { IS_DEVELOPMENT } from '@root/app-modes'

export const useReloadPage = () => {
  const store = useStore()

  const injectedIsWebApp = inject(isWebAppInjectionKey)
  const injectedIsSalesforceApp = inject(isSalesforceAppInjectionKey)

  const isPluginServer = computed(() => store?.getters['pluginOptions/isPluginServer']) || false

  const reloadPage = ({ isWebApp = false, isSalesforceApp = false } = {}) => {
    const resolvedIsWebApp = isWebApp || injectedIsWebApp

    const resolvedIsSalesforceApp = isSalesforceApp || injectedIsSalesforceApp

    if (resolvedIsWebApp || isPluginServer.value || IS_DEVELOPMENT) {
      location.reload()
    }

    if (resolvedIsSalesforceApp) {
      const message = { source: 'installation-app', action: 'RELOAD' }
      window.parent.postMessage(message, '*')
    }

    if (JIRA_CLOUD_API) {
      JIRA_CLOUD_API.navigator.reload()
    }
  }

  return {
    reloadPage
  }
}
