<template>
  <div class="ons-Settings">
    <div class="ons-Settings_Loader">
      <SavingIndicator ref="savingIndicatorTop" :type="SAVING_INDICATOR_TYPES.LINE" />
    </div>

    <div v-if="selectedOrganization" class="ons-Settings_Content">
      <div class="ons-ContentHeader">
        <OkrIcon :objective="levelToOkrIconElement(selectedOrganization)" :size="SIZES.XL" />
        <AppTitle :level="3" class="ons-ContentHeader_Title" disable-margin>
          {{ selectedOrganization[WEB_APP_ORGANIZATION_KEYS.NAME] }}
        </AppTitle>
      </div>

      <FormFieldNext :label="$t('organization.name.label')" class="bsb-FormField">
        <AppInput
          v-model.trim="name"
          :data-auto-testid="ORGANIZATION_NAME_INPUT_TEST_ID"
          :data-testid="ORGANIZATION_NAME_INPUT_TEST_ID"
          :is-error="!isNameValid"
          :max-length="50"
          :placeholder="$t('organization.name.label')"
          blur-on-enter
          size="xlg"
          style-type="primary"
          @blur="onBlur"
          @focus="isNameValid = true"
        />
        <AppFieldError v-if="!isNameValid" :show="!isNameValid">
          {{ $t('field.required') }}
        </AppFieldError>
      </FormFieldNext>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, unref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import WebAppOrganizationApiHandler from '@/api/web-app-organization'
import { SIZES } from '@/utils/components-configurations/okr-icon'
import { SAVING_INDICATOR_TYPES } from '@/utils/components-configurations/saving-indicator'
import { handleError } from '@/utils/error-handling'
import { isStringEmpty } from '@/utils/general'
import { showNotify } from '@/utils/notify'
import { levelToOkrIconElement } from '@/utils/objectives'
import { ORGANIZATION_NAME_INPUT_TEST_ID } from '@/utils/web-app/jest-helpers'
import { WEB_APP_ORGANIZATION_KEYS } from '@/utils/web-app/keys'

import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import OkrIcon from '@/components/objectives/items/OkrIcon'
import SavingIndicator from '@/components/SavingIndicator'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppTitle from '@/components/ui/AppTitle/AppTitle'

defineOptions({
  name: 'OrganizationNameSettings'
})

const store = useStore()
const { t } = useI18n()

const selectedOrganization = computed(() => {
  return store.getters['appContext/selectedOrganization']
})

const isNameValid = ref(true)
const name = ref(selectedOrganization.value?.[WEB_APP_ORGANIZATION_KEYS.NAME] || '')
const savingIndicatorTop = ref(null)

const onRenameOrganization = async () => {
  savingIndicatorTop.value.startSaving()
  const api = new WebAppOrganizationApiHandler()

  try {
    await api.renameOrganization({ [WEB_APP_ORGANIZATION_KEYS.NAME]: name.value.trim() })
    await store.dispatch('appContext/updateOrganization', {
      ...selectedOrganization.value,
      [WEB_APP_ORGANIZATION_KEYS.NAME]: name.value.trim()
    })
    name.value = selectedOrganization.value[WEB_APP_ORGANIZATION_KEYS.NAME]
    showNotify({
      title: t('organization.name.updated_successfully')
    })
  } catch (error) {
    handleError({ error })
  } finally {
    savingIndicatorTop.value.endSaving()
  }
}

const onBlur = async () => {
  if (isStringEmpty(unref(name))) {
    isNameValid.value = false
    return
  }
  if (unref(name) === selectedOrganization.value[WEB_APP_ORGANIZATION_KEYS.NAME]) {
    return
  }

  await onRenameOrganization()
}
</script>

<style lang="scss" scoped>
.ons-Settings {
  padding-top: 4px;
}

.ons-Settings_Loader {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  width: 100%;
}

.ons-Settings_Content {
  display: grid;
  gap: 20px;
}

.ons-ContentHeader {
  display: flex;
  gap: 20px;
  align-items: center;
  overflow: hidden;
}

.ons-ContentHeader_Title {
  white-space: pre;
}

.bsb-FormField {
  max-width: 400px;
}
</style>
