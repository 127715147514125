import { Extension, mergeAttributes } from '@tiptap/core'
import { PluginKey } from '@tiptap/pm/state'
import Suggestion from '@tiptap/suggestion'
import { VueRenderer } from '@tiptap/vue-3'
import tippy from 'tippy.js'

import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { KEY_CODES } from '@/utils/key-codes'
import { removeSlashCommandShortcut } from '@/utils/tiptap-utils'

import MenuList from '@/components/TipTapExtensions/SlashCommand/MenuList'

const extensionName = 'slashCommand'

let popup

export const SlashCommand = Extension.create({
  name: extensionName,

  priority: 200,

  onCreate() {
    popup = tippy(this.options.target, {
      interactive: true,
      trigger: 'manual',
      placement: 'bottom-start',
      theme: `${DROP_LIST_THEMES.COMMON_THEMES} ${DROP_LIST_THEMES.OLD_THEMES}`,
      maxWidth: '256px',
      offset: [0, 4],
      appendTo: this.options.appendTo || null,
      arrow: false,
      popperOptions: {
        modifiers: [
          {
            name: 'flip',
            enabled: false
          }
        ]
      }
    })
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        char: '/',
        allowSpaces: true,
        startOfLine: true,
        pluginKey: new PluginKey(extensionName),
        allow: ({ state, range }) => {
          const $from = state.doc.resolve(range.from)
          const isRootDepth = $from.depth === 1
          const isParagraph = $from.parent.type.name === 'paragraph'
          const isStartOfNode = $from.parent.textContent?.charAt(0) === '/'

          const isInColumn = this.editor.isActive('column')

          const afterContent = $from.parent.textContent?.substring(
            $from.parent.textContent?.indexOf('/')
          )
          const isValidAfterContent = !afterContent?.endsWith('  ')

          return (
            ((isRootDepth && isParagraph && isStartOfNode) ||
              (isInColumn && isParagraph && isStartOfNode)) &&
            isValidAfterContent
          )
        },
        command: ({ editor, props }) => {
          const { view } = editor

          removeSlashCommandShortcut(editor)

          props.action(editor)
          view.focus()
        },
        items: ({ query }) => {
          const withFilteredCommands = this.options.attributes.data.map(group => ({
            ...group,
            commands: group.commands
              .filter(command => !this.options.attributes.hiddenFeatures.includes(command.name))
              .map(command => {
                if (this.options.attributes?.commands?.[command.name]) {
                  return mergeAttributes(command, this.options.attributes.commands[command.name])
                }
                return command
              })
              .filter(item => {
                const labelNormalized = item.label.toLowerCase().trim()
                const queryNormalized = query.toLowerCase().trim()

                if (item.aliases) {
                  const aliases = item.aliases.map(alias => alias.toLowerCase().trim())

                  return (
                    labelNormalized.includes(queryNormalized) || aliases.includes(queryNormalized)
                  )
                }

                return labelNormalized.includes(queryNormalized)
              })
              .filter(command =>
                command.shouldBeHidden ? !command.shouldBeHidden(this.editor) : true
              )
          }))

          const withoutEmptyGroups = withFilteredCommands.filter(group => {
            return group.commands.length > 0
          })

          return withoutEmptyGroups.map(group => ({
            ...group,
            commands: group.commands.map(command => ({
              ...command,
              isEnabled: true
            }))
          }))
        },
        render: () => {
          let component

          let scrollHandler = null

          return {
            onStart: props => {
              component = new VueRenderer(MenuList, {
                props,
                editor: props.editor
              })

              const { view } = props.editor

              // const editorNode = view.dom

              const getReferenceClientRect = () => {
                if (!props.clientRect) {
                  return props.editor.storage[extensionName].rect
                }

                const rect = props.clientRect()

                if (!rect) {
                  return props.editor.storage[extensionName].rect
                }

                let yPos = rect.y

                if (rect.top + component.element.offsetHeight + 40 > window.innerHeight) {
                  const diff = rect.top + component.element.offsetHeight - window.innerHeight + 40
                  yPos = rect.y - diff
                }

                // Account for when the editor is bound inside a container that doesn't go all the way to the edge of the screen
                // const editorXOffset = editorNode.getBoundingClientRect().x
                return new DOMRect(rect.x, yPos, rect.width, rect.height)
              }

              scrollHandler = () => {
                popup?.[0].setProps({
                  getReferenceClientRect
                })
              }

              view.dom.parentElement?.addEventListener('scroll', scrollHandler)

              popup?.[0].setProps({
                getReferenceClientRect,
                content: component.element
              })

              if (!popup?.[0].props.appendTo) {
                popup?.[0].setProps({
                  appendTo: () => document.body
                })
              }

              popup?.[0].show()
            },

            onUpdate(props) {
              component.updateProps(props)

              const { view } = props.editor

              // const editorNode = view.dom

              const getReferenceClientRect = () => {
                if (!props.clientRect) {
                  return props.editor.storage[extensionName].rect
                }

                const rect = props.clientRect()

                if (!rect) {
                  return props.editor.storage[extensionName].rect
                }

                // Account for when the editor is bound inside a container that doesn't go all the way to the edge of the screen
                return new DOMRect(rect.x, rect.y, rect.width, rect.height)
              }

              let scrollHandler = () => {
                popup?.[0].setProps({
                  getReferenceClientRect
                })
              }

              view.dom.parentElement?.addEventListener('scroll', scrollHandler)

              // eslint-disable-next-line no-param-reassign
              props.editor.storage[extensionName].rect = props.clientRect
                ? getReferenceClientRect()
                : {
                    width: 0,
                    height: 0,
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0
                  }
              popup?.[0].setProps({
                getReferenceClientRect
              })
            },

            onKeyDown(props) {
              if (props.event.keyCode === KEY_CODES.ESCAPE) {
                popup?.[0].hide()

                return true
              }

              if (!popup?.[0].state.isShown) {
                popup?.[0].show()
              }

              return component.ref?.handleKeyDown(props)
            },

            onExit(props) {
              popup?.[0].hide()
              if (scrollHandler) {
                const { view } = props.editor
                view.dom.parentElement?.removeEventListener('scroll', scrollHandler)
              }
              component.destroy()
            }
          }
        }
      })
    ]
  },

  addStorage() {
    return {
      rect: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
        right: 0,
        bottom: 0
      }
    }
  }
})

export default SlashCommand
