/**
 *
 * @type {symbol}
 * @description Injection key for the app platform
 * @example inject(appPlatformInjectionKey)
 * @example provide(appPlatformInjectionKey, 'WEB_APP')
 * @see {@link https://vuejs.org/guide/components/provide-inject#working-with-symbol-keys toggle Composition and Options API to see all examples}
 * or in dedicated files: AppAuth.vue AppLink.vue ..etc
 */

// ▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮ APP PLATFORM KEYS ▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮
export const isWebAppInjectionKey = Symbol('isWebApp')
export const isSalesforceAppInjectionKey = Symbol('isSalesforceApp')
export const isJiraAppInjectionKey = Symbol('isJiraApp')
export const appPlatformInjectionKey = Symbol('appPlatform')
// ▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮ APP PLATFORM KEYS ▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮

// ▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮ OKR ELEMENTS KEYS ▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮
export const listStateInjectionKey = Symbol('listState')
export const commentFormInjectionKey = Symbol('commentForm')
export const descriptionFieldStateInjectionKey = Symbol('descriptionFieldState')
export const roadmapStateInjectionKey = Symbol('roadmapState')
export const subAppStateInjectionKey = Symbol('subAppState')
export const treeStateInjectionKey = Symbol('treeState')
export const tableStateInjectionKey = Symbol('tableState')
// ▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮ OKR ELEMENTS KEYS ▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮
