<template>
  <div class="dmwd-Label">
    <span class="dmwd-Label_Title">
      {{ t(labelItem.title) }}
    </span>
    <i18n-t
      v-if="labelItem.description"
      :keypath="labelItem.description"
      class="dmwd-Label_Description"
      tag="span"
    >
      <template #break>
        <br />
      </template>
    </i18n-t>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

defineOptions({
  name: 'DropdownMenuItemLabelWithDescription'
})

defineProps({
  labelItem: {
    type: Object,
    required: true
  }
})

const { t } = useI18n()
</script>

<style lang="scss" scoped>
.dmwd-Label {
  display: grid;
  gap: 3px;
}

.dmwd-Label_Title {
  &:not(:last-child) {
    margin-top: 3px;
  }
}

.dmwd-Label_Description {
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
}
</style>
