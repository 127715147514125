import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import isToday from 'dayjs/plugin/isToday'
import localeData from 'dayjs/plugin/localeData'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { isEmpty } from 'lodash'

import i18n from '@/i18n'
import { OKR_DATES_SELECT_DATE_PROPS } from '@/utils/okr-element-dates'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localeData)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.extend(isToday)
dayjs.extend(relativeTime)

// eslint-disable-next-line import/prefer-default-export
export const getDiffInDays = (start, end) => {
  const date1 = dayjs(start)
  const date2 = dayjs(end)
  const diff = date2.diff(date1, 'days')

  return Math.abs(diff)
}

export const dateFormat = 'DD MMM YYYY'

export const localDateToUtc = date => {
  if (!date) {
    return date
  }

  return compensateTimeZone(date, true)
}

export const utcDateToLocal = date => {
  if (!date) {
    return date
  }

  return compensateTimeZone(date)
}

export const formatDate = (timestamp, format = dateFormat) => dayjs.utc(timestamp).format(format)

export const addDaysToNow = days => {
  const now = dayjs()
  return now.add(days, 'days').format(dateFormat)
}

export const datepickerLang = () => ({
  days: dayjs.weekdaysShort(),
  months: dayjs.monthsShort(),
  monthsShort: dayjs.monthsShort(),
  formatLocale: {
    firstDayOfWeek: 1,
    days: dayjs.weekdaysShort(),
    months: dayjs.monthsShort(),
    monthsShort: dayjs.monthsShort()
  },
  placeholder: {
    date: i18n.global.t('datepicker.select_date'),
    dateRange: i18n.global.t('datepicker.select_date_range')
  },
  pickers: [
    i18n.global.t('datepicker.next', { days: 7 }),
    i18n.global.t('datepicker.next', { days: 30 }),
    i18n.global.t('datepicker.previous', { days: 7 }),
    i18n.global.t('datepicker.previous', { days: 30 })
  ]
})

export const getMonthList = () => dayjs.months()

export const getTimeDiffs = (now, targetDate) => {
  const msInHour = 60 * 60 * 1000
  const msInMin = 60 * 1000
  const msInDay = 24 * 60 * 60 * 1000
  return {
    diffForDay: Math.trunc((Number(now) - Number(targetDate)) / msInDay),
    diffForHour: Math.trunc((Number(now) - Number(targetDate)) / msInHour),
    diffForMin: Math.trunc((Number(now) - Number(targetDate)) / msInMin)
  }
}

const DAY_UNIT = 'day'

export const getValidDueOrStartDate = (date, dateProp = OKR_DATES_SELECT_DATE_PROPS.DUE_DATE) => {
  if (!date) {
    return date
  }

  if (dateProp === OKR_DATES_SELECT_DATE_PROPS.DUE_DATE) {
    return dayjs(date).endOf(DAY_UNIT).millisecond(0)
  } else {
    return dayjs(date).startOf(DAY_UNIT)
  }
}

export const getDisplayedDates = ({ dateFormat = 'DD MMM YYYY', selectedDates } = {}) => {
  if (!dateFormat) {
    return ''
  }
  if (isEmpty(selectedDates)) {
    return ''
  } else {
    const [start, end] = selectedDates
    return {
      start: dayjs(start).format(dateFormat),
      end: dayjs(end).format(dateFormat),
      full: `${dayjs(start).format(dateFormat)} — ${dayjs(end).format(dateFormat)}`
    }
  }
}

export const UNSELECTED_DATE = null

export const compensateTimeZone = (date, toUtc = false) => {
  if (!date || !dayjs(date).isValid()) {
    throw new Error('Invalid date')
  }

  const dateCopy = new Date(+date)
  const minutes = toUtc
    ? dateCopy.getMinutes() - dateCopy.getTimezoneOffset()
    : dateCopy.getMinutes() + dateCopy.getTimezoneOffset()
  return new Date(dateCopy.setMinutes(minutes))
}

export const getStartOfTheDay = date => {
  const dateCopy = new Date(+date)

  dateCopy.setHours(0)
  dateCopy.setMinutes(0)
  dateCopy.setSeconds(0)
  dateCopy.setMilliseconds(0)

  return dateCopy
}

export const getEndOfTheDay = date => {
  const dateCopy = new Date(+date)

  dateCopy.setHours(23)
  dateCopy.setMinutes(59)
  dateCopy.setSeconds(59)
  dateCopy.setMilliseconds(999)

  return dateCopy
}

export const DATE_FILTER_POPUP_STYLE = { left: 'unset', right: '10px' }
