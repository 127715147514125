<template>
  <li :class="classes" @click="onListItemClick" @mouseover="$emit('mouseover', $event)">
    <template v-if="multi">
      <AppCheckboxListItem
        ref="checkbox"
        :disable-toggling="disableToggling"
        :disabled="disabled"
        :hide-checkbox="hideCheckbox"
        :label="label"
        :model-value="modelValue"
        :rounded-checkmark="roundedCheckmark"
        :type="type"
        :val="val"
        @update:model-value="updateModelValue"
      >
        <template #checkmark>
          <slot name="checkmark" />
        </template>
        <template #label>
          <slot name="option-label">
            <div class="asi-SelectListOption">
              <div v-if="icon" class="asi-SelectListOption_Icon">
                <AppIcon :icon-name="icon" />
              </div>
              <span class="asi-SelectListOption_Label"> {{ label }} </span>
            </div>
          </slot>
        </template>
        <template #after>
          <slot name="after">
            <AppButton
              v-if="showOnlyThisButton"
              v-show="!onlyThisButtonIsHidden"
              class="asi-OnlyThisButton"
              size="sm"
              type="simple"
              @click.stop="onOnlyThisClick"
            >
              {{ $t('filter.only_this') }}
            </AppButton>
          </slot>
        </template>
      </AppCheckboxListItem>
    </template>

    <!-- option item for single mode -->
    <div v-else class="asi-AppSelectOptionsItem_Content-single">
      <slot name="option-label">
        <span class="asi-SelectListOption_Label">
          {{ label }}
        </span>
      </slot>
    </div>
  </li>
</template>

<script>
import { isArray, isNull, isNumber, isString } from 'lodash'
import { defineComponent } from 'vue'

import { SELECT_TYPES } from '@/utils/components-configurations/app-select'

import AppCheckboxListItem from '@/components/form/AppCheckboxListItem'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'

export default defineComponent({
  name: 'AppSelectListItem',

  components: {
    AppButton,
    AppCheckboxListItem,
    AppIcon
  },

  props: {
    modelValue: {
      required: true,
      validator: v => isArray(v) || isNumber(v) || isString(v) || isNull(v)
    },

    multi: {
      type: Boolean
    },

    label: {
      type: String,
      default: ''
    },

    icon: {
      type: String,
      default: ''
    },

    val: {
      type: [String, Number, Boolean],
      default: -1
    },

    selected: {
      type: Boolean
    },

    disabled: {
      type: Boolean
    },

    focused: {
      type: Boolean
    },

    split: {
      type: Boolean
    },

    highlighted: {
      type: Boolean
    },

    type: {
      type: String,
      default: SELECT_TYPES.DEFAULT_NEXT,
      validator: v => Object.values(SELECT_TYPES).includes(v)
    },

    hideCheckbox: {
      type: Boolean
    },

    showOnlyThisButton: {
      type: Boolean
    },

    roundedCheckmark: {
      type: Boolean
    },

    disableToggling: {
      type: Boolean
    }
  },

  emits: { mouseover: null, 'update:model-value': null, select: null },

  computed: {
    classes() {
      return {
        'asi-AppSelectOptionsItem': true,
        // -data modifier is used to differentiate 'technical' items like 'Loading' and
        // 'No options' from items with real data
        'asi-AppSelectOptionsItem-data': true,
        'asi-AppSelectOptionsItem-selected': this.selected,
        'asi-AppSelectOptionsItem-focused': this.focused,
        'asi-AppSelectOptionsItem-disabled': this.disabled,
        'asi-AppSelectOptionsItem-split': this.split,
        'asi-AppSelectOptionsItem-highlighted': this.highlighted,
        [`asi-AppSelectOptionsItem-${this.type}`]: true,
        'asi-AppSelectOptionsItem-withOnlyThisButton':
          this.showOnlyThisButton && !this.onlyThisButtonIsHidden
      }
    },

    onlyThisButtonIsHidden() {
      return this.modelValue.length === 1 && this.modelValue.includes(this.val)
    }
  },

  methods: {
    onListItemClick(event) {
      // checkbox emits click on toggle by itself, avoid duplicate
      if (this.multi) {
        event.preventDefault()
        this.$refs.checkbox.toggle()
      } else if (!this.disabled) {
        this.$emit('select', event)
      }
    },

    updateModelValue(value) {
      this.$emit('update:model-value', value)
      this.$emit('select', this.val)
    },

    onOnlyThisClick() {
      this.$emit('update:model-value', [this.val])
      this.$emit('select', this.val)
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
.asi-AppSelectOptionsItem {
  &-focused {
    background-color: $grey-medium;
  }

  &-focused#{&}-default-next,
  &-focused#{&}-modern {
    background-color: $grey-19;
  }

  &-split {
    margin-bottom: 9px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: -5px;
      width: calc(100% - 20px);
      height: 1px;
      background-color: $grey-2-next;
    }
  }
  &-highlighted {
    background-color: rgba($primary-color-next, 0.15);
  }
}

.asi-AppSelectOptionsItem_Content-single {
  display: flex;
  align-items: center;
  overflow: visible;
  width: 100%;
}

.asi-SelectListOption {
  display: flex;
  align-items: center;
  width: 100%;
}

.asi-SelectListOption_Icon {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.asi-SelectListOption_Label {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.asi-AppSelectOptionsItem {
  cursor: pointer;
  display: flex;
  align-items: center;

  color: $dark-1;
  font-size: $fs-14;
  @include hoverState($grey-19, 0%);
  @include activeState($grey-19);
  padding: 10px;
  min-height: 44px;
  box-sizing: border-box;

  &-secondary-next {
    padding-left: 12px;
  }
}

.asi-AppSelectOptionsItem-withOnlyThisButton {
  &:hover {
    &:deep(.o-checkbox-label-inner) {
      max-width: 60%;
    }
  }
}

.asi-OnlyThisButton {
  font-weight: fw('regular');
  font-size: $fs-12;
  line-height: 16px;
  border-radius: $border-radius-sm-next;
  padding: 4px 6px;
  height: 24px;
  display: none;
  max-width: 40%;

  .asi-AppSelectOptionsItem:hover & {
    display: block;
  }
}
</style>
