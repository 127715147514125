<template>
  <div class="gg-Wrapper">
    <SettingsPagesEmptyState
      v-if="isNoGroups && !isFiltersUsed"
      data-testid="empty-state"
      hero-height="128"
      hero-icon="no-groups-hero"
      hero-width="174"
    >
      <template #title> {{ $t('workspaces.no_groups.title') }} </template>
      <template #subtitle> {{ $t('workspaces.no_groups.subtitle') }} </template>

      <template #action>
        <AppButton
          data-testid="empty-state-create-button"
          height="24"
          icon="plus-next"
          type="primary-next"
          width="24"
          @click="onCreateGroupClick"
        >
          {{ $t('workspaces.create_group') }}
        </AppButton>
      </template>
    </SettingsPagesEmptyState>

    <template v-else>
      <div class="gg-Toolbar" data-testid="global-groups-toolbar">
        <div class="gg-Filters">
          <OkrFilterSelect
            :bottom-fixed-items="
              getFilterBottomItems({
                filtersValues: listState.filtersValues,
                filterKey: WORKSPACE_IDS
              })
            "
            :dropdown-min-width="200"
            :loading="isWorkspacesFilterLoading"
            :model-value="listState.filtersValues[WORKSPACE_IDS]"
            :options="workspacesOptions"
            :search-function="fetchWorkspacesForFilter"
            class="gg-Filters_Select"
            data-testid="workspaces-filter"
            has-only-this-button
            n-selected-label="workspaces.workspaces"
            prepend-icon="workspace"
            @update:model-value="onSelectFilterValue($event, WORKSPACE_IDS)"
            @update:options="workspacesOptions = $event"
          >
            <template #option-label="{ option }">
              <WorkspaceSelectOption
                v-if="option"
                :workspace="option"
                hide-lock-icon
                show-tooltip
              />
            </template>
            <template #bottom-fixed-items="{ bottomFixedItems }">
              <div v-for="item in bottomFixedItems" :key="item.id">
                <BottomFixedSelectItem
                  v-if="isClearSelectionAction(item.action)"
                  @click="clearSelection(WORKSPACE_IDS)"
                >
                  {{ $t(item.text) }}
                </BottomFixedSelectItem>
              </div>
            </template>
          </OkrFilterSelect>

          <OkrFilterSelect
            :bottom-fixed-items="
              getFilterBottomItems({
                filtersValues: listState.filtersValues,
                filterKey: GROUP_IDS
              })
            "
            :dropdown-min-width="300"
            :loading="isGroupsFilterLoading"
            :model-value="listState.filtersValues[GROUP_IDS]"
            :options="groupsFilterOptions"
            :search-function="searchString => fetchGroupsForFilter({ searchString })"
            class="gg-Filters_Select"
            data-testid="groups-filter"
            has-only-this-button
            n-selected-label="filter.groups"
            prepend-icon="team-next"
            @update:model-value="onSelectFilterValue($event, GROUP_IDS)"
            @update:options="groupsFilterOptions = $event"
          >
            <template #option-label="{ option }">
              <GlobalGroupsSelectOption v-if="option" :group="option" />
            </template>

            <template #bottom-fixed-items="{ bottomFixedItems }">
              <div v-for="item in bottomFixedItems" :key="item.id">
                <BottomFixedSelectItem
                  v-if="isClearSelectionAction(item.action)"
                  @click="clearSelection(GROUP_IDS)"
                >
                  {{ $t(item.text) }}
                </BottomFixedSelectItem>
              </div>
            </template>
          </OkrFilterSelect>

          <ResetFilterButton
            v-if="isFiltersUsed"
            data-testid="reset-filters-button"
            @click="onResetFilters"
          />
        </div>

        <AppButton
          data-testid="toolbar-create-button"
          height="24"
          icon="plus-next"
          type="primary-next"
          width="24"
          @click="onCreateGroupClick"
        >
          {{ $t('groups.create') }}
        </AppButton>
      </div>

      <div class="gg-TableWrapper" data-testid="global-groups-table-wrapper">
        <!-- IF THIS CHECKBOX WILL BE NEEDED, REMOVE SKIP PARAMETER FROM TESTS ABOUT THIS CHECKBOX TOO -->
        <!--        <AppCheckbox
          v-if="isPluginAdmin"
          v-model="allowWsAdminsManageGroups"
          class="gg-WsAdminCheckbox"
          data-testid="ws-admins-manage-groups-checkbox"
          style="&#45;&#45;label-color: var(&#45;&#45;dark-2); &#45;&#45;checkmark-margin: 4px"
          @update:model-value="updateWsAdminsSetting"
        >
          <i18n-t keypath="settings.company.ws_admins_manage_permission" scope="global">
            <b>{{ $t('settings.company.ws_admins_allowed_entities') }}</b>
          </i18n-t>
        </AppCheckbox>-->

        <InfiniteTableExpandable
          :child-items-key="CHILD_ITEMS_KEY"
          :column-with-expand-collapse-button="TABLE_COLUMNS_KEYS.NAME"
          :columns="resolvedColumns"
          :disable-user-select="false"
          :hover-row="tableHoverRow"
          :list-state="listState"
          :loading="listState.isLoading || isGroupsFilterLoading || isWorkspacesFilterLoading"
          :offset-left="GLOBAL_GROUP_TABLE_OFFSET"
          :offset-right="GLOBAL_GROUP_TABLE_OFFSET"
          :selected-items="groupsForMerge"
          sticky-header
          @toggle-expand-collapse="onExpandCollapseItem({ itemId: $event })"
          @expand-all="expandAll"
          @collapse-all="collapseAll"
        >
          <template #header-cell="{ column }">
            <template v-if="column.title">
              {{ $t(column.title) }}
            </template>
          </template>

          <template #cell="{ item, columnKey, depth }">
            <div v-if="columnKey === TABLE_COLUMNS_KEYS.BULK_CHECKBOX" class="gg-CheckboxWrapper">
              <AppCheckbox
                v-model="groupsForMerge"
                :disabled="isGroupUnavailable(item)"
                :value="item.id"
                data-testid="bulk-checkbox"
                full-height
                full-width
              />
            </div>

            <template v-if="columnKey === TABLE_COLUMNS_KEYS.NAME">
              <GlobalGroupNameCell
                :filtered-out="!item.fitFilterCriteria"
                :group="item"
                :hide-actions-label="depth >= 5"
                show-sync-icon
                skip-permissions-check
                @click="onExpandCollapseItem({ itemId: item.id })"
                @add-sub-group="onAddSubGroup(item)"
                @manage-group="onManageGroup(item)"
              />
            </template>
            <template
              v-if="columnKey === TABLE_COLUMNS_KEYS.WORKSPACE && !isGroupUnavailable(item)"
            >
              <WorkspacesListCell
                :filtered-out="!item.fitFilterCriteria"
                :workspaces="item.workspaces"
              />
            </template>

            <div
              v-if="columnKey === TABLE_COLUMNS_KEYS.ACTIONS && !isGroupUnavailable(item)"
              class="gg-ActionsCell"
              data-testid="actions-cell"
            >
              <DropdownMenu
                :items="getDropdownMenuItems(item)"
                :offset="[0, 0]"
                :type="DROPDOWN_MENU_TYPES.DEFAULT_NEXT"
                data-testid="row-dropdown-menu"
                preferred-position="bottom-end"
                @close="tableHoverRow = null"
                @open="tableHoverRow = getRowId(item.id)"
                @item-click="onMenuActionsClick($event, item)"
              >
                <template #activator>
                  <AppButton icon="more-next" size="sm" type="tertiary-next" />
                </template>
              </DropdownMenu>
            </div>
          </template>

          <template #footer>
            <NoSearchResults
              v-if="showNoSearchResults"
              :offset-left="GLOBAL_GROUP_TABLE_OFFSET"
              :offset-right="GLOBAL_GROUP_TABLE_OFFSET"
            >
              {{ $t('groups.no_groups') }}
            </NoSearchResults>
            <AppTableCreateButton
              :class="{
                'gg-FooterCreateButton-merge-enabled': isMergeModeEnabled
              }"
              class="gg-FooterCreateButton"
              full-width
              icon-name="plus-next"
              @click="onCreateGroupClick"
            >
              {{ $t('groups.create') }}
            </AppTableCreateButton>

            <InfiniteScrollLoaderNext
              v-if="!listState.isAllItemsLoaded"
              :loading="listState.isLoading"
              @load-more="onLoadMore"
            />

            <AppSnackbar
              :actions="snackbarActions"
              :count="groupsForMerge.length"
              data-testid="snackbar"
              inline
              sticky
              style="
                --padding-left: calc(var(--page-left-padding) + 4px);
                --padding-right: var(--page-right-padding);
                --text-color: var(--dark-2);
                --z-index: 4;
              "
              @close="clearSelectedGroupsForMerge"
              @action-click="onSnackbarActionClick"
            />
          </template>

          <template #loading>
            <GlobalGroupsLoader />
          </template>
        </InfiniteTableExpandable>
      </div>
    </template>
  </div>
  <portal to="modal-windows">
    <GlobalGroupModal
      v-model:show="isShowGlobalGroupModal"
      :predefined-parent-group="predefinedParentGroup"
      :tracking-source="trackingSource"
      data-testid="global-group-modal"
      @on-group-created="onCreateGroup"
      @clear-modal-payload="clearModalPayload"
    />

    <UnlinkPlatformGroupsModal
      :group="groupForUnlinkPlatformGroups"
      :show="isShowUnlinkPlatformGroupsModal"
      :tracking-source="EVENT_SOURCES.GROUP_LIST"
      @close="onCloseUnlinkPlatformGroupsModal"
      @on-groups-unlinked="onGroupsUnlinked"
    />

    <GlobalGroupsDeleteModal
      :group-to-delete="groupToDelete"
      :show="isDeleteGroupModalShow"
      :tracking-source="EVENT_SOURCES.GROUP_LIST"
      data-auto-testid="delete-group-modal"
      data-testid="delete-group-modal"
      @close="onDeleteGroupModalClose"
      @on-group-deleted="onGroupDeleted"
    />

    <ManageGlobalGroupModal
      :group-for-manage="groupForManage"
      :show="isManageGroupModalShow"
      :tracking-source="EVENT_SOURCES.GROUPS_SECTION"
      data-testid="manage-group-modal"
      @on-close="onManageGroupModalClose"
    />

    <MergeGlobalGroupsModal
      v-model:show="isShowMergeGroupsModal"
      :groups-for-merge="groupsForMerge"
      data-testid="merge-groups-modal"
      @on-group-merged="onGroupMerged"
    />
  </portal>
</template>

<script setup>
import { cloneDeep, has, isEmpty, uniq } from 'lodash'
import { computed, nextTick, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import GlobalGroupsApiHandler from '@/api/global-groups'
import { getFilterBottomItems, isClearSelectionAction } from '@/composables/bottom-fixed-items'
import { useGlobalGroups } from '@/composables/global-groups'
import { useFetchWorkspaceFilter } from '@/composables/plugin-users'
import {
  EVENT_SECTIONS,
  EVENT_SOURCES,
  FILTER_LABELS_FOR_TRACKING,
  TAB_NAMES_FOR_TRACKING,
  trackApplySettingsPagesFilterEvent,
  trackCreateGlobalGroupModalOpenedEvent
} from '@/tracking/amplitude-helpers'
import { ACTIONS_KEYS } from '@/utils/actions-keys'
// import { SETTING_PARAMETERS } from '@/utils/company-settings'
// import { COMPANY_SETTINGS_ENTITY_KEYS } from '@/utils/entity-keys'
import { DROPDOWN_MENU_TYPES } from '@/utils/components-configurations/dropdown-menu'
import { REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import {
  CHILD_ITEMS_KEY,
  GLOBAL_GROUP_TABLE_OFFSET,
  isGroupUnavailable,
  userCanUpdateGroup
} from '@/utils/global-groups'
import { memoizeGetGlobalGroupDropdownMenuItems } from '@/utils/memoizations'
import { highlightRow, isElementInViewPort } from '@/utils/objective-table'
import { DEFAULT_VALUE_FOR_FILTER, SELECT_ALL_VALUE } from '@/utils/okr-elements/filters'
import { EDIT_GROUP_KEY } from '@/utils/query-parameters'
import { getNewSelectWithSelectAllValue } from '@/utils/select'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import GlobalGroupsDeleteModal from '@/components/global-groups/GlobalGroupDeleteModal'
import GlobalGroupModal from '@/components/global-groups/GlobalGroupModal'
import GlobalGroupNameCell from '@/components/global-groups/GlobalGroupNameCell'
import GlobalGroupsSelectOption from '@/components/global-groups/GlobalGroupsSelectOption'
import ManageGlobalGroupModal from '@/components/global-groups/ManageGlobalGroupModal'
import MergeGlobalGroupsModal from '@/components/global-groups/MergeGlobalGroupsModal'
import BottomFixedSelectItem from '@/components/objectives/toolbar/BottomFixedSelectItem'
import OkrFilterSelect from '@/components/objectives/toolbar/OkrFilterSelect'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppSnackbar from '@/components/ui/AppSnackbar/AppSnackbar'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'
import InfiniteScrollLoaderNext from '@/components/ui/InfiniteScrollLoaderNext'
import { getRowId } from '@/components/ui/InfiniteTableExpandable/infinite-table-expandable-utils'
import InfiniteTableExpandable from '@/components/ui/InfiniteTableExpandable/InfiniteTableExpandable'
import NoSearchResults from '@/components/ui/NoSearchResults/NoSearchResults'
import ResetFilterButton from '@/components/ui/ResetFilterButton'
import SettingsPagesEmptyState from '@/components/ui/SettingsPagesEmptyState/SettingsPagesEmptyState'
import GlobalGroupsLoader from '@/components/ui/SkeletonLoaders/GlobalGroupsLoader'
import WorkspacesListCell from '@/components/ui/WorksapcesListCell/WorkspacesListCell'
import WorkspaceSelectOption from '@/components/ui/WorkspaceSelectOption/WorkspaceSelectOption'
import UnlinkPlatformGroupsModal from '@/components/workspaces/configuration-modal/UnlinkPlatformGroupsModal'
import { USERS_QUERY_KEYS } from '@/views/workspaces/settings/plugin-users/users-query-params'

defineOptions({
  name: 'GlobalGroupsPage'
})

const {
  fetchGroupsForFilter,
  useGlobalGroupsTableHelpers,
  ALL_GROUPS_OPTION,
  ALL_WORKSPACES_OPTION
} = useGlobalGroups()

const {
  listState,
  fetchGroupsForTable,
  reloadAllGroups,
  isFiltersUsed,
  restoreFiltersValuesAndExpandedItems,
  onExpandCollapseItem,
  expandAll,
  collapseAll
} = useGlobalGroupsTableHelpers({
  childItemsKey: CHILD_ITEMS_KEY
})

const trackingSource = ref('')

const getRowElement = id => document.getElementById(`iter-Row-${id}`)

const highlightNewRow = id => {
  const row = getRowElement(id)

  if (row) {
    // const { top } = row.getBoundingClientRect()
    setTimeout(() => {
      if (!isElementInViewPort(row)) {
        row.scrollIntoView({ block: 'end', behavior: 'smooth' })
      }
      nextTick(() => {
        highlightRow(row)
      })
    })
  }
}

const handleNewGroup = async newGroup => {
  if (newGroup.parentId) {
    const isParentRowOnPage = !!getRowElement(newGroup.parentId)

    if (isParentRowOnPage) {
      onExpandCollapseItem({ itemId: newGroup.parentId, keepExpanded: true })
      await nextTick()
      highlightNewRow(newGroup.id)
    }
  } else {
    await nextTick()
    highlightNewRow(newGroup.id)
  }
}

const { WORKSPACE_IDS, GROUP_IDS } = USERS_QUERY_KEYS

const COLUMNS = [
  {
    key: TABLE_COLUMNS_KEYS.NAME,
    title: 'group.label.name',
    width: 'auto'
  },
  {
    title: 'workspaces.workspaces',
    key: TABLE_COLUMNS_KEYS.WORKSPACE,
    width: 153
  },
  {
    key: TABLE_COLUMNS_KEYS.ACTIONS,
    width: 24
  }
]

const resolvedColumns = computed(() => {
  if (isMergeModeEnabled.value) {
    return [
      {
        key: TABLE_COLUMNS_KEYS.BULK_CHECKBOX,
        width: 32,
        isBulkAction: true
      },
      ...COLUMNS
    ]
  }

  return COLUMNS
})

const isMergeDisabled = computed(() => {
  return groupsForMerge.value.length < 2
})

const snackbarActions = computed(() => {
  return [
    {
      name: ACTIONS_KEYS.MERGE_GROUPS,
      icon: 'merge-groups',
      title: 'global_groups.merge_groups',
      color: 'var(--dark-2)',
      disable: isMergeDisabled.value,
      tooltipContent: isMergeDisabled.value ? t('global_groups.merge_disabled') : null
    }
  ]
})

const isShowGlobalGroupModal = ref(false)

const onCreateGroup = async newGroup => {
  isShowGlobalGroupModal.value = false
  await getInitialGroupsForFilter()
  await reloadAllGroups({
    keepLoadedItems: true,
    resetItems: false
  })

  await handleNewGroup(newGroup)
}

const clearModalPayload = () => {
  predefinedParentGroup.value = null
  trackingSource.value = ''
}

const store = useStore()

const userRoleForTracking = computed(() => {
  return store.getters['system/userRoleForTracking']
})
const onCreateGroupClick = () => {
  trackingSource.value = EVENT_SOURCES.GROUPS_SECTION
  isShowGlobalGroupModal.value = true

  trackCreateGlobalGroupModalOpenedEvent({
    role: userRoleForTracking.value,
    source: EVENT_SOURCES.GROUPS_SECTION
  })
}

const tableHoverRow = ref(null)

const onLoadMore = async () => {
  if (!listState.value.isLoading) {
    await getGroupsForTable()
  }
}

const { ADD_SUB_GROUP, MANAGE_GROUP, DELETE, MERGE_GROUPS, UNLINK_PLATFORM_GROUP } = ACTIONS_KEYS

const { t } = useI18n()
const getDropdownMenuItems = item => {
  return memoizeGetGlobalGroupDropdownMenuItems({
    group: item,
    t
  })
}

const onMenuActionsClick = (action, item) => {
  const [method] = [
    action === ADD_SUB_GROUP && onAddSubGroup,
    action === MANAGE_GROUP && onManageGroup,
    action === DELETE && onDeleteGroup,
    action === MERGE_GROUPS && onMergeGroups,
    action === UNLINK_PLATFORM_GROUP && onUnlinkPlatformGroup
  ].filter(Boolean)

  if (method) {
    method(item)
  }
}

const isShowMergeGroupsModal = ref(false)

const onSnackbarActionClick = action => {
  if (action === ACTIONS_KEYS.MERGE_GROUPS && !isMergeDisabled.value) {
    isShowMergeGroupsModal.value = true
  }
}

const predefinedParentGroup = ref(null)

const groupToDelete = ref({})
const isDeleteGroupModalShow = ref(false)
const onDeleteGroup = group => {
  groupToDelete.value = group
  nextTick(() => {
    isDeleteGroupModalShow.value = true
  })
}
const onAddSubGroup = group => {
  trackingSource.value = EVENT_SOURCES.CREATE_SUBGROUP_BUTTON
  predefinedParentGroup.value = group.id
  nextTick(() => {
    isShowGlobalGroupModal.value = true
    trackCreateGlobalGroupModalOpenedEvent({
      role: userRoleForTracking.value,
      source: EVENT_SOURCES.CREATE_SUBGROUP_BUTTON
    })
  })
}

const groupForManage = ref(null)
const isManageGroupModalShow = ref(false)

const onManageGroup = group => {
  clearSelectedGroupsForMerge()
  groupForManage.value = group
  nextTick(() => {
    isManageGroupModalShow.value = true
  })
}

const groupForUnlinkPlatformGroups = ref({})
const isShowUnlinkPlatformGroupsModal = ref(false)
const onUnlinkPlatformGroup = group => {
  clearSelectedGroupsForMerge()
  groupForUnlinkPlatformGroups.value = group
  nextTick(() => {
    isShowUnlinkPlatformGroupsModal.value = true
  })
}

const onGroupsUnlinked = async () => {
  onCloseUnlinkPlatformGroupsModal()
  await getInitialGroupsForFilter()
  await reloadAllGroups({
    keepLoadedItems: true,
    resetItems: false
  })
}

const onCloseUnlinkPlatformGroupsModal = () => {
  isShowUnlinkPlatformGroupsModal.value = false
  groupForUnlinkPlatformGroups.value = {}
}

const groupsForMerge = ref([])

const isMergeModeEnabled = computed(() => !isEmpty(groupsForMerge.value))
const onMergeGroups = group => {
  if (groupsForMerge.value.includes(group.id)) {
    groupsForMerge.value = groupsForMerge.value.filter(id => id !== group.id)
  } else {
    groupsForMerge.value = uniq([group.id, ...groupsForMerge.value])
  }
}

const clearSelectedGroupsForMerge = () => {
  if (!isEmpty(groupsForMerge.value)) {
    groupsForMerge.value = []
  }
}

const onGroupMerged = async newGroup => {
  clearSelectedGroupsForMerge()
  isShowMergeGroupsModal.value = false
  await getInitialGroupsForFilter()
  await reloadAllGroups({
    keepLoadedItems: true,
    resetItems: false
  })

  await handleNewGroup(newGroup)
}

const onManageGroupModalClose = async () => {
  await getInitialGroupsForFilter()
  await reloadAllGroups({
    keepLoadedItems: true,
    resetItems: false
  })
  isManageGroupModalShow.value = false
  groupForManage.value = null
}

const onSelectFilterValue = async (newValue, filterKey) => {
  clearSelectedGroupsForMerge()

  if (filterKey) {
    listState.value.filtersValues[filterKey] = getNewSelectWithSelectAllValue(
      newValue,
      listState.value.filtersValues[filterKey]
    )

    trackApplySettingsPagesFilterEvent({
      section: EVENT_SECTIONS.GROUPS_AND_USERS,
      tab: TAB_NAMES_FOR_TRACKING.GROUPS,
      label: FILTER_LABELS_FOR_TRACKING[filterKey]
    })

    await reloadAllGroups()
  }
}

const clearSelection = async filterKey => {
  clearSelectedGroupsForMerge()

  if (filterKey) {
    listState.value.filtersValues[filterKey] = cloneDeep(DEFAULT_VALUE_FOR_FILTER)
  }

  await reloadAllGroups()
}

const isWorkspacesFilterLoading = ref(false)
const workspacesOptions = ref([ALL_WORKSPACES_OPTION])
const isGroupsFilterLoading = ref(false)
const groupsFilterOptions = ref([ALL_GROUPS_OPTION])

const fetchWorkspacesForFilter = async (searchString = null) => {
  isWorkspacesFilterLoading.value = true
  let result = []
  try {
    result = await useFetchWorkspaceFilter(searchString, {
      [WORKSPACE_IDS]: listState.value.filtersValues[WORKSPACE_IDS]
    })
  } finally {
    isWorkspacesFilterLoading.value = false
  }

  return result
}

const validateFilterValue = ({ value = [], options = [], optionKey = 'id' } = {}) => {
  return value.filter(item => {
    return options.find(option => option[optionKey] === item) || item === SELECT_ALL_VALUE
  })
}

const getInitialWorkspacesForFilter = async () => {
  const workspaces = await fetchWorkspacesForFilter()
  workspacesOptions.value = [ALL_WORKSPACES_OPTION, ...workspaces]
  const validatedValue = validateFilterValue({
    value: listState.value.filtersValues[WORKSPACE_IDS],
    options: workspacesOptions.value
  })

  listState.value.filtersValues[WORKSPACE_IDS] = isEmpty(validatedValue)
    ? cloneDeep(DEFAULT_VALUE_FOR_FILTER)
    : validatedValue
}

const getInitialGroupsForFilter = async () => {
  isGroupsFilterLoading.value = true
  try {
    const selectedGroups = listState.value.filtersValues[GROUP_IDS]
    const groups = await fetchGroupsForFilter({ groupIds: selectedGroups })
    groupsFilterOptions.value = [ALL_GROUPS_OPTION, ...groups]
    const validatedValue = validateFilterValue({
      value: selectedGroups,
      options: groupsFilterOptions.value
    })

    listState.value.filtersValues[GROUP_IDS] = isEmpty(validatedValue)
      ? cloneDeep(DEFAULT_VALUE_FOR_FILTER)
      : validatedValue
  } catch (error) {
    handleError({ error })
  } finally {
    isGroupsFilterLoading.value = false
  }
}

const getGroupsForTable = async () => {
  await fetchGroupsForTable({ [REQUEST_ENTITY_KEYS.EXPAND_ALL]: true })
}

// const allowWsAdminsManageGroups = ref(false)

// const setInitialValueForAllowWsAdminsManage = () => {
//   if (isPluginAdmin.value) {
//     allowWsAdminsManageGroups.value =
//       store.state.system.settings[COMPANY_SETTINGS_ENTITY_KEYS.ALLOW_MANAGE_GROUPS_FOR_WS_ADMINS]
//   }
// }

const route = useRoute()

const handleManageGroup = async groupId => {
  const api = new GlobalGroupsApiHandler()

  const group = await api.getGlobalGroupById({
    groupId: Number(groupId)
  })

  if (group && userCanUpdateGroup(group)) {
    onManageGroup(group)
  }
}

// const store = useStore()
const getInitialData = async () => {
  // setInitialValueForAllowWsAdminsManage()

  if (has(route.query, EDIT_GROUP_KEY)) {
    handleManageGroup(route.query[EDIT_GROUP_KEY])
  }

  restoreFiltersValuesAndExpandedItems()

  await Promise.all([getInitialWorkspacesForFilter(), getInitialGroupsForFilter()])
  await getGroupsForTable()
}

onMounted(() => {
  getInitialData()
})

const onResetFilters = async () => {
  clearSelectedGroupsForMerge()
  await reloadAllGroups({ resetFilters: true })
}

const isNoGroups = computed(() => {
  return (
    !listState.value.isLoading &&
    !isWorkspacesFilterLoading.value &&
    !isGroupsFilterLoading.value &&
    isEmpty(listState.value.items)
  )
})

const showNoSearchResults = computed(() => isNoGroups.value && isFiltersUsed.value)

const onGroupDeleted = () => {
  clearSelectedGroupsForMerge()

  listState.value.filtersValues[GROUP_IDS] = listState.value.filtersValues[GROUP_IDS].filter(
    id => id !== groupToDelete.value.id
  )

  groupsFilterOptions.value = groupsFilterOptions.value.filter(
    option => option.id !== groupToDelete.value.id
  )

  onDeleteGroupModalClose()
  reloadAllGroups({ keepLoadedItems: true, resetItems: false })
}

const onDeleteGroupModalClose = () => {
  isDeleteGroupModalShow.value = false
  groupToDelete.value = null
}

// const updateWsAdminsSetting = async value => {
//   try {
//     const api = new PluginApiHandler()
//     await api.updateSettings({
//       settingId: SETTING_PARAMETERS.ALLOW_MANAGE_GROUPS_FOR_WS_ADMINS,
//       settingValue: value
//     })
//     await store.dispatch('system/setSettingsParameter', {
//       parameter: COMPANY_SETTINGS_ENTITY_KEYS.ALLOW_MANAGE_GROUPS_FOR_WS_ADMINS,
//       value
//     })
//   } catch (error) {
//     setInitialValueForAllowWsAdminsManage()
//     handleError({ error })
//   }
// }

// const isPluginAdmin = computed(() => {
//   return store.state.pluginOptions.isPluginAdmin
// })
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app-table-helpers';

.gg-Filters_Select {
  max-width: 220px;
  --select-skeleton-left: 0px;
  --select-skeleton-top: 0px;
}

.gg-Wrapper {
  --toolbar-padding-top: 16px;

  max-width: $page-width-lg;
  width: 100%;
}

.gg-TableWrapper {
  --head-padding-bottom: 4px;
  --head-padding-top: 24px;
  --sticky-top: calc(var(--toolbar-padding-top) + 32px); // where 32px is height of toolbar content
  padding: 0 v-bind(GLOBAL_GROUP_TABLE_OFFSET) 16px;
  &:deep(.iter-Cell-name) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.gg-ActionsCell {
  @extend %expandable-table-hidden-items;
}

.gg-FooterCreateButton {
  --padding-left: 64px;

  &-merge-enabled {
    --padding-left: calc(64px + 32px); // 32px is width of checkbox cell
  }
}

.gg-Toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--toolbar-padding-top) v-bind(GLOBAL_GROUP_TABLE_OFFSET) 0;
  position: sticky;
  top: 0;
  background: $white;
  z-index: 5;
}

.gg-Filters {
  display: flex;
  gap: 8px;
}

.gg-CheckboxWrapper {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  --label-justify-content: center;
}

// .gg-WsAdminCheckbox {
//   display: flex;
//   margin-top: 16px;
// }
</style>
