<template>
  <template v-if="!isFullAppLoader">
    <AppDialog
      v-if="!canMove.canMoveWithoutNested"
      :show="show"
      :title="$t('objective.cant_move')"
      @on-close="hideModal"
      @on-confirm="hideModal"
    >
      <div>
        <p class="smw-Subtitle smw-Subtitle_ObjectiveName">
          {{ objectiveData.displayId }} {{ objectiveData.name }}
        </p>

        <div class="smw-Subtitle">
          {{
            t('objective.obj_cant_move_because_kr', {
              closedStatus: t('dashboard.closed'),
              abandonedStatus: t('dashboard.abandoned')
            })
          }}
        </div>
      </div>
      <template #confirm-btn-text>{{ t('tour.got_it') }}</template>
    </AppDialog>
    <ObjectiveMoveModal
      v-else
      :disable="!isProceed || isAnyChildClosed"
      :loading="loading"
      :show="show"
      :title="t('objective.move_objective', { displayId: objectiveData?.displayId })"
      :with-back-button="false"
      @close="hideModal"
      @proceed="proceed"
    >
      <div>
        <p class="smw-Subtitle">
          {{ t('objective.move_objective_subtitle') }}
        </p>
        <p class="smw-Subtitle smw-Subtitle_ObjectiveName">
          {{ objectiveData.displayId }} {{ objectiveData.name }}
        </p>
        <div class="smw-SelectWrapper">
          <FormField type="default-next">
            <template #label>
              <span class="smw-FieldTitle">{{ t('field.workspace.title') }}</span>
            </template>
            <AppSelect
              v-model="formModel.workspaceId"
              :offset="[0, -40]"
              :options="workspacesOptions"
              :search-function="value => localSearch({ value, options: workspacesOptions })"
              class="smw-Select"
              dropdown-search
              item-label="name"
              item-value="id"
              @update:model-value="onUpdateWorkspace"
            >
              <template #button-content="{ option }">
                <WorkspaceSelectOption v-if="option" :workspace="option" hide-lock-icon />
              </template>
              <template #option-label="{ option }">
                <WorkspaceSelectOption v-if="option" :workspace="option" />
              </template>
            </AppSelect>
          </FormField>

          <FormField type="default-next">
            <template #label>
              <span class="smw-FieldTitle">{{ t('field.quoter.title') }}</span>
            </template>
            <AppSelect
              v-model="formModel.intervalId"
              :loading="loaders.intervalLoader"
              :offset="[0, -40]"
              :options="intervalOptions"
              :search-function="value => localSearch({ value, options: intervalOptions })"
              class="smw-Select"
              dropdown-search
              item-label="name"
              item-value="id"
              @update:model-value="onUpdateInterval"
            >
            </AppSelect>
          </FormField>

          <FormField type="default-next">
            <template #label>
              <span class="smw-FieldTitle">{{ t('field.parentItem') }}</span>
            </template>
            <AppSelect
              v-model="formModel.parentId"
              :loading="loaders.parentLoader"
              :offset="[0, -40]"
              :options="parentOptionsMap"
              :search-function="
                searchString =>
                  fetchParentObjectives(formModel.workspaceId, formModel.intervalId, searchString)
              "
              class="smw-Select"
              dropdown-search
              item-label="name"
              item-value="id"
              @update:model-value="value => updateModelValue({ value, key: 'parentId' })"
              @update:options="updateParentOptions"
            >
              <template #option-label="{ option }">
                <ObjectiveSelectItem
                  v-if="option"
                  :objective="option"
                  show-interval
                  show-workspace
                />
              </template>
              <template #button-content="{ option, isDropdownVisible }">
                <OkrIcon :objective="option" class="smw-Select_ObjectiveIcon" />
                <span
                  :class="{ 'smw-Select_ObjectiveId-focused': isDropdownVisible }"
                  class="smw-Select_ObjectiveId"
                >
                  {{ option.displayId }}
                </span>
                <span
                  :class="{ 'smw-Select_LabelName-focused': isDropdownVisible }"
                  class="smw-Select_LabelName"
                >
                  {{ option.name }}
                </span>
              </template>
            </AppSelect>
          </FormField>
          <FormField type="default-next">
            <template #label>
              <span class="smw-FieldTitle">{{ t('objective.move_nested_items') }}</span>
            </template>
            <div class="smw-SwitcherWrapper">
              <AppCheckbox v-model="formModel.moveNested">
                <span class="smw-SwitcherWrapper_Text">{{ t('kr.move_nested_items') }}</span>
              </AppCheckbox>
              <AppInfoMessage v-if="isAnyChildClosed" :type="INFO_MESSAGE_TYPES.ERROR">
                <span class="smw-ErrorMsg">
                  {{
                    $t('objective.obj_cant_move', {
                      closedStatus: $t('dashboard.closed'),
                      abandonedStatus: $t('dashboard.abandoned')
                    })
                  }}
                </span>
              </AppInfoMessage>
              <AppCheckbox v-model="formModel.moveCrossWorkspace" :disabled="!formModel.moveNested">
                <span class="smw-SwitcherWrapper_Text">{{ t('kr.move_from_all_workspaces') }}</span>
              </AppCheckbox>
            </div>
          </FormField>
        </div>
      </div>
    </ObjectiveMoveModal>
  </template>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { ref, computed, nextTick, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import IntervalsInfoApiHandler from '@/api/intervals-info'
import ObjectivesInfoApiHandler from '@/api/okr-elements'
import { INFO_MESSAGE_TYPES } from '@/utils/components-configurations/app-info-message'
import { handleError } from '@/utils/error-handling'
import { localSearch, OKR_ELEMENT_PERMISSIONS } from '@/utils/objectives'

import AppDialog from '@/components/AppDialog'
import FormField from '@/components/form/FormField'
import ObjectiveSelectItem from '@/components/objectives/forms/ObjectiveSelectItem'
import OkrIcon from '@/components/objectives/items/OkrIcon'
import ObjectiveMoveModal from '@/components/objectives/objective-move/ObjectiveMoveModal'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import WorkspaceSelectOption from '@/components/ui/WorkspaceSelectOption/WorkspaceSelectOption'

const { t } = useI18n()
const props = defineProps({
  show: {
    type: Boolean
  },
  data: {
    type: Object,
    default: () => ({})
  },
  loading: {
    type: Boolean
  },
  objectiveData: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits({
  'update:show': null,
  proceed: null
})

const intervalApi = new IntervalsInfoApiHandler()
const store = useStore()

const loaders = ref({
  intervalLoader: false,
  parentLoader: false
})

const workspacesOptions = computed(() => store.state.workspaces.workspaces)

const formModel = ref(props.data)

const intervalOptions = ref([])

const fetchIntervals = async workspaceId => {
  const { currentIndex, intervals } = await intervalApi.getIntervalsInfo({
    workspaceId
  })
  const onlyOpenedIntervals = intervals.filter(interval => interval.open)
  if (isEmpty(onlyOpenedIntervals)) {
    formModel.value.intervalId = null
  } else if (onlyOpenedIntervals.length >= 2) {
    const intervalByCurrentIndex = onlyOpenedIntervals[currentIndex]
    if (intervalByCurrentIndex && !intervalByCurrentIndex.backlog) {
      formModel.value.intervalId = intervalByCurrentIndex.id
    } else {
      const notBacklogInterval = onlyOpenedIntervals.find(interval => !interval.backlog)
      formModel.value.intervalId = notBacklogInterval.id
    }
  } else {
    formModel.value.intervalId = onlyOpenedIntervals[0].id
  }
  intervalOptions.value = onlyOpenedIntervals
}

const objectiveInfoApi = new ObjectivesInfoApiHandler()

const canMove = ref({
  canMoveWithoutNested: true,
  canMoveWithNested: true
})

const isFullAppLoader = computed(() => store.state.system.fullAppLoading.flag)
const toggleFullAppLoader = () => store.dispatch('system/toggleFullAppLoader')
const checkMovingAvailability = async () => {
  try {
    await toggleFullAppLoader()
    canMove.value = await objectiveInfoApi.checkMovingAvailability({
      elementId: props.objectiveData.id
    })
  } catch (error) {
    handleError({ error })
  } finally {
    await toggleFullAppLoader()
  }
}

watch(
  () => props.show,
  async value => {
    if (value) {
      await checkMovingAvailability()
      if (!canMove.value.canMoveWithoutNested) {
        return
      }
      await fetchIntervals(formModel.value.workspaceId)
      await fetchParentObjectives(formModel.value.workspaceId, formModel.value.intervalId)
    }
  },
  { immediate: true }
)

const parentOptions = ref([])
const parentOptionsMap = computed(() => {
  return [
    {
      name: t('move.no_parent'),
      id: null,
      customIcon: 'unassigned-owner',
      permissions: [OKR_ELEMENT_PERMISSIONS.READ],
      displayId: ''
    },
    ...parentOptions.value
  ]
})

const fetchParentObjectives = async (workspaceId, intervalId, searchString = null) => {
  let data = []
  try {
    loaders.value.parentLoader = true
    const { elements } = await objectiveInfoApi.getParentObjectiveInfo({
      workspaceId,
      intervalId,
      searchString,
      strict: true
    })
    if (searchString === null) {
      parentOptions.value = elements
    }
    data = elements
  } finally {
    loaders.value.parentLoader = false
  }
  return data
}

const hideModal = async () => {
  emit('update:show', false)

  await nextTick()
}
const updateModelValue = ({ value, key }) => (formModel.value[key] = value)
const isProceed = computed(() => formModel.value.workspaceId && formModel.value.intervalId)

const proceed = () => {
  emit('proceed', formModel.value)
}

const onUpdateWorkspace = async value => {
  updateModelValue({ value, key: 'workspaceId' })
  await fetchIntervals(value)
  await fetchParentObjectives(value, formModel.value.intervalId)
  formModel.value.parentId = null
}

const onUpdateInterval = intervalId => {
  updateModelValue({ value: intervalId, key: 'intervalId' })
  fetchParentObjectives(formModel.value.workspaceId, intervalId)
  formModel.value.parentId = null
}
const updateParentOptions = value => {
  parentOptions.value = value.filter(v => v.id)
}

const isAnyChildClosed = computed(() => {
  return !canMove.value.canMoveWithNested && formModel.value.moveNested
})
</script>
<script>
//eslint-disable-next-line
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SelectMoveWorkspace'
})
</script>

<style lang="scss" scoped>
.smw-FieldTitle {
  font-family: $system-ui;
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
  color: $dark-3;
}
.smw-SwitcherWrapper_Text {
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-2;
}
.smw-SwitcherWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
  margin-top: 12px;
  --checkmark-margin: 0 4px;
}
.smw-Subtitle {
  margin-bottom: 0;
  font-size: $fs-14;
  line-height: 20px;
  font-family: $system-ui;
  color: $dark-2;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  &_ObjectiveName {
    font-weight: fw('bold');
    margin-bottom: 23px;
  }
}
.smw-SelectWrapper {
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.smw-Select_ObjectiveIcon {
  margin-right: 8px;
}
.smw-Select_ObjectiveId {
  margin-right: 4px;
  font-weight: fw('semi-bold');
  font-size: $fs-14;
  line-height: 20px;
  color: $primary-color-next;
  min-width: max-content;
  &-focused {
    color: $white;
  }
}
.smw-Select_LabelName {
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &-focused {
    color: $white;
  }
}
.smw-ErrorMsg {
  color: $grade-low-color-next;
  font-size: $fs-14;
  line-height: 20px;
}
</style>
