<template>
  <div v-if="!isConnectionLost" class="jcs-DisclaimerWrapper">
    <AppAlert :with-right-side="false" style="--left-side-aligning: flex-start">
      <template #title>
        <span class="jcs-DisclaimerTitle">
          <!--    eslint-disable      -->
          Important: The Oboard OKR App and its integration are currently in Open Beta and may be
          unstable.
          <a :href="SUPPORT_URL" target="_blank"> Please contact our support team </a>
          to participate in the Early Bird program and receive support for these tools.
          <!--    eslint-enable      -->
        </span>
      </template>
    </AppAlert>
  </div>
  <div class="jcs-Settings">
    <slot />
    <UseAppPlatform v-slot="{ isJiraApp, isWebApp, isSalesforceApp, connectionStatus }">
      <AppTitle :level="4" disable-top-margin> {{ t('connections.status') }}</AppTitle>
      <div
        :class="{
          'jcs-StatusView-connected': connectionStatus && !isConnectionLost,
          'jcs-StatusView-error': isConnectionLost,
          'jcs-StatusView-loading': isLoading,
          'jcs-StatusView-reverse': isWebApp || isSalesforceApp
        }"
        class="jcs-StatusView"
      >
        <div class="jcs-StatusView_Item">
          <AppIcon height="48" icon-name="connection-jira" width="48" />
          <div class="jcs-StatusItem_Label">{{ t('app_name.jira') }}</div>
        </div>

        <div class="jcs-Connector">
          <div class="jcs-Connector_Icon">
            <div v-if="isLoading" class="jcs-ConnectorLoader">
              <LoadingCircle size="xsmall" />
            </div>

            <AppIcon
              v-else
              :icon-name="getConnectorProperties({ connectionStatus }).icon"
              class="jcs-ConnectorIcon"
              height="24"
              width="24"
            />
          </div>
          <div class="jcs-Connector_Label">
            {{ getConnectorProperties({ connectionStatus }).label }}
          </div>
        </div>

        <div class="jcs-StatusView_Item">
          <AppIcon height="48" icon-name="connection-web-app" width="48" />
          <div class="jcs-StatusItem_Label">
            {{ t('app_name') }} {{ t('app.platform.web_app') }}
          </div>
        </div>
      </div>

      <div class="jcs-Steps">
        <template v-if="!connectionStatus">
          <JiraAppIntegration v-if="isJiraApp" />
          <WebAppIntegration v-if="isWebApp || isSalesforceApp" v-model:is-loading="isLoading" />
        </template>

        <DisconnectModule
          v-else
          :is-connection-lost="isConnectionLost"
          :is-loading="isLoading"
          @on-disconnect-confirm="onDisconnectConfirm"
        />

        <AppDivider no-margin />
        <div>
          <AppTitle :level="4" disable-top-margin> {{ t('connections.help.title') }}</AppTitle>
          <AppHrefLink
            href="https://oboard.atlassian.net/wiki/spaces/OBPFJ/pages/1162018833"
            prepend-icon="guide"
          >
            {{ t('connections.guide.link_text') }}
          </AppHrefLink>
        </div>
      </div>
    </UseAppPlatform>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import WebAppConnectionsApiHandler from '@/api/web-app-connections'
import { handleError } from '@/utils/error-handling'
import { INSTANCE_STATES } from '@/utils/instance-states'
import { SUPPORT_URL } from '@/utils/support'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

import AppAlert from '@/components/AppAlert'
import AppHrefLink from '@/components/AppHrefLink'
import AppDivider from '@/components/ui/AppDivider'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppTitle from '@/components/ui/AppTitle/AppTitle'
import LoadingCircle from '@/components/ui/LoadingCircle/LoadingCircle'
import UseAppPlatform from '@/components/UseAppPlatform'
import DisconnectModule from '@/views/workspaces/settings/integrations/DisconnectModule'
import JiraAppIntegration from '@/views/workspaces/settings/integrations/JiraAppIntegration'
import WebAppIntegration from '@/views/workspaces/settings/integrations/WebAppIntegration'

defineOptions({
  name: 'JiraConnectionSettings'
})

const props = defineProps({
  isConnectionLost: {
    type: Boolean
  }
})

const isLoading = ref(false)

const { t } = useI18n()
const getConnectorProperties = ({ connectionStatus }) => {
  if (isLoading.value) {
    return {
      label: connectionStatus ? t('connections.disconnecting') : t('connections.connecting')
    }
  }

  if (props.isConnectionLost) {
    return {
      icon: 'info-error',
      label: t('connections.lost')
    }
  }

  const icon = connectionStatus ? 'connection-connected' : 'close-common'
  const label = connectionStatus ? t('connections.connected') : t('connections.disconnected')

  return { icon, label }
}

const store = useStore()
const onDisconnectConfirm = async () => {
  isLoading.value = true
  try {
    const api = new WebAppConnectionsApiHandler()
    await api.disconnect()

    store.dispatch('appContext/setIsDisconnectionInitializedByMe', true)

    store.dispatch('pluginOptions/setPluginOptions', {
      [PLUGIN_OPTIONS_KEYS.INSTANCE_STATE_ID]: INSTANCE_STATES.DISCONNECTION_IN_PROGRESS.id
    })
  } catch (error) {
    handleError({ error })
  } finally {
    isLoading.value = false
  }
}
</script>

<style lang="scss" scoped>
.jcs-Settings {
  width: 100%;
  max-width: $page-width-md;
  padding: 20px $page-right-padding 64px $page-left-padding;
}

.jcs-StatusView {
  display: flex;
  justify-content: start;
  align-items: center;

  --first-item-border-color: #{$grey-1-next};
  --last-item-border-color: #{$grey-3-next};
  --first-item-fill: initial;
  --last-item-fill: #{$grey-2-next};
  --first-item-label-color: #{$dark-3};
  --last-item-label-color: #{$grey-1-next};

  &-reverse {
    flex-direction: row-reverse;
    --first-item-border-color: #{$grey-3-next};
    --last-item-border-color: #{$grey-1-next};
    --first-item-fill: #{$grey-2-next};
    --last-item-fill: initial;
    --first-item-label-color: #{$grey-1-next};
    --last-item-label-color: #{$dark-3};
  }

  &-connected {
    --first-item-border-color: #{$grade-high-color-next};
    --last-item-border-color: #{$grade-high-color-next};
    --first-item-fill: initial;
    --last-item-fill: initial;
    --first-item-label-color: #{$dark-3};
    --last-item-label-color: #{$dark-3};
  }

  &-error {
    --first-item-border-color: #{$grade-low-color-next};
    --first-item-fill: initial;
    --first-item-label-color: #{$dark-3};
  }
}

.jcs-StatusView_Item {
  width: 228px;
  border: 2px solid;
  border-radius: $border-radius-lg-next;
  padding: 28px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  transition: $transition-normal;

  &:first-child {
    border-color: var(--first-item-border-color);
    --fill: var(--first-item-fill);
    color: var(--first-item-label-color);
  }

  &:last-child {
    border-color: var(--last-item-border-color);
    --fill: var(--last-item-fill);
    color: var(--last-item-label-color);
  }
}

.jcs-StatusItem_Label {
  font-weight: fw('semi-bold');
  font-size: $fs-14;
  line-height: 20px;
}

.jcs-Connector {
  min-width: 144px;
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  font-size: $fs-12;
  font-weight: fw('regular');
  line-height: 16px;
  color: $grey-1-next;

  .jcs-StatusView-connected & {
    color: $grade-high-color-next;
  }

  .jcs-StatusView-error & {
    color: $grade-low-color-next;
  }
}

.jcs-Connector_Icon {
  grid-row: 2;
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    width: calc((100% - 24px - 20px) / 2);
    background: $grey-3-next;
    transition: $transition-normal cubic-bezier(0.1, 0.7, 1, 0.1);
    top: 0;
    bottom: 0;
    margin: auto;

    .jcs-StatusView-connected & {
      background: $grade-high-color-next;
      width: 50%;
    }

    .jcs-StatusView-error & {
      width: 50%;
    }
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}

.jcs-ConnectorIcon {
  z-index: 1;
}

.jcs-ConnectorLoader {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;
  z-index: 1;
}

.jcs-Connector_Label {
  grid-row: 3;
  text-align: center;
  padding: 0 8px;
}

.jcs-Steps {
  margin-top: 40px;
  display: grid;
  gap: 40px;
}

.jcs-DisclaimerWrapper {
  width: 100%;
  max-width: $page-width-md;
  padding: 20px $page-right-padding 0 $page-left-padding;
}

.jcs-DisclaimerTitle {
  font-weight: fw('regular');
  color: $dark-3;
}
</style>
