const options = {
  baseUrl: null,
  atlassianBaseUrl: null,
  organizationId: null
}

export const setPluginOptions = newOptions => {
  Object.keys(newOptions).forEach(key => {
    //   TODO: validate
    options[key] = newOptions[key]
  })
}

export const getPluginOption = name => {
  return options[name]
}
