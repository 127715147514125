<template>
  <component
    :is="isMockLink ? 'span' : 'a'"
    v-if="hasAccessToJira"
    :href="linkHref"
    class="ejl-Link"
    data-testid="external-jira-link"
    target="_blank"
    @click="onLinkClick"
  >
    <AppIcon height="24" icon-name="shortcut-next" width="24" />
    <slot />
  </component>
</template>

<script setup>
import { computed, inject } from 'vue'
import { useStore } from 'vuex'

import { useExternalLinkHelper } from '@/utils/external-link-composables'
import { EXTERNAL_LINK_HANDLER_SOURCES } from '@/utils/external-link-helper'
import { isFirefox } from '@/utils/general'
import {
  appPlatformInjectionKey,
  isJiraAppInjectionKey,
  isSalesforceAppInjectionKey,
  isWebAppInjectionKey,
  subAppStateInjectionKey
} from '@/utils/injection-keys'
import {
  getAtlassianIssueUrl,
  getAtlassianIssueUrlForSalesforceApp,
  getAtlassianIssueUrlForWebApp
} from '@jira/util'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'ExternalJiraLink'
})

const props = defineProps({
  jiraIssueId: {
    type: [String, Number],
    required: true
  }
})

const appPlatform = inject(appPlatformInjectionKey)
const isWebApp = inject(isWebAppInjectionKey)
const isSalesforceApp = inject(isSalesforceAppInjectionKey)
const subAppState = inject(subAppStateInjectionKey)
const isJiraApp = inject(isJiraAppInjectionKey)

const linkHref = computed(() => {
  if (isWebApp) {
    return getAtlassianIssueUrlForWebApp(props.jiraIssueId)
  }

  if (isSalesforceApp) {
    return getAtlassianIssueUrlForSalesforceApp(props.jiraIssueId)
  }

  return getAtlassianIssueUrl(props.jiraIssueId)
})

const isMockLink = computed(() => {
  return isJiraApp && isFirefox()
})

const { handleExternalLink } = useExternalLinkHelper()

const onLinkClick = () => {
  if (isMockLink.value) {
    handleExternalLink({
      id: props.jiraIssueId,
      source: EXTERNAL_LINK_HANDLER_SOURCES.JIRA_ISSUE_LINK,
      appPlatform
    })
  }
}

const store = useStore()

const hasAccessToJira = computed(() => {
  if (subAppState) {
    return subAppState.hasAccessToJira || false
  }

  return store.state.system.userData.hasAccessToJira || false
})
</script>

<style lang="scss" scoped>
.ejl-Link {
  height: 24px;

  display: inline-flex;
  align-items: center;
  color: $primary-color-next;
  font-style: normal;
  font-weight: fw('regular');
  font-family: $system-ui;
  font-size: $fs-14;
  line-height: 20px;
  gap: 8px;
}
</style>
