<template>
  <AppDialog
    :class="{ [$attrs.class]: Boolean($attrs.class) }"
    :data-auto-testid="$attrs['data-auto-testid'] || 'main-modal'"
    :data-testid="$attrs['data-testid'] || 'main-modal'"
    :disable-confirm-button="disableGeneralConfirmButton"
    :hide-footer="hideFooter"
    :hide-hero="hideHero"
    :loading="loading"
    :loading-confirm-button="loadingGeneralConfirmButton"
    :show="show"
    :size="size"
    :subtitle="subtitle"
    :title="title"
    v-on="{ 'on-close': close, 'on-confirm': confirm }"
  >
    <template v-for="(_, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </AppDialog>
  <!-- Confirm close -->

  <AppDialog
    :show="isConfirmCloseShow"
    :title="$t('confirm_modal.title')"
    :type="DIALOG_TYPES.WARNING"
    data-testid="confirm-close-dialog"
    size="sm"
    @on-close="hideConfirmClose"
    @on-confirm="onConfirm"
  >
    {{ $t('confirm_modal.description') }}

    <template #confirm-btn-text>
      {{ $t('confirm.discard_btn') }}
    </template>
  </AppDialog>
  <!-- Confirm close end -->
</template>

<script>
import { defineComponent } from 'vue'

import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { isEscape } from '@/utils/key-codes'

import AppDialog from '@/components/AppDialog'

export default defineComponent({
  name: 'AppModalWithConfirmation',

  components: {
    AppDialog
  },

  inheritAttrs: false,

  props: {
    title: {
      type: String,
      default: ''
    },

    subtitle: {
      type: String,
      default: ''
    },

    size: {
      type: [String, Number],
      default: 'sm'
    },

    show: {
      type: Boolean
    },

    confirmClose: {
      type: Boolean
    },

    hideHero: {
      type: Boolean
    },

    hideFooter: {
      type: Boolean
    },

    loading: {
      type: Boolean
    },

    loadingGeneralConfirmButton: {
      type: Boolean
    },

    disableGeneralConfirmButton: {
      type: Boolean
    }
  },

  emits: { 'on-close': null, 'on-confirm': null },

  data() {
    return {
      isConfirmCloseShow: false
    }
  },

  computed: {
    DIALOG_TYPES: () => DIALOG_TYPES
  },

  created() {
    document.body.addEventListener('keyup', this.onEscPressed)
  },

  unmounted() {
    document.body.removeEventListener('keyup', this.onEscPressed)
  },

  methods: {
    onClose() {
      this.$emit('on-close')
    },

    hideConfirmClose() {
      this.isConfirmCloseShow = false
    },

    onConfirm() {
      this.hideConfirmClose()
      this.onClose()
    },

    close() {
      if (this.confirmClose) {
        this.isConfirmCloseShow = true
      } else {
        this.onClose()
      }
    },

    confirm() {
      this.$emit('on-confirm')
    },

    onEscPressed(event) {
      if (isEscape(event.keyCode) && this.show) {
        this.close()
      }
    }
  }
})
</script>

<style lang="scss"></style>
