<template>
  <div ref="commentForm" class="cf-CommentForm">
    <AppAvatar
      v-if="!isEdit"
      :avatar-url="myAvatar"
      class="cf-Avatar"
      data-testid="user-avatar"
      no-margins
    />
    <div class="cf-MessageAndActionsWrapper">
      <template v-if="active">
        <DescriptionField
          ref="editableField"
          v-model="formModel.text"
          :allow-empty="false"
          :append-mention-to-body="appendMentionToBody"
          :blur-on-save="!replyTo"
          :disable="loading"
          :hidden-features="hiddenFeatures"
          :max-length="2000"
          :placeholder="$t('description.type_mention')"
          :placement="COMMENT_FIELD_PLACEMENTS"
          :readonly="loading"
          :workspace-id="objective.workspaceId"
          data-testid="description-field"
          simple-view
          @cancel="cancel"
          @save="save"
        />
      </template>
      <template v-else>
        <AppInput
          :placeholder="$t('description.type_mention')"
          class="cf-MessageAndActionsWrapper_Input"
          data-testid="comment-input"
          model-value=""
          size="lg"
          style-type="primary"
          @focus="onInputFocus"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import CommentsApiHandler from '@/api/comments'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, TRACKING_UNKNOWN } from '@/tracking/amplitude-helpers'
import { handleError } from '@/utils/error-handling'
import { commentFormInjectionKey } from '@/utils/injection-keys'
import { COMMENT_FIELD_PLACEMENTS } from '@/utils/tiptap-utils'
import { uid } from '@/utils/uid'
import { isVisibleInScrollableParent } from '@/utils/window'

import DescriptionField from '@/components/objectives/forms/DescriptionField'
import AppAvatar from '@/components/ui/AppAvatar/AppAvatar'
import AppInput from '@/components/ui/AppInput/AppInput'

const DEFAULT_FORM_MODEL = {
  text: ''
}

export default defineComponent({
  name: 'CommentForm',
  components: {
    AppInput,
    DescriptionField,
    AppAvatar
  },

  inject: {
    commentForm: {
      from: commentFormInjectionKey
    }
  },

  props: {
    modelValue: {
      type: Object,
      default: null
    },

    active: {
      type: Boolean,
      default: false
    },

    myAvatar: {
      type: String,
      default: null
    },

    replyTo: {
      type: Number,
      default: null
    },

    objective: {
      type: Object,
      default: null
    },

    appendMentionToBody: {
      type: Boolean,
      default: true
    },

    trackingSource: {
      type: String,
      default: TRACKING_UNKNOWN
    },

    trackingMode: {
      type: String,
      default: TRACKING_UNKNOWN
    },

    hiddenFeatures: {
      type: Array,
      default: () => []
    }
  },

  emits: { update: null, create: null, 'update:active': null },

  data() {
    return {
      uid: uid(),
      formModel: { ...DEFAULT_FORM_MODEL },
      loading: false
    }
  },

  computed: {
    COMMENT_FIELD_PLACEMENTS: () => COMMENT_FIELD_PLACEMENTS,
    isEdit() {
      return this.modelValue !== null
    }
  },

  watch: {
    active: {
      handler(newValue) {
        if (newValue) {
          this.commentForm.opened = this.uid
          this.$nextTick(() => {
            this.focus()
          })
        } else {
          this.commentForm.opened = undefined
        }
      },

      immediate: true
    },

    'commentForm.opened': function handler(newValue) {
      if (newValue && this.uid !== newValue) {
        this.cancel()
      }
    }
  },

  mounted() {
    if (this.modelValue) {
      this.formModel.text = this.modelValue.text
    }
  },

  methods: {
    async save() {
      this.loading = true
      try {
        const { text } = this.formModel
        const commentsApi = new CommentsApiHandler()
        if (this.isEdit) {
          await commentsApi.updateComment({
            commentId: this.modelValue.id,
            text
          })
          this.$emit('update', { commentId: this.modelValue.id, text })

          tracker.logEvent('comment edited', {
            category: EVENT_CATEGORIES.COMMENTS,
            mode: 'main',
            source: this.trackingSource
          })
        } else {
          const commentsInfo = await commentsApi.createComment({
            objectiveId: this.objective.id,
            parentId: this.replyTo,
            text
          })
          this.$emit('create', commentsInfo)

          tracker.logEvent('comment left', {
            category: EVENT_CATEGORIES.COMMENTS,
            mode: this.trackingMode,
            source: this.trackingSource
          })
          this.formModel = { ...DEFAULT_FORM_MODEL }
        }
        this.$emit('update:active', false)
      } catch (error) {
        handleError({ error })
        await this.$nextTick()
        this.focus()
      } finally {
        this.loading = false
      }
    },

    cancel() {
      this.$emit('update:active', false)
      this.formModel = { ...DEFAULT_FORM_MODEL }
    },

    /* Public API */
    focus() {
      // wait for DescriptionField rendering and then scroll
      setTimeout(() => {
        if (this.$refs.commentForm && !isVisibleInScrollableParent(this.$refs.commentForm)) {
          this.$el.scrollIntoView('center')
        }
      }, 100)
      this.$refs.editableField?.focus()
    },

    /* Public API */
    // eslint-disable-next-line vue/no-unused-properties
    replyOnComment(payload) {
      // wait for DescriptionField rendering and then scroll
      this.$refs.editableField?.focus()
      this.$refs.editableField?.replyOnComment(payload)
    },

    onInputFocus() {
      this.$emit('update:active', true)
    }
  }
})
</script>

<style lang="scss" scoped>
.cf-Avatar {
  margin-top: 8px;
}

.cf-CommentForm {
  display: flex;
  padding: 8px 0;
  background-color: $white;
}

.cf-MessageAndActionsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.cf-MessageAndActionsWrapper_Input {
  &:deep(.o-input-wrapper .o-input-field) {
    font-weight: fw('regular');
    font-size: $fs-14;
    line-height: 20px;
    color: $dark-3;
    font-family: $system-ui;
  }
  &:deep(.o-input-wrapper) {
    border-color: $grey-2-next;
  }
}
</style>

<style lang="scss">
.cf-CommentForm {
  display: flex;
  gap: 8px;
  .o-textarea-field {
    background-color: $white;
    padding: 4px 10px;
    border: 1px solid $grey-10;

    &:focus {
      border-color: $primary-color;
    }

    &::placeholder {
      color: $blue-6;
      opacity: 0.5;
    }
  }
}
</style>
