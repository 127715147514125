export const LIMIT = 10

export const NOTIFICATIONS_STATUSES = {
  CREATED: 1,
  READY_TO_SEND: 2,
  SENT: 3,
  DELIVERED: 4
}

export const NOTIFICATION_ATTRIBUTES = {
  GRADE: 1, // unused here, need for email notifications
  ELEMENT_ID: 2,
  ELEMENT_TYPE_ID: 3,
  ELEMENT_DISPLAY_ID: 4,
  AUTHOR_NAME: 5,
  AUTHOR_AVATAR: 6,
  COMMENT_ID: 7,
  ELEMENT_TITLE: 8,
  ELEMENT_LEVEL_PREFIX: 9,
  ELEMENT_LEVEL_COLOR: 10,
  WORKSPACE_ID: 11,
  COMMENT_TEXT: 12,
  OKR_URL: 14, // unused here, need for email notifications
  USER_PREFERENCES_URL: 15, // unused here, need for email notifications
  OBJ_STATUS_BEFORE: 16,
  OBJ_STATUS_AFTER: 17,
  TIMESTAMP: 20, // unused here, need for email notifications
  COORDINATE_UNIVERSAL_TIME: 21, // unused here, need for email notifications
  CURRENT_CONFIDENCE_LEVEL: 22,
  CONFIDENCE_LEVEL_CUSTOM_NAME_OLD: 23,
  CONFIDENCE_LEVEL_CUSTOM_NAME_NEW: 24,
  CURRENT_CONFIDENCE_LEVEL_CUSTOM_NAME: 25
}

export const NOTIFICATION_TYPES = {
  USER_TAGGED: 1,
  GRADE_CHANGED: 2,
  MARKETING_PRODUCT_UPDATE: 3,
  MARKETING_PRODUCT_CASE_STUDY: 4,
  MARKETING_PRODUCT_PROMOS: 5,
  REPLY_COMMENT: 6,
  AGGREGATED_MESSAGE: 7,
  COMMENT: 8,
  CONFIDENCE_LEVEL_CHANGE: 9
}

export const NOTIFICATION_SETTING_TYPES = {
  USER_TAGGED: 1, // always sends in the params [1]
  GRADE_CHANGED_FOR_OWNER: 2, // always sends in the params [1]
  MARKETING_PRODUCT_UPDATE: 3,
  MARKETING_PRODUCT_CASE_STUDY: 4,
  MARKETING_PRODUCT_PROMOS: 5,
  REPLY_COMMENT: 6, // always sends in the params [1]
  COMMENTED_FOR_OWNER: 7, // always sends in the params [1]
  CONFIDENCE_LEVEL_CHANGE_FOR_OWNER: 8, // always sends in the params [1]
  GRADE_CHANGED_FOR_STAKEHOLDER: 9, // always sends in the params [1]
  COMMENTED_FOR_STAKEHOLDER: 10, // always sends in the params [1]
  CONFIDENCE_LEVEL_CHANGE_FOR_STAKEHOLDER: 11 // always sends in the params [1]
}

export const OWNER_CHANGES = 'OWNER_CHANGES'
export const REPLY_AND_MENTION = 'REPLY_AND_MENTION'
export const STAKEHOLDER_CHANGES = 'STAKEHOLDER_CHANGES'
export const GROUPED_NOTIFICATIONS = {
  [REPLY_AND_MENTION]: {
    value: 1,
    notificationSettingTypes: [
      NOTIFICATION_SETTING_TYPES.USER_TAGGED,
      NOTIFICATION_SETTING_TYPES.REPLY_COMMENT
    ]
  },
  [OWNER_CHANGES]: {
    value: 2,
    notificationSettingTypes: [
      NOTIFICATION_SETTING_TYPES.GRADE_CHANGED_FOR_OWNER,
      NOTIFICATION_SETTING_TYPES.COMMENTED_FOR_OWNER,
      NOTIFICATION_SETTING_TYPES.CONFIDENCE_LEVEL_CHANGE_FOR_OWNER
    ]
  },
  [STAKEHOLDER_CHANGES]: {
    value: 3,
    notificationSettingTypes: [
      NOTIFICATION_SETTING_TYPES.GRADE_CHANGED_FOR_STAKEHOLDER,
      NOTIFICATION_SETTING_TYPES.COMMENTED_FOR_STAKEHOLDER,
      NOTIFICATION_SETTING_TYPES.CONFIDENCE_LEVEL_CHANGE_FOR_STAKEHOLDER
    ]
  }
}
