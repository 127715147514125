<template>
  <div v-if="distantRelative || isPortalHasContent" class="ob-ParentItems">
    <div v-if="distantRelative" class="ob-DistantRelative" style="--depth: 0">
      <OkrIcon :objective="distantRelative.element" />
      <TitleItems
        :id-as-link="currentUserCanReadObjective(distantRelative.element)"
        :objective="distantRelative.element"
        id-size="small"
        with-tooltip
        @click-on-id="emit('item-click', distantRelative.element)"
      />
      <ContributeLine class="ob-DistantRelative_ContributeLine" />
      <div :class="{ 'ob-Actions-transparent': distantRelative }" class="ob-Actions">
        <slot name="breadcrumbs-actions" />

        <AppButton
          v-tippy="{
            content: $t('action.close'),
            placement: 'top'
          }"
          class="ob-CloseButton"
          data-auto-testid="close-button"
          icon="close-next"
          size="sm"
          type="subtle"
          @click="emit('close')"
        />
      </div>
    </div>
    <div class="ob-ImmediateParent">
      <div class="ob-ImmediateParent_Wrapper">
        <div class="ob-ImmediateParent_Content">
          <portal-target :name="portalName" />
        </div>

        <div
          v-if="!distantRelative"
          :class="{ 'ob-Actions-transparent': isTransparentActionsBg }"
          class="ob-Actions"
        >
          <slot name="breadcrumbs-actions" />
          <AppButton
            v-tippy="{
              content: $t('action.close'),
              placement: 'top'
            }"
            class="ob-CloseButton"
            data-auto-testid="close-button"
            icon="close-next"
            size="sm"
            type="subtle"
            @click="emit('close')"
          />
        </div>
      </div>
      <ContributeLine v-if="currentItem" class="ob-ImmediateParent_ContributeLine" />
    </div>
  </div>
  <div :style="getBreadcrumbsDepth(items)" class="ob-CurrentItem">
    <template v-if="currentItem">
      <OkrIconAndId :clickable="!!currentItem.id" :okr-element="currentItem" />
      <UseAppPlatform v-slot="{ isSalesforceApp }">
        <div
          v-if="currentItem.id && !isSalesforceApp"
          :class="{ 'ob-CurrentItem_Copy-loading': shareLinkLoading }"
          class="ob-CurrentItem_Copy"
        >
          <AppButton
            v-tippy="{
              content: $t('breadcrumb.copy_link'),
              placement: 'top'
            }"
            :loading="shareLinkLoading"
            class="ob-CopyButton"
            height="24"
            hide-icon-on-loading
            icon="copy-standard"
            loading-circle-color="var(--grey-1-next)"
            remove-padding
            size="sm"
            type="ghost-next"
            width="24"
            @click="onCopyClick"
          />
        </div>
      </UseAppPlatform>
    </template>

    <div class="ob-CurrentItem_Right">
      <slot name="current-item-append" />
    </div>
  </div>

  <UseAppPlatform v-slot="{ isSalesforceApp }">
    <CopySharedLinkModal
      v-if="!isSalesforceApp"
      v-model:show="showCopyShareLinkModal"
      :token="shareToken"
    />
  </UseAppPlatform>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { Wormhole } from 'portal-vue'
import { computed } from 'vue'

import { currentUserCanReadObjective, isKR, objectiveIsJiraTask } from '@/utils/objectives'
import { getBreadcrumbsDepth, getPortalName } from '@/utils/okr-breadcrumbs'

import OkrIconAndId from '@/components/form/OkrIconAndId'
import ContributeLine from '@/components/objectives/forms/ContributeLine'
import OkrIcon from '@/components/objectives/items/OkrIcon'
import TitleItems from '@/components/objectives/items/TitleItems'
import AppButton from '@/components/ui/AppButton/AppButton'
import { useCopySharedLinkModal } from '@/components/ui/CopySharedLinkModal/composables'
import CopySharedLinkModal from '@/components/ui/CopySharedLinkModal/CopySharedLinkModal'
import UseAppPlatform from '@/components/UseAppPlatform'

defineOptions({
  name: 'OkrBreadcrumbs'
})

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  },
  modalView: {
    type: Number,
    required: true
  },
  isEdit: {
    type: Boolean
  }
})

const isTransparentActionsBg = computed(() => {
  if (currentItem.value) {
    const isObjective = !isKR(currentItem.value) && !objectiveIsJiraTask(currentItem.value)

    if (isObjective) {
      return !currentItem.value?.parentId
    } else {
      return !!distantRelative.value
    }
  } else {
    return false
  }
})

const emit = defineEmits(['item-click', 'close', 'update-privacy'])

const isItemsExist = computed(() => !isEmpty(props.items) > 0)

const hasTwoParents = computed(() => props.items.length > 2)

const currentItem = computed(() => {
  return isItemsExist.value ? props.items[props.items.length - 1].element : null
})

const distantRelative = computed(() => {
  if (hasTwoParents.value) {
    const [firstParent] = props.items
    return firstParent
  }

  return null
})

const immediateParentDepth = computed(() => (distantRelative.value ? 1 : 0))

const portalName = computed(() => getPortalName(props.modalView, props.isEdit))

const isPortalHasContent = computed(() => {
  return !isEmpty(Wormhole.getContentForTarget(portalName.value))
})

const { onCopyClick, shareToken, shareLinkLoading, showCopyShareLinkModal } =
  useCopySharedLinkModal()

//const onCopyClick = () => {
//  if (JIRA_CLOUD_API) {
//    JIRA_CLOUD_API.getLocation(location => {
//      copyToClipboard(location)
//      showNotify({ title: t('notifications.link_copied') })
//    })
//  } else {
//    copyToClipboard(window.location.href)
//    showNotify({ title: t('notifications.link_copied') })
//  }
//}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/okr-modal';
$item-height: 44px;

/**
@note max width can be specified in the parent component
@note by using the --okr-element-form-content-max-width variable
@note same variable is used for SidebarLayout.vue component
@note but in case of this it can't specified by own variable
@note because that doesn't have sense coz this component used only with OKR element modals
 */
$maxWidth: var(--okr-element-form-content-max-width, 100%);

$resolvedGap: var(--okr-breadcrumbs-gap, #{$okr-element-modal-default-gap});

.ob-ParentItems {
  background-color: $grey-3-next;

  width: 100%;
  $padding: calc(
    (100% - (#{$maxWidth} + #{$depth-shift})) / 2
  ); // calculate padding for replace margin (works like margin: 0 auto)
  padding: 20px max(#{$padding}, #{$resolvedGap});
}

.ob-DistantRelative {
  display: flex;
  align-items: center;

  gap: 8px;
  padding: 10px 0 10px $depth-shift;
  position: relative;
  --id-font-weight: 600;
}

.ob-ImmediateParent_Content {
  flex: 1 0 auto;
  width: min-content;
}

.ob-ImmediateParent_Wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  gap: 32px;

  margin-left: -10px; // 10px to compensate inner padding
}

.ob-ImmediateParent {
  position: relative;
  padding-left: calc(v-bind(immediateParentDepth) * var(--breadcrumbs-offset));
}

.ob-CurrentItem {
  height: $item-height;
  display: flex;
  align-items: flex-end;
  padding: 0 $resolvedGap 0 calc(#{$resolvedGap} + #{$depth-shift});
  gap: 8px;
  --id-font-weight: 600;
  width: 100%;
  max-width: calc(#{$maxWidth} + #{$resolvedGap} + (#{$resolvedGap} + #{$depth-shift}));
  margin-inline: auto;
}

.ob-CurrentItem_Copy {
  height: 24px;
  display: flex;
  align-items: center;
  visibility: hidden;

  &-loading {
    visibility: visible;
  }

  .ob-CurrentItem:hover & {
    visibility: visible;
  }
}

.ob-CopyButton {
  background: transparent;
  color: $grey-1-next;

  &:hover {
    color: $primary-color-next;
  }
}

.ob-CurrentItem_Right {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: auto;
}

.ob-DistantRelative_ContributeLine,
.ob-ImmediateParent_ContributeLine {
  display: flex;
  position: absolute;
}

.ob-DistantRelative_ContributeLine {
  --height: calc(#{$item-height} / 2);
  top: $item-height;
  left: 12px; // okrIcon half width
}

.ob-ImmediateParent_ContributeLine {
  --height: calc(
    100% - #{$item-height} + 20px + 32px
  ); // where 20px is bottom padding of wrapper; 32px (20px (current item height 44px - okrIcon Height 24px) + 12px okrIcon half height)
  top: $item-height;
  left: calc(
    v-bind(immediateParentDepth) * var(--breadcrumbs-offset) + 12px
  ); // where 12 is okrIcon half width
}

.ob-Actions {
  display: flex;
  align-items: center;
  gap: 16px;
  max-height: 44px;
  border-radius: $border-radius-sm-next;
  transition: background-color, padding $transition-fast ease-in-out;

  &:not(&-transparent) {
    padding: 10px;
    background-color: $white;
  }

  &-transparent {
    background-color: transparent;
  }
}

.ob-CloseButton {
  &:hover {
    background-color: rgba($dark-1, 0.05);
  }
}
</style>
