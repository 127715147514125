<template>
  <portal to="okr-toolbar-actions-left">
    <RoadmapExport @toggle-show-area="toggleShowArea" @export-roadmap="exportRoadmap" />
  </portal>
  <template v-if="isTimelinesHighlighted">
    <div class="rm-HighlightArea" />
    <div :style="borderBounding" class="rm-HighlightAreaBorder" />
  </template>
  <div
    ref="wrapper"
    :class="wrapperClasses"
    :style="{
      '--width': newTableSideWidth,
      '--roadmap-footer-height': `${ROADMAP_FOOTER_HEIGHT}px`
    }"
  >
    <div
      ref="tableSide"
      :class="{ 'rm-OkrPage_Table-collapsed': isTableCollapsed }"
      class="rm-OkrPage_Table"
    >
      <!-- <div v-if="!noObjectives" v-show="!isTableCollapsed" class="rm-OkrPage_TableHead">
        {{ $t('objectives.table_header_name') }}
      </div> -->

      <ObjectiveTable
        ref="table"
        :enabled-columns-by-default="ROADMAP_ENABLED_COLUMNS_BY_DEFAULT"
        :objective-levels="objectiveLevels"
        :table-placement="ROADMAP_KEY"
        :user-can-create-objectives="userCanCreateObjectives"
        :workspace-id="workspaceId"
        first-level-draggable
        head-padding-top="23px"
        header-height="56px"
        hide-bulk-actions
        no-padding-bottom
        offset-left="var(--page-left-padding)"
        offset-right="var(--page-right-padding)"
        resizeable-columns
        show-filter-buttons
        tab-name="Alignment"
        transparent-filtered
        @create-objective="onCreateObjectiveClick($event.typeId)"
        @reset-search-criteria="$emit('reset-search-criteria', $event)"
        @load-more="$emit('load-more', $event)"
        @update-okr-data="onUpdateOkrData"
        @expand-all="$emit('expand-all')"
        @collapse-all="$emit('collapse-all')"
      />
    </div>

    <div
      v-if="isTableCollapsed && !noObjectives"
      class="rm-CollapsedTablePlug"
      @click="onCollapseExpandTable(false)"
    >
      <div class="rm-CollapsedTablePlug_Text">
        {{ $t('group.table_view') }}
      </div>
    </div>

    <div
      v-show="!noObjectives"
      :class="{ 'rm-OkrPage_Resizer-collapsedTable': isTableCollapsed }"
      class="rm-OkrPage_Resizer"
      @mousedown.self="onResizerMouseDown"
    >
      <div class="rm-BackButtonWrapper">
        <AppButton
          v-tippy="{
            content: tooltipText,
            placement: 'right'
          }"
          class="rm-BackButton"
          remove-padding
          type="primary-next"
          @click="onCollapseExpandTable(false)"
        >
          <AppIcon
            class="rm-BackButton_Icon"
            height="24"
            icon-name="roadmap-collapse-table"
            width="24"
          />
        </AppButton>
      </div>
      <span class="rm-OkrPageShadow" />
    </div>
    <OkrTimelines
      v-show="!noObjectives"
      ref="timelinesList"
      v-model:timelines-body-height="timelinesBodyHeight"
      v-model:timelines-body-width="timelinesBodyWidth"
      :no-objectives="noObjectives"
      @update-elements="updateElements"
      @on-okr-element-updated="onOkrElementUpdated"
      @reset-scroll-target="scrollTarget = null"
      @toggle-show-area="toggleShowArea"
      @export-roadmap="exportRoadmap"
    />

    <div v-if="!noObjectives" class="rm-OkrPage_Footer" data-export-ignore>
      <div class="rm-OkrPageFooterRight">
        <TaskIconForExport />
        <portal-target name="roadmap-footer" />
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import JSZip from 'jszip'
import { isEmpty, debounce, isNull, isBoolean } from 'lodash'
import mergeImages from 'merge-images'
import { defineComponent } from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'

import { saveFile } from '@/utils/file-saver-helpers'
import {
  addExtraSpaceForFirefox,
  alignOkrIcons,
  ignoreElementsForExport,
  replaceSvgIcons,
  replaceTaskIcons
} from '@/utils/html2canvas-helpers'
import { listStateInjectionKey, roadmapStateInjectionKey } from '@/utils/injection-keys'
import { clearRoadmapCache } from '@/utils/memoizations'
import { ROADMAP_ENABLED_COLUMNS_BY_DEFAULT } from '@/utils/objective-table'
import { currentUserCanCreateObjective } from '@/utils/objectives'
import { updateStorageByKey } from '@/utils/persist'
import { EXPORT_TYPES, getExportFileName, ROADMAP_KEY, roadmapBus } from '@/utils/roadmap'
import {
  getResolvedRestoredValue,
  ROADMAP_TABLE_COLLAPSED_STATE,
  ROADMAP_TABLE_SIDE_WIDTH,
  USER_SETTINGS_MAPPER
} from '@/utils/user-settings'

import OkrTimelines from '@/components/objectives/roadmap/OkrTimelines'
import RoadmapExport from '@/components/objectives/roadmap/RoadmapExport'
import ObjectiveTable from '@/components/objectives/table/ObjectiveTable'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import TaskIconForExport from '@/components/ui/TaskIconForExport'

const LS_TABLE_WIDTH_KEY = 'roadmapTableWidth'
const LS_IS_TABLE_COLLAPSED_KEY = 'roadmapIsTableCollapsed'
const MIN_TABLE_SIDE_WIDTH = 220
const ROADMAP_FOOTER_HEIGHT = 64

const transformPixelsToPercents = (pixels, wrapperWidth) => {
  return (pixels * 100) / wrapperWidth
}

const getElementWidth = el => el.getBoundingClientRect().width

const COLLAPSED_TABLE_WIDTH = 0

export default defineComponent({
  name: 'OkrElementsRoadmapPage',
  components: {
    TaskIconForExport,
    RoadmapExport,
    AppIcon,
    AppButton,
    OkrTimelines,
    ObjectiveTable
  },

  provide() {
    return {
      [roadmapStateInjectionKey]: this.roadmapState
    }
  },

  inject: {
    listState: {
      from: listStateInjectionKey
    }
  },

  emits: {
    'reset-search-criteria': null,
    'load-more': null,
    'update-data': null,
    'create-objective': null,
    'expand-all': null,
    'collapse-all': null
  },

  data() {
    return {
      resizeContinues: false,
      isTableCollapsed: false,
      mouseX: 0,
      tableSideWidth: 0,
      newTableSideWidth: '50%',
      timelinesBodyHeight: 0,
      timelinesBodyWidth: 0,
      resizeObserver: null,
      roadmapState: {
        canvas: null,
        headCells: {},
        timelinesWidth: null,
        someTimelineInteracted: false
      },

      scrollTarget: null,
      polygonPath: null,
      borderBounding: null,
      isTimelinesHighlighted: false
    }
  },

  computed: {
    ...mapState('system', {
      fullscreen: state => state.fullscreen,
      appMenuPinned: state => state.appMenu.pinned,
      pageHeaderHeight: state => state.pageHeaderHeight,
      appLicenseBannerHeight: state => state.appLicenseBannerHeight,
      otWrapperHeight: state => state.otWrapperHeight
    }),

    ...mapState('objectives', {
      objectiveLevels: state => state.levels
    }),

    ...mapGetters('workspaces', {
      selectedWorkspace: 'selectedWorkspace'
    }),

    ...mapState('pluginOptions', {
      isPluginAdmin: state => state.isPluginAdmin
    }),

    ...mapGetters('system', {
      roadmapTableCollapsedState: 'roadmapTableCollapsedState',
      roadmapTableSideWidth: 'roadmapTableSideWidth'
    }),

    ROADMAP_ENABLED_COLUMNS_BY_DEFAULT: () => ROADMAP_ENABLED_COLUMNS_BY_DEFAULT,
    ROADMAP_FOOTER_HEIGHT: () => ROADMAP_FOOTER_HEIGHT,
    ROADMAP_KEY: () => ROADMAP_KEY,

    tooltipText() {
      return this.isTableCollapsed
        ? this.$t('menu.tooltip.expand')
        : this.$t('menu.tooltip.collapse')
    },

    wrapperClasses() {
      return {
        'rm-OkrPage': true,
        'rm-OkrPage-resizeContinues': this.resizeContinues,
        'rm-OkrPage-fullscreen': this.fullscreen,
        'rm-OkrPage-noObjectives': this.noObjectives
      }
    },

    userCanCreateObjectives() {
      return currentUserCanCreateObjective(this.selectedWorkspace, this.isPluginAdmin)
    },

    workspaceId() {
      return this.$route.params.workspaceId
    },

    noObjectives() {
      return isEmpty(this.listState.objectives) && isEmpty(this.listState.krs)
    }
  },

  watch: {
    isTimelinesHighlighted: {
      handler(newValue) {
        if (newValue) {
          window.addEventListener('scroll', this.calculateHighlightAreas)
        } else {
          window.removeEventListener('scroll', this.calculateHighlightAreas)
        }
      },

      immediate: true
    }
  },

  mounted() {
    this.restoreTableCollapsedState()

    if (!this.isTableCollapsed) {
      this.restoreTableSideWidth()
    }

    // this.roadmapState.canvas = this.$refs.timelines

    if (!this.isTableCollapsed) {
      this.initResizeObserver()
    }

    this.setScrollTarget()
  },

  beforeUnmount() {
    this.disconnectResizeObserver()
    roadmapBus.off('set-scroll-target', this.debouncedSetScrollTarget)
    clearRoadmapCache()
  },

  beforeUpdate() {
    this.debouncedSetScrollTarget()
  },

  updated() {
    if (!this.resizeContinues) {
      this.scrollToScrollTarget()
    }
  },

  created() {
    this.debouncedSetScrollTarget = debounce(this.setScrollTarget, 400)
    this.$nextTick(() => {
      roadmapBus.on('set-scroll-target', this.debouncedSetScrollTarget)
    })
  },

  methods: {
    ...mapActions('system', {
      toggleFullAppLoader: 'toggleFullAppLoader',
      updateUserSettings: 'updateUserSettings'
    }),

    calculateHighlightAreas() {
      const { offsetLeft } = this.$refs.timelinesList.$refs.timelinesWrapper
      const topPart = this.getTopPart()
      const HEIGHT_WITHOUT_FOOTER = `calc(100% - ${ROADMAP_FOOTER_HEIGHT}px)`
      this.polygonPath = `polygon(0 0, 100% 0, 100% ${topPart}px, ${offsetLeft}px ${topPart}px, ${offsetLeft}px ${HEIGHT_WITHOUT_FOOTER}, 100% ${HEIGHT_WITHOUT_FOOTER}, 100% 100%, 0 100%)`
      this.borderBounding = {
        top: `${topPart}px`,
        left: `${offsetLeft}px`,
        width: `calc(100% - ${offsetLeft}px)`,
        height: `calc(${HEIGHT_WITHOUT_FOOTER} - ${topPart}px)`
      }
    },

    getTopPart() {
      const { pageHeaderHeight, appLicenseBannerHeight, otWrapperHeight } = this
      const defaultHeight = pageHeaderHeight + appLicenseBannerHeight
      if (this.fullscreen) {
        return defaultHeight
      }

      return window.scrollY >= otWrapperHeight
        ? defaultHeight
        : defaultHeight + otWrapperHeight - window.scrollY
    },

    toggleShowArea(isShowArea) {
      this.isTimelinesHighlighted = isShowArea

      if (isShowArea) {
        this.calculateHighlightAreas()
      }
    },

    exportRoadmap({ exportType, cropSize }) {
      this.toggleFullAppLoader()
      // setTimeout is for avoid start of export before the loader is shown
      setTimeout(async () => {
        if (exportType === EXPORT_TYPES.VISIBLE) {
          await this.getVisibleAreaExport()
        } else {
          await this.getFullRoadmapExport({ exportType, cropSize })
        }
        await this.toggleFullAppLoader()
      })
    },

    async getVisibleAreaExport() {
      const { fullscreen } = this
      const MIN_TOP_OFFSET = 0
      const timelinesWrapper = this.$refs.timelinesList.$refs.timelinesWrapper
      const BORDERS_SIZE = 2

      const topPart = fullscreen ? MIN_TOP_OFFSET : this.getTopPart()

      const roadmapOffsetTop = fullscreen ? window.scrollY : window.scrollY - this.otWrapperHeight

      const y = roadmapOffsetTop < MIN_TOP_OFFSET ? MIN_TOP_OFFSET : roadmapOffsetTop + BORDERS_SIZE

      const baseHeight = window.innerHeight - topPart - ROADMAP_FOOTER_HEIGHT

      const height = fullscreen
        ? baseHeight - this.pageHeaderHeight - this.appLicenseBannerHeight
        : baseHeight

      const canvas = await html2canvas(timelinesWrapper, {
        onclone: clonedNode => {
          addExtraSpaceForFirefox(clonedNode)
          alignOkrIcons(clonedNode)
          replaceTaskIcons(clonedNode)
          replaceSvgIcons(clonedNode)
        },
        useCORS: true, // necessary to display base64 images
        imageTimeout: 0,
        height,
        y,
        ignoreElements: ignoreElementsForExport()
      })
      const dataUrl = canvas.toDataURL('image/png')
      saveFile(dataUrl, getExportFileName({ exportType: EXPORT_TYPES.VISIBLE }))
    },

    async getFullRoadmapExport({ exportType, cropSize = 1e4 }) {
      const timelinesWrapper = this.$refs.timelinesList.$refs.timelinesWrapper
      const isFullExport = exportType === EXPORT_TYPES.FULL
      const needCollapseTable = !this.isTableCollapsed

      if (needCollapseTable) {
        this.onCollapseExpandTable(true)
      }

      await this.$nextTick()

      const MAX_PART_SIZE = cropSize
      const TOTAL_SIZE = this.timelinesBodyWidth
      const PARTS_COUNT = Math.ceil(TOTAL_SIZE / MAX_PART_SIZE)
      const LAST_PART = TOTAL_SIZE - MAX_PART_SIZE * (PARTS_COUNT - 1)
      const ALL_PARTS = Array.from({ length: PARTS_COUNT }).fill(MAX_PART_SIZE)

      const parts = ALL_PARTS.map((item, index) => {
        const isLastPart = index === ALL_PARTS.length - 1
        return {
          width: isLastPart ? LAST_PART : item,
          scrollX: item * index,
          isLastPart
        }
      })

      const DEFAULT_MENU_WIDTH = 240
      const COLLAPSED_MENU_WIDTH = DEFAULT_MENU_WIDTH * 0.1
      const FULLSCREEN_MENU_WIDTH = 0
      const [APP_MENU_WIDTH] = [
        this.fullscreen && FULLSCREEN_MENU_WIDTH,
        this.appMenuPinned && DEFAULT_MENU_WIDTH,
        COLLAPSED_MENU_WIDTH
      ].filter(width => width >= 0 && width !== false)
      const IMAGE_HEIGHT = this.$refs.wrapper.offsetHeight - ROADMAP_FOOTER_HEIGHT
      const TABLE_SIDE_WIDTH = 40

      const images = await Promise.all(
        parts.map(async (item, index) => {
          const { width, scrollX, isLastPart } = item
          const canvas = await html2canvas(timelinesWrapper, {
            onclone: clonedNode => {
              addExtraSpaceForFirefox(clonedNode)
              alignOkrIcons(clonedNode)
              replaceTaskIcons(clonedNode)
              const timelinesBody = clonedNode.querySelector('.otl-Timelines')
              const timelinesHead = clonedNode.querySelector('.otl-Head')
              if (isLastPart) {
                // necessary to get the right side of last image instead of the left side
                timelinesBody.style.width = `${width}px`
                timelinesHead.style.width = `${width}px`
              }
              timelinesBody.scrollLeft = scrollX
              timelinesHead.scrollLeft = scrollX
            },
            useCORS: true, // necessary to display base64 images
            width,
            windowWidth: width + TABLE_SIDE_WIDTH + APP_MENU_WIDTH,
            x: 0,
            y: 0,
            height: IMAGE_HEIGHT,
            imageTimeout: 0,
            ignoreElements: ignoreElementsForExport()
          })

          const src = canvas.toDataURL('image/png')

          return {
            name: getExportFileName({ exportType, index }),
            src,
            // split → get only base64 data
            base64: src.split('base64,')[1],
            x: parts[index].scrollX,
            y: 0
          }
        })
      )

      if (isFullExport) {
        mergeImages([...images], {
          width: TOTAL_SIZE,
          height: IMAGE_HEIGHT
        }).then(mergedImage => {
          saveFile(mergedImage, getExportFileName({ exportType }))
        })
      } else {
        const zip = new JSZip()

        images.forEach(item => {
          const { name, base64 } = item
          zip.file(name, base64, { base64: true })
        })

        zip.generateAsync({ type: 'blob' }).then(archive => {
          saveFile(archive, getExportFileName({ isArchive: true }))
        })
      }

      if (needCollapseTable) {
        this.onCollapseExpandTable(true)
      }
    },

    setTableSideCollapsedWidth() {
      this.newTableSideWidth = `${COLLAPSED_TABLE_WIDTH}px`
    },

    async onCollapseExpandTable(isManual = false) {
      if (!this.isTableCollapsed) {
        this.disconnectResizeObserver()
        this.setTableSideCollapsedWidth()
      } else {
        this.restoreTableSideWidth()
        this.initResizeObserver()
      }
      this.isTableCollapsed = !this.isTableCollapsed
      if (!isManual) {
        updateStorageByKey(LS_IS_TABLE_COLLAPSED_KEY, this.isTableCollapsed)

        await this.updateUserSettings({
          [USER_SETTINGS_MAPPER[ROADMAP_TABLE_COLLAPSED_STATE]]: this.isTableCollapsed
        })
      }
    },

    setScrollTarget() {
      const headCells = Object.values(this.roadmapState.headCells)
      const timelines = this.roadmapState.canvas
      if (timelines) {
        const timelinesOffsetLeft = timelines.offsetLeft
        const scrolledHeadCells = headCells
          .map(selector => {
            return {
              selector,
              boundary: selector.getBoundingClientRect()
            }
          })
          .filter(item => {
            return item.boundary.x <= timelinesOffsetLeft
          })

        const headCellForScrollTarget = scrolledHeadCells.reduce((acc, val) => {
          const accX = acc.boundary?.x || -Infinity
          const valX = val.boundary.x
          acc = accX > valX ? acc : val
          return acc
        }, {})

        if (!isEmpty(headCellForScrollTarget)) {
          this.scrollTarget = headCellForScrollTarget
        }
      }
    },

    scrollToScrollTarget() {
      if (!isEmpty(this.scrollTarget)) {
        const { selector, boundary } = this.scrollTarget
        const { right, width } = boundary
        // const { timelines } = this.$refs
        const timelines = this.roadmapState.canvas
        timelines.scrollLeft = selector.offsetLeft + (width - (right - timelines.offsetLeft))
      }
    },

    onUpdateOkrData(data) {
      this.$refs.timelinesList.refreshPositions()
      this.$emit('update-data', data)
    },

    onOkrElementUpdated() {
      this.setScrollTarget()
    },

    updateElements(options) {
      this.$refs.timelinesList.refreshPositions()
      this.$emit('update-data', options)
    },

    async onCreateObjectiveClick(levelId) {
      this.$emit('create-objective', {
        levelId,
        intervalId: this.intervalId
      })
    },

    /** @public */
    requestData(expandAll) {
      this.$refs.table.requestData(expandAll)
    },

    restoreTableCollapsedState() {
      const resolvedRestoredCollapsedState = getResolvedRestoredValue({
        valueFromSettings: this.roadmapTableCollapsedState,
        localStorageKey: LS_IS_TABLE_COLLAPSED_KEY,
        isBooleanOrNumberValueRestores: true
      })

      if (isBoolean(resolvedRestoredCollapsedState)) {
        this.isTableCollapsed = resolvedRestoredCollapsedState
        if (resolvedRestoredCollapsedState) {
          this.setTableSideCollapsedWidth()
        }
      }
    },

    restoreTableSideWidth() {
      const resolvedRestoredWidth = getResolvedRestoredValue({
        valueFromSettings: this.roadmapTableSideWidth,
        localStorageKey: LS_TABLE_WIDTH_KEY,
        isBooleanOrNumberValueRestores: true
      })

      if (!isNull(resolvedRestoredWidth)) {
        const wrapperWidth = getElementWidth(this.$refs.wrapper)
        this.newTableSideWidth = `${transformPixelsToPercents(
          resolvedRestoredWidth,
          wrapperWidth
        )}%`
      }
    },

    resizeTable(e) {
      const dx = e.clientX - this.mouseX
      const wrapperWidth = getElementWidth(this.$refs.wrapper)

      const newTableSideWidthInPercent = transformPixelsToPercents(
        this.tableSideWidth + dx,
        wrapperWidth
      )
      const tableSideMinWidthInPercent = transformPixelsToPercents(
        MIN_TABLE_SIDE_WIDTH,
        wrapperWidth
      )
      const tableSideMaxWidthInPercent = 60

      const isNewWidthBetweenMinMax =
        newTableSideWidthInPercent >= tableSideMinWidthInPercent &&
        newTableSideWidthInPercent <= tableSideMaxWidthInPercent

      const [newTableSideWidth] = [
        isNewWidthBetweenMinMax && `${newTableSideWidthInPercent}%`,
        newTableSideWidthInPercent < tableSideMinWidthInPercent && `${MIN_TABLE_SIDE_WIDTH}px`,
        newTableSideWidthInPercent > tableSideMaxWidthInPercent && `${tableSideMaxWidthInPercent}%`
      ].filter(Boolean)

      this.newTableSideWidth = newTableSideWidth
    },

    async leaveResizing() {
      document.removeEventListener('mousemove', this.resizeTable)
      this.resizeContinues = false
      const width = getElementWidth(this.$refs.tableSide)
      updateStorageByKey(LS_TABLE_WIDTH_KEY, width)

      await this.updateUserSettings({
        [USER_SETTINGS_MAPPER[ROADMAP_TABLE_SIDE_WIDTH]]: width
      })

      document.removeEventListener('mouseup', this.leaveResizing)
    },

    onResizerMouseDown(e) {
      if (!this.isTableCollapsed) {
        this.scrollTarget = null
        this.mouseX = e.clientX
        this.tableSideWidth = getElementWidth(this.$refs.tableSide)
        this.resizeContinues = true

        document.addEventListener('mousemove', this.resizeTable)
        document.addEventListener('mouseup', this.leaveResizing)
      }
    },

    initResizeObserver() {
      const { tableSide } = this.$refs
      const timelines = this.roadmapState.canvas
      this.resizeObserver = new ResizeObserver(async () => {
        const tableSideWidth = getElementWidth(tableSide)
        if (tableSideWidth < MIN_TABLE_SIDE_WIDTH) {
          this.newTableSideWidth = `${MIN_TABLE_SIDE_WIDTH}px`
          const width = getElementWidth(this.$refs.tableSide)
          updateStorageByKey(LS_TABLE_WIDTH_KEY, width)

          await this.updateUserSettings({
            [USER_SETTINGS_MAPPER[ROADMAP_TABLE_SIDE_WIDTH]]: width
          })
        }
        this.roadmapState.timelinesWidth = timelines.offsetWidth
      })

      this.resizeObserver.observe(tableSide)
    },

    disconnectResizeObserver() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect()
        this.resizeObserver = null
      }
    }
  }
})
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
@import '~@/assets/styles/roadmap';
@import '~@/assets/styles/canvas-dimensions';

.rm-OkrPage {
  display: flex;
  min-height: calc(#{$default-canvas-height} - var(--roadmap-footer-height));
  padding-bottom: var(--roadmap-footer-height);

  &-noObjectives {
    justify-content: center;
  }

  &-fullscreen {
    min-height: calc(#{$fullscreen-canvas-height} - var(--roadmap-footer-height));
  }
}

/* eslint-disable-next-line */
%interaction-remove-styles {
  pointer-events: none;
  user-select: none;
}

.rm-OkrPage_Table {
  z-index: 1;
  position: relative;
  width: var(--width);
  // box-shadow: inset 0 1px 0 $grey-medium;
  // padding: 0 $page-right-padding 0 $page-left-padding;
  padding: 0 $page-right-padding 0 $page-left-padding;

  .rm-OkrPage-noObjectives & {
    width: 100%;
  }

  .rm-OkrPage-resizeContinues & {
    @extend %interaction-remove-styles;
  }

  &-collapsed {
    padding: 0;
    overflow: hidden;
  }
}

/* eslint-disable-next-line */
%collapse-table-offset-top {
  top: calc(
    var(--objectives-page-header-height, #{$default-objectives-page-header-height}) +
      var(--app-license-banner-height, 0px) + #{$timelines-head-height}
  );
  .rm-OkrPage-fullscreen & {
    top: calc(
      var(--objectives-page-header-height, #{$default-objectives-page-header-height}) +
        var(--app-license-banner-height, 0px)
    );
  }
}

.rm-BackButtonWrapper {
  opacity: 0;
  pointer-events: none;
  transform: translateX(-50%);
  width: 32px;
  height: calc(#{$default-canvas-height} - var(--roadmap-footer-height));
  display: flex;
  align-items: center;
  position: sticky;
  @extend %collapse-table-offset-top;

  .rm-OkrPage-fullscreen & {
    height: calc(#{$fullscreen-canvas-height} - var(--roadmap-footer-height));
  }
}

.rm-BackButton {
  width: 32px;

  align-items: center;
  transition: background, color $transition-normal;

  &:not(.rm-OkrPage-fullscreen &) {
    // necessary to keep this position in center of block when el stuck to the top
    margin-bottom: calc((#{$head-navigations} / 2) - (var(--roadmap-footer-height) / 2));
  }
}

.rm-BackButton_Icon {
  .rm-OkrPage_Resizer-collapsedTable & {
    transform: rotate(180deg);
  }
}

.rm-OkrPageFooter_CollapseButton {
  flex: 0 0 auto;
  &-collapsed {
    transform: rotate(180deg);
  }
}

/* eslint-disable-next-line */
%resizer-line {
  background: linear-gradient(
    to right,
    transparent 0%,
    transparent 80%,
    $primary-color-next 80%,
    $primary-color-next 100%
  );
}

.rm-CollapsedTablePlug {
  width: $page-left-padding;
  flex: 0 0 $page-left-padding;
  //position: sticky;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  //padding-bottom: 80px;

  color: $placeholder-color;

  border-right: 2px solid $grey-2-next;

  // @extend %collapse-table-offset-top;

  height: auto;
  //height: calc(
  //  100vh - var(--objectives-page-header-height) - var(--app-license-banner-height) - #{$jira-elements-height} -
  //    var(--roadmap-footer-height)
  //);
  //
  //top: calc(
  //  var(--objectives-page-header-height, #{$default-objectives-page-header-height}) +
  //    var(--app-license-banner-height, 0px) + #{$jira-elements-height}
  //);

  //.rm-OkrPage-fullscreen & {
  //  height: calc(#{$fullscreen-canvas-height} - var(--roadmap-footer-height));
  //}

  cursor: pointer;

  @media (any-hover: hover) {
    &:hover {
      color: $blue-1;
    }
  }

  &:hover + .rm-OkrPage_Resizer {
    .rm-BackButtonWrapper {
      opacity: 1;
      z-index: 4;
    }

    &:before {
      @extend %resizer-line;
    }
  }
}

.rm-CollapsedTablePlug_Text {
  text-transform: uppercase;
  font-weight: fw('medium');
  font-size: $fs-14;
  transform: rotate(-180deg);
  writing-mode: vertical-rl;
  position: sticky;
  bottom: calc(var(--roadmap-footer-height) + 6px);
}

.rm-OkrPage_Resizer {
  width: 0;
  background-color: transparent;

  position: relative;
  z-index: 4;

  @media (any-hover: hover) {
    &:hover {
      .rm-BackButtonWrapper {
        opacity: 1;
        z-index: 4;
      }

      .rm-BackButton {
        pointer-events: all;
      }
    }
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 10px;
    background-color: transparent;
  }
  &:before {
    left: 0;
    transform: translateX(-100%);
  }

  &:after {
    right: 0;
    transform: translateX(100%);
  }
  &:hover,
  &:active {
    &:before {
      @extend %resizer-line;
    }
  }

  &:not(&-collapsedTable) {
    cursor: ew-resize;
    &:before,
    &:after {
      cursor: ew-resize;
    }
  }
}

.rm-OkrPageShadow {
  position: absolute;
  height: 100%;
  width: 10px;
  right: 0;
  left: unset;
  top: 0;
  pointer-events: none;
  box-shadow: 8px 0 10px rgba($dark-1, 0.15);
}

.rm-HighlightArea {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($dark-1, 0.75);
  z-index: 11;
  clip-path: v-bind(polygonPath);
}

.rm-HighlightAreaBorder {
  position: fixed;
  border: 4px solid $grade-high-color-next;
  z-index: 12;
}

.rm-OkrPage_Footer {
  position: fixed;
  height: var(--roadmap-footer-height);
  bottom: 0;
  right: 0;
  width: calc(100% - (#{$menu-width} * 0.1));
  background: $white;
  box-shadow: inset 0 2px 0 $grey-2-next;
  z-index: 4;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 $page-right-padding 0 $page-left-padding;

  .app-App-menu-pinned & {
    width: calc(100% - #{$menu-width});
  }

  .rm-OkrPage-fullscreen & {
    width: 100%;
  }
}

.rm-OkrPageFooterRight {
  margin-left: auto;
}
</style>
