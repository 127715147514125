import { saveDevAccessToken, saveDevRefreshToken } from '@/utils/web-app/tokens-helpers'
import { IS_DEVELOPMENT } from '@root/app-modes'

export const DEV_ORGANIZATION_ID_LS_KEY = 'DEV_ORGANIZATION_ID'

export const getLastDevOrganizationId = () => {
  const devOrganizationId = localStorage.getItem(DEV_ORGANIZATION_ID_LS_KEY)
  return devOrganizationId || process.env.VUE_APP_DEFAULT_DEV_ORGANIZATION_ID
}

export const saveDevOrganizationId = (id, saveFromTemplateOptions = false) => {
  if (saveFromTemplateOptions) {
    localStorage.setItem(
      DEV_ORGANIZATION_ID_LS_KEY,
      process.env.VUE_APP_DEFAULT_DEV_ORGANIZATION_ID
    )
    return
  }
  localStorage.setItem(DEV_ORGANIZATION_ID_LS_KEY, id)
}
export const switchDevOrganization = ({ iss, accessToken, refreshToken }) => {
  if (IS_DEVELOPMENT) {
    saveDevOrganizationId(iss)
    saveDevAccessToken(accessToken)
    saveDevRefreshToken(refreshToken)
  }
}
