import { kebabCase } from 'lodash'

import { JIRA_APP_SETTINGS_ROUTES } from '@/routes/jira-app-settings-pages'
import { ROUTE_NAMES } from '@/routes/route-helpers'
import { SALESFORCE_APP_SETTINGS_ROUTES } from '@/routes/salesforce-app-settings-pages'
import { WEB_APP_SETTINGS_ROUTES } from '@/routes/web-app-settings-pages'

const ALLOWED_ONLY_FOR_PLUGIN_ADMIN = 'allowedOnlyForPluginAdmin'
const ALLOWED_FOR_EVERYBODY = 'allowedForEverybody'
const ALLOWED_ONLY_FOR_OWNER = 'allowedForOwner'
export const menuItemsNames = {
  HOME: {
    name: kebabCase(ROUTE_NAMES.HOME_PAGE)
  },
  OBJECTIVES: {
    name: ROUTE_NAMES.OBJECTIVES.toLowerCase(),
    children: {
      MY_OKR: {
        name: 'my-okr'
      },
      MY_GROUP_OKR: {
        name: 'my-group-okr'
      }
    }
  },
  DASHBOARD: {
    name: ROUTE_NAMES.DASHBOARD.toLowerCase()
  },
  SETTINGS: {
    name: ROUTE_NAMES.SETTINGS.toLowerCase(),
    children: {
      WORKSPACES: {
        name: kebabCase(ROUTE_NAMES.SETTINGS_WORKSPACES),
        routeName: ROUTE_NAMES.SETTINGS_WORKSPACES
      },
      OKR_LEVELS: {
        name: `okr-${ROUTE_NAMES.SETTINGS_LEVELS.toLowerCase()}`,
        [ALLOWED_ONLY_FOR_PLUGIN_ADMIN]: true,
        routeName: ROUTE_NAMES.SETTINGS_LEVELS
      },
      STATUSES: {
        name: kebabCase(ROUTE_NAMES.SETTINGS_STATUSES),
        [ALLOWED_ONLY_FOR_PLUGIN_ADMIN]: true,
        routeName: ROUTE_NAMES.SETTINGS_STATUSES
      },
      CUSTOM_FIELDS: {
        name: kebabCase(ROUTE_NAMES.SETTINGS_CUSTOM_FIELDS),
        [ALLOWED_ONLY_FOR_PLUGIN_ADMIN]: true,
        routeName: ROUTE_NAMES.SETTINGS_CUSTOM_FIELDS
      },
      ORGANIZATION: {
        name: ROUTE_NAMES.ORGANIZATION.toLowerCase(),
        routeName: ROUTE_NAMES.ORGANIZATION
      },
      INTEGRATIONS: {
        name: ROUTE_NAMES.SETTINGS_INTEGRATIONS.toLowerCase(),
        [ALLOWED_ONLY_FOR_PLUGIN_ADMIN]: true,
        routeName: ROUTE_NAMES.SETTINGS_INTEGRATIONS
      },
      LABELS: {
        name: ROUTE_NAMES.SETTINGS_LABELS.toLowerCase(),
        [ALLOWED_ONLY_FOR_PLUGIN_ADMIN]: true,
        routeName: ROUTE_NAMES.SETTINGS_LABELS
      },
      COMPANY_SETTINGS: {
        name: ROUTE_NAMES.SETTINGS_COMPANY.toLowerCase(),
        [ALLOWED_ONLY_FOR_PLUGIN_ADMIN]: true,
        routeName: ROUTE_NAMES.SETTINGS_COMPANY
      },
      USER_PREFERENCES: {
        name: kebabCase(ROUTE_NAMES.SETTINGS_USER_PREFERENCES),
        routeName: ROUTE_NAMES.SETTINGS_USER_PREFERENCES,
        [ALLOWED_FOR_EVERYBODY]: true
      },
      SYNCHRONIZATION_SETTINGS: {
        name: ROUTE_NAMES.SETTINGS_SYNCHRONIZATION.toLowerCase(),
        [ALLOWED_ONLY_FOR_PLUGIN_ADMIN]: true,
        routeName: ROUTE_NAMES.SETTINGS_SYNCHRONIZATION
      },
      NOTIFICATIONS_SETTINGS: {
        name: kebabCase(ROUTE_NAMES.SETTINGS_NOTIFICATIONS),
        routeName: ROUTE_NAMES.SETTINGS_NOTIFICATIONS,
        [ALLOWED_FOR_EVERYBODY]: true
      },
      SECURITY_SETTINGS: {
        name: kebabCase(ROUTE_NAMES.SETTINGS_SECURITY),
        routeName: ROUTE_NAMES.SETTINGS_SECURITY
      },
      BILLING: {
        name: kebabCase(ROUTE_NAMES.SETTINGS_BILLING),
        [ALLOWED_ONLY_FOR_OWNER]: true,
        routeName: ROUTE_NAMES.SETTINGS_BILLING
      },
      AUDIT_LOGS: {
        name: kebabCase(ROUTE_NAMES.AUDIT_LOGS),
        [ALLOWED_ONLY_FOR_PLUGIN_ADMIN]: true,
        routeName: ROUTE_NAMES.AUDIT_LOGS
      }
      // BRANDING: {
      //   name: kebabCase(ROUTE_NAMES.SETTINGS_BRANDING),
      //   [ALLOWED_ONLY_FOR_PLUGIN_ADMIN]: true,
      //   routeName: ROUTE_NAMES.SETTINGS_BRANDING
      // }
    }
  },
  SUPPORT: {
    name: ROUTE_NAMES.SUPPORT.toLowerCase()
  }
}

const createFlattenList = obj => {
  return Object.values(obj).reduce((acc, val) => {
    if (val.children) {
      return [...acc, ...createFlattenList(val.children), val]
    } else {
      return [...acc, val]
    }
  }, [])
}

const flattenMenuItems = createFlattenList(menuItemsNames)

const getMenuItemsBySpecification = specification => {
  return flattenMenuItems
    .filter(item => item[specification])
    .reduce((acc, val) => {
      return {
        ...acc,
        [val.name]: val.routeName
      }
    }, {})
}

const menuItemsAllowedOnlyForPluginAdmin = getMenuItemsBySpecification(
  ALLOWED_ONLY_FOR_PLUGIN_ADMIN
)

const menuItemsAllowedForEverybody = getMenuItemsBySpecification(ALLOWED_FOR_EVERYBODY)

const menuItemsAllowedForOwner = getMenuItemsBySpecification(ALLOWED_ONLY_FOR_OWNER)

export const menuItemsNamesAllowedOnlyForPluginAdmin = Object.keys(
  menuItemsAllowedOnlyForPluginAdmin
)

export const menuItemsNamesAllowedOnlyForEverybody = Object.keys(menuItemsAllowedForEverybody)

export const menuItemsNamesAllowedOnlyForOwner = Object.keys(menuItemsAllowedForOwner)

export const routeNamesAllowedOnlyForPluginAdmin = Object.values(menuItemsAllowedOnlyForPluginAdmin)

export const routeNamesAllowedOnlyForOwner = Object.values(menuItemsAllowedForOwner)

const getRouteNames = routes => {
  return routes.map(route => route.name.toLowerCase())
}
export const menuItemNamesOnlyForJiraPlatform = getRouteNames(
  Object.values(JIRA_APP_SETTINGS_ROUTES)
)
export const menuItemNamesOnlyForWebApp = getRouteNames(Object.values(WEB_APP_SETTINGS_ROUTES))

export const menuItemNamesOnlyForSalesforceApp = getRouteNames(
  Object.values(SALESFORCE_APP_SETTINGS_ROUTES)
)

export const RELEASE_UPDATES_MENU_ITEM_NAME = 'release-updates'

export const SUPPORT_MENU_ITEM_NAME = 'support'
