<template>
  <div class="ip-IntegrationsPage">
    <div class="ip-TokensPage">
      <PageContentHeader
        :level="3"
        :max-width="HEADER_SIZES.LG"
        :title="t('menu.integrations')"
        class="ip-IntegrationsPage_Header"
        no-margin
        style="--padding-bottom: 13px"
      >
        <AppButton
          v-if="isApiTokensView && !isNoTokens"
          v-tippy="{
            content: createButtonDisabled ? 'You used all available tokens' : null,
            placement: 'top'
          }"
          :disable="createButtonDisabled"
          height="24"
          icon="plus-next"
          type="primary-next"
          width="24"
          @click="onCreateTokenClick"
        >
          {{ t('tokens.create_token') }}
        </AppButton>
      </PageContentHeader>

      <div v-if="Object.values(resolvedViews).length > 1" class="ip-IntegrationsPage_Toolbar">
        <AppRadioGroup
          :model-value="currentView"
          :options="Object.values(resolvedViews)"
          name="page-navigation"
          type="tab-like"
          @update:model-value="onViewChange"
        >
          <template #item-label="{ item }">
            <AppBadge
              v-if="item.badgeTitle"
              :content="item.badgeTitle"
              class="ogp-Badge"
              is-uppercase
            >
              {{ item.label }}
            </AppBadge>
          </template>
        </AppRadioGroup>
      </div>

      <div v-if="isApiTokensView && !isNoTokens" class="ip-Description">
        <i18n-t keypath="tokens.description" scope="global">
          <template #break>
            <br />
          </template>
          <template #usedTokensCount>
            {{ tokens.length }}
          </template>
        </i18n-t>
      </div>

      <template v-if="isApiTokensView">
        <div class="ip-MainContent">
          <AppTable
            v-if="!isNoTokens"
            :columns="tableColumns"
            :data="tokens"
            :disable-user-select="false"
            :loading="areTokensLoading"
            border-on-last-row
            class="ip-Table"
            no-border
            offset-left="var(--page-left-padding)"
            offset-right="var(--page-right-padding)"
            sticky-header
            type="primary-next"
          >
            <template #header-cell="{ column }">
              <template v-if="column.title">
                {{ t(column.title) }}
              </template>
            </template>
            <template #cell="{ columnKey, item, index }">
              <template v-if="columnKey === TABLE_COLUMNS_KEYS.NUMBER">
                <div class="ip-TokenNumber">
                  {{ index + 1 }}
                </div>
              </template>
              <template v-if="columnKey === TABLE_COLUMNS_KEYS.LABEL">
                <div class="ip-TokenName">{{ item.name }}</div>
              </template>

              <!--
              <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.LAST_ACCESSED">
                {{ getLastAccessed(item) }}
              </template>
              -->

              <template v-else-if="columnKey === TABLE_COLUMNS_KEYS.ACTION">
                <AppButton
                  class="ip-DeleteTokenBtn"
                  icon="delete-next"
                  size="sm"
                  type="tertiary-next"
                  @click="onTokenRevoke(item)"
                />
              </template>
            </template>
            <template #loading>
              <ApiTokensLoader />
            </template>

            <template #footer>
              <AppTableCreateButton
                v-if="!createButtonDisabled"
                full-width
                icon-name="plus-next"
                @click="onCreateTokenClick"
              >
                {{ t('tokens.create_token') }}
              </AppTableCreateButton>

              <AppInfoMessage
                v-if="isTokensLimitExceeded"
                :type="INFO_MESSAGE_TYPES.WARNING"
                class="ip-Message"
              >
                {{
                  t('tokens.limit_exceeded', {
                    limit: MAX_TOKENS_COUNT
                  })
                }}
              </AppInfoMessage>
            </template>
          </AppTable>
        </div>

        <SettingsPagesEmptyState
          v-if="isNoTokens"
          hero-height="128"
          hero-icon="no-tokens-hero"
          hero-width="142"
        >
          <template #title>
            {{ t('tokens.no_tokens') }}
          </template>
          <template #subtitle> {{ t('tokens.no_tokens_subtitle') }}</template>

          <template #action>
            <AppButton
              class="ip-CreateTokenBtn"
              height="24"
              icon="plus-next"
              type="primary-next"
              width="24"
              @click="onCreateTokenClick"
            >
              {{ t('tokens.create_token') }}
            </AppButton>
          </template>
        </SettingsPagesEmptyState>

        <TokenModal v-model:show="showTokenModal" @create="onTokenCreated" />

        <CopyTokenModal
          v-model:show="showCopyTokenModal"
          :token="tokenToCopy"
          @update:show="onCopyTokenModalInput"
        />

        <TokenDeleteModal
          :show="showTokenDeleteModal"
          :token="tokenToDelete"
          @on-close="hideTokenDeleteModal"
          @on-confirm="revokeToken"
        />
      </template>

      <JiraConnectionSettings v-if="isJiraConnectionsView" />
    </div>
  </div>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { computed, onMounted, ref, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import WorkspaceTokensApiHandler from '@/api/workspace-tokens'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, EVENT_NAMES } from '@/tracking/amplitude-helpers'
import { INFO_MESSAGE_TYPES } from '@/utils/components-configurations/app-info-message'
import { HEADER_SIZES } from '@/utils/components-configurations/page-content-header'
import { handleError } from '@/utils/error-handling'
import {
  isJiraAppInjectionKey,
  isSalesforceAppInjectionKey,
  isWebAppInjectionKey
} from '@/utils/injection-keys'
import { showNotify } from '@/utils/notify'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import AppBadge from '@/components/ui/AppBadge/AppBadge'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppRadioGroup from '@/components/ui/AppRadioGroup/AppRadioGroup'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import PageContentHeader from '@/components/ui/PageContentHeader/PageContentHeader'
import SettingsPagesEmptyState from '@/components/ui/SettingsPagesEmptyState/SettingsPagesEmptyState'
import ApiTokensLoader from '@/components/ui/SkeletonLoaders/ApiTokensLoader'
import AppTable from '@/components/ui/Table/AppTable'
import CopyTokenModal from '@/components/workspaces/CopyTokenModal'
import TokenDeleteModal from '@/components/workspaces/TokenDeleteModal'
import TokenModal from '@/components/workspaces/TokenModal'
import JiraConnectionSettings from '@/views/workspaces/settings/integrations/JiraConnectionSettings'

defineOptions({
  name: 'IntegrationsPage'
})

const { t } = useI18n()
const store = useStore()

const isJiraApp = inject(isJiraAppInjectionKey)
const isWebApp = inject(isWebAppInjectionKey)
const isSalesforceApp = inject(isSalesforceAppInjectionKey)

const connectionTabTitle = computed(() => {
  if (isJiraApp) {
    return t('app.platform.web_app')
  }

  if (isWebApp || isSalesforceApp) {
    return t('app_name.jira_plugin')
  }

  return ''
})

const isPluginServer = computed(() => store.getters['pluginOptions/isPluginServer'])

const VIEWS = {
  CONNECTIONS: {
    value: 'connections',
    label: connectionTabTitle.value,
    trackingTab: 'connection'
  },
  TOKENS: {
    value: 'api-tokens',
    label: t('menu.api_tokens'),
    trackingTab: t('menu.api_tokens', 1, { locale: 'en' }).toLowerCase()
  }
}

const resolvedViews = computed(() => {
  const { TOKENS } = VIEWS

  if (isPluginServer.value) {
    return {
      TOKENS
    }
  }

  return VIEWS
})

const tokensApi = new WorkspaceTokensApiHandler()

const MAX_TOKENS_COUNT = 10

const tableColumns = [
  {
    title: 'tokens.token_number',
    key: TABLE_COLUMNS_KEYS.NUMBER,
    width: 24
  },
  {
    title: 'tokens.label',
    key: TABLE_COLUMNS_KEYS.LABEL,
    width: 'auto'
  },
  // {
  //   title: 'tokens.last_accessed',
  //   key: TABLE_COLUMNS_KEYS.LAST_ACCESSED,
  //   width: 224
  // },
  {
    key: TABLE_COLUMNS_KEYS.ACTION,
    width: 24
  }
]

const tokens = ref([])
const showTokenModal = ref(false)
const showCopyTokenModal = ref(false)
const tokenToCopy = ref(null)
const tokenToDelete = ref(null)
const showTokenDeleteModal = ref(false)
const areTokensLoading = ref(false)

const isTokensLimitExceeded = computed(() => {
  return tokens.value.length >= MAX_TOKENS_COUNT
})

const createButtonDisabled = computed(() => {
  return areTokensLoading.value || isTokensLimitExceeded.value
})

const isNoTokens = computed(() => {
  return isEmpty(tokens.value) && !areTokensLoading.value
})

const getTokens = async () => {
  try {
    areTokensLoading.value = true
    tokens.value = await tokensApi.getTokens()
  } catch (error) {
    handleError({ error })
  } finally {
    areTokensLoading.value = false
  }
}

onMounted(() => {
  getTokens()
})

const onCreateTokenClick = () => {
  if (!createButtonDisabled.value) {
    showTokenModal.value = true
  }
}

const onTokenCreated = token => {
  showTokenModal.value = false
  getTokens()

  tracker.logEvent('Token created', {
    category: EVENT_CATEGORIES.SETTINGS
  })

  tokenToCopy.value = token
  showCopyTokenModal.value = true
}

const onTokenRevoke = token => {
  tokenToDelete.value = token
  showTokenDeleteModal.value = true
}

const hideTokenDeleteModal = () => {
  showTokenDeleteModal.value = false
}

const onCopyTokenModalInput = value => {
  if (!value) {
    tokenToCopy.value = null
  }
}
const revokeToken = async () => {
  try {
    await tokensApi.removeToken({
      tokenId: tokenToDelete.value.id
    })

    hideTokenDeleteModal()

    tracker.logEvent('Token revoked', {
      category: EVENT_CATEGORIES.SETTINGS
    })

    showNotify({
      title: t('token.toast.revoke_message')
    })
  } catch (error) {
    handleError({ error })
  }
  tokenToDelete.value = null
  await getTokens()
}

// getLastAccessed(item) {
//   if (item.createDate === null) {
//     return this.$t('tokens.never_accessed')
//   }
//   const createDate = new Date(item.createDate)
//   const now = new Date()
//
//   const msInHour = 60 * 60 * 1000
//   const msInMin = 60 * 1000
//
//   const monthsList = [
//     'January',
//     'February',
//     'March',
//     'April',
//     'May',
//     'June',
//     'July',
//     'August',
//     'September',
//     'October',
//     'November',
//     'December'
//   ]
//   const formatHours = `0${createDate.getHours()}`.slice(-2)
//   const formatMins = `0${createDate.getMinutes()}`.slice(-2)
//
//   const diffForHour = Math.trunc((+now - +createDate) / msInHour)
//   const diffForMin = Math.trunc((+now - +createDate) / msInMin)
//
//   if (diffForHour > 24) {
//     return `${
//       monthsList[createDate.getMonth()]
//     } ${createDate.getDate()}, ${createDate.getFullYear()} ${formatHours}:${formatMins}`
//   }
//
//   if (diffForHour < 1) {
//     return `${diffForMin} ${this.$t('token.minutes_ago')}`
//   }
//
//   if (diffForMin < 1) {
//     return this.$t('objective.a_moment_ago')
//   }
//
//   return `${diffForHour} ${this.$t('objective.hours_ago')}`
// },

const [firstView] = Object.values(resolvedViews.value)

const currentView = ref(firstView.value)

const userRoleForTracking = computed(() => {
  return store.getters['system/userRoleForTracking']
})

const onViewChange = value => {
  const view = Object.values(VIEWS).find(view => view.value === value)
  if (view) {
    currentView.value = value

    const trackerData = {
      category: EVENT_CATEGORIES.SETTINGS,
      subCategory: t('menu.integrations', 1, { locale: 'en' }),
      role: userRoleForTracking.value,
      tab: view.trackingTab
    }
    tracker.logEvent(EVENT_NAMES.SETTINGS_OPENED, trackerData)
  }
}

const isApiTokensView = computed(() => currentView.value === VIEWS.TOKENS.value)
const isJiraConnectionsView = computed(() => currentView.value === VIEWS.CONNECTIONS.value)
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/app-table-helpers';

.ip-IntegrationsPage {
  width: 100%;
  max-width: $page-width-lg;
  padding-bottom: $page-bottom-padding;
}

.ip-MainContent {
  width: 100%;
  max-width: $page-width-md;
  padding-left: $page-left-padding;
  padding-right: $page-right-padding;
}

.ip-Description {
  font-style: normal;
  font-weight: fw('regular');
  font-size: $fs-14;
  line-height: 20px;
  color: $dark-2;
  padding: 13px $page-right-padding 0 $page-left-padding;
  font-family: $system-ui;
  width: 100%;
  max-width: $page-width-md;
}

.ip-TokenName,
.ip-TokenNumber {
  font-style: normal;
  font-weight: fw('semi-bold');
  font-size: $fs-14;
  line-height: 20px;
}

.ip-TokenName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $dark-1;
}

.ip-TokenNumber {
  color: $dark-3;
}

.ip-DeleteTokenBtn {
  @extend %app-table-hidden-items;
}

.ip-Table {
  --head-padding-top: 13px;
}

.ip-Message {
  margin-top: 10px;
  padding-left: $page-left-padding;
}

.ip-IntegrationsPage_Header {
  &:deep(.pc-Title) {
    min-height: 32px;
  }
}

.ip-IntegrationsPage_Toolbar {
  padding: 0 $page-right-padding 0 $page-left-padding;
  width: 100%;
  max-width: $page-width-md;
}
</style>
