<template>
  <!--  <AppButton @click="showConfBanner">Show banner</AppButton>-->
  <div v-if="!isNoBanners" class="abw-Wrapper">
    <ConfluenceBanner
      v-if="isShowConfluenceServerBanner"
      marketplace-link="https://marketplace.atlassian.com/apps/1229394/okr-board-for-confluence?ref_source=left_nav_app&hosting=datacenter"
      @update-banner-data="updateBannerData(CONFLUENCE_SERVER_BANNER)"
    />

    <SurveyBanner
      v-else-if="isShowSurveyBanner"
      link="https://oboard.io/okr-accelerator?utm_source=product_sidebar&utm_medium=referral&utm_campaign=okr_accelerator"
      @update-banner-data="updateBannerData(SURVEY_BANNER)"
    >
      <template #title>
        {{ $t('banner.okr_accelerator.title') }}
      </template>
      <template #button-text> {{ $t('banner.join_now') }}</template>
    </SurveyBanner>

    <SurveyBanner
      v-else-if="isShowConferenceBanner"
      link="https://www.linkedin.com/events/7251961544668827649/comments/"
      @update-banner-data="updateBannerData(CONFERENCE_BANNER)"
    >
      <template #title>
        <!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
        How to Plan an OKR Cycle
      </template>
      <template #button-text> Join webinar</template>
      <!-- eslint-enable @intlify/vue-i18n/no-raw-text -->
    </SurveyBanner>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'
import { max } from 'lodash'
import { computed, inject } from 'vue'
import { useStore } from 'vuex'

import { isJiraAppInjectionKey } from '@/utils/injection-keys'
import {
  BANNER,
  CONFERENCE_BANNER,
  CONFLUENCE_BANNER,
  CONFLUENCE_SERVER_BANNER,
  DEFAULT_USERS_SETTINGS,
  SURVEY_BANNER,
  USER_SETTINGS_DATE_FORMAT,
  USER_SETTINGS_MAPPER
} from '@/utils/user-settings'

import ConfluenceBanner from '@/components/ui/AppBanners/ConfluenceBanner'
import SurveyBanner from '@/components/ui/AppBanners/SurveyBanner'
// import AppButton from '@/components/ui/AppButton/AppButton'

defineOptions({
  name: 'AppBannerWrapper'
})

const isJiraApp = inject(isJiraAppInjectionKey)

const store = useStore()
const isServer = computed(() => store.state.pluginOptions.isPluginServer)

const userSettings = computed(() => {
  return store.state.system.userData?.userSettings
})
const isExpired = expirationDate => {
  const currentDate = dayjs().utc()
  const bannerExpirationDate = dayjs(expirationDate, USER_SETTINGS_DATE_FORMAT).utc()
  return currentDate > bannerExpirationDate
}
const checkIsCurrentBanner = (bannerId, bannerType = SURVEY_BANNER) => {
  return DEFAULT_USERS_SETTINGS[bannerType].id === bannerId
}

const isShowConferenceBanner = computed(() => showBanner(CONFERENCE_BANNER) && !isServer.value)
const isShowSurveyBanner = computed(() => showBanner(SURVEY_BANNER) && !isServer.value)

const isShowConfluenceServerBanner = computed(
  () => showBanner(CONFLUENCE_SERVER_BANNER) && isServer.value
)

const isHighestId = computed(() => {
  const maxId = max([
    DEFAULT_USERS_SETTINGS[SURVEY_BANNER].id,
    DEFAULT_USERS_SETTINGS[CONFLUENCE_BANNER].id,
    DEFAULT_USERS_SETTINGS[CONFLUENCE_SERVER_BANNER].id,
    DEFAULT_USERS_SETTINGS[CONFERENCE_BANNER].id
  ])
  const bannerField = userSettings.value[USER_SETTINGS_MAPPER[BANNER]]

  return maxId === bannerField.id
})

const isNoBanners = computed(
  () =>
    (!isShowSurveyBanner.value &&
      !isShowConfluenceServerBanner.value &&
      !isShowConferenceBanner.value) ||
    isHighestId.value
)

const showBanner = (bannerType = SURVEY_BANNER) => {
  if (!isJiraApp) {
    return false
  }

  if (!userSettings.value) {
    return false
  }

  const bannerField = userSettings.value[USER_SETTINGS_MAPPER[BANNER]]
  const isCurrentBannerExpired = isExpired(DEFAULT_USERS_SETTINGS[bannerType].expirationDate)

  if (bannerField) {
    const bannerFromSettingsEqualsCurrent = checkIsCurrentBanner(bannerField.id, bannerType)
    if (bannerFromSettingsEqualsCurrent && !bannerField.visible) {
      // if user has current banner and it is closed
      return false
    }

    if (bannerFromSettingsEqualsCurrent && bannerField.visible && !isCurrentBannerExpired) {
      // if user has current banner
      return true
    }

    if (!bannerFromSettingsEqualsCurrent && !isCurrentBannerExpired) {
      // if user has another banner
      return true
    }
  } else {
    if (!isCurrentBannerExpired) {
      // if user has no banner
      return true
    }
  }

  return false
}
// const emit = defineEmits({ 'recalculate-footer-height': null })
// const recalculateFooterHeight = () => {
//   emit('recalculate-footer-height')
// }
const updateBannerData = (bannerType = SURVEY_BANNER) => {
  const payload = {
    [USER_SETTINGS_MAPPER[BANNER]]: DEFAULT_USERS_SETTINGS[bannerType]
  }

  store.dispatch('system/updateUserSettings', payload)
}

// const showConfBanner = () => {
//   store.dispatch('system/updateUserSettings', {
//     [USER_SETTINGS_MAPPER[BANNER]]: {
//       id: 1,
//       visible: true,
//       expirationDate: null
//     }
//   })
// }
// setTimeout(() => {
//   // For debug banners
//   store.dispatch('system/updateUserSettings', {
//     [USER_SETTINGS_MAPPER[BANNER]]: {
//       id: 1,
//       visible: true,
//       expirationDate: null
//     }
//   })
// }, 2000)
</script>

<style lang="scss" scoped>
.abw-Wrapper {
  margin-top: 16px;
}

/*.abw-Divider {
  width: 100%;
  --border-width: 2px;
  margin: 8px 0;
  color: #e6e9ed;
  border-radius: 1px;
}*/
</style>
