<template>
  <div
    :class="{
      'dth-HeaderCell-sorting': column.sortable
    }"
    class="dth-HeaderCell"
    @click="changeSortBy(column)"
  >
    <span class="oboard-truncated-text dth-HeaderCell_Label">{{ column.title }}</span>
    <AppIcon
      v-if="sortingColumns.includes(column.key)"
      :class="{
        'dth-HeaderCell_DefaultStateSorting': defaultStateSorting(getSortIconName(column.key))
      }"
      :icon-name="getSortIconName(column.key)"
      height="20"
      width="20"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'

import {
  ascSortValue,
  avgDecreaseValue,
  avgIncreaseValue,
  descSortValue
} from '@/utils/sort-options'
import { TABLE_COLUMNS_KEYS } from '@/utils/table-columns'

import AppIcon from '@/components/ui/AppIcon/AppIcon'

defineOptions({
  name: 'DashboardTableHeaderCell'
})

const SORT_TWO_WAYS_NEXT = 'sort-two-ways-next'
const props = defineProps({
  column: {
    type: Object,
    required: true
  },
  sortBy: {
    type: String,
    default: descSortValue
  }
})

const changeSortBy = column => {
  if (!column.sortable) {
    return
  }
  switch (column.key) {
    case TABLE_COLUMNS_KEYS.LABEL:
      sortByGroups()
      break
    case TABLE_COLUMNS_KEYS.AVERAGE_TOTAL:
      sortByAverage()
      break
    case TABLE_COLUMNS_KEYS.OWNERS:
      sortByGroups()
      break
  }
}

const emit = defineEmits({
  'update:sortBy': null
})
const updateSortBy = sortBy => emit('update:sortBy', sortBy)
const sortByAverage = () => {
  const sortBy = props.sortBy === avgIncreaseValue ? avgDecreaseValue : avgIncreaseValue
  updateSortBy(sortBy)
}
const sortByGroups = () => {
  const sortBy = props.sortBy === ascSortValue ? descSortValue : ascSortValue
  updateSortBy(sortBy)
}

const defaultStateSorting = iconName => {
  return iconName === SORT_TWO_WAYS_NEXT
}
const sortingColumns = [
  TABLE_COLUMNS_KEYS.LABEL,
  TABLE_COLUMNS_KEYS.OWNERS,
  TABLE_COLUMNS_KEYS.AVERAGE_TOTAL
]
const getSortIconName = key => {
  const iconArrowName =
    props.sortBy === ascSortValue || props.sortBy === avgIncreaseValue
      ? 'arrow-up-next'
      : 'arrow-down-next'
  if (key === TABLE_COLUMNS_KEYS.LABEL || key === TABLE_COLUMNS_KEYS.OWNERS) {
    return isGroupSortActive.value ? iconArrowName : SORT_TWO_WAYS_NEXT
  } else {
    return isAverageSortActive.value ? iconArrowName : SORT_TWO_WAYS_NEXT
  }
}

const isGroupSortActive = computed(() => {
  return props.sortBy === ascSortValue || props.sortBy === descSortValue
})

const isAverageSortActive = computed(() => {
  return props.sortBy === avgIncreaseValue || props.sortBy === avgDecreaseValue
})
</script>

<style lang="scss" scoped>
.dth-HeaderCell {
  display: inline-flex;
  align-items: center;
  cursor: default;
  max-width: 100%;
  &-sorting {
    cursor: pointer;
  }
}
.dth-HeaderCell_DefaultStateSorting {
  color: $grey-2-next;
  flex-shrink: 0;
}

.dth-HeaderCell_Label {
  padding-left: 8px;
}
</style>
