<template>
  <div class="omc-Wrapper">
    <div
      v-for="(conflict, conflictIndex) in conflictItems"
      :key="conflictIndex"
      class="omc-WrapperRow"
    >
      <AppInput
        :model-value="conflict.name.trim()"
        class="omc-WrapperRow_Input"
        disabled
        size="xlg"
        style="--input-with-prepend-padding-left: 8px; --prepend-padding-left: 6px"
        style-type="primary"
      >
        <template #prepend>
          <slot :conflict="conflict" name="prepend-input"></slot>
        </template>
      </AppInput>
      <AppIcon height="24" icon-name="arrow-right-next" width="24" />
      <AppSelect
        :dropdown-min-width="200"
        :item-label="itemLabel"
        :item-value="itemValue"
        :model-value="selectedItems[conflictIndex]"
        :offset="[0, 0]"
        :options="selectItemsOptions"
        :type="SELECT_TYPES.MODERN"
        class="omc-WrapperRow_Select"
        fit-content-width
        @update:model-value="$event => updateValue(conflictIndex, $event)"
      >
        <template #button-content="{ option }">
          <span class="omc-SelectItemLabel oboard-truncated-text">
            {{ option ? option[itemLabel] : selectPlaceholder }}
          </span>
        </template>
        <template #prepend-icon="{ option }">
          <div v-if="option" class="omc-SelectPrependIcon">
            <slot
              :conflict-index="conflictIndex"
              :selected-item="selectedItemsMapped[conflictIndex]"
              name="prepend-select-icon"
            />
          </div>
        </template>

        <template #option-label="{ option }">
          <slot :option="option" name="select-option-label" />
        </template>
      </AppSelect>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { SELECT_TYPES } from '@/utils/components-configurations/app-select'

import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppInput from '@/components/ui/AppInput/AppInput'
import AppSelect from '@/components/ui/AppSelect/AppSelect'

defineOptions({
  name: 'ObjectiveMoveConflictItems'
})

const props = defineProps({
  selectItems: {
    type: Array,
    default: () => []
  },
  conflictItems: {
    type: Array,
    default: () => []
  },
  selectedItems: {
    type: Array,
    default: () => []
  },
  itemValue: {
    type: String,
    default: 'id'
  },
  itemLabel: {
    type: String,
    default: 'name'
  },

  selectPlaceholder: {
    type: String,
    default: ''
  }
})

const emit = defineEmits({
  'update:model-value': null
})

const updateValue = (index, value) => {
  emit('update:model-value', { index, value })
}
const selectItemsOptions = computed(() => {
  return props.selectItems.map(item => ({
    ...item,
    [props.itemValue]: item[props.itemValue],
    [props.itemLabel]: item[props.itemLabel].trim()
  }))
})
const selectedItemsMapped = computed(() => {
  return props.selectedItems.map(item =>
    props.selectItems.find(selectItem => selectItem.id === item || selectItem.accountId === item)
  )
})
</script>

<style lang="scss" scoped>
.omc-Wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 0 20px 0;
}
.omc-WrapperRow {
  display: flex;
  align-items: center;
  gap: 16px;
}

.omc-WrapperRow_Input {
  width: 200px;

  &:deep(.o-input-field) {
    color: $dark-1;
  }
  &:deep(.o-input-wrapper) {
    background: $grey-3-next;
    font-family: $system-ui;
    border-radius: $border-radius-sm-next;
    font-style: normal;
    font-weight: fw('regular');
    font-size: $fs-14;
    line-height: 20px;
  }
}
.omc-WrapperRow_Select {
  width: 200px;
}
.omc-SelectPrependIcon {
  padding-left: 6px;

  &:has(+ .as-AppDroplistButton_Content > .omc-SelectItemLabel) {
    margin-right: 6px;
  }
}

.omc-SelectItemLabel {
  padding-left: 2px;
}
</style>
