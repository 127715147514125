<template>
  <template v-if="(isWebApp || isSalesforceApp) && isPluginAdmin">
    <slot :on-trigger-click="onTriggerClick" name="trigger">
      <AppButton class="ium-Trigger" icon="user-next" type="primary-next" @click="onTriggerClick">
        {{ t('users.invite') }}
      </AppButton>
    </slot>
    <portal to="modal-windows">
      <AppModalWithConfirmation
        :confirm-close="!isEmpty(users)"
        :show="show"
        :size="744"
        :title="t('organization.invite_users.title')"
        :type="DIALOG_TYPES.INFO"
        hide-hero
        @on-close="onClose"
      >
        <div class="ium-ModalBody">
          <FormFieldNext :label="$t('organization.invite_users.email_label')">
            <EmailCollectorInput
              v-model="users"
              :is-error="usersIsEmpty"
              show-list-of-emails
              @focus="usersIsEmpty = false"
            />
            <AppFieldError v-if="usersIsEmpty" :show="usersIsEmpty">
              {{ t('field.required') }}
            </AppFieldError>
          </FormFieldNext>

          <div v-if="isShowAccessSettings" class="ium-AccessSettings">
            <FormFieldNext :label="t('field.workspace.title')" class="ium-AccessSettings_Field">
              <AppSelect
                v-model="formModel[REQUEST_ENTITY_KEYS.WORKSPACE_ID]"
                :dropdown-search="showDropdownSearch"
                :offset="[0, showDropdownSearch ? -40 : 0]"
                :options="allWorkspaces"
                :search-function="value => localSearch({ value, options: allWorkspaces })"
                boundary="scrollParent"
                item-label="name"
                item-value="id"
              >
                <template #option-label="{ option }">
                  <WorkspaceSelectOption v-if="option" :workspace="option" />
                </template>

                <template #button-content="{ option }">
                  <WorkspaceSelectOption v-if="option" :workspace="option" hide-lock-icon />
                  <template v-else>
                    <span class="oboard-truncated-text">{{ $t('group.select_workspace') }}</span>
                  </template>
                </template>
              </AppSelect>
              <AppInfoMessage>
                {{ t('users.add_to_workspace_without_group', users.length) }}
              </AppInfoMessage>
            </FormFieldNext>

            <FormFieldNext :label="$t('workspaces.role')" class="ium-AccessSettings_Field">
              <AppSelect
                v-model="formModel[ASSIGNEE_ENTITY_KEYS.ROLE_ID]"
                :dropdown-search="false"
                :inline-loader="false"
                :offset="[0, 0]"
                :options="resolvedRoles"
                boundary="scrollParent"
                item-label="label"
                item-value="value"
              />
            </FormFieldNext>
          </div>
        </div>

        <template v-if="!isShowAccessSettings" #footer-prepend>
          <AppTableCreateButton icon-name="cog" no-hover no-padding @click="showAccessSettings">
            {{ t('action.access_settings') }}
          </AppTableCreateButton>
        </template>

        <template #footer-actions>
          <AppButton type="ghost-next" @click="onClose">
            {{ t('action.cancel') }}
          </AppButton>
          <AppButton
            :disable="isLoading"
            :loading="isLoading"
            data-testid="save-button"
            type="primary-next"
            @click="onConfirm"
          >
            {{ t('organization.invite_users.title') }}
          </AppButton>
        </template>
      </AppModalWithConfirmation>
    </portal>
  </template>
</template>

<script setup>
import { isEmpty } from 'lodash'
import { computed, inject, nextTick, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import UserApiHandler from '@/api/user-details'
import { useFetchRoles } from '@/composables/plugin-users'
import { tracker } from '@/tracking/amplitude'
import {
  EVENT_CATEGORIES,
  EVENT_SOURCES,
  MODE_NAMES_FOR_TRACKING,
  trackAddUserEvent
} from '@/tracking/amplitude-helpers'
import { trackInviteUsersEvent } from '@/tracking/gtm-helpers'
import { DIALOG_TYPES } from '@/utils/components-configurations/app-dialog'
import { ASSIGNEE_ENTITY_KEYS, REQUEST_ENTITY_KEYS } from '@/utils/entity-keys'
import { handleError } from '@/utils/error-handling'
import { isSalesforceAppInjectionKey, isWebAppInjectionKey } from '@/utils/injection-keys'
import { localSearch, WORKSPACE_USER_ROLES } from '@/utils/objectives'
import { IDENTIFIER_TYPE_IDS } from '@/utils/web-app/emails-in-select'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'
import EmailCollectorInput from '@shared-modules/components/EmailCollectorInput'

import AppModalWithConfirmation from '@/components/AppModalWithConfirmation'
import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppInfoMessage from '@/components/ui/AppInfoMessage'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import AppTableCreateButton from '@/components/ui/AppTableCreateButton'
import WorkspaceSelectOption from '@/components/ui/WorkspaceSelectOption/WorkspaceSelectOption'

defineOptions({
  name: 'InviteUsersToWorkspaceModal'
})

const { t } = useI18n()

const isWebApp = inject(isWebAppInjectionKey)
const isSalesforceApp = inject(isSalesforceAppInjectionKey)
const isPluginAdmin = computed(() => store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.IS_PLUGIN_ADMIN])

const users = ref([])

const show = ref(false)

const isShowAccessSettings = ref(false)

const formModel = ref({
  [REQUEST_ENTITY_KEYS.WORKSPACE_ID]: null,
  [ASSIGNEE_ENTITY_KEYS.ROLE_ID]: WORKSPACE_USER_ROLES.USER
})

const store = useStore()

const selectedWorkspaceId = computed(() => {
  return store.state.workspaces[REQUEST_ENTITY_KEYS.WORKSPACE_ID]
})

const allWorkspaces = computed(() => {
  return store.state.workspaces.workspaces
})

const showDropdownSearch = computed(() => {
  return allWorkspaces.value.length > 10
})

const roles = ref([])

const fetchRoles = async () => {
  roles.value = await useFetchRoles()
}

const resolvedRoles = computed(() => {
  return roles.value.filter(({ value }) => value)
})

const showAccessSettings = () => {
  formModel.value[REQUEST_ENTITY_KEYS.WORKSPACE_ID] = selectedWorkspaceId.value
  isShowAccessSettings.value = true
}

onMounted(() => {
  if ((isWebApp || isSalesforceApp) && isPluginAdmin.value) {
    fetchRoles()
  }
})

watch(
  show,
  newValue => {
    if (newValue) {
      formModel.value[REQUEST_ENTITY_KEYS.WORKSPACE_ID] = selectedWorkspaceId.value
    }
  },
  {
    immediate: true
  }
)

const usersIsEmpty = ref(false)
const isLoading = ref(false)

const emit = defineEmits({
  success: null
})

const onConfirm = async () => {
  usersIsEmpty.value = isEmpty(users.value)
  await nextTick()

  if (!usersIsEmpty.value) {
    const api = new UserApiHandler()
    isLoading.value = true

    const payload = users.value.map(user => {
      const { email } = user
      return {
        [REQUEST_ENTITY_KEYS.IDENTIFIER]: email,
        [REQUEST_ENTITY_KEYS.IDENTIFIER_TYPE_ID]: IDENTIFIER_TYPE_IDS.EMAIL,
        [REQUEST_ENTITY_KEYS.GROUP_IDS]: [],
        [ASSIGNEE_ENTITY_KEYS.ROLE_ID]: formModel.value[ASSIGNEE_ENTITY_KEYS.ROLE_ID],
        [REQUEST_ENTITY_KEYS.WORKSPACE_ID]:
          formModel.value[REQUEST_ENTITY_KEYS.WORKSPACE_ID] || selectedWorkspaceId.value
      }
    })

    try {
      await api.addUserToWorkspaces(payload)
      trackAddUserEvent({
        selectedUsers: users.value,
        mode: MODE_NAMES_FOR_TRACKING.ADD_TO_WORKSPACE,
        source: EVENT_SOURCES.INVITATION_FORM
      })

      trackInviteUsersEvent()

      emit('success')
      onClose()
    } catch (error) {
      handleError({ error })
    } finally {
      isLoading.value = false
    }
  }
}

const resetState = () => {
  users.value = []
  usersIsEmpty.value = false
  formModel.value[REQUEST_ENTITY_KEYS.WORKSPACE_ID] = selectedWorkspaceId.value
  formModel.value[ASSIGNEE_ENTITY_KEYS.ROLE_ID] = WORKSPACE_USER_ROLES.USER
  isShowAccessSettings.value = false
}

const onClose = () => {
  show.value = false
  resetState()
}

const route = useRoute()

const onTriggerClick = () => {
  show.value = !show.value
  if (show.value) {
    tracker.logEvent('invitation form opened', {
      category: EVENT_CATEGORIES.USER_MANAGEMENT,
      source: route.name
    })
  }
}
</script>

<style lang="scss" scoped>
$gap: 8px;
.ium-AccessSettings {
  display: flex;
  gap: $gap;
}

.ium-AccessSettings_Field {
  width: calc(50% - #{$gap / 2});
}

.ium-ModalBody {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ium-Trigger {
  flex-shrink: 0;
}
</style>
