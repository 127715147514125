export const TABLE_COLUMNS_KEYS = {
  LABEL: 'label',
  ACTION: 'action',
  CHECKBOX: 'checkbox',
  BULK_CHECKBOX: 'bulkCheckbox',
  NAME: 'name',
  DEFAULT_GROUP: 'defaultGroup',
  ROLE: 'role',
  ACTIONS: 'actions',
  USERS: 'users',
  ASSOCIATED: 'associated',
  SYNCHRONIZATION: 'synchronization',
  TIMEZONE: 'timezone',
  KEY: 'key',
  VISIBILITY: 'visibility',
  PERIOD: 'period',
  STATUS: 'status',
  WORKSPACE: 'workspace',
  WORKSPACE_NAME: 'workspaceName',
  LAST_ACCESSED: 'lastAccessed',
  OWNERS: 'owners',
  OWNER: 'owner',
  NOT_STARTED_COUNT: 'notStartedCount',
  CLOSED_COUNT: 'closedCount',
  ABANDONED_COUNT: 'abandonedCount',
  ON_TRACK_COUNT: 'onTrackCount',
  BEHIND_COUNT: 'behindCount',
  AT_RISK_COUNT: 'atRiskCount',
  AVERAGE_TOTAL: 'averageTotal',
  CONTRIBUTE: 'contribute',
  INTERVAL: 'interval',
  WIGHT: 'weight',
  IMPACT: 'impact',
  GRADE: 'grade',
  NUMBER: 'number',
  GROUPS: 'groups',
  GROUP: 'group',
  INTERVALS: 'intervals',
  SYNC: 'sync',
  VISIBILITY_ICON: 'visibilityIcon',
  PRIVATE_WORKSPACES: 'privateWorkspaces',
  PUBLIC_WORKSPACES: 'publicWorkspaces',
  ROLES_IN_THE_WORKSPACES: 'rolesInWorkspaces',
  LINKED_GROUPS: 'linkedGroups',
  FAVORITES: 'favorites',
  PROGRESS: 'progress',
  DATE: 'date',
  USER: 'user',
  USER_NAME: 'userName',
  TEXT: 'text',
  EXPAND_COLLAPSE: 'expandCollapse',
  OKR_COUNT: 'okrCount',
  MORE_THAN_100_GRADE_COUNT: 'moreThan100GradeCount',
  ZERO_GRADE_COUNT: 'zeroGradeCount',
  GRADE_BEHIND_COUNT: 'gradeBehindCount',
  GRADE_ON_TRACK_COUNT: 'gradeOnTrackCount',
  GRADE_AT_RISK_COUNT: 'gradeAtRiskCount',
  REQUIRED: 'required',
  FOR: 'for',
  CONTENT_AREA: 'contentArea',
  PLATFORM: 'platform',
  PRICE: 'price',
  PLAN_NAME: 'planName',
  SETTINGS: 'settings',
  ICON: 'icon'
}

export const CELL_TEXT_ALIGNS = {
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right'
}
