<template>
  <portal to="modal-windows">
    <div class="com-ModalWrapper">
      <Modal
        :show="showModal"
        data-testid="close-objective-modal"
        hide-close
        manual-close
        size="lg-next"
        @close="closeModal()"
      >
        <template #loader>
          <SavingIndicator ref="savingIndicatorTop" :type="SAVING_INDICATOR_TYPES.LINE" />
        </template>
        <template #header>
          <div class="com-ModalHeader">
            <OkrIconAndId :okr-element="objective" />

            <div>
              <SavingIndicator ref="savingIndicator" />
              <AppButton
                class="com-Button"
                icon="close-next"
                size="sm"
                type="ghost-next"
                @click="closeModal()"
              />
            </div>
          </div>
        </template>
        <div class="com-BodyWrapper">
          <div class="com-Body">
            <div class="com-HeaderWrapper">
              <div class="com-TitleWrapper">
                <TitleWithAction>
                  <template #prepend> {{ getCloseStatusText }}: <br /></template>
                  {{ objective.name }}
                </TitleWithAction>
              </div>
              <OkrGradeValue :color="objective.gradeColor" :grade="objective.gradeToUse" />
            </div>
            <div class="com-ObjectiveComment">
              <FormFieldNext :label="t('menu.comment')">
                <DescriptionField
                  v-model="formModel.description"
                  :actions="false"
                  :max-length="2000"
                  :placeholder="t('description.placeholder')"
                  :placement="COMMENT_FIELD_PLACEMENTS"
                  :workspace-id="objective.workspaceId"
                  show-on-init
                />
              </FormFieldNext>
            </div>
            <AppCheckbox
              v-model="formModel.updateStatusForNestedElements"
              :disabled="disabledCheckNestedItems"
              data-testid="update-status-for-nested-elements"
              style="--label-color: var(--dark-2); --checkmark-margin: 4px"
            >
              {{ t('element.close_nested_description') }}
            </AppCheckbox>
          </div>
          <div class="com-ModalFooter">
            <ModalFooterActions hide-checkbox @close="closeModal()" @create="closeOkr">
              <template #confirm-button-text> {{ t('action.change_status') }}</template>
            </ModalFooterActions>
          </div>
        </div>
      </Modal>
    </div>
  </portal>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import CommentsApiHandler from '@/api/comments'
import ObjectivesInfoApiHandler from '@/api/okr-elements'
import { SAVING_INDICATOR_TYPES } from '@/utils/components-configurations/saving-indicator'
import { handleError } from '@/utils/error-handling'
import { isEscape } from '@/utils/key-codes'
import { isKR, OKR_STATUSES } from '@/utils/objectives'
import { COMMENT_FIELD_PLACEMENTS } from '@/utils/tiptap-utils'

import FormFieldNext from '@/components/form/FormFieldNext'
import OkrGradeValue from '@/components/form/OkrGradeValue'
import OkrIconAndId from '@/components/form/OkrIconAndId'
import DescriptionField from '@/components/objectives/forms/DescriptionField'
import ModalFooterActions from '@/components/objectives/forms/ModalFooterActions'
import TitleWithAction from '@/components/objectives/TitleWithAction'
import SavingIndicator from '@/components/SavingIndicator'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import Modal from '@/components/ui/Modal/Modal'

const props = defineProps({
  showModal: {
    type: Boolean,
    default: false
  },

  objective: {
    type: Object,
    default: () => ({})
  },

  tempStatus: {
    type: [String, Number],
    default: OKR_STATUSES.CLOSED
  }
})

defineOptions({
  name: 'CloseObjectiveModal'
})

const emit = defineEmits({
  'update:showModal': null,
  'update-status': null
})
const { t } = useI18n()

const closeModal = ({ updateStatus = props.objective.confidenceLevelId, reset = true } = {}) => {
  formModel.value.description = ''
  emit('update:showModal', false)
  emit('update-status', {
    updateStatus,
    updateStatusForNestedElements: formModel.value.updateStatusForNestedElements,
    reset
  })

  formModel.value.updateStatusForNestedElements = false
}

const disabledCheckNestedItems = ref(false)
watch(
  () => props.showModal,
  async val => {
    if (val) {
      const api = new ObjectivesInfoApiHandler()
      try {
        const { allChildHasPermission } = await api.checkNestedElementsPermission({
          elementId: props.objective.id,
          confidenceLevelId: props.tempStatus
        })
        disabledCheckNestedItems.value = !allChildHasPermission
      } catch (error) {
        handleError({ error })
      }
    }
  }
)

const savingIndicatorTop = ref(null)
const savingIndicator = ref(null)

onMounted(() => {
  document.body.addEventListener('keyup', onEscPressed)
})
onBeforeUnmount(() => {
  document.body.removeEventListener('keyup', onEscPressed)
})
const onEscPressed = event => {
  if (isEscape(event.keyCode) && props.showModal) {
    closeModal()
  }
}

const formModel = ref({
  description: '',
  updateStatusForNestedElements: false
})

const createComment = async () => {
  try {
    const text = formModel.value.description
    const commentsApi = new CommentsApiHandler()
    await commentsApi.createComment({
      objectiveId: props.objective.id,
      parentId: null,
      text
    })
  } catch (error) {
    handleError({ error })
  }
}
const closeOkr = () => {
  if (formModel.value.description) {
    createComment()
  }
  closeModal({ updateStatus: props.tempStatus, reset: false })
}

const CLOSE_STATUSES_TEXT = {
  [OKR_STATUSES.ABANDONED]: 'dashboard.abandoned',
  [OKR_STATUSES.CLOSED]: 'dashboard.closed'
}

const okrIsKR = computed(() => isKR(props.objective))

const getCloseStatusText = computed(() => {
  return t('element.set_new_status', {
    status: t(CLOSE_STATUSES_TEXT[props.tempStatus]),
    okr: okrIsKR.value ? t('element.type.key_result') : t('okr_elements.objective')
  })
})
</script>

<style lang="scss" scoped>
.com-ModalFooter {
  padding: 20px 40px;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    height: 1px;
    width: 100%;
    background-color: $grey-2-next;
    left: 0;
    top: 0;
    pointer-events: none;
  }
}

.com-BodyWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.com-Body {
  position: relative;
  padding-left: 40px;
}

.com-ModalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  --id-font-weight: 600;
}

.com-Button {
  color: $dark-2;
}

.com-HeaderWrapper {
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
}

.com-TitleWrapper {
  max-width: 600px;
  word-break: break-word;
}

.com-ObjectiveComment {
  max-width: 555px;
  margin: 19px 0 16px 0;
}
</style>
<style lang="scss">
.com-ModalWrapper {
  .o-modal {
    .o-modal-content {
      height: auto;
    }

    .o-modal-body {
      padding: 0;
    }
  }
}
</style>
