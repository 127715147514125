<template>
  <div class="ffn-FormField">
    <label
      v-if="label || $slots.label"
      :class="{ 'ffn-Label-disabled': disabled }"
      class="ffn-Label"
    >
      <slot name="label">
        {{ label }}
      </slot>
    </label>

    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FormFieldNext'
})
</script>

<script setup>
defineProps({
  label: {
    type: String,
    default: ''
  },

  disabled: {
    type: Boolean
  }
})
</script>

<style lang="scss" scoped>
.ffn-FormField {
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  gap: var(--gap, 6px);
  font-family: $system-ui;
  max-width: 100%;
}

.ffn-Label {
  font-weight: fw('bold');
  font-size: $fs-12;
  line-height: 16px;
  margin-bottom: var(--label-bottom-offset, 0px);

  &:not(&-disabled) {
    color: $dark-3;
  }

  &-disabled {
    color: $grey-1-next;
    cursor: not-allowed;
  }
}
</style>
