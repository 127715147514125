<template>
  <div
    :key="virtualUid || objective.uniqueId"
    :class="{
      'o-objective-rowgroup': true,
      'o-objective-rowgroup-expanded': expanded,
      'o-objective-rowgroup-unavailable': !userHasReadAccess,
      'o-objective-rowgroup-withdot': rowGroupDotNeed
    }"
    :style="{
      '--depth': depth
    }"
  >
    <div
      :data-objective-child-count="
        objective.childCount ? $t('objective.move_all_count_okrs', objective.childCount) : null
      "
      :style="rowContentOffsetLeft"
      class="otr-RowWrapper"
    >
      <!-- <div class="otr-Row_Border" /> -->
      <!-- !!!!!!!!!!!!!!!! -->
      <div
        :class="{
          hovered: isActionsShow || isNestedShow,
          [`o-objective-row-${objective.uniqueId}`]: true,
          'o-objective-row-no-nested-tasks':
            objective.typeId === FAKE_OBJECTIVE_TYPES.NO_NESTED_TASKS_FAKE_ROW
        }"
        class="o-objective-row"
        @mouseout="rowHovered = false"
        @mouseover="rowHovered = true"
      >
        <NoNestedTasksRow
          v-if="objective.typeId === FAKE_OBJECTIVE_TYPES.NO_NESTED_TASKS_FAKE_ROW"
          :style="{ ...getLeftOffset }"
        />

        <template v-else>
          <div v-if="showDragButton" class="otr-Drag">
            <i class="drag-trigger">
              <AppIcon class="otr-Drag_Icon" height="24" icon-name="drag-next" width="24" />
            </i>
          </div>

          <div v-if="listState.bulkActions.enabled" class="otr-BulkCheckbox">
            <AppCheckbox
              v-model="listState.bulkActions.selectedElements"
              v-tippy="{
                content: bulkCheckboxDisableState.tooltip,
                theme: `${DROP_LIST_THEMES.COMMON_TOOLTIP_THEMES} ${DROP_LIST_THEMES.TEXT_CENTER}`,
                placement: 'top',
                arrow: true,
                offset: [0, 8],
                maxWidth: 296
              }"
              :disabled="bulkCheckboxDisableState.disabled"
              :value="objective.id"
              data-testid="bulk-checkbox"
              name="otr-bulk-actions-checkbox"
            />
          </div>

          <div
            v-if="showFilterButton"
            class="otr-BtnShowFilteredChildren"
            data-testid="filter-button"
          >
            <AppButton
              v-tippy="{
                content: $t(`filter.${showFilteredChildren ? 'hide' : 'show'}_filtered_items`, {
                  count: filteredChildItemsCount
                })
              }"
              :icon="showFilteredChildren ? 'not-watch' : 'watch-line-grey'"
              height="24"
              size="sm"
              type="ghost-next"
              width="24"
              @click="onFilteredChildrenButtonClick(false)"
            >
              <span class="otr-FilteredChildItemsCount">{{ filteredChildItemsCount }}</span>
            </AppButton>
          </div>

          <div
            :style="{ ...getLeftOffset, '--width': `${tableState.columnWidth[COLUMNS.NAME]}px` }"
            class="o-objective-cell o-objective-cell-name"
            @click.self="expand"
          >
            <!--          <ContributeLine-->
            <!--            v-if="objective.contribute && depth !== 0"-->
            <!--            :class="{ 'otr-ContributeLine-short': showExpandButton }"-->
            <!--            class="otr-ContributeLine"-->
            <!--          />-->

            <AppButton
              v-if="showExpandTaskButton"
              :class="{
                'ab-Button-expand': true,
                'ab-Button-expand-filteredout': isFilteredOut
              }"
              :icon="expanded ? 'collapse-task' : 'expand-task'"
              :loading="isRowLoading"
              data-auto-testid="expand-task-button"
              data-testid="expand-task-button"
              hide-icon-on-loading
              loading-circle-color="var(--dark-2)"
              size="sm"
              type="subtle"
              @click="onExpandCollapseTask"
            />

            <AppButton
              v-else-if="showExpandButton"
              :class="{
                'ab-Button-expand': true,
                'ab-Button-expand-expanded': expanded,
                'ab-Button-expand-filteredout': isFilteredOut
              }"
              :loading="isRowLoading"
              data-auto-testid="expand-button"
              data-testid="expand-button"
              hide-icon-on-loading
              icon="okr-table-expand"
              loading-circle-color="var(--dark-2)"
              size="sm"
              type="subtle"
              @click="expand"
            />

            <portal
              v-if="showExpandButton && isRoadmapView"
              :to="`timeline-expand-${objective.id}`"
            >
              <AppButton
                :loading="isRowLoading"
                loading-circle-color="var(--dark-2)"
                size="sm"
                type="ghost"
                @click="expand"
              >
                <AppIcon
                  v-if="!isRowLoading"
                  class="ot-IconExpand"
                  height="24"
                  icon-name="timeline-expand"
                  width="24"
                />
              </AppButton>
            </portal>

            <OkrIcon :objective="objective" class="otr-OkrIcon" />
            <TitleItems
              :id-as-link="objective.isNestedTask ? false : userHasReadAccess"
              :objective="objective"
              :semi-transparent-id="isFilteredOut"
              id-size="small"
              with-tooltip
              @click-on-id="edit"
              @click-on-name="expand"
            >
              <InlineRename
                v-if="showOkrElementRename"
                :entity-name="objective.name"
                :is-loading="renameLoading"
                dropdown-width="438px"
                max-length="512"
                @update-name="onUpdateName"
              />
            </TitleItems>
          </div>
          <!-- Objectives and Key results cell end -->

          <!-- Columns that can be disabled -->
          <div
            :class="{
              'otr-RestColumns-filteredout': isFilteredOut
            }"
            class="otr-RestColumns"
          >
            <div
              v-for="column in availableColumns"
              :id="getColumnId(column)"
              :key="column"
              :class="getColumnClass(column)"
              @click.self="onColumnClick(column)"
            >
              <component
                :is="getCellComponent(column)"
                :column="column"
                :data-testid="getCellComponent(column)"
                :is-kr="isKR"
                :item="objective"
                :objective-type-id="objective.typeId"
                :readable="userHasReadAccess"
                :show-multiplier="depth > 0"
                :tracking-source="EVENT_SOURCES.TABLE"
                :updatable="getUpdatableStatus(column)"
                :workspace-id="objective.workspaceId"
                @loading="isRowLoading = $event"
                @update-dates="onDatesUpdate"
                @update-assignee="onAssigneeUpdate"
                @update-stakeholders="onAssigneeUpdate"
                @update-group="onGroupUpdate"
                @update-interval="onIntervalUpdate"
                @update-labels="onLabelsUpdate"
                @save-grade="onGradeUpdate"
                @update-privacy="onPrivacyUpdate"
                @update-comment="onCommentsUpdate"
                @update-contribute-status="onContributeStatusUpdate"
                @error-on-save-grade="onGradeErrorOnSave"
                @comments-scroll-to="commentsScrollTo"
                @update-custom-field-value="onUpdateCustomFieldValue"
              />
            </div>
          </div>
          <!-- Columns that can be disabled end-->
          <!--           ref="actionsCell" -->
          <ActionsCell
            v-if="!hideTableRowActions"
            :depth="depth"
            :is-actions-show="isActionsShow"
            :is-last="isLast"
            :is-nested-show="isNestedShow"
            :is-roadmap-view="isRoadmapView"
            :is-row-loading="isRowLoading"
            :loading="loading"
            :onboarding="onboarding"
            :options-menu-items="getResolvedObjectiveActionsMenuItems"
            :row-hovered="rowHovered"
            :user-can-copy="isUserCanCopy"
            :user-can-delete="isUserCanDelete"
            :user-can-update="isUserCanUpdate"
            :user-has-create-access="userHasCreateAccess"
            :user-has-read-access="userHasReadAccess"
            @options-menu-item-click="onActionsMenuItemClick"
            @update-show="onUpdateShow"
            @update-nested-show="isNestedShow = $event"
          />
        </template>
      </div>

      <div class="otr-Row_Border" />
    </div>

    <!-- Temporary disabled functionality -->
    <!-- <div
      v-if="
        false &&
        expanded &&
        childItems.length - filteredChildItemsCount === 0 &&
        !showFilteredChildren
      "
      :style="getFilteredPlaceholderStyles"
      class="otr-FilteredObjectivesHeader"
    >
      <span class="otr-FilteredObjectivesTitle">{{ $t('filter.all_are_filtered') }}</span>
      <AppButton type="link" size="sm" @click="showFilteredChildren = !showFilteredChildren">
        {{ $t('filter.show_them') }}
      </AppButton>
    </div> -->

    <!--        <ObjectiveTableRowList-->
    <!--          v-if="expanded"-->
    <!--          :key="`childs-for-${objective.id}`"-->
    <!--          ref="childrenList"-->
    <!--          v-model="childItems"-->
    <!--          v-memo="[]"-->
    <!--          :depth="depth + 1"-->
    <!--          :disable-save-filter-values="disableSaveFilterValues"-->
    <!--          :edit-external="editExternal"-->
    <!--          :hide-okr-element-rename="hideOkrElementRename"-->
    <!--          :hide-table-row-actions="hideTableRowActions"-->
    <!--          :table-row-grade-configurable="tableRowGradeConfigurable"-->
    <!--          :workspace-id="workspaceId"-->
    <!--          @updated="$emit('updated', $event)"-->
    <!--        />-->
  </div>
</template>

<script>
import { cloneDeep, isEmpty, isFunction, upperFirst } from 'lodash'
import { defineAsyncComponent, defineComponent } from 'vue'
import { HandleDirective } from 'vue-slicksort'
import { mapGetters, mapState } from 'vuex'

import ObjectivesApiHandler from '@/api/okr-elements'
import ObjectivesInfoApiHandler from '@/api/okr-elements'
import { tracker } from '@/tracking/amplitude'
import { EVENT_CATEGORIES, EVENT_SOURCES } from '@/tracking/amplitude-helpers'
import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { ALL_CUSTOM_FIELDS } from '@/utils/custom-fields/factory'
import { useCustomFieldsHelpers } from '@/utils/custom-fields/use-custom-fields'
import { handleError } from '@/utils/error-handling'
import {
  isJiraAppInjectionKey,
  isSalesforceAppInjectionKey,
  isWebAppInjectionKey,
  listStateInjectionKey,
  subAppStateInjectionKey,
  tableStateInjectionKey
} from '@/utils/injection-keys'
import {
  memoizeTableNameCellOffsetLeft,
  memoizeTableRowContentOffsetLeft
} from '@/utils/memoizations'
import { COLUMNS, getAvailableColumns } from '@/utils/objective-table'
import { FAKE_OBJECTIVE_TYPES, OBJECTIVE_TYPES } from '@/utils/objective-types'
import {
  saveUpdatedElementParameters,
  currentUserCanCopyObjective,
  currentUserCanCreateObjective,
  currentUserCanDeleteObjective,
  currentUserCanReadObjective,
  currentUserCanUpdateObjective,
  filteredObjectivesToDisplay,
  getObjectiveActionsMenuItems,
  getObjectiveAddMenuItems,
  getObjectiveLevelsForCreate,
  isKR,
  isShowFilterButton,
  objectiveIsJiraTask,
  OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS,
  OKR_ELEMENTS_TABLE_ADD_MENU_ITEMS,
  OKR_VIEW_PAGES,
  okrElementGradeIsEditable,
  okrElementIsExpandable,
  updateDependedElements,
  isOkrElementClosed
} from '@/utils/objectives'
import { FILTERS_KEYS } from '@/utils/okr-elements/filters'
import { MAX_SELECTED_ELEMENTS_FOR_BULK_ACTIONS } from '@/utils/okr-elements-table-bulk-actions'
import { checkIsTextSelectionProceed } from '@/utils/text-selection'
import { usePlansLimitations } from '@/utils/web-app/plans-limitations'
import { removeSelection } from '@/utils/window'
import IssuesApiHandler from '@jira/api/issues'
import { PLUGIN_OPTIONS_KEYS } from '@root/template-options-keys'

import AppDroplist from '@/components/AppDroplist'
import OkrElementsTableCustomFieldsHub from '@/components/custom-fields/okr-elements-table-cells/OkrElementsTableCustomFieldsHub'
import ContributeLine from '@/components/objectives/forms/ContributeLine'
import OkrIcon from '@/components/objectives/items/OkrIcon'
import TitleItems from '@/components/objectives/items/TitleItems'
import ActionsCell from '@/components/objectives/table/cells/ActionsCell'
import AssigneeCell from '@/components/objectives/table/cells/AssigneeCell'
import CommentsCell from '@/components/objectives/table/cells/CommentsCell'
import ContributeCell from '@/components/objectives/table/cells/ContributeCell'
import CurrentTargetCell from '@/components/objectives/table/cells/CurrentTargetCell'
import DuedateCell from '@/components/objectives/table/cells/DuedateCell'
import GradeCell from '@/components/objectives/table/cells/GradeCell'
import GroupsCell from '@/components/objectives/table/cells/GroupsCell'
import IntervalCell from '@/components/objectives/table/cells/IntervalCell'
import LabelsCell from '@/components/objectives/table/cells/LabelsCell'
import LastGradeUpdateCell from '@/components/objectives/table/cells/LastGradeUpdateCell'
import PrivacyCell from '@/components/objectives/table/cells/PrivacyCell'
import StakeholdersCell from '@/components/objectives/table/cells/StakeholdersCell'
import StartDateCell from '@/components/objectives/table/cells/StartDateCell'
import StatusCell from '@/components/objectives/table/cells/StatusCell'
import NoNestedTasksRow from '@/components/objectives/table/NoNestedTasksRow'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppSelect from '@/components/ui/AppSelect/AppSelect'
import DropdownMenu from '@/components/ui/DropdownMenu/DropdownMenu'
import InlineRename from '@/components/ui/InlineRename/InlineRename'

// const checkIsShowFilterButton = ({ tableState = {}, userHasReadAccess = true, objective = {} }) => {
//   return (
//     tableState.showFilterButtons &&
//     userHasReadAccess &&
//     objective?.childCount &&
//     objective?.visibleCount !== objective?.childCount
//   )
// }

export default defineComponent({
  name: 'ObjectiveTableRow',

  components: {
    NoNestedTasksRow,
    AppCheckbox,
    AppDroplist,
    InlineRename,
    OkrElementsTableCustomFieldsHub,
    ContributeLine,
    ActionsCell,
    AppButton,
    AppIcon,
    AssigneeCell,
    GradeCell,
    LabelsCell,
    GroupsCell,
    DuedateCell,
    StartDateCell,
    StatusCell,
    IntervalCell,
    CommentsCell,
    CurrentTargetCell,
    ContributeCell,
    TitleItems,
    DropdownMenu,
    OkrIcon,
    AppSelect,
    LastGradeUpdateCell,
    PrivacyCell,
    ObjectiveTableRowList: defineAsyncComponent(() => import('./ObjectiveTableRowList')),
    StakeholdersCell
  },

  directives: {
    handle: HandleDirective
  },

  inject: {
    listState: {
      from: listStateInjectionKey
    },

    tableState: {
      from: tableStateInjectionKey
    },

    subAppState: {
      from: subAppStateInjectionKey
    },

    isWebApp: {
      from: isWebAppInjectionKey
    },

    isSalesforceApp: {
      from: isSalesforceAppInjectionKey
    },

    isJiraApp: {
      from: isJiraAppInjectionKey
    }
  },

  props: {
    depth: {
      type: Number,
      default: 0
    },

    objective: {
      type: Object,
      required: true
    },

    isLast: {
      type: Boolean,
      required: true
    },

    draggable: {
      type: Boolean,
      default: true
    },

    hideTableRowActions: {
      type: Boolean
    },

    workspaceId: {
      type: [String, Number],
      required: true
    },

    tableRowGradeConfigurable: {
      type: Boolean,
      default: true
    },

    expanded: {
      type: Boolean
    },

    hideOkrElementRename: {
      type: Boolean
    },

    virtualUid: {
      type: String,
      default: ''
    }
  },

  emits: {
    updated: null,
    add: null,
    'edit-weights': null,
    duplicate: null,
    delete: null,
    edit: null,
    'update:expanded': null,
    move: null,
    'update-show-actions': null
  },

  setup() {
    const checkIsCustomFiled = fieldId => {
      const { isCustomField } = useCustomFieldsHelpers({
        fieldId
      })

      return isCustomField.value
    }

    const getCellComponent = column => {
      return checkIsCustomFiled(column)
        ? OkrElementsTableCustomFieldsHub
        : `${upperFirst(column)}Cell`
    }

    const getCustomFieldCellName = fieldId => {
      const { fieldParams } = useCustomFieldsHelpers({
        fieldId
      })

      const { typeId } = fieldParams.value

      return ALL_CUSTOM_FIELDS.getNames()[typeId]
    }

    const { okrWeightsLimitations } = usePlansLimitations()

    return {
      getCellComponent,
      checkIsCustomFiled,
      getCustomFieldCellName,
      okrWeightsLimitations
    }
  },

  data() {
    return {
      showFilteredChildren: false,
      isRowLoading: false,

      isChildLoading: false,
      isActionsShow: false,
      isNestedShow: false,

      rowHovered: false,

      renameLoading: false
    }
  },

  computed: {
    FAKE_OBJECTIVE_TYPES: () => FAKE_OBJECTIVE_TYPES,

    DROP_LIST_THEMES: () => DROP_LIST_THEMES,

    EVENT_SOURCES: () => EVENT_SOURCES,

    ...mapGetters('objectives', {
      getLevelItem: 'getLevelItem'
    }),

    ...mapGetters('system', {
      allowLinkOkrElementsToKr: 'allowLinkOkrElementsToKr'
    }),

    ...mapState('system', {
      userData: state => state.userData || {}
    }),

    hasAccessToJira() {
      if (this.subAppState) {
        return this.subAppState.hasAccessToJira || false
      }

      return this.userData.hasAccessToJira || false
    },

    isWeightsActionDisabled() {
      return this.okrWeightsLimitations.isDisabled({ okrElement: this.objective })
    },

    weightTriggerTooltip() {
      return this.okrWeightsLimitations.getTooltip({ okrElement: this.objective })
    },

    isJiraConnected() {
      if (!this.$store) {
        return false
      }

      return this.$store.state.pluginOptions[PLUGIN_OPTIONS_KEYS.JIRA_CONNECTED]
    },

    columnsIdsByWorkspaceId() {
      // check $store existing cause in jira gadget we haven't store and that might crash app
      if (!this.$store) {
        return []
      }

      return this.$store.getters['customFields/columnsIdsByWorkspaceId'](this.workspaceId)
    },

    COLUMNS: () => COLUMNS,

    isOkrElementClosed() {
      return isOkrElementClosed(this.objective)
    },

    availableColumns() {
      return getAvailableColumns({
        columnsList: this.tableState.columns,
        currentWorkspaceCustomFieldsIds: this.columnsIdsByWorkspaceId
      })
    },

    showOkrElementRename() {
      return (
        !this.hideOkrElementRename && this.isUserCanUpdate && !this.isTask && this.userHasReadAccess
      )
    },

    isFilteredOut() {
      return this.tableState.transparentFiltered && !this.objective.fitFilterCriteria
    },

    isRoadmapView() {
      return this.listState.view === OKR_VIEW_PAGES.ROADMAP
    },

    isOkrExplorerView() {
      return this.listState.view === OKR_VIEW_PAGES.OKREXPLORER
    },

    showDragButton() {
      const selectedIntervals = this.listState.filtersValues[FILTERS_KEYS.INTERVAL_IDS]
      return this.draggable && selectedIntervals.length === 1 && this.userHasUpdateAccess
    },

    objectiveLevels() {
      let levels = []
      // required this syntax for gadget and other jira instances
      if (this.$store) {
        levels = this.$store.getters['objectives/getLevelsByWorkspaceId'](
          this.objective.workspaceId
        )
      }
      return levels
    },

    objectiveLevelsForCreate() {
      return getObjectiveLevelsForCreate(this.objectiveLevels)
    },

    childItems: {
      get() {
        const allChildren = this.listState.okrElementChildren[this.objective.uniqueId] || []
        return !this.showFilterButton || this.showFilteredChildren
          ? allChildren
          : filteredObjectivesToDisplay(allChildren, this.listState)
      },

      set(value) {
        this.listState.okrElementChildren[this.objective.uniqueId] = value
      }
    },

    rowContentOffsetLeft() {
      return memoizeTableRowContentOffsetLeft(this.depth, this.tableState.offsetLeft)
    },

    getLeftOffset() {
      return memoizeTableNameCellOffsetLeft(this.depth)
    },

    // getFilteredPlaceholderStyles() {
    //   const margin = (this.depth + 1) * 24;
    //   return {
    //     'margin-left': `${margin}px`,
    //   };
    // },

    isTask() {
      return objectiveIsJiraTask(this.objective)
    },

    isKR() {
      return isKR(this.objective)
    },

    isUserCanUpdate() {
      return currentUserCanUpdateObjective(this.objective) && !this.isOkrElementClosed
    },

    isUserCanDelete() {
      return currentUserCanDeleteObjective(this.objective)
    },

    isUserCanCopy() {
      return currentUserCanCopyObjective(this.objective)
    },

    isRowCanExpand() {
      return this.objective.childCount > 0
    },

    userHasReadAccess() {
      return currentUserCanReadObjective(this.objective)
    },

    userHasUpdateAccess() {
      return currentUserCanUpdateObjective(this.objective)
    },

    userHasCreateAccess() {
      if (!this.$store) {
        return false
      }
      const { isPluginAdmin } = this.$store.state.pluginOptions
      return currentUserCanCreateObjective(
        this.$store.getters['workspaces/selectedWorkspace'],
        isPluginAdmin
      )
    },

    isGradeEditable() {
      return this.tableRowGradeConfigurable
        ? (this.isKR || this.isTask) && okrElementGradeIsEditable(this.objective)
        : false
    },

    onboarding() {
      return this.$store?.state.pluginOptions.onboarding || false
    },

    filteredChildItemsCount() {
      return this.objective.childCount - this.objective.visibleCount
    },

    showExpandButton() {
      return okrElementIsExpandable({
        element: this.objective,
        filterButtonEnabled: this.tableState.showFilterButtons,
        filteredChildrenVisible: this.showFilteredChildren
      })
    },

    showExpandTaskButton() {
      const baseCondition =
        this.isTask &&
        this.listState.view === OKR_VIEW_PAGES.ALIGNMENT &&
        this.hasAccessToJira &&
        this.userHasReadAccess

      if (this.objective.isNestedTask) {
        return baseCondition && this.objective.childCount > 0
      }

      return baseCondition
    },

    showFilterButton() {
      return isShowFilterButton({
        element: this.objective,
        tableState: this.tableState
      })
    },

    // resolvedShowFilterButton() {
    //   if (this.depth) {
    //     return this.objective.visibleCount ? this.showFilterButton : false
    //   } else {
    //     return this.showFilterButton
    //   }
    // },

    // addMenuItems() {
    //   return getObjectiveAddMenuItems(this.objective.typeId, this.objectiveLevelsForCreate).map(
    //     action => {
    //       const { iconNext, name, ...rest } = action
    //       return {
    //         ...rest,
    //         name: this.$t(name),
    //         icon: iconNext
    //       }
    //     }
    //   )
    // },

    rowGroupDotNeed() {
      if (!this.expanded || !this.childItems) {
        return false
      }

      if (this.childItems.length > 0) {
        const lastChild = this.listState.okrElements[this.childItems[this.childItems.length - 1]]
        if (!lastChild) {
          // children might be not loaded yet
          return false
        }
        const lastChildExpanded =
          this.listState.filtersValues[FILTERS_KEYS.EXPANDED_ITEMS][
            `${lastChild.id}-${this.depth + 1}`
          ]
        return lastChildExpanded ? true : !lastChild.contribute
      } else {
        return false
      }
    },

    // contributeLineIcon() {
    //   const icons = {
    //     default: 'contribute-line',
    //     withDot: 'contribute-line-dot'
    //   }
    //   return this.isLast && !this.expanded && this.childItems.length === 0
    //     ? icons.withDot
    //     : icons.default
    // },

    loading() {
      return this.isRowLoading || this.isChildLoading
    },

    getResolvedObjectiveActionsMenuItems() {
      const actions = getObjectiveActionsMenuItems(
        this.objective,
        this.isWeightsActionDisabled,
        this.weightTriggerTooltip
      )

      const addItems = getObjectiveAddMenuItems({
        objective: this.objective,
        objectiveLevels: this.objectiveLevelsForCreate,
        allowLinkOkrElementsToKr: this.allowLinkOkrElementsToKr,
        isJiraApp: this.isJiraApp,
        isWebApp: this.isWebApp,
        isSalesforceApp: this.isSalesforceApp,
        isJiraConnected: this.isJiraConnected,
        hasAccessToJira: this.hasAccessToJira
      })

      return [...addItems, ...actions]
    },

    bulkCheckboxDisableState() {
      const hasUpdateAccess = currentUserCanUpdateObjective(this.objective)

      const state = {
        disabled: false,
        tooltip: null
      }

      if (this.objective.isNestedTask) {
        state.disabled = true
        state.tooltip = this.$t('bulk_actions.disabled_for_nested_issue')
        return state
      }

      if (!hasUpdateAccess) {
        state.disabled = true
        state.tooltip = this.$t('bulk_actions.disabled_by_permissions')
        return state
      }

      if (this.isOkrElementClosed) {
        state.disabled = true
        state.tooltip = this.$t('bulk_actions.disabled_by_status', {
          closedStatus: this.$t('dashboard.closed'),
          abandonedStatus: this.$t('dashboard.abandoned')
        })
        return state
      }

      const isLimitExceeded =
        this.listState.bulkActions.selectedElements.length >= MAX_SELECTED_ELEMENTS_FOR_BULK_ACTIONS

      if (isLimitExceeded) {
        state.disabled = !this.listState.bulkActions.selectedElements.includes(this.objective.id)
        state.tooltip = state.disabled
          ? this.$t('bulk_actions.disabled_by_count', MAX_SELECTED_ELEMENTS_FOR_BULK_ACTIONS)
          : null

        return state
      }

      return state
    }
  },

  watch: {
    // isNestedShow(newValue, oldValue) {
    //   if (newValue !== oldValue) {
    //     if (newValue) {
    //       this.$refs.actionsCell?.showDropdown()
    //     } else {
    //       this.$refs.actionsCell?.hideDropdown()
    //     }
    //   }
    // },

    'listState.dataLoaded': function (newValue) {
      if (newValue) {
        this.onObjectiveTableLoaded()
      }
    }
  },

  mounted() {
    if (
      this.listState.showFilteredChildrenObjectives[this.objective.uniqueId] &&
      !this.isOkrExplorerView
    ) {
      this.onFilteredChildrenButtonClick(true)
    }
  },

  methods: {
    onUpdateShow($event) {
      this.isActionsShow = $event
      this.$emit('update-show-actions', $event)
    },

    getUpdatableStatus(column) {
      const defaultCondition = this.isUserCanUpdate

      if (column === COLUMNS.GRADE) {
        return defaultCondition && this.isGradeEditable
      }

      if ((column === COLUMNS.DUE_DATE || column === COLUMNS.START_DATE) && this.isTask) {
        return defaultCondition && this.hasAccessToJira
      }

      return defaultCondition
    },

    getColumnClass(column) {
      const isForCustomField = this.checkIsCustomFiled(column)
      let cellClass = `otr-${upperFirst(column)}`
      if (isForCustomField) {
        cellClass = `otr-Custom${this.getCustomFieldCellName(column)}`
      }
      const classes = {
        'o-objective-cell': true,
        [`o-objective-cell-${column}`]: !isForCustomField,
        [cellClass]: true
      }

      // if (column !== COLUMNS.GRADE) {
      //   classes['o-objective-cell-column-disalable'] = true
      // }

      if (column === COLUMNS.GRADE) {
        classes['otr-Grade-updatable'] = this.isGradeEditable
      }

      return classes
    },

    getColumnId(column) {
      if (
        column === COLUMNS.GRADE &&
        this.onboarding &&
        this.depth > 0 &&
        this.isKR &&
        this.isGradeEditable &&
        this.isLast
      ) {
        return 'v-step-2'
      }
    },

    onObjectiveTableLoaded() {
      this.isRowLoading = false
    },

    onActionsMenuItemClick(name) {
      this.clearBulkActions()
      const { REMOVE, EDIT, COPY, WEIGHTS, MOVE } = OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS
      if (name === REMOVE.name) {
        this.$emit(REMOVE.action)
      } else if (name === EDIT.name) {
        this.$emit(EDIT.action)
      } else if (name === COPY.name) {
        this.$emit(COPY.action)
      } else if (name === WEIGHTS.name) {
        this.$emit(WEIGHTS.action)
      } else if (name === MOVE.name) {
        this.$emit(MOVE.action)
      }

      const { CREATE_OBJECTIVE, CREATE_KR, LINK_TASK, CREATE_JIRA_ISSUE } =
        OKR_ELEMENTS_TABLE_ADD_MENU_ITEMS
      const payload = {}
      let typeId = null
      if (name === CREATE_KR.name) {
        typeId = OBJECTIVE_TYPES.KR
      } else if (name === LINK_TASK.name) {
        typeId = OBJECTIVE_TYPES.TASK
        payload.action = LINK_TASK.action
      } else if (name === CREATE_OBJECTIVE.name) {
        typeId = OBJECTIVE_TYPES.PERSONAL
        payload.action = CREATE_OBJECTIVE.action
      } else if (name === CREATE_JIRA_ISSUE.name) {
        typeId = OBJECTIVE_TYPES.TASK
        payload.action = CREATE_JIRA_ISSUE.action
      }

      if (typeId) {
        payload.levelId = this.getLevelItem('typeId', typeId).id
        payload.typeId = typeId
      } else {
        payload.levelId = name
        payload.typeId = this.getLevelItem('id', name).typeId
        payload.intervalId = this.objective.intervalId
      }

      this.$emit('add', payload)
    },

    /*

    onMenuActionsClick(name) {
      const { REMOVE, EDIT, COPY, WEIGHTS, MOVE } = OKR_ELEMENTS_TABLE_ACTIONS_MENU_ITEMS
      if (name === REMOVE.name) {
        this.$emit(REMOVE.action)
      } else if (name === EDIT.name) {
        this.edit()
      } else if (name === COPY.name) {
        this.$emit(COPY.action)
      } else if (name === WEIGHTS.name) {
        this.$emit(WEIGHTS.action)
      } else if (name === MOVE.name) {
        this.$emit(MOVE.action)
      }
    },

    onActionMenuItemsClick(name) {
      const { CREATE_OBJECTIVE, CREATE_KR, LINK_TASK, CREATE_JIRA_ISSUE } =
        OKR_ELEMENTS_TABLE_ADD_MENU_ITEMS
      const payload = {}
      let typeId = null
      if (name === CREATE_KR.name) {
        typeId = OBJECTIVE_TYPES.KR
      } else if (name === LINK_TASK.name) {
        typeId = OBJECTIVE_TYPES.TASK
        payload.action = LINK_TASK.action
      } else if (name === CREATE_OBJECTIVE.name) {
        typeId = OBJECTIVE_TYPES.PERSONAL
        payload.action = CREATE_OBJECTIVE.action
      } else if (name === CREATE_JIRA_ISSUE.name) {
        typeId = OBJECTIVE_TYPES.TASK
        payload.action = CREATE_JIRA_ISSUE.action
      }

      if (typeId) {
        payload.levelId = this.$store.state.objectives.levels.find(
          item => item.typeId === typeId
        )?.id
        payload.typeId = typeId
      } else {
        payload.levelId = name
        payload.typeId = this.$store.state.objectives.levels.find(item => item.id === name)?.typeId
        payload.intervalId = this.objective.intervalId
      }

      this.$emit('add', payload)
    },
     */

    onColumnClick(column) {
      if (column === COLUMNS.GRADE) {
        this.expand()
      }
    },

    async onExpandCollapseTask() {
      removeSelection()

      if (!this.showExpandTaskButton) {
        return
      }

      if (!this.userHasReadAccess) {
        return
      }

      const issuesApi = new IssuesApiHandler()

      const newExpanded = !this.expanded

      if (newExpanded) {
        try {
          this.isRowLoading = true

          const items = await issuesApi.getTaskNestedElements({
            elementId: this.objective.id,
            workspaceId: this.workspaceId,
            parentJiraIssueKey: this.objective.jiraIssueId
          })

          this.listState.nestedTasks = {
            ...this.listState.nestedTasks,
            ...items.reduce((acc, item) => {
              return {
                ...acc,
                [item.id]: item
              }
            }, {})
          }

          this.listState.tasksChildren[this.objective.uniqueId] = items.map(({ id }) => id)

          this.$emit('update:expanded', newExpanded)
        } catch (error) {
          handleError({ error })
        } finally {
          this.isRowLoading = false
        }
      } else {
        this.$emit('update:expanded', newExpanded)
        await this.$nextTick()
        this.collapseTaskRecursively({
          taskId: this.objective.uniqueId
        })
      }
    },

    collapseTaskRecursively({ taskId }) {
      delete this.listState.expandedTasks[taskId]

      const taskChildren = this.listState.tasksChildren[taskId]

      if (!isEmpty(taskChildren)) {
        this.listState.tasksChildren[taskId].forEach(id => {
          this.collapseTaskRecursively({ taskId: id })
          delete this.listState.nestedTasks[id]
        })
      }

      delete this.listState.tasksChildren[taskId]
    },

    async expand() {
      if (this.isTask) {
        this.onExpandCollapseTask()
        return
      }
      if (!this.isRowCanExpand || this.isChildLoading || checkIsTextSelectionProceed()) {
        return
      }

      const objectivesApi = new ObjectivesApiHandler()

      const newExpanded = !this.expanded
      this.$emit('update:expanded', newExpanded)

      if (newExpanded) {
        const { id } = this.objective

        const payload = {
          parentId: id,
          workspaceId: this.workspaceId,
          ...this.listState.requestParameters,
          ownerIds: this.listState.requestParameters.ownerIds,
          groupIds: this.listState.requestParameters.groupIds,
          // all children should use `childOrder` for both order and childOrder
          order: this.listState.requestParameters.childOrder,
          offset: 0,
          limit: 1000
        }

        this.isRowLoading = true
        try {
          const { items } = await objectivesApi.getChildObjectives(payload)

          // save children of current element
          this.listState.okrElementChildren[this.objective.uniqueId] = items.map(
            childElement => childElement.uniqueId
          )
          await this.saveOkrElementsAndChildren(items)

          // ↓ show filtered children on expand only in case when all element children are hidden by filter
          if (this.tableState.showFilterButtons && !this.objective.visibleCount) {
            this.showFilteredChildren = true
            this.listState.showFilteredChildrenObjectives[this.objective.uniqueId] = true
          }
        } catch (error) {
          handleError({ error })
        }

        this.isRowLoading = false
      }
    },

    saveOkrElementsAndChildren(elements) {
      elements.forEach(element => {
        const zeroLength = 0
        const clone = cloneDeep(element)

        if (this.listState.okrElements[element.uniqueId]) {
          // remove uid from element, for prevent rerender list item
          delete clone.uid
          this.listState.okrElements[element.uniqueId] = {
            uid: this.listState.okrElements[element.uniqueId].uid,
            ...clone
          }
        } else {
          // если элемент ен пришел изначально в childElements (на странцие okr-explorer) то его нужно добавить
          this.listState.okrElements[element.uniqueId] = clone
        }

        if (
          (element.childCount === zeroLength && element.childItems === zeroLength) ||
          (element.childCount && element.childItems)
        ) {
          this.listState.okrElementChildren[element.uniqueId] = element.childElements.map(
            childElement => childElement.uniqueId
          )
        }

        if (element.childElements.length) {
          this.saveOkrElementsAndChildren(element.childElements)
        }
      })
    },

    onGradeUpdate({ element, updatedElementParameters }) {
      this.clearBulkActions()
      const {
        currentValue,
        confidenceLevelId,
        automaticConfidenceLevelId,
        predictedGrade,
        gradeMode,
        gradeToUse,
        grade
      } = element

      // nextTick fixing bug with grade value
      this.$nextTick(() => {
        this.listState.okrElements[element.uniqueId] = {
          ...this.listState.okrElements[element.uniqueId],
          currentValue,
          confidenceLevelId,
          automaticConfidenceLevelId,
          predictedGrade,
          gradeMode,
          gradeToUse,
          grade
        }
      })
      saveUpdatedElementParameters(this.listState, updatedElementParameters)
    },

    onCommentsUpdate({ createdCommentId }) {
      this.listState.okrElements[this.objective.uniqueId] = {
        ...this.listState.okrElements[this.objective.uniqueId],
        lastCommentId: createdCommentId
      }
    },

    onAssigneeUpdate({ element, updatedElementParameters }) {
      this.clearBulkActions()
      const { users, permissions } = element
      this.listState.okrElements[this.objective.uniqueId] = {
        ...this.listState.okrElements[this.objective.uniqueId],
        users,
        permissions
      }
      saveUpdatedElementParameters(this.listState, updatedElementParameters)
    },

    onLabelsUpdate({ element, updatedElementParameters }) {
      this.clearBulkActions()
      const { labels } = element
      this.listState.okrElements[this.objective.uniqueId] = {
        ...this.listState.okrElements[this.objective.uniqueId],
        labels
      }
      saveUpdatedElementParameters(this.listState, updatedElementParameters)
    },

    onGroupUpdate({ element, updatedElementParameters }) {
      this.clearBulkActions()
      const { groups, permissions } = element
      this.listState.okrElements[this.objective.uniqueId] = {
        ...this.listState.okrElements[this.objective.uniqueId],
        groups,
        permissions
      }
      saveUpdatedElementParameters(this.listState, updatedElementParameters)
    },

    onUpdateCustomFieldValue({ fieldId, value }) {
      this.clearBulkActions()
      this.listState.okrElements[this.objective.uniqueId] = {
        ...this.listState.okrElements[this.objective.uniqueId],
        customFields: {
          ...this.listState.okrElements[this.objective.uniqueId].customFields,
          [fieldId]: value
        }
      }
    },

    onPrivacyUpdate({ element, updatedElementParameters }) {
      this.clearBulkActions()
      const { permissions } = element
      this.listState.okrElements[this.objective.uniqueId] = {
        ...this.listState.okrElements[this.objective.uniqueId],
        private: element.private,
        permissions
      }
      saveUpdatedElementParameters(this.listState, updatedElementParameters)
    },

    async onUpdateName({ newName, callback }) {
      this.renameLoading = true

      try {
        const api = new ObjectivesInfoApiHandler()
        const {
          contribute,
          dueDate,
          elementStartDate,
          jiraIssueId,
          intervalId,
          orderValue,
          parentId,
          levelId,
          workspaceId,
          confidenceLevelId,
          originalValue,
          currentValue,
          targetValue,
          fieldTypeId,
          grade,
          labelIds
        } = this.objective

        const result = await api.updateOkrElement({
          contribute,
          dueDate,
          elementStartDate,
          jiraIssueId,
          intervalId,
          name: newName,
          orderValue,
          parentId,
          levelId,
          workspaceId,
          confidenceLevelId,
          originalValue,
          currentValue,
          targetValue,
          fieldTypeId,
          grade,
          elementId: this.objective.id,
          labelIds
        })

        this.renameLoading = false

        this.onNameUpdated({ ...this.objective, ...result })

        await this.$nextTick()

        if (callback && isFunction(callback)) {
          callback()
        }
      } catch (error) {
        handleError({ error })
      } finally {
        this.renameLoading = false
      }
    },

    onNameUpdated({ element, updatedElementParameters }) {
      const { name } = element
      this.listState.okrElements[this.objective.uniqueId] = {
        ...this.listState.okrElements[this.objective.uniqueId],
        name
      }
      saveUpdatedElementParameters(this.listState, updatedElementParameters)
    },

    onContributeStatusUpdate({ element, updatedElementParameters }) {
      this.clearBulkActions()
      const { contribute, weight, weightMultiplier } = element
      this.listState.okrElements[this.objective.uniqueId] = {
        ...this.listState.okrElements[this.objective.uniqueId],
        contribute,
        weight,
        weightMultiplier
      }

      saveUpdatedElementParameters(this.listState, updatedElementParameters)
    },

    onDatesUpdate({ element, updatedElementParameters, changedElementDates }) {
      this.clearBulkActions()
      const {
        dueDate,
        elementStartDate,
        startDateManual,
        dueDateManual,
        grade,
        gradeColor,
        automaticConfidenceLevelId,
        confidenceLevelId
      } = element
      this.listState.okrElements[this.objective.uniqueId] = {
        ...this.listState.okrElements[this.objective.uniqueId],
        dueDate,
        grade,
        gradeColor,
        automaticConfidenceLevelId,
        confidenceLevelId,
        elementStartDate,
        startDateManual,
        dueDateManual
      }

      updateDependedElements(this.listState, changedElementDates)

      saveUpdatedElementParameters(this.listState, updatedElementParameters)
    },

    onIntervalUpdate() {
      this.clearBulkActions()
      this.$emit('updated')
    },

    commentsScrollTo() {
      this.clearBulkActions()
      this.$emit('edit', { scrollToComments: true })
    },

    clearBulkActions() {
      this.listState.bulkActions.clearSelectedElements()
    },

    edit() {
      this.clearBulkActions()
      this.$emit('edit')
    },

    // returns array of expanded children(their ids as in listState.filtersValues[FILTERS_KEYS.EXPANDED_ITEMS])
    // including current row
    /** @public */
    getExpandedChildren() {
      let result = []
      if (this.expanded) {
        result.push(`${this.objective.id}-${this.depth}`)
      }
      if (this.$refs.childrenList) {
        result = result.concat(this.$refs.childrenList.getExpandedChildren())
      }

      return result
    },

    onFilteredChildrenButtonClick(isManual = false) {
      const wasHiddenAndShow = !this.showFilteredChildren && !this.expanded
      const wasVisibleAndHide = this.showFilteredChildren && this.expanded
      const allAreFiltered = this.childItems.length - this.filteredChildItemsCount === 0
      if (wasHiddenAndShow || (wasVisibleAndHide && allAreFiltered)) {
        this.expand()
      }
      this.showFilteredChildren = isManual ? true : !this.showFilteredChildren
      if (!isManual) {
        this.listState.showFilteredChildrenObjectives[this.objective.uniqueId] =
          this.showFilteredChildren
        tracker.logEvent(
          this.showFilteredChildren ? 'Show filtered items' : 'Hide filtered items',
          {
            category: EVENT_CATEGORIES.ALIGNMENT_NAVIGATION,
            filterState: allAreFiltered ? 'hidden all' : 'hidden partially'
          }
        )
      }
    },

    onGradeErrorOnSave(error) {
      handleError({ error })
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

$controls-button-width: var(--okr-table-row-controls-button-width, 0px);
$gap: var(--okr-table-row-gap, 8px);
$expand-collapse-button-with-gap-width: calc(#{$controls-button-width} + #{$gap});

.o-objective-rowgroup {
  position: relative;
  display: flex;
  flex-direction: column;
  // margin-top: -1px;
}

.o-objective-row {
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: $white;
  z-index: 3;

  // padding: 0 $page-right-padding 0 $page-left-padding;
  // padding: 0 0 0 calc(#{$controls-button-width} + #{$page-left-padding});
  padding: 0 0 0
    calc(
      #{$controls-button-width} + #{$page-left-padding} +
        var(--bulk-actions-width, var(--okr-table-row-controls-button-width))
    );

  .o-objective-table-scrollable & {
    // padding: 0 0 0 $page-left-padding;
    // padding: 0 0 0 calc(#{$controls-button-width} + #{$page-left-padding});
    padding: 0 0 0
      calc(
        #{$controls-button-width} + #{$page-left-padding} +
          var(--bulk-actions-width, var(--okr-table-row-controls-button-width))
      );
  }

  &:not(&-no-nested-tasks) {
    &:hover,
    &.hovered {
      background-color: $grey-3-next;
      z-index: 4;

      .otr-ContributeLine {
        opacity: 0;
      }

      .otr-Drag {
        opacity: 1;
      }
    }
  }

  &-no-nested-tasks {
    @include tableLinesGradient(
      transparent,
      $grey-3-next,
      var(--page-left-padding),
      var(--page-right-padding)
    );
  }

  &.animation {
    // should be larger as next row z-index and table header
    // z-index: 6;

    transition: background-color $transition-normal cubic-bezier(0.1, 0.7, 1, 0.1);

    animation: shine 1500ms 1, growth 300ms 1;

    @keyframes growth {
      0% {
        height: 0;
      }
      100% {
        height: 44px;
      }
    }

    @keyframes shine {
      0% {
        background-color: $white;
      }
      50% {
        background-color: rgba($grade-high-color-next, 0.15);
      }
      100% {
        background-color: $white;
      }
    }

    /*
    &::before {
      top: 0;
      height: 1px;
      width: 100%;
      animation: pulse-top 1500ms 1;
      content: '';
      position: absolute;
      z-index: auto;
      left: 0;
      right: 0;
      background-color: transparent;

      @keyframes pulse-top {
        0% {
          box-shadow: 0 0 0 0 $blue-3;
        }
        70% {
          box-shadow: 0 -4px 0 4px rgba($blue-3, 0);
        }
        100% {
          box-shadow: 0 0 0 0 rgba($blue-3, 0);
        }
      }
    }

    &::after {
      animation: pulse-bottom 1500ms 1;
      bottom: 0;
      height: 1px;
      width: 100%;
      content: '';
      position: absolute;
      z-index: auto;
      background-color: transparent;
      left: 0;
      right: 0;

      @keyframes pulse-bottom {
        0% {
          box-shadow: 0 0 0 0 $blue-3;
        }
        70% {
          box-shadow: 0 4px 0 4px rgba($blue-3, 0);
        }
        100% {
          box-shadow: 0 0 0 0 rgba($blue-3, 0);
        }
      }
    }

    */
  }
}

.otr-RowWrapper[data-objective-child-count] {
  .sortable-chosen & {
    &:after {
      border-radius: 0 0 $border-radius-md-next $border-radius-md-next;
      color: $dark-3;
      background: $grey-2-next;
      font-size: $fs-12;
      font-weight: fw('bold');
      line-height: 16px;
      position: absolute;
      left: var(--content-offset-left);
      top: calc(100% + 1px);
      padding: 4px 8px;
      z-index: 4;
      display: block;
      content: attr(data-objective-child-count);
    }
  }
}
.otr-Row_Border {
  height: 1px;

  /*  @include tableLinesGradient(
    transparent,
    $grey-2-next,
    var(--content-offset-left),
    var(--page-right-padding)
  );*/

  @include tableLinesGradient(
    transparent,
    $grey-2-next,
    var(--page-left-padding),
    var(--page-right-padding)
  );

  .rm-OkrPage_Table & {
    // @include tableLinesGradient(transparent, $grey-2-next, var(--content-offset-left));
    @include tableLinesGradient(transparent, $grey-2-next, var(--page-left-padding));
  }

  // .otr-RowWrapper:hover & {
  //   margin: 0 !important;
  // }
}

.otr-Drag {
  cursor: grab;
  position: absolute;
  width: 24px;
  height: 100%;
  top: 0;
  left: 4px;
  display: flex;
  align-items: center;
  z-index: 10;
  opacity: 0;
  transition: opacity $transition-fast ease;
}
.o-objective-cell {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0 10px 12px;
  flex-shrink: 0;

  &-name {
    font-family: $system-ui;
    display: flex;
    align-items: center;
    padding-right: 4px;
    min-width: 0;
    user-select: text;

    // gap: 8px;

    width: calc(
      var(--width) - var(--margin-left) - #{$controls-button-width} - var(--bulk-actions-width, var(--okr-table-row-controls-button-width))
    );
    margin-left: var(--margin-left);

    .o-task-edit-link {
      margin-left: 5px;
      font-weight: fw('regular');
      white-space: nowrap;
    }

    :deep(.oi-ObjectiveId) {
      font-weight: fw('semi-bold');
    }
  }

  &-column-disalable {
    // padding-left: 12px;
    padding-left: 6px;
  }

  &-editable {
    cursor: pointer;
  }

  &-grade {
    justify-content: flex-end;
    padding-left: 12px;
    &:deep(.cv-CellValue) {
      width: 100%;
    }
  }
}

// .o-objective-cell-content {
//   // width: 100%;
//
//   cursor: pointer;
//   //
//   // width: auto;
//   // max-width: 100%;
//
//   &-noeditable {
//     cursor: default;
//   }
// }

.ab-Button-expand {
  position: relative;
  color: $dark-2;
  margin-left: calc((#{$gap} + #{$controls-button-width}) * -1);
  margin-right: $gap;
  min-width: $controls-button-width;
  &-expanded {
    transform: rotateZ(90deg);
  }

  &-filteredout {
    opacity: 0.5;
  }
  // margin-left: -24px;
  // width: 24px;

  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   width: 0;
  //   height: 0;
  //   background-color: black;
  //   opacity: 0;
  //   border-radius: 50%;
  //   z-index: 1;
  //   transition: all $transition-fast ease;
  // }
  // i {
  //   position: relative;
  //   z-index: 10;
  //   transition: transform $transition-fast ease;
  //   border-radius: 50%;
  //
  //   &.o-icon-arrow {
  //     background-image: url(~@/assets/images/arrow.svg);
  //     background-size: 7px;
  //     background-position: center;
  //     background-repeat: no-repeat;
  //     width: 24px;
  //     height: 24px;
  //
  //     .o-objective-rowgroup-unavailable & {
  //       background-image: url(~@/assets/images/arrow-disabled.svg);
  //     }
  //   }
  // }

  // &.o-expanded {
  //   i {
  //     transform: rotateZ(90deg);
  //   }
  // }
}

.o-objective-rowgroup-expanded {
  position: relative;

  //&::before {
  //  content: '';
  //  position: absolute;
  //  width: 1px; // 2px;
  //  height: calc(100% - 62px);
  //  top: 34px;
  //  left: calc(
  //    var(--page-left-padding) + (#{$expand-collapse-button-with-gap-width} * var(--depth)) + #{$controls-button-width} +
  //      (#{$controls-button-width} / 2)
  //  );
  //  background-color: $grey-2-next;
  //  z-index: 4;
  //}
  &.o-objective-rowgroup-withdot {
    &:before {
      height: calc(100% - 57px);
    }
    // &::after {
    //   content: '';
    //   position: absolute;
    //   width: 3px;
    //   height: 3px;
    //   border-radius: 50%;
    //   bottom: 23px;
    //   left: calc(24px * var(--depth) + var(--page-left-padding) + 10px + 24px);
    //   background-color: black;
    //   z-index: 3;
    // }
  }

  // &.depth-0 {
  //   & > .o-objective-row {
  //     & > .o-objective-cell {
  //       & > .ab-Button-expand {
  //         &.o-expanded {
  //           &::before {
  //             top: 2px;
  //             left: 2px;
  //             width: 20px;
  //             height: 20px;
  //             opacity: 0.15;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}

.o-name-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.otr-BulkCheckbox {
  width: var(--okr-table-row-controls-button-width);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: var(--page-left-padding);
  height: 100%;
}

.otr-BtnShowFilteredChildren {
  position: absolute;
  // width: 24px;
  height: 100%;
  top: 0;
  left: calc(
    var(--page-left-padding) + var(--bulk-actions-width, var(--okr-table-row-controls-button-width))
  );
  display: flex;
  align-items: center;
  z-index: 10;
  // opacity: 0;
  // margin: 0 4px;
  // transition: opacity $transition-fast;

  .o-objective-row:hover & {
    opacity: 1;
  }

  .ab-Button-sm {
    position: relative;
  }
}

.otr-FilteredChildItemsCount {
  position: absolute;
  min-width: 14px;
  height: 14px;
  top: -8px;
  right: -4px;
  font-size: $fs-8;
  text-align: center;

  /* Gray 2 line */

  background: $grey-2-next;
  border-radius: 12px;
  padding: 2px 4px;
  // color: $grey-7;
  // font-size: $fs-16;
  // font-weight: fw('regular');
}

.otr-FilteredObjectivesHeader {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 50px;
}

.otr-FilteredObjectivesTitle {
  padding-right: 16px;
  line-height: 24px;
  color: $grey-7;
}

.otr-RestColumns {
  display: grid;
  grid-template-columns: var(--rest-columns-template);

  &-filteredout {
    opacity: 0.5;
  }
}

.otr-Grade {
  cursor: default;

  &-updatable {
    cursor: pointer;
  }
}

.otr-Assignee,
.otr-Interval,
.otr-StartDate,
.otr-Duedate,
.otr-CustomSingleSelect,
.otr-CustomDate,
.otr-CustomMoney,
.otr-CustomNumber {
  padding-left: 6px;
}

.otr-ContributeLine {
  // --height: calc(#{$item-height} / 2);
  // top: $item-height;
  // left: 12px; // okrIcon half width

  position: absolute;
  left: -20px;
  top: 16px; // 10 px row padding top + 12px (half height of expand collapse button) - 6px height of Line
  --height: 6px;
  --width: 44px;

  &-short {
    --width: 20px;
  }
}

.otr-OkrIcon {
  margin: 0 $gap 0 0;
}

.otr-Objective_ChildCount {
  display: none;
  border-radius: 0 0 $border-radius-md-next $border-radius-md-next;
  color: $dark-3;
  background: $grey-2-next;
  font-size: $fs-12;
  font-weight: fw('bold');
  line-height: 16px;
  position: absolute;
  left: var(--offset-left);
  top: calc(100% + 1px);
  padding: 4px 8px;
  z-index: 3;
}

.otr-Drag_Icon {
  // fix bug with drugging elements
  pointer-events: none;
}

.drag-trigger {
  line-height: 0;
}
</style>
