<template>
  <AppTippy
    ref="tippy"
    :append-to="appendToElement"
    :arrow="arrow"
    :boundary="boundaryResult"
    :class="classes"
    :duration="duration"
    :follow-cursor="followCursor"
    :hide-on-click="hideOnClick"
    :max-width="maxWidth"
    :offset="offset"
    :on-hidden="onHidden"
    :on-hide="onHide"
    :on-show="onShow"
    :on-shown="onShown"
    :placement="position"
    :popper-options="popperOptions"
    :show-on-create="showOnInit"
    :sticky="sticky"
    :theme="`${theme} ${DROP_LIST_THEMES.OLD_THEMES}`"
    :to="toSelector"
    :trigger="trigger"
    interactive
    multiple
    tabindex="0"
  >
    <template v-if="!toSelector">
      <slot :expanded="localValue" name="button" />
      <template v-if="loading">
        <SkeletonItem
          v-if="skeletonLoader"
          :color="skeletonLoaderColor"
          :height="skeletonLoaderHeight"
          :width="skeletonLoaderWidth"
          border-radius="6px"
          class="ad-Skeleton"
        />
        <LoadingCircle v-else class="ad-Loader" size="xsmall" />
      </template>
    </template>

    <template #content>
      <slot />
    </template>
  </AppTippy>
</template>

<script>
import { isEqual } from 'lodash'
import { defineComponent } from 'vue'
import { Tippy as AppTippy } from 'vue-tippy'

import { DROP_LIST_THEMES } from '@/utils/components-configurations/app-droplist'
import { isEscape } from '@/utils/key-codes'

import LoadingCircle from '@/components/ui/LoadingCircle/LoadingCircle'
import SkeletonItem from '@/components/ui/SkeletonLoaders/SkeletonItem'

// TODO: move icons in appropriate place

export default defineComponent({
  name: 'AppDroplist',

  components: {
    SkeletonItem,
    LoadingCircle,
    AppTippy
  },

  props: {
    followCursor: {
      type: [String, Boolean],
      default: false
    },

    modelValue: {
      type: Boolean
    },

    position: {
      type: String,
      default: 'left'
    },

    loading: {
      type: Boolean
    },

    disabled: {
      type: Boolean
    },

    offset: {
      type: Array,
      default: () => [0, 8]
    },

    arrow: {
      type: Boolean
    },

    appendTo: {
      type: String,
      default: ''
    },

    maxWidth: {
      type: [String, Number],
      default: 350
    },

    theme: {
      type: String,
      default: DROP_LIST_THEMES.COMMON_THEMES,
      validator: v => {
        const themes = v.split(' ')
        return themes.every(theme => Object.values(DROP_LIST_THEMES).includes(theme))
      }
    },

    hasFixedParent: {
      type: Boolean
    },

    toSelector: {
      type: String,
      default: undefined
    },

    hideOnClick: {
      type: Boolean,
      default: true
    },

    trigger: {
      type: String,
      default: 'click'
    },

    showOnInit: {
      type: Boolean
    },

    boundary: {
      type: [String, Object],
      default: ''
    },

    widthAsParent: {
      type: Boolean
    },

    dropdownMinWidth: {
      type: Number,
      default: null
    },

    dropdownWidth: {
      type: String,
      default: null
    },

    fitContentWidth: {
      type: Boolean
    },

    duration: {
      type: Array,
      default: () => [275, 250]
    },

    skeletonLoader: {
      type: Boolean
    },

    skeletonLoaderHeight: {
      type: String,
      default: 'calc(100% - 20px)'
    },

    skeletonLoaderWidth: {
      type: String,
      default: 'calc(100% - 20px)'
    },

    skeletonLoaderColor: {
      type: String,
      default: '#f4f5f7'
    },

    sticky: {
      type: Boolean
    }
  },

  emits: {
    'update:modelValue': null,
    hidden: null,
    opened: null,
    open: null,
    close: null
  },

  data() {
    return {
      localValue: this.modelValue
    }
  },

  computed: {
    DROP_LIST_THEMES: () => DROP_LIST_THEMES,

    classes() {
      return {
        'o-droplist': true,
        'o-droplist-standalone': this.toSelector === null,
        [`o-droplist-${this.position}`]: true,
        'o-droplist--loading': this.loading,
        'o-droplist--disabled': this.disabled,
        'o-droplist--expanded': this.localValue,
        'o-droplist--fitcontent': this.fitContentWidth
      }
    },

    appendToElement() {
      if (this.appendTo === 'parent') {
        return 'parent'
      }
      if (this.appendTo && this.appendTo.length > 0) {
        return () => document.querySelector(this.appendTo)
      }
      return () => document.body
    },

    popperOptions() {
      const result = {}
      if (this.hasFixedParent) {
        result.strategy = 'fixed'
      }
      return result
    },

    boundaryResult() {
      if (this.boundary === '') {
        return 'scrollParent'
      }
      return this.boundary
    }
  },

  watch: {
    modelValue(newValue) {
      if (this.localValue !== newValue) {
        this.toggle()
        // sometimes dropdown is misplaced after toggling, fix by updating
        this.update()
        this.localValue = newValue
      }
    },

    localValue(newValue) {
      if (this.modelValue !== newValue) {
        this.$emit('update:modelValue', newValue)
      }
    },

    disabled: {
      handler(newValue) {
        if (newValue) {
          this.$refs.tippy.disable()
        } else {
          this.$refs.tippy.enable()
        }
      }
    },

    offset: {
      // need for dynamic offset
      handler(oldValue, newValue) {
        if (!isEqual(oldValue, newValue)) {
          this.$nextTick(() => {
            this.$refs.tippy.tippy.setProps({
              offset: this.offset
            })
          })
        }
      },

      deep: true
    }
  },

  updated() {
    // recalculate dropdown position after content update
    this.$nextTick(() => {
      this.$refs.tippy.tippy?.popperInstance?.update()
    })
  },

  created() {
    document.body.addEventListener('keyup', this.onKeyUp)
  },

  mounted() {
    // disable tippy on mounted hook instead of watcher + immediate: true
    // coz we must await for component create
    this.handleDisabledStatus()
  },

  unmounted() {
    document.body.removeEventListener('keyup', this.onKeyUp)
  },

  methods: {
    handleDisabledStatus() {
      if (this.disabled) {
        this.$refs.tippy.disable()
      }
    },

    hide() {
      this.$nextTick(() => {
        this.$refs.tippy.hide()
      })
    },

    show() {
      this.$nextTick(() => {
        if (!this.disabled) {
          this.$refs.tippy.show()
        }
      })
    },

    toggle() {
      if (this.localValue) {
        this.hide()
      } else {
        this.show()
      }
    },

    update() {
      if (this.localValue) {
        this.$nextTick(() => {
          this.$refs.tippy.tippy.popperInstance?.update()
        })
      }
    },

    onHide() {
      this.$emit('close')
      this.localValue = false
    },

    onShow({ reference, popper, setProps }) {
      const { widthAsParent, dropdownMinWidth, dropdownWidth } = this

      if (widthAsParent) {
        const referenceWidth = reference.getBoundingClientRect().width
        let newWidth = referenceWidth
        if (dropdownMinWidth && referenceWidth < dropdownMinWidth) {
          newWidth = dropdownMinWidth
        }
        popper.style.width = `${newWidth}px`
      } else {
        if (dropdownWidth) {
          setProps({
            maxWidth: dropdownWidth
          })
          popper.style.width = dropdownWidth
        }
      }

      this.$emit('open')
      this.localValue = true
    },

    onShown() {
      this.$emit('opened')
    },

    onHidden() {
      this.$emit('hidden')
    },

    onKeyUp(event) {
      // 27 is key Esc
      if (isEscape(event.keyCode)) {
        if (this.localValue) {
          // in Safari press on Esc focuses dropdown even if it is attached at the end of page
          // -> side effect - scrolling the page. Avoid by focusing on droplist before
          this.$el.focus()

          this.hide()
        }
      }
    }
  }
})
</script>

<style lang="scss">
.o-droplist {
  position: relative;
  display: block;

  &-standalone {
    min-height: 20px;
  }

  &--fitcontent {
    width: fit-content;
  }
}

.o-droplist--loading {
  pointer-events: none;
}

.o-droplist--disabled {
  // pointer-events: none;
  cursor: not-allowed;
  opacity: var(--disabled-opacity, 0.8);

  * {
    pointer-events: none;
  }
}

.tippy-box[data-theme~='transparent'] {
  background-color: transparent;
}

.tippy-box[data-theme~='no-padding'] .tippy-content {
  padding: 0;
}

.tippy-box[data-theme~='no-shadow'] .tippy-content {
  box-shadow: none;
}

.tippy-box[data-theme~='light-no-shadow'][data-theme~='light'] {
  border-radius: $border-radius-md;
  box-shadow: none;

  .tippy-backdrop {
    background-color: transparent;
  }
}

.tippy-box[data-theme~='no-shadow'][data-theme~='light'] {
  border-radius: $border-radius-md;
  // border-radius: $border-radius-sm-next;
  // box-shadow: 0 4px 8px rgba($black, 0.1);
  box-shadow: $common-box-shadow;

  .tippy-backdrop {
    background-color: transparent;
  }
}

.tippy-box[data-theme~='no-shadow-next'][data-theme~='light'] {
  border-radius: $border-radius-sm-next;
  box-shadow: $common-box-shadow;

  .tippy-content {
    border-radius: inherit;

    > * {
      border-radius: inherit;
    }
  }

  .tippy-backdrop {
    background-color: transparent;
  }
}

.tippy-box[data-theme~='word-break'] {
  word-break: break-word;
}

.tippy-box[data-theme~='text-center'] {
  text-align: center;
}

.tippy-box[data-theme~='overflow-hidden'] {
  overflow: hidden;
}

.tippy-box[data-theme~='oboard-light'] .tippy-content {
  white-space: nowrap;
  text-align: left;
}

.o-droplist .tippy-active {
  outline: none;
}

[data-tippy-root] {
  outline: none;
}

.ad-Loader {
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
}
</style>

<style lang="scss" scoped>
.ad-Skeleton {
  position: absolute;
  left: var(--select-skeleton-left, 10px);
  top: var(--select-skeleton-top, 10px);
}
</style>
