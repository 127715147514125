<template>
  <div class="ws-Step">
    <AppIcon height="104" icon-name="onboarding-cat" width="68" />

    <div class="ws-Step_Content">
      <slot name="step-title" />

      <i18n-t :keypath="keyPath" class="ws-Step_Subtitle" scope="global" tag="div">
        <template #break>
          <br />
        </template>
      </i18n-t>

      <template v-if="setupNameStepNeed">
        <AppDivider no-margin />

        <FormFieldNext :label="$t('onboarding.setup_name.input_label')" class="ws-Step_Field">
          <AppInput
            v-model="name"
            :is-error="!isNameValid"
            :max-length="50"
            :placeholder="$t('onboarding.setup_name.input_label')"
            blur-on-enter
            size="xlg"
            style-type="primary"
            @focus="isNameValid = true"
          />
          <AppFieldError v-if="!isNameValid" :show="!isNameValid">
            {{ $t('field.required') }}
          </AppFieldError>
        </FormFieldNext>
      </template>

      <AppCheckbox
        v-if="!isRerunOnboarding"
        v-model="localSubscription"
        align-start
        class="ws-Step_Checkbox"
      >
        {{ $t('onboarding.subscription') }}
      </AppCheckbox>

      <template v-if="setupNameStepNeed">
        <AppDivider no-margin />

        <AppButton
          :loading="loading"
          class="ws-Step_Action"
          icon-after="chevron-right-next"
          type="primary-next"
          @click="onContinue"
        >
          {{ $t('action.lets_start') }}
        </AppButton>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'

import NotificationsApiHandler from '@/api/notifications'
import { handleError } from '@/utils/error-handling'
import { NOTIFICATION_SETTING_TYPES } from '@/utils/in-app-notifications'
import { useRenameUser } from '@/utils/web-app/composables'

import AppFieldError from '@/components/form/AppFieldError'
import FormFieldNext from '@/components/form/FormFieldNext'
import AppButton from '@/components/ui/AppButton/AppButton'
import AppCheckbox from '@/components/ui/AppCheckbox/AppCheckbox'
import AppDivider from '@/components/ui/AppDivider'
import AppIcon from '@/components/ui/AppIcon/AppIcon'
import AppInput from '@/components/ui/AppInput/AppInput'

defineOptions({
  name: 'WelcomeSetupNameStep'
})

const notificationTypeAndChannels = ref([])

const fetchTypeAndChannels = async () => {
  const api = new NotificationsApiHandler()

  try {
    notificationTypeAndChannels.value = await api.getNotificationTypeAndChannels()
  } catch (error) {
    handleError({ error })
  }
}

onMounted(() => {
  fetchTypeAndChannels()
})

const setSubscriptionsForUser = async () => {
  const { MARKETING_PRODUCT_UPDATE, MARKETING_PRODUCT_CASE_STUDY, MARKETING_PRODUCT_PROMOS } =
    NOTIFICATION_SETTING_TYPES

  const subscriptionsTypeIds = [
    MARKETING_PRODUCT_UPDATE,
    MARKETING_PRODUCT_CASE_STUDY,
    MARKETING_PRODUCT_PROMOS
  ]

  const payload = subscriptionsTypeIds.map(notificationTypeId => {
    const currentNotificationSettings = notificationTypeAndChannels.value.find(
      item => Number(item.id) === notificationTypeId
    )

    let notificationChannelsIds = localSubscription.value
      ? currentNotificationSettings.channels.map(({ id }) => id)
      : []

    return {
      notificationTypeId,
      notificationChannelsIds
    }
  })

  try {
    const api = new NotificationsApiHandler()

    await api.patchNotificationSettings({
      notificationsSettings: payload
    })
  } catch (error) {
    handleError({ error })
  }
}

const props = defineProps({
  subscription: {
    type: Boolean
  },

  isRerunOnboarding: {
    type: Boolean
  },

  setupNameStepNeed: {
    type: Boolean
  }
})

const keyPath = computed(() => {
  return props.isRerunOnboarding
    ? 'onboarding.welcome.rerun_subtitle'
    : 'onboarding.welcome.subtitle'
})

const emit = defineEmits({
  'update:subscription': null,
  continue: null
})

const localSubscription = computed({
  get: () => props.subscription,
  set: value => {
    emit('update:subscription', value)
  }
})
onBeforeUnmount(() => {
  if (localSubscription.value && !props.isRerunOnboarding) {
    setSubscriptionsForUser()
  }
})

const { onRenameUser, name, loading, isNameValid } = useRenameUser()
const onContinue = async () => {
  try {
    await onRenameUser()
    if (!isNameValid.value) return
    emit('continue')
  } catch (error) {
    handleError({ error })
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/wizard-steps';

.ws-Step {
  @extend %wizard-step-styles;

  display: grid;
  grid-template-columns: 1fr var(--step-max-width) 1fr;
  --step-gap: 50px;
  width: fit-content;
}

.ws-Step_Checkbox {
  // max-width: 496px;
  --font-size: #{$fs-12};
  --line-height: 16px;
  --gap: 12px;
  padding-left: 4px;
}

.ws-Step_Content {
  @extend %wizard-step-content-styles;
}

.ws-Step_Subtitle {
  color: $dark-1;
  font-size: $fs-14;
  line-height: 20px;
  font-weight: fw('semi-bold');
}

// .ws-Step_Divider {
//   width: 100%;
// }

.ws-Step_Field {
  width: 100%;
}

.ws-Step_Action {
  --button-low-padding: 4px;

  margin-left: auto;
}
</style>
