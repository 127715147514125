import UsersApi from '@/api/workspace-users'
import {
  OBJECTIVES_ADD_USERS,
  OBJECTIVES_SET_FULL_LEVELS_LIST,
  OBJECTIVES_SET_LEVELS,
  OBJECTIVES_SET_SORTING,
  SET_ELEMENT_FOR_EDIT
} from '@/store/mutation-types'

const state = {
  levels: [],
  fullLevelsList: [],
  users: {}, // used for mentions, { id: object }
  sorting: [],
  elementForEdit: null
}

const levelsByWorkspaceId = new Map()

const getters = {
  getLevelItem: state => (key, type) =>
    state.levels.find(item => item[key] === type) ||
    state.fullLevelsList.find(item => item[key] === type) ||
    {},
  getSortingItem: state => column => state.sorting[column] || {},
  getLevelsByWorkspaceId: state => workspaceId => {
    if (levelsByWorkspaceId.has(workspaceId)) {
      return levelsByWorkspaceId.get(workspaceId)
    }

    const levels = state.fullLevelsList.filter(level => level.workspaceIds.includes(workspaceId))
    levelsByWorkspaceId.set(workspaceId, levels)
    return levels
  }
}

const actions = {
  setElementForEdit({ commit }, element = null) {
    commit(SET_ELEMENT_FOR_EDIT, element)
  },

  clearElementForEdit({ dispatch }) {
    dispatch('setElementForEdit', null)
  },

  setLevels({ commit, dispatch }, { levels = {}, updateFullLevelsList = false } = {}) {
    commit(OBJECTIVES_SET_LEVELS, levels)
    if (updateFullLevelsList) {
      dispatch('setFullLevelsList', levels)
    }
  },

  async getUsersForMentioning({ commit, state }, { accountIds, searchString, limit, workspaceId }) {
    const unknownUsers = accountIds.filter(userId => !(userId in state.users))

    if (unknownUsers.length > 0) {
      const usersApi = new UsersApi()
      const users = await usersApi.getUsersForMentioning({
        accountIds: unknownUsers,
        searchString,
        limit,
        workspaceId
      })
      commit(OBJECTIVES_ADD_USERS, users)
    }

    return accountIds.reduce((acc, value) => ({ ...acc, [value]: state.users[value] }), {})
  },

  addUsersFromList({ commit }, users) {
    commit(OBJECTIVES_ADD_USERS, users)
  },
  updateSorting({ commit }, sorting) {
    commit(OBJECTIVES_SET_SORTING, sorting)
  },

  async setFullLevelsList({ commit }, levels) {
    const normalizedLevels = levels.map(level => {
      return {
        ...level,
        workspaceIds: level.workspaces.map(({ id }) => id)
      }
    })

    commit(OBJECTIVES_SET_FULL_LEVELS_LIST, normalizedLevels)
  },

  clearLevelsByWorkspaceIdCache() {
    if (levelsByWorkspaceId.size) {
      levelsByWorkspaceId.clear()
    }
  }
}

const mutations = {
  [OBJECTIVES_SET_LEVELS](state, levels) {
    state.levels = levels
  },
  [OBJECTIVES_ADD_USERS](state, users) {
    users.forEach(user => (state.users[user.accountId] = user))
  },
  [OBJECTIVES_SET_SORTING](state, sorting) {
    state.sorting = sorting
  },
  [OBJECTIVES_SET_FULL_LEVELS_LIST](state, levels) {
    state.fullLevelsList = levels
  },
  [SET_ELEMENT_FOR_EDIT](state, element = null) {
    state.elementForEdit = element
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
