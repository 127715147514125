export default {
  "action.access_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
  "action.activate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren"])},
  "action.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
  "action.add_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse hinzufügen"])},
  "action.add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere hinzufügen"])},
  "action.add_column_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spalte rechts einfügen"])},
  "action.add_column_before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spalte davor einfügen"])},
  "action.add_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link hinzufügen"])},
  "action.add_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Methode hinzufügen"])},
  "action.add_row_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeile darunter einfügen"])},
  "action.add_row_before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeile darüber einfügen"])},
  "action.add_to_favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu Favoriten hinzufügen"])},
  "action.add_to_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Gruppe hinzufügen"])},
  "action.add_to_group.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Benutzer wird zu allen Bereichen ", _interpolate(_named("break")), " hinzugefügt, die mit der  ", _interpolate(_named("break")), "Gruppe verknüpft sind."])},
  "action.add_user_qty": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Hinzufügen"]), _normalize(["Hinzufügen"])])},
  "action.add_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
  "action.back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
  "action.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "action.change_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status ändern"])},
  "action.claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beanspruchen"])},
  "action.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "action.code_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code-Ausschnitt"])},
  "action.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
  "action.connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinden"])},
  "action.continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
  "action.copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieren"])},
  "action.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
  "action.create_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard erstellen"])},
  "action.create_jira_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira-vorgang erstellen"])},
  "action.create_keyResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key result erstellen"])},
  "action.create_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel erstellen"])},
  "action.created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt"])},
  "action.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "action.delete_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spalte löschen"])},
  "action.delete_row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeile löschen"])},
  "action.delete_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabelle löschen"])},
  "action.delete_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer löschen"])},
  "action.disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trennen"])},
  "action.done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erledigt"])},
  "action.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
  "action.edit_on_user_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten auf der Benutzerseite"])},
  "action.emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji"])},
  "action.get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erste Schritte"])},
  "action.grant_web_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web-Zugang gewähren"])},
  "action.grant_web_access.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bitte beachten Sie, dass es", _interpolate(_named("break")), " separat berechnet wird, basierend", _interpolate(_named("break")), " auf der Web-App-Lizenz."])},
  "action.import_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer importieren"])},
  "action.inactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktivieren"])},
  "action.leave_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertung abgeben"])},
  "action.lets_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s start"])},
  "action.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlinken"])},
  "action.link_jira_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira-iorgang verlinken"])},
  "action.link_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("platform")), " Gruppen verknüpfen"])},
  "action.load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr zeigen"])},
  "action.mention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erwähnung"])},
  "action.merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenführung"])},
  "action.merge_or_split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zellen zusammenführen oder teilen"])},
  "action.move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschieben"])},
  "action.open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnen"])},
  "action.paste_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link hinzufügen"])},
  "action.proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
  "action.quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zitat"])},
  "action.remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen"])},
  "action.remove_entity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("entity")), " entfernen"])},
  "action.remove_from_admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von Administratoren entfernen"])},
  "action.remove_from_favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus Favoriten entfernen"])},
  "action.remove_from_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus Gruppe entfernen"])},
  "action.remove_from_ws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus ws entfernen"])},
  "action.rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umbenennen"])},
  "action.reopen_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reopen"])},
  "action.reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antworten"])},
  "action.restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederherstellen"])},
  "action.revoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widerrufen"])},
  "action.revoke_web_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web-Zugang sperren"])},
  "action.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "action.select_user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Benutzer auswählen"]), _normalize(["Benutzer auswählen"])])},
  "action.select_user_or_email": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Email eingeben oder Benutzer auswählen"]), _normalize(["Emails eingeben oder Benutzer auswählen"])])},
  "action.share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilen"])},
  "action.skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überspringen"])},
  "action.table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabelle"])},
  "action.table_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabellenoptionen"])},
  "action.toggle_header_cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überschriftenzelle ein- und ausschalten"])},
  "action.toggle_header_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überschriftsspalte ein- und ausschalten"])},
  "action.toggle_header_row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überschriftenzeile ein- und ausschalten"])},
  "action.unlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung aufheben"])},
  "action.unlink_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen trennen"])},
  "action.unlink_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira-Vorgang löchen"])},
  "action.update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
  "action.update_license_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenzstatus aktualisieren"])},
  "action.updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisiert"])},
  "actions.chain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstAction")), " und ", _interpolate(_named("secondAction"))])},
  "add_users_without_group": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Benutzer wird ohne Gruppe hinzugefügt"]), _normalize(["Benutzer werden ohne Gruppe hinzugefügt"])])},
  "admin.user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
  "admins.platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plattform"])},
  "app.platform.both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beide Plattformen"])},
  "app.platform.jira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira"])},
  "app.platform.web_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web App"])},
  "app_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oboard"])},
  "app_name.jira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR Board for Jira"])},
  "app_name.jira_plugin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira Plugin"])},
  "audit_log.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "audit_log.element_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Name"])},
  "audit_log.workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereich"])},
  "audit_logs.all_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Typen"])},
  "audit_logs.elements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemente (OKRs)"])},
  "audit_logs.types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typen"])},
  "auth.session_expired.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte laden Sie die Seite neu, um genaue Daten zu erhalten."])},
  "auth.session_expired.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist schon eine Weile her seit Ihrer letzten Aktivität"])},
  "badge.added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzugefügt"])},
  "badge.already_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereits hinzugefügt"])},
  "badge.already_linked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereits verlinkt"])},
  "badge.soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soon"])},
  "banner.join_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt beitreten!"])},
  "banner.license_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Lizenz ist abgelaufen. Bitte erneuern Sie sie, um die vollständige Plugin-Funktionalität zu behalten."])},
  "banner.license_renew": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihre Lizenz läuft in Kürze ab. Bitte erneuern Sie sie bis ", _interpolate(_named("licenseDaysLeft")), ", um OKR-Board für Jira weiterhin zu nutzen."])},
  "banner.okr_accelerator.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR-Beschleuniger für Führungskräfte"])},
  "banner.trial_subscribe": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihr Testzeitraum läuft bald aus. Bitte schließen Sie innerhalb von ", _interpolate(_named("licenseDaysLeft")), " ein Abonnement ab, damit Sie das OKR-Board für Jira weiter benutzen können."])},
  "breadcrumb.copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link kopieren"])},
  "comments.add_a_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar hinzufügen"])},
  "comments.edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitet"])},
  "comments.more_reply": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " weitere Antwort"]), _normalize([_interpolate(_named("count")), " weitere Antworten"])])},
  "comments.open_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentare öffnen"])},
  "comments.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tippen Sie  ", "@", ", um Personen zu erwähnen"])},
  "comments.placeholder.leave_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Kommentar hinzufügen..."])},
  "comments.see_last_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzter Kommentar anzeigen"])},
  "common.active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "common.complete_progress": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("start")), " von ", _interpolate(_named("end"))])},
  "common.default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
  "common.getting_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erste Schritte"])},
  "common.inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
  "common.link_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link kopiert"])},
  "common.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ergebnisse"])},
  "common.settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
  "common.users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
  "confirm.admin.message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sind Sie sicher, dass Sie ", _interpolate(_named("userName")), " als Administrator entfernen wollen? Der Benutzer wird nicht aus dem Plugin gelöscht, wenn er mindestens einem Bereich zugeordnet ist."])},
  "confirm.admin.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " als Administrator entfernen"])},
  "confirm.delete_entity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sind Sie sicher, dass Sie ", _interpolate(_named("entity")), " löschen möchten?"])},
  "confirm.discard_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwerfen"])},
  "confirm.dontLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht verbinden"])},
  "confirm.interval_change_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hierbei wird der Wert ", _interpolate(_named("dateProp")), " auf den Wert des neuen Zyklus zurückgesetzt."])},
  "confirm.kr.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KR löschen"])},
  "confirm.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es verbinden"])},
  "confirm.objective.nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschachtelte elemente löschen"])},
  "confirm.objective.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel löschen"])},
  "confirm.user.message": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sind Sie sicher, dass Sie den Benutzer löschen wollen?"]), _normalize(["Sind Sie sicher, dass Sie die Benutzer löschen wollen?"])])},
  "confirm_interval_change.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen Sie die Änderung des Zyklus"])},
  "confirm_modal.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können ungespeicherte Daten haben. Sind Sie sicher, dass Sie alle ungespeicherten Änderungen verwerfen möchten?"])},
  "confirm_modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen verwerfen?"])},
  "confluence.add_okrs_to_confluence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKRs zu Confluence hinzufügen"])},
  "confluence.error_page.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte laden Sie die Seite neu."])},
  "confluence.error_page.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist schief gelaufen."])},
  "confluence.okr_board_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR-Board-Verbindung ist derzeit"])},
  "confluence.permission_less.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie glauben, dass dies nicht korrekt ist, wenden Sie sich bitte an Ihre JIRA- oder Oboard-Administratoren."])},
  "confluence.permission_less.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keine Zugangsberechtigung für diese Seite."])},
  "confluence.settings.api_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-Token"])},
  "confluence.token.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das API-Token, das von Ihrer Oboard-App auf Ihrer JIRA-Instanz generiert wurde. Bitte wenden Sie sich an Ihren Oboard Jira-Administrator, um das API-Token zu erhalten."])},
  "connect_modal.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobald Sie auf 'Verbinden' drücken, werden alle Arbeitsbereiche und zugehörigen OKRs aus beiden Instanzen in einer einzigen Datenbank zusammengeführt."])},
  "connect_modal.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Web-App übernimmt die internen Einstellungen der Jira-App, wie z. B. Status, Schwellenwerte und Unternehmenseinstellungen."])},
  "connect_modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinden Sie die Web-App mit der Jira-App"])},
  "connections.connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbunden"])},
  "connections.connected_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbunden mit"])},
  "connections.connecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinden..."])},
  "connections.connection_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindungsaufbau läuft"])},
  "connections.copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token kopiert"])},
  "connections.copy_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token kopieren"])},
  "connections.disconnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getrennt"])},
  "connections.disconnecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trennen der Verbindung..."])},
  "connections.disconnection_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindungstrennung läuft"])},
  "connections.get_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token abrufen"])},
  "connections.guide.link_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration guide"])},
  "connections.help.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brauchen Sie Hilfe?"])},
  "connections.lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung verloren"])},
  "connections.paste_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token einfügen"])},
  "connections.paste_token_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie den Token ein, von"])},
  "connections.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindungsstatus"])},
  "connections.token_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrations-Token einfügen"])},
  "create.admin.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator hinzufügen"])},
  "create.keyResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key Result Name"])},
  "create.keyResult.label.currentValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuell"])},
  "create.keyResult.label.originalValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "create.keyResult.label.targetValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel"])},
  "create.keyResult.success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key Result erstellt"])},
  "create.objective.btn.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel hinzufügen"])},
  "create.objective.btn.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "create.objective.btn.select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziele auswählen"])},
  "create.objective.success_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte überprüfen Sie die Filter, wenn Sie es nicht sehen"])},
  "create.objective.success_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel geschaffen"])},
  "create.objective.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues ziel erstellen"])},
  "create.okr_element.create_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiteres erstellen"])},
  "create.task.label.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
  "create.task.success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira-Vorgang verlinkt"])},
  "custom_fields.add_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierte Felder hinzufügen"])},
  "custom_fields.add_field_to_placement": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Feld zum ", _interpolate(_named("placement")), " hinzufügen"])},
  "custom_fields.add_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwendung"])},
  "custom_fields.assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignee"])},
  "custom_fields.content_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content area"])},
  "custom_fields.create_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierte Felder erstellen"])},
  "custom_fields.currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Währung"])},
  "custom_fields.currency.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Währung eingeben"])},
  "custom_fields.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "custom_fields.delete_field_confirm_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie wirklich das Feld ", _interpolate(_named("fieldName")), " löschen? Alle Informationen aus diesem Feld werden gelöscht."])},
  "custom_fields.delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feld löschen"])},
  "custom_fields.disable_create_button_tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aktuell ist es nur möglich, ", _interpolate(_named("limit")), " benutzerdefinierte Felder zu erstellen"])},
  "custom_fields.disable_create_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitten Sie Ihren Administrator, ein neues benutzerdefiniertes Feld hinzuzufügen"])},
  "custom_fields.field.placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fieldName")), " eingeben"])},
  "custom_fields.field_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
  "custom_fields.field_name.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreiben Sie Bezeichnung"])},
  "custom_fields.field_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
  "custom_fields.field_type.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feldtyp auswählen"])},
  "custom_fields.fields.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie das erste benutzerdefinierte Feld, um zu loszulegen"])},
  "custom_fields.for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwendung"])},
  "custom_fields.help_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfetext"])},
  "custom_fields.help_text.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreiben Sie Hilfetext"])},
  "custom_fields.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
  "custom_fields.link.limit_exceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie haben die maximale Anzahl (", _interpolate(_named("limit")), ") von Verknüpfungen für das OKR erreicht"])},
  "custom_fields.link.name.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigename"])},
  "custom_fields.long_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Long text"])},
  "custom_fields.money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Währung"])},
  "custom_fields.multi_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-select"])},
  "custom_fields.no_fields.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine benutzerdefinierte Felder"])},
  "custom_fields.notification.created_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion erfolgreich"])},
  "custom_fields.number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahl"])},
  "custom_fields.options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionen"])},
  "custom_fields.options.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option hinzufügen"])},
  "custom_fields.options.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den Optionsnamen ein"])},
  "custom_fields.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erforderlich"])},
  "custom_fields.right_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right panel"])},
  "custom_fields.single_line_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single line text"])},
  "custom_fields.single_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
  "custom_fields.text_field.placeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fieldName")), " hinzufügen\n"])},
  "custom_fields.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierte Felder"])},
  "custom_fields.warning.filtering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld kann nicht gefiltert oder sortiert werden"])},
  "custom_fields.warning.not_editable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwendung kann in Zukunft nicht bearbeitet werden"])},
  "custom_fields.warning.options.create": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie können Optionen nur konfigurieren, wenn Sie eine neue Auswahl erstellen. ", _interpolate(_named("break")), "Optionen können in Zukunft nicht bearbeitet werden."])},
  "custom_fields.warning.options.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können diese Optionen nicht konfigurieren, sobald das Feld erstellt wurde"])},
  "custom_weights.contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beitragen"])},
  "custom_weights.impact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswirkung"])},
  "custom_weights.weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewicht"])},
  "dashboard.access_denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriff verweigert"])},
  "dashboard.across_all_the_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in der gesamten Gruppe"])},
  "dashboard.across_all_the_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über alle Gruppen hinweg"])},
  "dashboard.add_custom_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Dashboard hinzufügen"])},
  "dashboard.add_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr hinzufügen"])},
  "dashboard.add_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen von Personen"])},
  "dashboard.all_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personen"])},
  "dashboard.assigned_to_all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allen Gruppen zugewiesen"])},
  "dashboard.assigned_to_the_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["der Gruppe zugewiesen"])},
  "dashboard.average_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtfortschritt"])},
  "dashboard.avg_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtfortschritt"])},
  "dashboard.by_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Gruppe"])},
  "dashboard.choose_custom_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Dashboard auswählen"])},
  "dashboard.created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erstellt von ", _interpolate(_named("userName"))])},
  "dashboard.dashboard_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dashboard: ", _interpolate(_named("dashboardName")), " erfolgreich gelöscht"])},
  "dashboard.days_left_in_cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbleibende Tage im Zyklus"])},
  "dashboard.duplicate_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doppeltes Dashboard"])},
  "dashboard.enter_the_main_goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie das Hauptziel ein"])},
  "dashboard.export_as_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als PDF exportieren"])},
  "dashboard.horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal"])},
  "dashboard.in_this_cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in diesem Zyklus"])},
  "dashboard.in_this_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In dieser Gruppe"])},
  "dashboard.in_this_ws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In diesem Workspace"])},
  "dashboard.interval_passed_percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("cyclePassedPercent")), " % bestanden"])},
  "dashboard.jira_issue_linked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira-Vorgänge"])},
  "dashboard.last_qty_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Letzte ", _interpolate(_named("qty")), " Tage"])},
  "dashboard.layout_orientation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layoutorientierung"])},
  "dashboard.linked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verlinkt"])},
  "dashboard.menu.custom_dashboard": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Custom dashboard"]), _normalize(["Custom dashboards"])])},
  "dashboard.menu.general_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeines Dashboard"])},
  "dashboard.menu.performance_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortschrittsbericht"])},
  "dashboard.new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu"])},
  "dashboard.no_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt noch keine Gruppen"])},
  "dashboard.no_intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard erfordert mindestens ein geöffnetes Intervall"])},
  "dashboard.no_intervals_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie ein erstes Intervall, um zu beginnen"])},
  "dashboard.no_linked_krs_nested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine verbundenen KRs, eingebettete Ziele"])},
  "dashboard.no_linked_krs_nested_jira_issues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ohne untergeordnete KR, Obj., Jira-Einträge"])},
  "dashboard.no_matching_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt keine Dashboards, die den angewendeten Filtern entsprechen"])},
  "dashboard.no_objectives_in_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ziele in der Gruppe"])},
  "dashboard.no_owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt noch keine Owners"])},
  "dashboard.no_owners_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Owners für diese Suche gefunden"])},
  "dashboard.no_report_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie das erste Custom Dashboard"])},
  "dashboard.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Custom Dashboards"])},
  "dashboard.objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziele"])},
  "dashboard.people_with_okr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personen mit OKR"])},
  "dashboard.people_without_okr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personen ohne OKR"])},
  "dashboard.reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reopen"])},
  "dashboard.share.public.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alle öffentlichen Dashboards sind für alle Benutzer sichtbar und für duplica verfügbar.", _interpolate(_named("break")), _interpolate(_named("break")), " Machen Sie das Dashboard privat, wenn Sie es mit bestimmten Personen teilen möchten oder es sensible Daten hat."])},
  "dashboard.share_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard teilen"])},
  "dashboard.share_with_same_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teile es mit den gleichen Leuten"])},
  "dashboard.start_presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Präsentation starten"])},
  "dashboard.stop_presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Präsentation stoppen"])},
  "dashboard.vertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertikal"])},
  "dashboard.you_dont_have_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keine Berechtigung zum Zugriff auf einen oder mehrere OKRs in diesem Bericht"])},
  "date.custom_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten definieren"])},
  "date.date_with_word_break": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("start")), " ", _interpolate(_named("break")), " - ", _interpolate(_named("end"))])},
  "datepicker.next": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["nächste ", _interpolate(_named("days")), " Tage"])},
  "datepicker.previous": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["vorherige ", _interpolate(_named("days")), " Tage"])},
  "datepicker.select_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum auswählen"])},
  "datepicker.select_date_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datumsbereich auswählen"])},
  "datepicker.without_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Datum"])},
  "debug.calculate_grades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noten berechnen"])},
  "debug.calculate_grades_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuberechnung der Noten beginnen"])},
  "debug.claim_admin-rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anspruch auf Administratorrechte"])},
  "debug.clean_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufräumen"])},
  "debug.clean_up_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaputte Links zu Jira-Issues aufräumen"])},
  "debug.confirm_migration_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Migration kann nicht automatisch rückgängig gemacht werden, starten Sie sie nicht, es sei denn, Sie wurden vom Support-Team dazu aufgefordert."])},
  "debug.confirm_migration_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie die Migrierung beginnen wollen?"])},
  "debug.debug_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlerbehebungsinformation"])},
  "debug.delete_deactivated_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktivierte Benutzer löschen"])},
  "debug.download_info_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlerbehebungsinformation herunterladen"])},
  "debug.download_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herunterladen"])},
  "debug.download_logs_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logdateien herunterladen"])},
  "debug.export_to_zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export nach . zip"])},
  "debug.grade_calculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notenberechnung"])},
  "debug.grades_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noten aktualisiert"])},
  "debug.i_understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich verstehe"])},
  "debug.import_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importfehler"])},
  "debug.import_file_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Archiv ist zu groß, bitte schreiben Sie uns <a href=\"mailto:support", "@", "oboard.io\">support", "@", "oboard.io</a> um das Limit zu erhöhen."])},
  "debug.import_to_zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import aus . zip"])},
  "debug.instance_migration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instanzmigration"])},
  "debug.migrate_attributes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribute migrieren"])},
  "debug.migrate_attributes_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrierung von Aufgaben-Attributen"])},
  "debug.migrate_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail migrieren"])},
  "debug.migrate_email_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Migration"])},
  "debug.migrate_intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zyklen migrieren"])},
  "debug.migrate_intervals_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zyklen-Migrierung"])},
  "debug.migrate_labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labels migrieren"])},
  "debug.migrate_labels_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etikettenmigration"])},
  "debug.migrate_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels migrieren"])},
  "debug.migrate_levels_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels-Migrierung"])},
  "debug.migrate_tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgaben migrieren"])},
  "debug.migrate_tasks_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrierung von Jira-Issues"])},
  "debug.migration_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["- Bitte migrieren Sie Ihre Jira-Instanz zuerst (mit allen Jira-Issues, Benutzern, Gruppen etc.), damit OKRs nicht die Verbindung zu Jira-Issues, Verantwortlichen usw. verlieren. ", _interpolate(_named("break")), " - Plugin Company-Einstellungen werden überschrieben. ", _interpolate(_named("break")), " - Alle vorhandenen Bereiche werden nicht gelöscht oder überschrieben."])},
  "debug.migrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrierungen"])},
  "debug.remove_duplicated_intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplizierte Zyklus entfernen"])},
  "debug.remove_okr_board_global_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globale berechtigung des OKR board entfernen"])},
  "debug.remove_users_without_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer ohne Gruppen entfernen"])},
  "debug.restore_attributes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribute wiederherstellen"])},
  "debug.restore_attributes_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Elementattribute wiederherstellen"])},
  "debug.restore_interval_dates_for_elements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zyklusdaten für Elemente wiederherstellen"])},
  "debug.restore_jira_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira-Details wiederherstellen"])},
  "debug.restore_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels wiederherstellen"])},
  "debug.restore_levels_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levels für bereiche wiederherstellen"])},
  "debug.restore_task_chain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabenkette wiederherstellen"])},
  "debug.restore_users_and_groups_for_elements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer und Gruppen für Elemente wiederherstellen"])},
  "debug.rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechte"])},
  "debug.start_migration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrierung beginnen"])},
  "debug.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlerbehebungsseite"])},
  "debug.update_users_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerinformationen aktualisieren"])},
  "debug.update_users_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerinformationen aktualisieren"])},
  "debug.upload_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])},
  "debug.warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warnung"])},
  "delete_confirm_interval_input_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tippen Sie zur Bestätigung das ", _interpolate(_named("keyword"))])},
  "delete_confirm_interval_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sind Sie sicher, dass Sie den Zyklus ", _interpolate(_named("intervalName")), " und alle OKRs aus diesem Intervall löschen möchten?"])},
  "delete_confirm_interval_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zyklus löschen"])},
  "delete_confirm_issue_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie das Thema entkoppeln möchten:"])},
  "delete_confirm_kr_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie das Key Result löschen möchten:"])},
  "delete_confirm_objective_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie das Ziel löschen möchten:"])},
  "delete_confirm_token_message_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Widerruf eines API-Tokens ist nicht reversibel"])},
  "delete_confirm_token_message_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie Ihr API-Token endgültig widerrufen wollen?"])},
  "delete_confirm_token_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-Token widerrufen"])},
  "delete_confirm_workspace_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sind Sie sicher, dass Sie Bereich ", _interpolate(_named("workspaceName")), " und alle OKRs aus diesem Bereich löschen möchten?"])},
  "delete_confirm_workspace_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereich löschen"])},
  "delete_user.count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Benutzer löschen"])},
  "delete_user.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("userName")), " löschen"]), _normalize(["Benutzer löschen"])])},
  "delete_user_from_app": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sind Sie sicher, dass Sie ", _interpolate(_named("userName")), " aus ", _interpolate(_named("appName")), " löschen möchten?"])},
  "delete_user_from_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sind Sie sicher, dass Sie ", _interpolate(_named("userName")), " aus dem Bereich löschen möchten: ", _interpolate(_named("workspaceName")), "?"])},
  "description.clear_formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formatierung löschen"])},
  "description.heading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überschrift 1"])},
  "description.heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überschrift 2"])},
  "description.heading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überschrift 3"])},
  "description.heading_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überschrift 4"])},
  "description.heading_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überschrift 5"])},
  "description.heading_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überschrift 6"])},
  "description.normal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normaler Text"])},
  "description.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Beschreibung hinzufügen ..."])},
  "description.position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position:"])},
  "description.strikethrough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchgestrichen"])},
  "description.subscript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiefgestellt"])},
  "description.superscript": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochgestellt"])},
  "description.underline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterstreichen"])},
  "disconnect_modal.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies wird zu folgenden Änderungen führen"])},
  "disconnect_modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trennen Sie die Web-App von der Jira-App"])},
  "disconnect_modal_events_list": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Jira-Benutzer löschen"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Jira-Gruppenverbindungen löschen"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen von Jira-Issues"])},
    
  ],
  "dropdown.copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplizieren"])},
  "dropdown.export_csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV exportieren"])},
  "dropdown.link_new_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Neue ", _interpolate(_named("platform")), "-Gruppen verlinken"])},
  "dropdown.restore_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederherstellen der Standardwerte"])},
  "dropdown.sync_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt synchronisieren"])},
  "dropdown.unlink_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verknüpfung von ", _interpolate(_named("platform")), "-Gruppen aufheben"])},
  "dropdown.viewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrachter"])},
  "dropdown.weights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierte gewichte"])},
  "edit_later.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können sie später bearbeiten"])},
  "element.close_nested_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschieben sie alle untergeordneten OKRs auf denselben status."])},
  "element.set_new_status": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("okr")), " in den Status ", _interpolate(_named("status")), " verschieben"])},
  "element.type.jira_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira-Issue"])},
  "element.type.key_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key result"])},
  "expired_license.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenz kaufen"])},
  "expired_license.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siehe detaillierte Preise"])},
  "expired_license.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie nicht der JIRA-Admin sind, bitten Sie ihn, den Zugang zu aktivieren"])},
  "expired_license.subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nutzen sie OKR dashboards, roadmaps und andere leistungsstarke tools ", _interpolate(_named("break")), "von OKR Board für ", _interpolate(_named("platform")), " weiter, indem sie den kostenpflichtigen plan abonnieren."])},
  "expired_license.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihre OKR Board for ", _interpolate(_named("platform")), " lizenz ", _interpolate(_named("break")), "ist nicht mehr gültig"])},
  "feedback.modal.message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was finden Sie im Plugin am herausforderndsten oder könnte verbessert werden?"])},
  "feedback.modal.rate_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie würden Sie uns bewerten?"])},
  "feedback.modal.review_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es hilft uns, schneller zu wachsen und ein besseres Produkt zu liefern."])},
  "feedback.modal.review_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir würden uns über Ihre Bewertung im Marketplace freuen"])},
  "feedback.modal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helfen Sie uns, ein besseres Produkt zu entwickeln"])},
  "field.assignee.stakeholders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stakeholder"])},
  "field.assignee.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
  "field.contribute.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für übergeordnete Zielerreichung beitragen"])},
  "field.group.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe auswählen"])},
  "field.group.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen"])},
  "field.groups.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen"])},
  "field.interval_wrong_keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsches Schlüsselwort"])},
  "field.issue.jql_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JQL Query eingeben"])},
  "field.issue.name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira-Vorgang nach Namen oder ID suchen"])},
  "field.labels.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label hinzufügen"])},
  "field.labels.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
  "field.name_reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Name ist reserviert"])},
  "field.not_set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leer"])},
  "field.owner.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie den Owner..."])},
  "field.parentItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übergeordnetes element"])},
  "field.parentObjective.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übergeordnetes ziel"])},
  "field.period.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum"])},
  "field.quoter.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zyklus auswählen"])},
  "field.quoter.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zyklus"])},
  "field.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erforderliches feld"])},
  "field.select.loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lädt"])},
  "field.select.nooptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kein Ergebnis"])},
  "field.status.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "field.workspace.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereiche"])},
  "field.workspace_key_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Schlüssel sollte aus 1-3 Zeichen bestehen"])},
  "field.workspace_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Name sollte aus mindestens 2 Zeichen bestehen"])},
  "fields.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erforderliche Felder"])},
  "figma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figma"])},
  "filed.assignee.stakeholders.plug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stakeholder hinzufügen"])},
  "filter.A_Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A-Z"])},
  "filter.Z_A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z-A"])},
  "filter.active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "filter.add_another_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein weiteres Feld hinzufügen"])},
  "filter.all_are_filtered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Ziele sind gefiltert"])},
  "filter.all_grade_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Status"])},
  "filter.all_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Gruppen"])},
  "filter.all_labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Label"])},
  "filter.all_okr_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle OKR-Levels"])},
  "filter.all_owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Owners"])},
  "filter.all_platforms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Plattformen"])},
  "filter.all_roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Rollen"])},
  "filter.all_stakeholders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Stakeholder"])},
  "filter.all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Benutzer"])},
  "filter.at_least_comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Mindestens ", _interpolate(_named("count")), " Kommentare"]), _normalize(["Mindestens ", _interpolate(_named("count")), " Kommentare"])])},
  "filter.avg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschn."])},
  "filter.clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "filter.clear_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle löschen"])},
  "filter.clear_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "filter.clear_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl löschen"])},
  "filter.collapse_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle einklappen"])},
  "filter.copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link kopieren"])},
  "filter.creating_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ein neues Ziel zu erstellen."])},
  "filter.custom_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderer Zeitraum"])},
  "filter.custom_range.before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis"])},
  "filter.custom_range.between": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwischen"])},
  "filter.custom_range.since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vom"])},
  "filter.due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fälligkeitsdatum"])},
  "filter.expand_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle ausklappen"])},
  "filter.groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen"])},
  "filter.hide_krs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KRs verbergen"])},
  "filter.inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
  "filter.is_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist leer"])},
  "filter.is_not_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist nicht leer"])},
  "filter.last_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzter Kommentar"])},
  "filter.last_grade_update_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortschritt aktualisiert"])},
  "filter.less_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weniger als"])},
  "filter.levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
  "filter.limitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begrenzung"])},
  "filter.manage_intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwalten von Zyklen"])},
  "filter.more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr"])},
  "filter.more_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr"])},
  "filter.more_than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr als"])},
  "filter.never_commented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie kommentiert"])},
  "filter.no_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine zu Ihrer Suche passenden OKRs gefunden"])},
  "filter.no_objective_jira_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine zu Ihrer Suche passenden OKRs gefunden. Versuchen Sie, Ihre Suchkriterien zu verändern."])},
  "filter.no_objective_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie das erste Ziel, um loszulegen"])},
  "filter.no_objective_subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Versuchen Sie, Ihre Suchkriterien zu ändern oder ", _interpolate(_named("creating"))])},
  "filter.no_objective_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ziele in diesem Zyklus"])},
  "filter.okr_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR-Level"])},
  "filter.only_this": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur dies"])},
  "filter.owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
  "filter.search_okr_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR suchen"])},
  "filter.show_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle anzeigen"])},
  "filter.show_krs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KRs anzeigen"])},
  "filter.show_them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie anzeigen"])},
  "filter.sort_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortieren nach"])},
  "filter.start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum"])},
  "filter.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "filter.statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "filter.this_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Monat"])},
  "filter.this_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Woche"])},
  "filter.today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heute"])},
  "filters.custom_fields.warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der gespeicherte Filter enthält ein benutzerdefiniertes Feld, das nicht mehr verfügbar ist"])},
  "filters.filter_deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Filter: ", _interpolate(_named("filterName")), " erfolgreich gelöscht"])},
  "filters.save_as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern unter ..."])},
  "filters.save_filter_as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter speichern unter"])},
  "filters.update_dashboard_as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard aktualisieren als"])},
  "filters.update_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
  "filters.update_filter_as": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren als"])},
  "filters_saver.filter_renamed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Filter: ", _interpolate(_named("filterName")), " erfolgreich umbenannt"])},
  "filters_saver.filter_saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Filter: ", _interpolate(_named("filterName")), " erfolgreich gespeichert"])},
  "filters_saver.filter_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Filter: ", _interpolate(_named("filterName")), " erfolgreich aktualisiert"])},
  "global_groups.add_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen hinzufügen"])},
  "global_groups.add_groups_to_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen von Gruppen zum Bereich"])},
  "global_groups.add_selected_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ausgewählte Gruppe hinzufügen ", _interpolate(_named("count"))]), _normalize(["Ausgewählte Gruppen hinzufügen ", _interpolate(_named("count"))])])},
  "global_groups.add_sub_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Untergruppe hinzufügen"])},
  "global_groups.audit_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auditprotokoll"])},
  "global_groups.available_only_in_jira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur im Jira-Plugin verfügbar"])},
  "global_groups.delete_note": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sind Sie sicher, dass Sie ", _interpolate(_named("groupName")), " löschen möchten? Die Gruppe wird aus allen Ziele und allen Bereichen gelöscht!"])},
  "global_groups.delete_users.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen Sie Benutzer mit Gruppe."])},
  "global_groups.keep_users.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halten Sie Benutzer nach dem Löschen der Gruppe im Bereich."])},
  "global_groups.manage_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe verwalten"])},
  "global_groups.manage_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer verwalten"])},
  "global_groups.merge_affect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Zusammenführen von Gruppen wird die Arbeitsbereiche beeinflussen"])},
  "global_groups.merge_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select at least two groups to merge"])},
  "global_groups.merge_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merge groups"])},
  "global_groups.no_users.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie Benutzer zu dieser Gruppe hinzu, um sie über Arbeitsbereiche hinweg zu verwenden"])},
  "global_groups.no_users.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Benutzer in dieser Gruppe"])},
  "global_groups.parent_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übergeordnete Gruppe"])},
  "global_groups.remove_from_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus der Gruppe entfernen"])},
  "global_groups.remove_from_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe aus dem Bereich entfernen"])},
  "global_groups.remove_from_workspace.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sind Sie sicher, dass Sie ", _interpolate(_named("groupName")), " aus dem Bereich  entfernen möchten?"])},
  "global_groups.select_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übergeordnete Gruppe auswählen"])},
  "global_groups.sub_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untergruppen"])},
  "global_groups.unavailable_for_add_to_ws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen Workspace-Administrator in allen Workspaces sein, denen die Gruppe zugewiesen ist. Bitte bitten Sie Ihren Plugin-Administrator, die Rechte zu gewähren."])},
  "global_groups.unavailable_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keine Rechte, diese Gruppe zu verwalten"])},
  "global_groups.users_affect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Hinzufügen oder Entfernen von Benutzern könnte sich auf Bereiche auswirken"])},
  "global_groups.will_be_removed_from_ws": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Benutzer könnte auch aus den Arbeitsbereichen entfernt werden"]), _normalize(["Benutzer könnten auch aus den Arbeitsbereichen entfernt werden"])])},
  "global_groups.without_parent_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Elterngruppe"])},
  "grade_info.filtered_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefilterte Elemente"])},
  "grade_info.total_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtnote"])},
  "grade_info.your_krs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre KRs"])},
  "group.group_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppieren nach"])},
  "group.label.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppenname"])},
  "group.select_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereich auswählen"])},
  "group.table_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabellenansicht"])},
  "group.user_will_be_added_to_the_workspaces": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Benutzer werden zu den Bereichen hinzugefügt:"]), _normalize(["Benutzer werden zu den Bereichen hinzugefügt:"])])},
  "groups.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe erstellen"])},
  "groups.delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe löschen"])},
  "groups.new_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue gruppe"])},
  "groups.no_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Gruppen gefunden"])},
  "groups.no_groups_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keine Gruppen in diesem Bereich."])},
  "groups.rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe umbenennen"])},
  "groups.unlink.delete_users.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen von Benutzern"])},
  "groups.unlink.delete_users.message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Löschen Sie Benutzer aus ", _interpolate(_named("group")), " , nachdem Sie die Verknüpfung mit ", _interpolate(_named("platform")), "-Gruppe aufgehoben haben."])},
  "groups.unlink.keep_users.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer beibehalten"])},
  "groups.unlink.keep_users.message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Behalten Sie die Benutzer nach dem Aufheben der Verknüpfung mit der Gruppe ", _interpolate(_named("platform")), " in ", _interpolate(_named("group")), " bei ."])},
  "groups.unlink.strategy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option auswählen"])},
  "groups.users_tab.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzergruppen. Standard-Gruppen werden automatisch ausgewählt wenn ein Ziel erstellt wird und für den “Meine Gruppe“-Filter."])},
  "homepage.export_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*.pdf exportieren"])},
  "homepage.group_summary.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personen in Gruppe"])},
  "homepage.key_result_qty": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Key result"]), _normalize(["Key results"])])},
  "homepage.notify.error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konnte ausgewählte ", _interpolate(_named("role")), " nicht finden"])},
  "homepage.notify.role.user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
  "homepage.objective_qty": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ziel"]), _normalize(["Ziele"])])},
  "homepage.objectives.foreign_objectives": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), "s KRs von foreign Ziele"])},
  "homepage.objectives.owner": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), "s Ziele"])},
  "homepage.objectives.stakeholder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " ist Stakeholder"])},
  "homepage.objectives.watcher": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), " folgt"])},
  "homepage.objectives_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("groupName")), " Ziele"])},
  "homepage.okr_elements_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ziele oder Key Results."])},
  "homepage.okr_elements_empty_foreign_objectives": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Keine Schlüsselergebnisse von ", _interpolate(_named("name")), "."])},
  "homepage.okr_elements_empty_krs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt keine Ziele mit Ihnen zugewiesenen Schlüsselergebnissen"])},
  "homepage.progress.last_week_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortschritt letzte Woche"])},
  "homepage.progress.switch.krs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortschritt der key results"])},
  "homepage.progress.switch.objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortschritt der Ziele"])},
  "homepage.role.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["die Gruppe ist ein Owner"])},
  "homepage.role.owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind ein Owner"])},
  "homepage.role.stakeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind ein Stakeholder"])},
  "homepage.role.watcher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind ein Beobachter"])},
  "homepage.user_select.groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmeneinheiten"])},
  "homepage.user_select.users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teammitglieder"])},
  "homepage.user_summary.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("userName")), "s Gruppen"])},
  "in_app_notifications.button.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben ungelesene Benachrichtigungen"])},
  "in_app_notifications.category.older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ältere"])},
  "in_app_notifications.category.today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heute"])},
  "in_app_notifications.no_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben derzeit keine Benachrichtigungen"])},
  "in_app_notifications.notification.commented.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " kommentiert ein ", _interpolate(_named("element"))])},
  "in_app_notifications.notification.replied.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " hat auf ein Problem geantwortet"])},
  "in_app_notifications.notification.show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weniger anzeigen"])},
  "in_app_notifications.notification.status.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " Status geändert"])},
  "in_app_notifications.notification.updates.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["+ ", _interpolate(_named("qty")), " Updates von ", _interpolate(_named("userName")), " und anderen"])},
  "in_app_notifications.notification_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("author")), " erwähnte Sie auf dem ", _interpolate(_named("element"))])},
  "in_app_notifications.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
  "integration_settings.connection_lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sieht so aus, als wäre das Jira-Plugin deinstalliert worden. Stellen Sie das Jira-Plugin wieder her oder trennen Sie die Verbindung."])},
  "integration_settings.jira.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um die Verbindung zu trennen, drücken Sie die Schaltfläche, um die Web-App zu öffnen und zum zugehörigen Konto zu navigieren."])},
  "integration_settings.jira.step1.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drücken Sie die Taste, um den Integrations-Token zu kopieren. Der Token hat eine Lebensdauer von 30 Minuten. Das Token kann nur einmal verwendet werden."])},
  "integration_settings.jira.step2.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Um eine Verbindung herzustellen, benötigen Sie ein Web-App-Konto. Drücken Sie die Schaltfläche, um die Web-App-Seite zu öffnen.", _interpolate(_named("break")), "Melden Sie sich an. Wählen Sie das Konto aus. Navigieren Sie zu Einstellungen/Integrationen/Jira-Plugin."])},
  "integration_settings.web_app.step1.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie bereits einen Token haben, fahren Sie mit Schritt 2 fort. Andernfalls stellen Sie sicher, dass Sie ein Jira-Konto haben. Öffnen Sie den Atlassian Marketplace. Installieren Sie das OKR-Board-Plugin für Jira. Öffnen Sie das Plugin und gehen Sie zu Einstellungen/Integrationen/Web App."])},
  "integration_settings.web_app.step1.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira-Plugin-Token erhalten"])},
  "integration_settings.web_app.step2.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie das Jira Plugin Integrations-Token in das Eingabefeld ein. Klicken Sie auf die Taste Verbinden. Die Verbindung dauert einige Zeit."])},
  "interval.label.end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum"])},
  "interval.label.time_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitabschnitt"])},
  "intervals.create_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Zyklus erstellen"])},
  "intervals.edit_interval": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zyklus bearbeiten ", _interpolate(_named("intervalName"))])},
  "intervals.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "intervals.new_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues zyklus"])},
  "intervals.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Zyklen gefunden"])},
  "intervals.status.archived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiviert"])},
  "intervals.status.open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])},
  "jira.auto_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-Bewertung"])},
  "jira.auto_grade.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die automatische Bewertung basiert auf dem Status der Jira-Problembehebung oder dem Fortschritt der Teilaufgaben"])},
  "jira.dashboard.access.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keinen Zugriff auf den Bereich."])},
  "jira.dashboard.alignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausrichtung"])},
  "jira.dashboard.hierarchy_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hierarchie-Ansicht"])},
  "jira.dashboard.okr_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR-Explorer"])},
  "jira.dashboard.okr_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR-Typ"])},
  "jira.dashboard.okr_types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR-Typen"])},
  "jira.dashboard.okrs_displayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angezeigte OKRs (max. 100)"])},
  "jira.dashboard.okrs_displayed_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Zahlen 1 bis 100 sind erlaubt"])},
  "jira.dashboard.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard für OKR einrichten"])},
  "jira.manual_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuelle Bewertung"])},
  "jira.sidebar.unlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Verbindung zwischen dem Jira-Vorgang und dem ergebnis von objective oder key result wird aufgehoben. Bitte bestätigen sie den vorgang."])},
  "kr.add_nested_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingebundenes Objekt hinzufügen"])},
  "kr.move_from_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von allen Bereiche aus verschieben."])},
  "kr.move_nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschachtelte Elemente verschieben. Nur vom aktuellen Bereich aus."])},
  "kr.nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingebundene Objekte"])},
  "labels.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label erstellen"])},
  "labels.delete_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sind sie sicher, dass sie daslabel ", _interpolate(_named("labelName")), " aus allen objectives und key results entfernen wollen?"])},
  "labels.delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label entfernen"])},
  "labels.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label-Name"])},
  "labels.new_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Label"])},
  "labels.no_labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Labels"])},
  "labels.no_labels_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie das erste Label, um loszulegen"])},
  "labels.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Labels gefunden"])},
  "labels.switch_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label-Erstellung aus OKR-Formularen erlauben"])},
  "labels.toast.create_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label erstellt"])},
  "levels.add_level_to_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level zu diesen Bereichen hinzufügen"])},
  "levels.add_to_all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu allen Bereichen hinzufügen"])},
  "levels.add_workspace": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Bereich hinzufügen"]), _normalize(["Bereiche hinzufügen"])])},
  "levels.and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und"])},
  "levels.color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farbe"])},
  "levels.convert_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("levelName")), "-OKRs konvertieren zu"])},
  "levels.delete_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Um die Level ", _interpolate(_named("levelName")), " zu löschen, müssen Sie alle OKRs der Level ", _interpolate(_named("levelName")), " in eine der bestehenden OKR-Levels umwandeln."])},
  "levels.delete_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Level ", _interpolate(_named("levelName")), " löschen"])},
  "levels.header_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR-Levels"])},
  "levels.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon"])},
  "levels.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level-Name"])},
  "levels.no_levels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Levels"])},
  "levels.no_levels_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie das erste OKR-Level, um loszulegen"])},
  "levels.other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["andere"])},
  "levels.prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id (1–2)"])},
  "levels.title.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR-Level erstellen"])},
  "levels.title.update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR-Level bearbeiten"])},
  "limitations.create_button.tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Für Ihr Abonnement ist es nur möglich, ", _interpolate(_named("limit")), " ", _interpolate(_named("entity")), " zu erstellen."])},
  "limitations.entity.limit_exceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie haben die maximale Anzahl (", _interpolate(_named("limit")), ") von ", _interpolate(_named("entity")), " erreicht."])},
  "link_jira_issue.check_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnis im neuen Tab öffnen"])},
  "link_jira_issue.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie möchten den beitragenden Jira-Vorgang mit dem key result verbinden."])},
  "link_jira_issue.import_count_jira_issues": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wir werden nur die erste ", _interpolate(_named("count")), " Jira-Issues importieren"])},
  "link_jira_issue.it_means": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es bedeutet:"])},
  "link_jira_issue.jira_issues_found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Jira-Vorgänge gefunden."])},
  "link_jira_issue.jql": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JQL"])},
  "link_jira_issue.jql_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige JQL-Anfrage"])},
  "link_jira_issue.jql_timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage-Zeitüberschreitung"])},
  "link_jira_issue.link_another_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine weiteres Jira-Issue verknüpfen"])},
  "link_jira_issue.link_anyway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trotzdem verbinden?"])},
  "link_jira_issue.linked_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpftes Jira-Issue:"])},
  "link_jira_issue.list_item_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note wird automatisch entsprechend des Status des Jira-Vorgangs berechnet."])},
  "link_jira_issue.list_item_second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können die Note nicht automatisch ändern, außer Sie lösen die Verbindung mit dem Vorgang oder deaktivieren die Beitragsoption."])},
  "link_jira_issue.open_task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe in Jira öffnen"])},
  "link_jira_issue.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira-Vorgang mit key result verbinden"])},
  "lock_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie haben keine Rechte, dieses ", _interpolate(_named("element")), " zu bearbeiten"])},
  "lock_message.entity_parent_closed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das übergeordnete ", _interpolate(_named("parentOkr")), " ist ", _interpolate(_named("statusName")), " und seine Bewertung wird nicht aktualisiert"])},
  "lock_message.grade_closed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Bewertung für ", _interpolate(_named("status")), " OKRs ist nicht aktualisiert"])},
  "lock_message.kr_closed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Bewertung kann nicht bearbeitet werden, da das KR ", _interpolate(_named("statusName")), " ist"])},
  "marketplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketplace"])},
  "menu.activity_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
  "menu.admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administratoren"])},
  "menu.api_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-Tokens"])},
  "menu.audit_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit logs"])},
  "menu.billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])},
  "menu.branding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branding"])},
  "menu.comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
  "menu.comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentare"])},
  "menu.custom_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierte Felder"])},
  "menu.dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "menu.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "menu.integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrationen"])},
  "menu.my_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine OKR-Gruppen"])},
  "menu.my_okr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein OKR"])},
  "menu.objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziele"])},
  "menu.okr_general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemein"])},
  "menu.organization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gruppen & ", _interpolate(_named("break")), "mitglieder"])},
  "menu.organization.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globale gruppen und benutzer"])},
  "menu.personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profileinstellungen"])},
  "menu.security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheit"])},
  "menu.settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
  "menu.show_other_views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Ansichten anzeigen"])},
  "menu.support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe & support"])},
  "menu.synchronization_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync-Einstellungen"])},
  "menu.tooltip.collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausblenden"])},
  "menu.tooltip.expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einblenden"])},
  "menu.tooltip.go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurückgehen"])},
  "menu.whats_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktneuheiten"])},
  "menu.workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereiche"])},
  "menu.workspaces.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen, Benutzer und Zyklen"])},
  "miro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miro"])},
  "missing_page.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptseite"])},
  "missing_page.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entweder ist etwas schief gelaufen oder die Seite existiert nicht mehr."])},
  "missing_page.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoppla, leider können wir die Seite nicht finden"])},
  "move.copy_nested_objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschachtelte Ziele kopieren"])},
  "move.no_parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein übergeordnetes element"])},
  "move.select_assignee_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben keinen entsprechenden Owner im Zielbereich gefunden, bitte weisen Sie diesen zu."])},
  "move.select_groups_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben keine entsprechenden Gruppen im Zielbereich gefunden, bitte weisen Sie diese zu."])},
  "move.select_levels_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben keine entsprechenden Levels im Zielbereich gefunden, bitte legen Sie diese fest."])},
  "move.success_moved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortschritt verschoben"])},
  "notification.contact_ws_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte kontaktieren sie Ihren administrator."])},
  "notifications.administrator_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator hinzugefügt."])},
  "notifications.comment_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar gelöscht"])},
  "notifications.comment_undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückgängig machen"])},
  "notifications.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
  "notifications.excel_export_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Bericht ist in Vorbereitung und wird Ihnen per E-Mail zugesandt, sobald er fertig ist."])},
  "notifications.excel_export_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Bericht ist angefordert und wird in Kürze heruntergeladen."])},
  "notifications.groups_removed_from_ws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe aus Bereich entfernt"])},
  "notifications.link_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link kopiert."])},
  "notifications.lost_group_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es scheint, dass Sie den Zugang zu dieser Gruppe verloren haben. Bitte kontaktieren Sie Ihren Plugin-Administrator"])},
  "notifications.oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops…"])},
  "notifications.plugin_version_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Plugin-Version wurde kopiert."])},
  "notifications.role_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle geändert."])},
  "notifications.role_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle erfolgreich aktualisiert."])},
  "notifications.user_deleted": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Benutzer gelöscht."]), _normalize(["Benutzer gelöscht."])])},
  "objective.a_moment_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vor Kurzem"])},
  "objective.activity_log_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Aktivitäten"])},
  "objective.add_key_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key Result hinzufügen"])},
  "objective.btn.add_nested_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingebundenes Ziel hinzufügen"])},
  "objective.btn.custom_weights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierte Gewichtung"])},
  "objective.btn.no_nested_objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine verschachtelten Elemente"])},
  "objective.btn.read_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast Leserechte"])},
  "objective.by_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nach Status"])},
  "objective.cant_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können das Ziel nicht verschieben"])},
  "objective.chart.daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täglich"])},
  "objective.chart.downloadCSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV herunterladen"])},
  "objective.chart.download_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herunterladen .xlsx"])},
  "objective.chart.fullSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollbild"])},
  "objective.chart.fullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollbild"])},
  "objective.chart.monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlich"])},
  "objective.chart.no_items_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nichts ausgewählt"])},
  "objective.chart.progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortschritt"])},
  "objective.chart.quarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vierteljährlich"])},
  "objective.chart.weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wöchentlich"])},
  "objective.chart.years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahre"])},
  "objective.chart_table.split": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Beitragenden aufteilen"])},
  "objective.clear_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe löschen"])},
  "objective.columns_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen für Spalten"])},
  "objective.columns_settings.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spalten"])},
  "objective.complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständig"])},
  "objective.copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel kopieren"])},
  "objective.hours_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vor einigen Stunden"])},
  "objective.incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unvollständig"])},
  "objective.key_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key results"])},
  "objective.level_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die OKR-level ist nicht vorhanden. Bitte kontaktiere den Support unter <a href='mailto:support", "@", "oboard.io'><strong>support", "@", "oboard.io</strong></a>"])},
  "objective.link_to_another_okr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link zu einem anderen OKR"])},
  "objective.link_to_parent_okr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übergeordnetes Ziel verlinken"])},
  "objective.make_okr_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur für Owner und Stakeholder sichtbar machen"])},
  "objective.make_okr_public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für alle sichtbar machen"])},
  "objective.modal_title.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel Name"])},
  "objective.move_all_count_okrs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Alle ", _interpolate(_named("count")), " OKR verschieben"]), _normalize(["Alle ", _interpolate(_named("count")), " OKRs verschieben"])])},
  "objective.move_nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschieben verschachtelter Elemente"])},
  "objective.move_objective": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("displayId")), " zu einem anderen WS verschieben"])},
  "objective.move_objective_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sich sicher, dass Sie Ihr Ziel verschieben wollen:"])},
  "objective.no_key_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Key Results"])},
  "objective.no_key_results_for_selected_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für das ausgewählte ziele sind keine key results verfügbar."])},
  "objective.no_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Owner"])},
  "objective.obj_cant_move": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das Ziel kann nicht verschoben werden, wenn verschachtelte Elemente vorhanden sind, da eines der verschachtelten Elemente ", _interpolate(_named("closedStatus")), " oder ", _interpolate(_named("abandonedStatus")), " ist"])},
  "objective.obj_cant_move_because_kr": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das Ziel kann nicht verschoben werden, da eines der verschachtelten Elemente ", _interpolate(_named("closedStatus")), " oder ", _interpolate(_named("abandonedStatus")), " ist"])},
  "objective.search_objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach ID oder Namen suchen"])},
  "objective.select_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner auswählen"])},
  "objective.select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe auswählen"])},
  "objective.select_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level auswählen"])},
  "objective.sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortieren"])},
  "objective.sorting_drag_blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(drag'n'drop ist blockiert)"])},
  "objective.sorting_switch_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untergeordnete sortieren"])},
  "objective.weights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewichtung:"])},
  "objective.you_cant_make_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können das Ziel nicht privat machen. Es muss einen Owner oder Stakeholder haben."])},
  "objectives.binary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binär"])},
  "objectives.days_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vor einigen Tagen"])},
  "objectives.due_date.end_of_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zyklusende"])},
  "objectives.due_date.start_of_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start des zyklus"])},
  "objectives.krs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KRs"])},
  "objectives.last_count_items_shown": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die letzten ", _interpolate(_named("count")), " ", _interpolate(_named("entity")), " werden angezeigt. Um mehr anzuzeigen, suchen Sie bitte mit der vollständigen ID oder dem Namen."])},
  "objectives.number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
  "objectives.okr": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["OKR"]), _normalize(["OKRs"])])},
  "objectives.percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prozentsatz"])},
  "objectives.period_mode.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum modus"])},
  "objectives.predicted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erwartet ", _interpolate(_named("symbol"))])},
  "objectives.status_indicator.description.grade": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("grade")), " - Aktuelle Bewertung"])},
  "objectives.status_indicator.description.passed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("passed")), " - Die Zeit ist vergangen"])},
  "objectives.status_indicator.description.predicted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("predicted")), " - Erwartet (", _interpolate(_named("grade")), "/", _interpolate(_named("passed")), ")*100"])},
  "objectives.table_header_assignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
  "objectives.table_header_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentare"])},
  "objectives.table_header_contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beitragen"])},
  "objectives.table_header_currentTarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuell / Ziel"])},
  "objectives.table_header_dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten"])},
  "objectives.table_header_duedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fälligkeitsdatum"])},
  "objectives.table_header_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
  "objectives.table_header_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschritt"])},
  "objectives.table_header_gradeUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortschritt aktualisiert"])},
  "objectives.table_header_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen"])},
  "objectives.table_header_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zyklus"])},
  "objectives.table_header_labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labels"])},
  "objectives.table_header_lastGradeUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortschritt aktualisiert"])},
  "objectives.table_header_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziele und Key Results"])},
  "objectives.table_header_privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
  "objectives.table_header_stakeholders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stakeholder"])},
  "objectives.table_header_startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum"])},
  "objectives.table_header_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "objectives.table_header_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wgt"])},
  "objectives.type_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen"])},
  "objectives.type_GROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
  "objectives.type_PERSONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönlich"])},
  "objectives.unavailable_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast für dieses Zielwerk keine Berechtigung. Bitte kontaktiere den Jira Administrator."])},
  "okr_element.rename.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Namen eingeben"])},
  "okr_elements.kr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KR"])},
  "okr_elements.objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel"])},
  "okr_elements.objectives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziele"])},
  "onboarding.admins.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Administratoren hinzufügen"])},
  "onboarding.demo.product_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktdemo"])},
  "onboarding.demo.product_demo.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchen Sie Ihre kostenlose Demo-Sitzung und entdecken Sie die effektive Implementierung von OKRs in verschiedenen Maßstäben."])},
  "onboarding.demo.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treffen Sie einen unserer Experten"])},
  "onboarding.existed_admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR Board Admins"])},
  "onboarding.invite_admins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie den Admin ein"])},
  "onboarding.okr_consulting.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lassen Sie Ihre OKRs überprüfen und verbessern Sie Ihr OKR-Lernen mit den besten Coaches."])},
  "onboarding.setup_name.input_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihren Namen ein"])},
  "onboarding.setup_name.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto einrichten"])},
  "onboarding.step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schritt"])},
  "onboarding.subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnieren Sie den Oboard-Newsletter mit wichtigen Produkt-Updates, Angeboten und Ankündigungen zu bevorstehenden Veranstaltungen und Webinaren. Etwa 1 pro Monat, kein Spam."])},
  "onboarding.tutorial.admin_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administratoren-Einrichtungsanleitung"])},
  "onboarding.tutorial.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videoanleitung ansehen"])},
  "onboarding.tutorial.user_guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerhandbuch"])},
  "onboarding.welcome.rerun_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn ein Benutzer manuell hinzugefügt wird, kann die Rolle dort ebenfalls ausgewählt werden und bei Bedarf jederzeit geändert werden."])},
  "onboarding.welcome.rerun_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihnen wurde die Rolle des Administrators ", _interpolate(_named("break")), " im OKR-Board für Jira zugewiesen"])},
  "onboarding.welcome.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir freuen uns, dich in unserer App zu haben. Lass uns gemeinsam unsere Reise beginnen."])},
  "onboarding.welcome.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Herzlich willkommen, ", _interpolate(_named("userName"))])},
  "organization.create_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Der Organisationsname wird nur für Ihr Team sichtbar sein. ", _interpolate(_named("break")), "Sie können ihn in Zukunft ändern."])},
  "organization.create_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation einrichten"])},
  "organization.invite_users.email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie eine oder mehrere E-Mail-Adressen ein, getrennt durch ein Komma oder ein Leerzeichen"])},
  "organization.invite_users.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer einladen"])},
  "organization.name.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Organisation"])},
  "organization.name.updated_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisationsname erfolgreich aktualisiert"])},
  "pagination.entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einträge"])},
  "pagination.of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
  "pagination.owners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owners"])},
  "pagination.show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigen"])},
  "period_mode.auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisch (bottom-up)"])},
  "period_mode.auto.tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Geplante Start- und Enddaten ", _interpolate(_named("break")), " basierend auf seinen verschachtelten Elementen"])},
  "period_mode.manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuell"])},
  "period_mode.manual.tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Start- und Enddaten ", _interpolate(_named("break")), " manuell geplant"])},
  "placeholder.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen"])},
  "placeholder.search_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchergebnisse"])},
  "placeholder.search_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzersuche"])},
  "release_updates.footer_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Updates finden Sie unter:"])},
  "release_updates.link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changelog öffnen"])},
  "roadmap.backlog_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roadmap ist für den backlog-zyklus nicht verfügbar"])},
  "roadmap.backlog_error.list.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die folgenden OKRs oder ihre verschachtelten elemente befinden sich im backlog-zyklus"])},
  "roadmap.export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportieren"])},
  "roadmap.export.max_width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale Breite für Bild:"])},
  "roadmap.export.max_width_item": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["• ", _interpolate(_named("app")), " – ", _interpolate(_named("width")), " Pixel"])},
  "roadmap.export.safari_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Safari-Browser unterstützt die Exportfunktion nicht vollständig. Bitte verwenden Sie Chrome oder Firefox."])},
  "roadmap.export.width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breite eines Teils:"])},
  "roadmap.export_beta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roadmap exportieren (Beta)"])},
  "roadmap.export_option.full.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganze Roadmap"])},
  "roadmap.export_option.multiple.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportieren als mehrere Bilder"])},
  "roadmap.export_option.visible.hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur das exportieren, was Sie auf dem Bildschirm sehen"])},
  "roadmap.export_option.visible.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sichtbarer Teil"])},
  "roadmap.invalid_dates_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum muss vor dem fälligkeitsdatum liegen"])},
  "roadmap.quarter_shortcut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q"])},
  "roadmap.start_and_due_dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start- und fälligkeitsdaten"])},
  "roadmap.updated_timeline_success_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("dateProp")), " erfolgreich aktualisiert."])},
  "roadmap.views.months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monate"])},
  "roadmap.views.quarters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viertel"])},
  "roadmap.views.weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wochen"])},
  "roles.admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "roles.group_leader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group leader"])},
  "roles.not_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht ausgewählt"])},
  "roles.owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
  "roles.read_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read only"])},
  "roles.suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspended"])},
  "roles.user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
  "roles.user_no_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User/no delete"])},
  "roles.workspace_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace admin"])},
  "saving_indicator.saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gespeichert"])},
  "saving_indicator.saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird gespeichert ..."])},
  "search_criteria.reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchkriterien zurücksetzen"])},
  "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ausgewählt"])},
  "settings.billing.header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])},
  "settings.company.adjust_thresholds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passen Sie die Schwellenwerte, die Statuses darstellen, an, indem Sie die Punkte auf der Linie verschieben"])},
  "settings.company.alignment_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguration der OKR-Hierarchie"])},
  "settings.company.alignment_settings_switch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erlauben, ", _interpolate(_named("objective")), " und ", _interpolate(_named("keyResult")), " mit dem key result verknüpft werden"])},
  "settings.company.default_states_okr_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschachtelte Ziele tragen zu ihren Elternelementen bei."])},
  "settings.company.default_states_tasks_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschachtelte Jira-Issues tragen zu ihren elternelementen bei."])},
  "settings.company.default_states_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard-Zustände"])},
  "settings.company.default_user_role_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn ein Benutzer manuell hinzugefügt wird, kann die Rolle dort ausgewählt und bei Bedarf jederzeit geändert werden."])},
  "settings.company.header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systemeinstellungen"])},
  "settings.company.homepage_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Option der Fortschrittsüberprüfung wird für reguläre Benutzer deaktiviert. ", _interpolate(_named("break")), "Nur der WS-Administrator und die Teamleiter können den Fortschritt verfolgen."])},
  "settings.company.homepage_switch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Beobachtung des Fortschritts anderer Personen blockieren"])},
  "settings.company.homepage_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR-Homepage-Einstellungen"])},
  "settings.company.okr_grading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR-Bewertungsansatz"])},
  "settings.company.ws_admins_allowed_entities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alle Bereiche"])},
  "settings.company.ws_admins_manage_permission": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Erlauben Sie Arbeitsbereichsadministratoren, Benutzer und Gruppen in ", _interpolate(_list(0)), " zu verwalten."])},
  "settings.get_email_updates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten Sie E-Mail-Updates für OKR-Aktivitäten, wenn"])},
  "settings.learn_more_email_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfahren Sie mehr über E-Mail-Benachrichtigungen"])},
  "settings.private_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Bereiche"])},
  "settings.public_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffentliche Bereich"])},
  "settings.roles_in_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen in den Bereichen"])},
  "settings.synchronization.custom_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierte Einstellungen"])},
  "settings.synchronization.delete_custom_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierte Einstellungen löschen"])},
  "settings.synchronization.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mit der Synchronisierung können Sie die Fälligkeitsdaten in der OKR-Roadmap mit den entsprechenden Vorgangs-Feldern in Jira synchronisieren. Die hier definierten Einstellungen gelten für alle Vorgänge, die zu OKRs hinzugefügt wurden. ", _interpolate(_named("break")), "Wenn diese Option aktiviert ist, erfolgt die Aufgabensynchronisierung bidirektional und sofort. Wenn Sie zum Beispiel einen Vorgang mit Hilfe der OKR-Roadmap verschieben, werden die Felder \"Startdatum\" und \"Enddatum\" Ihres Vorgangs aktualisiert. Wenn Sie umgekehrt die Felder \"Startdatum\" oder \"Enddatum\" in der Vorgangs-Ansicht von Jira bearbeiten, werden die Vorgangsänderungen aktualisiert und in der OKR-Roadmap angezeigt."])},
  "settings.synchronization.general_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Einstellungen"])},
  "settings.synchronization.jira_replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Jira-Werte werden durch OKR-Werte 1 zu 1 ersetzt (überschrieben)."])},
  "settings.synchronization.keep_value_jira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den ursprünglichen Wert von Jira beibehalten, wenn der OKR-Wert null ist."])},
  "settings.synchronization.keep_value_okr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den ursprünglichen Wert von OKR beibehalten, wenn der Jira-Wert null ist."])},
  "settings.synchronization.not_synchronized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht synchronisiert"])},
  "settings.synchronization.oboard_replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle OKR-Werte werden durch Jira-Werte 1 zu 1 ersetzt (überschrieben)."])},
  "settings.synchronization.project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt"])},
  "settings.synchronization.save_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync-Einstellungen speichern"])},
  "settings.synchronization.select.end_date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feld Enddatum"])},
  "settings.synchronization.select.end_date_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie das Enddatum aus"])},
  "settings.synchronization.select.start_date_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feld Startdatum"])},
  "settings.synchronization.select.start_date_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie ein Startdatum aus"])},
  "settings.synchronization.select_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt auswählen"])},
  "settings.synchronization.select_synchronisation_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronisationsart auswählen"])},
  "settings.synchronization.sync_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronisierung im gange..."])},
  "settings.synchronization.synchronization": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie die benutzerdefinierten Synchronisierungseinstellungen für ", _interpolate(_named("projectName")), " löschen?"])},
  "settings.when_someone_manually_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn jemand manuell den Status ändert oder einen Kommentar in den OKRs hinterlässt, bei denen:"])},
  "setup_groups.message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dies ist eine Standardorganisationsstruktur. ", _interpolate(_named("break")), "Sie können sie später im Einstellungsbereich anpassen."])},
  "setup_groups.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen einrichten"])},
  "setup_intervals.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR-Intervalle einrichten"])},
  "setup_okr_levels.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR-Levels einrichten"])},
  "setup_workspace.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können Ihren Arbeitsbereich später umbenennen und weitere hinzufügen, wenn Sie fortfahren."])},
  "setup_workspace.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich einrichten"])},
  "stand_with_ukraine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beistand für die Ukraine"])},
  "status.backlog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backlog"])},
  "status.none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erledigt"])},
  "status.switch_auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto"])},
  "status.switch_auto_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-Status"])},
  "status.switch_equal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gleich"])},
  "status.switch_manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuell"])},
  "status.switch_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir werden den Status auf Grundlage von Fortschritt und Zeitplan verändern"])},
  "statuses.close_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status 'Geschlossen'"])},
  "statuses.close_statuses.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Status 'Geschlossen' stoppt Aktualisierungen des Fortschritts und verhindert das Bearbeiten der OKR"])},
  "statuses.notifications.renamed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status erfolgreich umbenannt"])},
  "statuses.progress_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status des Fortschritts"])},
  "statuses.rename_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status umbenennen"])},
  "statuses.status_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Name"])},
  "step_with_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Schritt ", _interpolate(_named("count")), "."])},
  "subscription.address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "subscription.address_line_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresszeile 1"])},
  "subscription.address_line_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresszeile 2"])},
  "subscription.amount_due": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Heute fällig ", _interpolate(_named("value"))])},
  "subscription.annually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jährlich"])},
  "subscription.annually_save_count_percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jährlich 15% SPAREN"])},
  "subscription.applied_balance": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Angewendetes Guthaben ", _interpolate(_named("value"))])},
  "subscription.apply_discount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percent")), " Rabatt anwenden"])},
  "subscription.back_to_billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Abrechnung"])},
  "subscription.billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsadresse"])},
  "subscription.billing_cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnungszyklus"])},
  "subscription.billing_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnungsdatum"])},
  "subscription.bills_on_date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rechnungen am ", _interpolate(_named("date")), " für 1 Monat"])},
  "subscription.book_meeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchen Sie ein Meeting"])},
  "subscription.business_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftliche E-Mail"])},
  "subscription.cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement kündigen"])},
  "subscription.choose_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan wählen"])},
  "subscription.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
  "subscription.company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmenname"])},
  "subscription.company_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensgröße"])},
  "subscription.compare_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pläne vergleichen"])},
  "subscription.contact_oboard_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oboard-Team kontaktieren"])},
  "subscription.contact_your_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktieren Sie Ihren Manager"])},
  "subscription.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "subscription.current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current plan"])},
  "subscription.current_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Ebene"])},
  "subscription.days_remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tage verbleibend"])},
  "subscription.discount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("percent")), " Rabatt"])},
  "subscription.downgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downgrade"])},
  "subscription.downgrade_to_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herunterstufen auf jährlich"])},
  "subscription.drop_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht hinterlassen"])},
  "subscription.expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablaufdatum"])},
  "subscription.expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Läuft ab:"])},
  "subscription.final_amount_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Der endgültige Betrag wird beim Checkout festgelegt"])},
  "subscription.from_to_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("from")), " von ", _interpolate(_named("to")), " Benutzern"])},
  "subscription.get_your_price_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holen Sie sich Ihr Preisangebot"])},
  "subscription.invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung"])},
  "subscription.invoice_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungshistorie"])},
  "subscription.is_canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement starten"])},
  "subscription.leave_anyway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trotzdem verlassen"])},
  "subscription.leaving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es tut uns leid, dass Sie uns verlassen."])},
  "subscription.license_inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenz inaktiv"])},
  "subscription.manage_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan verwalten"])},
  "subscription.manage_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ebene verwalten"])},
  "subscription.monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlich"])},
  "subscription.next_bill_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächstes Rechnungsdatum"])},
  "subscription.next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste Zahlung"])},
  "subscription.next_payment_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächstes Zahlungsdatum"])},
  "subscription.number_of_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Benutzer"])},
  "subscription.number_of_users_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können jederzeit weitere Benutzer hinzufügen. Diese zusätzlichen Benutzer werden Ihnen nur im nächsten Abonnementzeitraum automatisch in Rechnung gestellt."])},
  "subscription.payment_incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung unvollständig. Überprüfen Sie Ihr Guthaben oder aktualisieren Sie die Zahlungsmethode."])},
  "subscription.payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsart"])},
  "subscription.per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pro Monat"])},
  "subscription.per_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pro Benutzer"])},
  "subscription.per_user_billed_monthly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("usersCount")), " Benutzer, $", _interpolate(_named("price")), " pro Benutzer, monatlich abgerechnet"])},
  "subscription.phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
  "subscription.popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beliebt"])},
  "subscription.postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
  "subscription.receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quittung"])},
  "subscription.renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneuern"])},
  "subscription.save_count_percent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sparen Sie ", _interpolate(_named("count")), "%"])},
  "subscription.schedule_free_demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinbaren Sie eine kostenlose Demo-Sitzung und erfahren Sie, wie OKRs in verschiedenen Maßstäben richtig implementiert werden."])},
  "subscription.select_subscription_cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen Abonnementzyklus"])},
  "subscription.select_user_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerebene auswählen"])},
  "subscription.select_user_tier_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können die Benutzerebene jederzeit aktualisieren. Wenn Sie die Benutzerebene aktualisieren, wird Ihrem Konto ein anteiliger Betrag basierend auf dem Prozentsatz des verbleibenden Abrechnungszyklus berechnet, wenn die Ebene aktualisiert wurde."])},
  "subscription.state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundesland"])},
  "subscription.subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnieren"])},
  "subscription.subscription_canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Abonnement ist gekündigt"])},
  "subscription.subscription_free_trial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenlose Testversion"])},
  "subscription.subscription_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement erfolgreich aktualisiert"])},
  "subscription.subscription_wasnt_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement wurde nicht aktualisiert"])},
  "subscription.subscription_will_be_downgraded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abonnement wird am Ende des Zyklus auf ", _interpolate(_named("name")), " ", _interpolate(_named("cycle")), " herabgestuft."])},
  "subscription.switch_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan wechseln"])},
  "subscription.switch_to_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Jahresabo wechseln"])},
  "subscription.switch_to_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Monatsabo wechseln"])},
  "subscription.tax_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USt-ID"])},
  "subscription.tier_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Benutzeranzahl überschreitet das Ebenenlimit. Aktualisieren Sie Ihre Ebene oder löschen Sie Benutzer"])},
  "subscription.tier_upper_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Ebenenobergrenze kann nicht weniger als die aktuelle Benutzeranzahl sein."])},
  "subscription.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])},
  "subscription.total_pay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gesamt: ", _interpolate(_named("value"))])},
  "subscription.try_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sparen Sie 15%. Abonnieren Sie den Jahresplan."])},
  "subscription.up_sale_promocode": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bitte erwägen Sie ", _interpolate(_named("discount")), " bei uns zu bleiben"])},
  "subscription.up_to_count_users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bis zu ", _interpolate(_named("count")), " Benutzer"])},
  "subscription.upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade"])},
  "subscription.upgrade_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade tier"])},
  "subscription.upgrade_to_annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Jahresabo upgraden"])},
  "subscription.upgrade_to_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Monatsabo upgraden"])},
  "subscription.upgrade_your_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade your tier"])},
  "subscription.upgrade_your_tier_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Die Benutzeranzahl überschreitet das Ebenenlimit. ", _interpolate(_named("day")), " Tag übrig, um Ihre Ebene zu aktualisieren oder Benutzer zu löschen."]), _normalize(["Die Benutzeranzahl überschreitet das Ebenenlimit. ", _interpolate(_named("day")), " Tage übrig, um Ihre Ebene zu aktualisieren oder Benutzer zu löschen."])])},
  "subscription.user_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerebene"])},
  "subscription.user_tier_billed_annually": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Benutzerebene ", _interpolate(_named("label")), ", jährlich abgerechnet"])},
  "subscription.web_app_only_delete_from_oboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Web-App-Benutzer werden von Oboard gelöscht"])},
  "subscription.your_current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr aktueller Plan"])},
  "subscription.your_current_tier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr aktueller Tier"])},
  "subscription.your_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Name"])},
  "support.btn_write_to_support_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktieren Sie uns"])},
  "support.consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulting"])},
  "support.contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktieren Sie uns"])},
  "support.define_your_okr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define your OKR readiness level"])},
  "support.define_your_okr.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Level bestimmen"])},
  "support.define_your_okr.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluate your organisation's readiness, culture, and capabilities to effectively implement OKRs."])},
  "support.help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe-Center"])},
  "support.help_center.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antworten auf häufig gestellte Fragen finden."])},
  "support.help_center.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe-Center besuchen"])},
  "support.help_center.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antworten auf häufig gestellte Fragen finden Sie in unserem Confluence Hilfe-Center."])},
  "support.instance_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Instanz-ID:"])},
  "support.okr_consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR \nconsulting"])},
  "support.okr_consulting.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchberatung"])},
  "support.okr_consulting.link.text.next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book free OKR review"])},
  "support.okr_consulting.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boost your OKR learning curve with Oboard's OKR consultants training, and workshops."])},
  "support.okr_review.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchen Sie einen Anruf mit OKR-Coach"])},
  "support.onboarding_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onboarding call"])},
  "support.onboarding_call.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchen Sie einen Anruf"])},
  "support.onboarding_call.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starten Sie Ihren ersten 90-Tage-OKR-Zyklus mit vollständiger Produktunterstützung."])},
  "support.plugin_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Plugin-Version:"])},
  "support.response_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir versuchen alle unsere Supportanfragen innerhalb eines Geschäftstags zu beantworten. Oboard befindet sich in Mitteleuropa und unsere offiziellen"])},
  "support.security_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud-Security-Programm"])},
  "support.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir freuen uns auch über Vorschläge, Funktionsideen und allgemeine Kommentare zu unserem Plugin"])},
  "support.support.subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erstellen sie hier ein support-ticket oder schreiben sie uns eine E-Mail an ", _interpolate(_named("emailAddress"))])},
  "support.support_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supportzeiten sind 9:00 – 17:00 Uhr von Montag – Freitag in der Zeitzone GMT+1"])},
  "support.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support-Seite"])},
  "support.video_guides": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video guides"])},
  "support.video_guides.link.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video abspielen"])},
  "support.video_guides.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehen Sie sich unsere Anleitungen an, um sicherzustellen, dass Sie unsere App optimal nutzen"])},
  "table.no_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Gegenstände"])},
  "text_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In die Zwischenablage kopiert"])},
  "token.copy.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Api-Token"])},
  "token.copy.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellen Sie sicher, dass Sie Ihr neues API-Token kopieren. Sie werden dieses Token nicht mehr sehen können."])},
  "token.copy.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr neues API-Token"])},
  "token.create.label_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie ein Label, das kurz und einprägsam ist und das Sie sich leicht merken können."])},
  "token.create.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein API-Token erstellen"])},
  "token.minutes_ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minuten her"])},
  "token.toast.revoke_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr API-Token wurde widerrufen"])},
  "tokens.create_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token erstellen"])},
  "tokens.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie können ein API-Token nutzen, um die Authentifizierung mit der OKR-Board-Anwendung durchzuführen. ", _interpolate(_named("break")), "Sie sollten API-Token so sicher aufbewahren wie jedes andere Passwort. Sie haben ", _interpolate(_named("usedTokensCount")), " von 10 Token benutzt."])},
  "tokens.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token-Name"])},
  "tokens.limit_exceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie haben die maximale Anzahl (", _interpolate(_named("limit")), ") an API-Token erreicht"])},
  "tokens.no_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Token"])},
  "tokens.no_tokens_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie das erste Token, um loszulegen"])},
  "tokens.token_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#"])},
  "tour.got_it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles klar!"])},
  "tour.next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
  "tour.skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tour überspringen"])},
  "tour.step1.content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können Sie ein neues Ziel erstellen."])},
  "tour.step1.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Ziel erstellen"])},
  "tour.step2.content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können Sie Ziele oder Key Result hinzufügen oder Ihr Jira-Issue mit einem OKR verknüpfen."])},
  "tour.step2.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues ziel oder KR hinzufügen"])},
  "tour.step3.content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können Sie den Fortschritt für das KR ändern. Der Fortschritt von Jira-Issues wird automatisch berechnet."])},
  "tour.step3.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Note ändern"])},
  "tour.step4.content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie Ihre Gruppenkollegen hinzu und verwalten Sie Gruppen."])},
  "tour.step4.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppenmitglieder hinzufügen"])},
  "tree.fit_content_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Größe des Inhalts anpassen"])},
  "tree.legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legende"])},
  "tree.legend.hide_filtered_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefilterte Elemente ausblenden"])},
  "tree.legend.not_contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trägt nicht zur übergeordneten Bewertung bei"])},
  "tree.legend.show_child_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untergeordnete Elemente anzeigen"])},
  "tree.legend.show_filtered_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefilterte Elemente anzeigen"])},
  "tree.switch_to_compact_card_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umschalten auf kompakte Kartenansicht"])},
  "tree.switch_to_detailed_card_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umschalten auf detaillierte Kartenansicht"])},
  "units.px": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P"])},
  "unlink_jira_issue.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfung zu Jira eintrag aufheben"])},
  "update.keyResult.result_type_lock_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fortschritt kann nicht bearbeitet werden, da er auf verschachtelten Elementen basiert"])},
  "update.keyResult.result_type_lock_message_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie zum manuellen Modus zurückkehren möchten, schalten Sie den Beitrag aller verschachtelten Elemente aus."])},
  "update.keyResult.result_type_lock_message_description_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beitrag ausschalten"])},
  "user.group.sync.tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Gruppe kann nicht ausgewählt werden, da sie Nutzer aus verbundenen ", _interpolate(_named("platform")), "-Gruppen importiert."])},
  "user.personal_settings.about_qty_every_months": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Über ", _interpolate(_named("qty")), " alle ", _interpolate(_named("repeat")), " Monate"])},
  "user.personal_settings.about_qty_per_month": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Etwa ", _interpolate(_named("qty")), " pro Monat"])},
  "user.personal_settings.dont_have_email_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben keinen Zugriff auf Ihre E-Mail-Adresse"])},
  "user.personal_settings.mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["@", " erwähnt"])},
  "user.personal_settings.other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstiges"])},
  "user.personal_settings.please_contact_our": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bitte kontaktieren Sie unseren ", _interpolate(_named("contact")), " für weitere Informationen"])},
  "user.personal_settings.product_case_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipps zur Zusammenarbeit, Fallstudien, Tutorials und viele aufschlussreiche Artikel."])},
  "user.personal_settings.product_promos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankündigung von Promos und anstehenden Veranstaltungen und Webinaren."])},
  "user.personal_settings.product_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir senden Ihnen einen Newsletter mit wichtigen Produkt-Updates in Oboard."])},
  "user.personal_settings.reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["antworten"])},
  "user.personal_settings.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profileinstellungen"])},
  "user.personal_settings.when_someone_reply_me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn jemand auf deinen Kommentar oder dein Update antwortet oder dich mit ", "@", "username erwähnt."])},
  "user.personal_settings.you_are_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind der Owner"])},
  "user.personal_settings.you_are_stakeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind der Stakeholder"])},
  "user.preferences.change_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
  "user.preferences.default_group": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Standard-Gruppe"]), _normalize(["Standard-Gruppen"])])},
  "user.preferences.no_groups_for_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt keine Gruppen, denen Sie angehören"])},
  "user.preferences.user.email.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "user.preferences.user.name.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "user.selected_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selektierte Gruppen"])},
  "user_details.add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch ein Bereich"])},
  "user_details.add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Bereich hinzufügen"])},
  "user_details.add_user.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Benutzer zum Bereich mit einer ", _interpolate(_named("break")), "bestimmten Gruppe hinzufügen"])},
  "user_details.delete_user_from_synced_group": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Um Benutzer zu löschen, entfernen Sie sie bitte aus der entsprechenden synchronisierten ", _interpolate(_named("platform")), "-Gruppe."])},
  "user_details.delete_user_from_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Um einen Benutzer aus ", _interpolate(_named("workspaceName")), " zu löschen, entfernen Sie ihn bitte aus der entsprechenden synchronisierten ", _interpolate(_named("platform")), "-Gruppe."])},
  "user_details.no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Benutzer ist in keinem Bereich"])},
  "user_details.platform_profile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("platform")), "-Profil"])},
  "user_details.select_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen auswählen"])},
  "user_menu.create_organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Organisation erstellen"])},
  "user_menu.logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausloggen"])},
  "user_menu.profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "user_menu.switch_org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Organisationen"])},
  "users.add_another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr hinzufügen"])},
  "users.add_to_group": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Benutzer zu gruppe hinzufügen"]), _normalize(["Benutzer zu gruppen hinzufügen"])])},
  "users.add_to_group_userName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("userName")), " zu gruppe hinzufügen"]), _normalize([_interpolate(_named("userName")), " zu gruppen hinzufügen"])])},
  "users.add_to_workspace": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Benutzer zum arbeitsbereich hinzufügen"]), _normalize(["Benutzer zum arbeitsbereich hinzufügen"])])},
  "users.add_to_workspace_without_group": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Benutzer wird ohne Gruppe hinzugefügt"]), _normalize(["Benutzer werden ohne Gruppe hinzugefügt"])])},
  "users.add_users_to_workspace": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Ausgewählten Benutzer zum Bereich hinzufügen"]), _normalize([_interpolate(_named("count")), " ausgewählter Benutzer zum Bereich hinzufügen"])])},
  "users.add_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace hinzufügen"])},
  "users.all_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Bereiche"])},
  "users.bulk_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulk hinzufügen"])},
  "users.change_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardgruppe ändern"])},
  "users.change_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle ändern"])},
  "users.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer hinzufügen"])},
  "users.delete_user_from_plugin": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Um ausgewählte Benutzer zu löschen, entfernen Sie sie bitte aus der entsprechenden synchronisierten ", _interpolate(_named("platform")), "-Gruppe."])},
  "users.import_users_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importieren Sie Benutzer aus der Jira-Gruppe einmal. Importierte Benutzer können jederzeit manuell entfernt werden"])},
  "users.invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer einladen"])},
  "users.link_jira_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira-groups verbinden"])},
  "users.link_jira_groups_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfen Sie eine Jira-Gruppe mit dem OKR-Board. Alle Änderungen an der Jira-Gruppe werden automatisch in die OKR-Board-Gruppe gezogen. Verknüpfte Benutzer können nicht manuell entfernt werden"])},
  "users.no_matching_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt keine Benutzer, die den angewendeten Filtern entsprechen."])},
  "users.roles.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen"])},
  "users.roles.total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("qty")), " rollen"])},
  "users.select_import_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importoptionen auswählen"])},
  "users.select_jira_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jira-Gruppe auswählen"])},
  "users.table_header_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "users.table_header_role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle"])},
  "validation.input_number.allowed_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erlaubte ", _interpolate(_named("integerMaxLength")), " Zeichen"])},
  "validation.input_number.allowed_max_characters_before_dot": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erlaubte ", _interpolate(_named("integerMaxLength")), " Zeichen vor Punkt"])},
  "validation.input_number.allowed_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlaubte Zahlen, Punkt, Komma"])},
  "validation.input_number.allowed_one_dot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlaubt ist ein Punkt"])},
  "validation.input_number.allowed_one_minus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erlaubt ist ein Minus"])},
  "validation.input_number.integer_begins_with_zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganzzahl beginnt mit Null"])},
  "view_select.alignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alignment Ansicht"])},
  "view_select.explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste explorer"])},
  "view_select.list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste"])},
  "view_select.mindmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OKR-Karte"])},
  "view_select.roadmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roadmap"])},
  "weights.objective.cant_change_weight_by_nested_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um benutzerdefinierte Gewichte zu verwenden, müssen Sie mindestens 1 verschachteltes Element haben."])},
  "weights.objective.cant_change_weight_by_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbar im Professional-Abonnementplan oder höher."])},
  "weights.objective.cant_change_weight_desc": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie können die Gewichte im ", _interpolate(_named("status")), "-Ziel nicht ändern.\nÖffnen Sie das Ziel erneut, um benutzerdefinierte Gewichte zu bearbeiten"])},
  "weights.objective.manual.description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("mode")), " Um Ihre benutzerdefinierten OKR-Gewichte zu erhalten, werden alle neuen OKRs mit einem Gewicht von 0 hinzugefügt. Ihr Gewicht kann danach geändert werden."])},
  "weights.objective.manual_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manueller Modus"])},
  "workspace.change_role": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Rolle für Benutzer ändern"]), _normalize(["Rolle für ", _interpolate(_named("qty")), " Benutzer ändern"])])},
  "workspace.label.add_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung hinzufügen"])},
  "workspace.label.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "workspace.label.key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüssel"])},
  "workspace.label.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereichsname"])},
  "workspace.select_timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitzone auswählen"])},
  "workspace.timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitzone"])},
  "workspaces.action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion"])},
  "workspaces.add_users_to_group": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Benutzer zu ", _interpolate(_named("group")), " hinzufügen"])},
  "workspaces.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereich erstellen"])},
  "workspaces.create_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Gruppe erstellen"])},
  "workspaces.delete_user_from_group": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sind Sie sicher, dass Sie ", _interpolate(_named("userName")), " aus der Gruppe ", _interpolate(_named("groupName")), " löschen möchten?"])},
  "workspaces.edit_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsbereich bearbeiten"])},
  "workspaces.groups_tab.linked_groups.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Gruppen, in denen wir eine Liste von Benutzern erhalten, die zum Plugin hinzugefügt werden."])},
  "workspaces.groups_tab.linked_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verknüpfte ", _interpolate(_named("platform")), " Gruppen"])},
  "workspaces.intervals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zyklen"])},
  "workspaces.key.tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Schlüssel wird als Prefix zur Identifizierung der Objekte eines Bereichs verwendet, z.B. TE-O-203"])},
  "workspaces.linked_user_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfte Benutzer können nicht manuell entfernt werden"])},
  "workspaces.manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereich verwalten"])},
  "workspaces.manage_workspace.access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workspace-Zugriff und Rollen für alle Benutzer verwalten"])},
  "workspaces.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereichsname"])},
  "workspaces.new_workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Bereich"])},
  "workspaces.no_groups.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen einer Gruppe zum Hinzufügen von Personen zum Bereich"])},
  "workspaces.no_groups.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Gruppen"])},
  "workspaces.no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Bereiche gefunden"])},
  "workspaces.no_rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keine Rechte, einen neuen Bereich zu erstellen. Bitte wenden Sie sich an Ihren Administrator"])},
  "workspaces.no_workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch keine Bereiche"])},
  "workspaces.no_workspaces_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie den ersten Bereich, um loszulegen"])},
  "workspaces.private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private"])},
  "workspaces.public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffentliche"])},
  "workspaces.role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle im Bereich"])},
  "workspaces.select_linked_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verknüpfte ", _interpolate(_named("platform")), "-Gruppen auswählen"])},
  "workspaces.select_platform_groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wählen Sie ", _interpolate(_named("platform")), "-Gruppen"])},
  "workspaces.table.cell.groups": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Gruppe"]), _normalize([_interpolate(_named("count")), " Gruppen"])])},
  "workspaces.table.cell.intervals": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Zyklus"]), _normalize([_interpolate(_named("count")), " Zyklen"])])},
  "workspaces.table.cell.users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Benutzer"]), _normalize([_interpolate(_named("count")), " Benutzer"])])},
  "workspaces.users_from_platform": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Benutzer aus verknüpften ", _interpolate(_named("platform")), "-Gruppen"])},
  "workspaces.visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sichtbarkeit"])},
  "workspaces.workspace_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereich erstellt"])},
  "workspaces.workspace_field_updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fieldName")), " erfolgreich aktualisiert"])},
  "workspaces.workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereiche"])},
  "wysiwyg.menu_list.code_snippet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codefragment"])},
  "wysiwyg.menu_list.table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabelle"])}
}