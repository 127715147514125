<template>
  <div>
    <div class="ni-Wrapper">
      <NotificationEntity
        v-bind="getCurrentComponentProps"
        @mark-as-read="markAsRead"
        @update-notifications-statuses="updateNotificationsStatuses"
      />
      <div v-if="isOpen || showContributeLine" class="ni-ContributeLine" />
    </div>

    <template v-if="isOpen">
      <NotificationItem
        v-for="(aggregatedItem, aggregatedItemIndex) in aggregatedItems"
        :key="aggregatedItem.id"
        :item="aggregatedItem"
        :show-contribute-line="aggregatedItemIndex !== aggregatedItems.length - 1"
        :with-okr-icon="false"
        contributed-item
        @mark-as-read="markAsRead"
        @update-notifications-statuses="updateNotificationsStatuses"
      />
    </template>
    <AggregatedShowMore
      v-if="withAggregatedNotification && !contributedItem"
      :aggregated-items="aggregatedItems"
      :is-open="isOpen"
      @toggle="isOpen = !isOpen"
    />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

import { NOTIFICATION_TYPES, NOTIFICATIONS_STATUSES } from '@/utils/in-app-notifications'

import AggregatedShowMore from '@/components/ui/InAppNotifications/AggregatedShowMore'
import NotificationEntity from '@/components/ui/InAppNotifications/NotificationEntity'

defineOptions({
  name: 'NotificationItem'
})

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  showContributeLine: {
    type: Boolean
  },
  contributedItem: {
    type: Boolean
  },
  showOkrIcon: {
    type: Boolean,
    default: true
  }
})
const emit = defineEmits(['mark-as-read', 'update-notifications-statuses'])

const getCurrentComponentProps = computed(() => {
  const isChildRead = props.item.childNotifications?.every(
    item => item.statusId === NOTIFICATIONS_STATUSES.DELIVERED
  )
  let payload = {
    item: getItem.value,
    isChildRead
  }
  const getProps = {
    [NOTIFICATION_TYPES.USER_TAGGED]: {
      titleKeyPath: 'in_app_notifications.notification_title',
      showOkrIcon: props.showOkrIcon
    },
    [NOTIFICATION_TYPES.CONFIDENCE_LEVEL_CHANGE]: {
      titleKeyPath: 'in_app_notifications.notification.status.title',
      showTextBody: false,
      showStatusChanges: true,
      showOkrIcon: props.showOkrIcon
    },
    [NOTIFICATION_TYPES.COMMENT]: {
      titleKeyPath: 'in_app_notifications.notification.commented.title',
      showOkrIcon: props.showOkrIcon
    },
    [NOTIFICATION_TYPES.REPLY_COMMENT]: {
      titleKeyPath: 'in_app_notifications.notification.replied.title',
      showOkrIcon: props.showOkrIcon
    }
  }

  return { ...payload, ...getProps[getItem.value.typeId] }
})

const withAggregatedNotification = computed(() => props.item.childNotifications?.length)
const isOpen = ref(false)

const getItem = computed(() => {
  if (withAggregatedNotification.value) {
    return props.item.childNotifications[0]
  } else {
    return props.item
  }
})
const aggregatedItems = computed(
  () => props.item.childNotifications.filter((item, index) => index) || []
)

const markAsRead = payload => emit('mark-as-read', payload)
const updateNotificationsStatuses = () => emit('update-notifications-statuses')
</script>

<style lang="scss" scoped>
.ni-ContributeLine {
  width: 2px;
  height: calc(100% - 32px);
  background: $grey-2-next;
  position: absolute;
  top: 38px;
  left: 21px;
  z-index: 1;
}
.ni-Wrapper {
  position: relative;
}
</style>
