import ApiHandler from '@/api/base'

class UsersApiHandler extends ApiHandler {
  async getWorkspaceFilter({ searchString = null, workspaceIds = [] }) {
    const { data } = await this.http.get('/settings/workspaceFilter', {
      params: {
        searchString,
        workspaceIds
      }
    })
    return data
  }

  async getUserFilter({ searchString = null, workspaceIds = [], accountIds = [] }) {
    const { data } = await this.http.get('/settings/userFilter', {
      params: {
        searchString,
        workspaceIds,
        accountIds
      }
    })
    return data
  }

  async getGroupFilter({ searchString = null, workspaceIds = [], groupIds = [] }) {
    const { data } = await this.http.get('/settings/groupFilter', {
      params: {
        searchString,
        workspaceIds,
        groupIds
      }
    })
    return data
  }
  async getFilteredUsers({
    workspaceIds = [],
    accountIds = [],
    groupIds = [],
    roleIds = [],
    platformIds = [],
    startAt = null,
    limit = null
  }) {
    const { data } = await this.http.get('/settings/users', {
      params: {
        workspaceIds,
        userAccountIds: accountIds,
        groupIds,
        roleIds,
        platformIds,
        startAt,
        limit
      }
    })
    return data
  }
  async bulkRemoveUserFromPlugin({ accountIds }) {
    const { data } = await this.http.delete('/user/removeFromPlugin', {
      data: {
        accountIds
      }
    })

    return data
  }

  async addBulkUsers({
    keepSynced = false,
    sourcePlatformGroupIds = [],
    targetPluginGroupIds = [],
    workspaceId = null
  }) {
    const { data } = await this.http.post('/user/bulk', {
      keepSynced,
      sourcePlatformGroupIds,
      targetPluginGroupIds,
      workspaceId
    })
    return data
  }
}

export default UsersApiHandler
